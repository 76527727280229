<template>
  <p
    class="flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium bg-gray-200  focus:outline-none"
  >
    <span class="flex">
      <span class="mr-3">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-5 h-5"
        >
          <path :d="svgdata"></path>
        </svg>
      </span>

      <span :class="{ 'text-red-500': !isValid }">{{ title }} </span>
      <span v-if="!isValid">❗</span>
    </span>
    <button
      v-if="hasAddButton"
      class="btn-add-multi px-2 py-1"
      @click.prevent="addItem"
    >
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
    </button>
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "headerChild",
  props: {
    title: {
      type: String,
      required: true
    },
    numbersOfItems: {
      type: Number,
      required: false
    },
    svgdata: {
      type: String,
      require: true
    },
    hasAddButton: {
      type: Boolean,
      require: true,
      default: true
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    addItem() {
      this.$emit("addItem");
    }
  }
});
</script>
