import { computed, onMounted, ref, Ref } from "vue";
import { apiFabricantes } from "@/services/fabricantes/api";
import {
  FabricanteView,
  FabricanteFilter
} from "@/types/fabricantes/FabricanteView";

import {
  Fabricante,
  FabricanteDireccion,
  FabricanteEmail,
  FabricanteTelefono,
  FabricanteMarca
} from "@/types/fabricantes/Fabricante";
import { usePagination } from "@/components/usePagination";
import {
  isValidObject,
  isValidArrayOfObjects
} from "../utilidades/useValidations";
import { getTempId } from "../utilidades/useIDLibrary";

export function useFabricantes() {
  const idNotEditItem = -999;
  const items: Ref<FabricanteView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Fabricante);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);

  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");
  const filter = ref({} as FabricanteFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("fabricanteFilter", JSON.stringify(filter.value));
    items.value = await apiFabricantes.getFabricantesView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
    /*isLoading.value = true;
    items.value = await apiFabricantes.getFabricantesView();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;*/
  });

  async function editItem(id: number) {
    if (id != 0) editingItem.value = await apiFabricantes.getFabricante(id);
    else {
      editingItem.value = {
        id: 0,
        direcciones: [] as FabricanteDireccion[],
        emails: [] as FabricanteEmail[],
        telefonos: [] as FabricanteTelefono[],
        marcas: [] as FabricanteMarca[]
      } as Fabricante;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as FabricanteView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiFabricantes
      .deleteFabricante(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Fabricante;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiFabricantes
      .saveFabricante(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    if (index == -1) return;
    const tipoUpdated = await apiFabricantes.getFabricanteView(id);
    items.value[index] = tipoUpdated;
    editingItem.value = { id: idNotEditItem } as Fabricante;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addDireccion() {
    editingItem.value.direcciones.push({
      idDireccion: getTempId(editingItem.value.direcciones, "idDireccion"),
      idFabricante: editingItem.value.id,
      correspondencia: false,
      fiscal: false
    } as FabricanteDireccion);
  }

  function deleteDireccion(id: number) {
    editingItem.value.direcciones = editingItem.value.direcciones.filter(
      item => item.idDireccion != id
    );
  }

  function addEmail() {
    editingItem.value.emails.push({
      idEmail: getTempId(editingItem.value.emails, "idEmail"),
      idFabricante: editingItem.value.id,
      principal: false
    } as FabricanteEmail);
  }

  function deleteEmail(id: number) {
    editingItem.value.emails = editingItem.value.emails.filter(
      item => item.idEmail != id
    );
  }

  function addTelefono() {
    editingItem.value.telefonos.push({
      idTelefono: getTempId(editingItem.value.telefonos, "idTelefono"),
      idFabricante: editingItem.value.id
    } as FabricanteTelefono);
  }

  function deleteTelefono(id: number) {
    editingItem.value.telefonos = editingItem.value.telefonos.filter(
      item => item.idTelefono != id
    );
  }

  function addMarca() {
    editingItem.value.marcas.push({
      idMarca: getTempId(editingItem.value.marcas, "idMarca"),
      idFabricante: editingItem.value.id,
      porcentajeComision: 0,
      activa: true
    } as FabricanteMarca);
  }

  function deleteMarca(id: number) {
    editingItem.value.marcas = editingItem.value.marcas.filter(
      item => item.idMarca != id
    );
  }
  async function resetFilter() {
    filter.value = {
      inexistenteSN: "N"
    } as FabricanteFilter;
  }

  function isValid(item: Fabricante): boolean {
    return isValidObject(item, ["codigo", "nombre"]);
  }

  function isValidDirecciones(direcciones: FabricanteDireccion[]): boolean {
    return isValidArrayOfObjects(direcciones, ["domicilio"]);
  }

  function isValidEmails(emails: FabricanteEmail[]): boolean {
    return isValidArrayOfObjects(emails, ["email"]);
  }

  function isValidTelefonos(telefonos: FabricanteTelefono[]): boolean {
    return isValidArrayOfObjects(telefonos, ["telefono"]);
  }

  function isValidMarcas(telefonos: FabricanteMarca[]): boolean {
    return isValidArrayOfObjects(telefonos, ["codigo", "descripcion"]);
  }

  return {
    fabricantes: items,
    fabricantesShowed: itemsShowed,
    fabricantesFilter: filter,
    fabricantesSearch: filterItems,
    fabricantesResetFilter: resetFilter,
    fabricanteEdit: editItem,
    fabricanteAdd: addItem,
    fabricanteDelete: deleteItem,
    fabricanteCancel: cancelEdit,
    fabricanteSave: saveEdit,
    fabricanteInEdition: editingItem,
    fabricantesIndex: currentRow,
    fabricantesGotoNextPage: nextPage,
    fabricantesGoToPreviousPage: previousPage,
    fabricanteIsEditing: isEditing,
    fabricantesIsLoading: isLoading,
    fabricantesIsFirstPage: isFirstPage,
    fabricantesIsLastPage: isLastPage,
    fabricanteAddDireccion: addDireccion,
    fabricanteDeleteDireccion: deleteDireccion,
    fabricanteAddEmail: addEmail,
    fabricanteDeleteEmail: deleteEmail,
    fabricanteAddTelefono: addTelefono,
    fabricanteDeleteTelefono: deleteTelefono,
    fabricanteAddMarca: addMarca,
    fabricanteDeleteMarca: deleteMarca,
    fabricanteSuccess: success,
    fabricanteError: error,
    fabricanteErrorText: errorText,
    fabricanteIsValid: isValid,
    fabricanteIsValidTelefonos: isValidTelefonos,
    fabricanteIsValidDirecciones: isValidDirecciones,
    fabricanteIsValidEmails: isValidEmails,
    fabricanteIsValidMarcas: isValidMarcas
  };
}
