
import { defineComponent, ref, watch } from "vue";

import { useTipoTemporada } from "@/views/temporadas/useTipoTemporada";
import { useTemporada } from "@/views/temporadas/useTemporada";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import UITextArea from "../../components/UITextArea.vue";

export default defineComponent({
  name: "Temporadas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIListBox,
    UITextArea,
    UICheckBox
  },
  setup() {
    const {
      tiposTemporada,
      tiposTemporadaShowed,
      tiposTemporadaResetFilter,
      tipoTemporadaEdit,
      tipoTemporadaAdd,
      tipoTemporadaDelete,
      tipoTemporadaCancel,
      tiposTemporadaave,
      tipoTemporadaInEdition,
      tiposTemporadaGotoNextPage,
      tiposTemporadaGoToPreviousPage,
      tipoTemporadaIsEditing,
      tiposTemporadaIsLoading,
      tiposTemporadaIsFirstPage,
      tiposTemporadaIsLastPage,
      tipoTemporadaSuccess,
      tipoTemporadaError,
      tipoTemporadaErrorText,
      tipoTemporadaIsValid,
    } = useTipoTemporada();

    const {
      temporadas,
      temporadasShowed,
      temporadasResetFilter,
      temporadaEdit,
      temporadaAdd,
      temporadaDelete,
      temporadaCancel,
      temporadaSave,
      temporadaInEdition,
      temporadasGotoNextPage,
      temporadasGoToPreviousPage,
      temporadaIsEditing,
      temporadasIsLoading,
      temporadasIsFirstPage,
      temporadasIsLastPage,
      temporadasUpdateTipo,
      temporadaSuccess,
      temporadaError,
      temporadaErrorText,
      temporadaIsValid,
    } = useTemporada();

    const ShowValidateTipoTemporada = ref(false);
    const ShowValidateTemporada = ref(false);

    const tipoTemporadaWaitingForSave = ref(false);
    const temporadaWaitingForSave = ref(false);

    temporadasResetFilter();
    tiposTemporadaResetFilter();

    function tiposTemporadaSubmit() {
      ShowValidateTipoTemporada.value = !tipoTemporadaIsValid(
        tipoTemporadaInEdition.value
      );
      if (ShowValidateTipoTemporada.value) return;
      tipoTemporadaWaitingForSave.value = true;
      temporadasUpdateTipo(
        tipoTemporadaInEdition.value.id,
        tipoTemporadaInEdition.value.descripcion
      );
      tiposTemporadaave().then(() => {
        tipoTemporadaWaitingForSave.value = false;
      });
    }

    function temporadaSubmit() {
      console.log("1");
      ShowValidateTemporada.value = !temporadaIsValid(temporadaInEdition.value);
      console.log(ShowValidateTemporada.value);
      if (ShowValidateTemporada.value) return;
      temporadaWaitingForSave.value = true;
      temporadaSave().then(() => {
        temporadaWaitingForSave.value = false;
      });
    }

    function tipoTemporadaPreviousCancel() {
      ShowValidateTipoTemporada.value = false;
      tipoTemporadaCancel();
    }

    function temporadaPreviousCancel() {
      ShowValidateTemporada.value = false;
      temporadaCancel();
    }

    watch(
      [temporadaError, temporadaSuccess],
      ([temporadaError, temporadaSuccess]) => {
        if (temporadaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (temporadaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: temporadaErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    watch(
      [tipoTemporadaError, tipoTemporadaSuccess],
      ([tipoTemporadaError, tipoTemporadaSuccess]) => {
        if (tipoTemporadaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (tipoTemporadaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: tipoTemporadaErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      //Tipos de temporada
      tiposTemporada,
      tiposTemporadaShowed,
      tipoTemporadaEdit,
      tipoTemporadaAdd,
      tipoTemporadaDelete,
      tipoTemporadaPreviousCancel,
      tiposTemporadaave,
      tipoTemporadaInEdition,
      tiposTemporadaGotoNextPage,
      tiposTemporadaGoToPreviousPage,
      tipoTemporadaIsEditing,
      tiposTemporadaIsLoading,
      tiposTemporadaIsFirstPage,
      tiposTemporadaIsLastPage,
      tiposTemporadaSubmit,
      //Temporadas
      temporadas,
      temporadasShowed,
      temporadaEdit,
      temporadaAdd,
      temporadaDelete,
      temporadaPreviousCancel,
      temporadaSave,
      temporadaInEdition,
      temporadasGotoNextPage,
      temporadasGoToPreviousPage,
      temporadaIsEditing,
      temporadasIsLoading,
      temporadasIsFirstPage,
      temporadasIsLastPage,
      temporadasUpdateTipo,
      temporadaSubmit,

      ShowValidateTipoTemporada,
      ShowValidateTemporada,
    };
  },
});
