import { ClienteView, ClienteFilter } from "@/types/clientes/ClienteView";
import { Cliente } from "@/types/clientes/Cliente";
import { CategoriaCliente } from "@/types/clientes/CategoriaCliente";
import { EtiquetaCliente } from "@/types/clientes/EtiquetaCliente";
import { cleanFilter } from "../apiUtility";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";

export const apiClientes = {
  getClientesView(filter: ClienteFilter): Promise<ClienteView[]> {
    filter = cleanFilter(filter);
    return new Promise((resolve) => {
      const url = "ClienteView/filter";
      axios
        .post<ClienteView[]>(url, filter)
        .then((response) => resolve(response.data));
    });
  },
  getClienteView(id: number): Promise<ClienteView> {
    return new Promise((resolve) => {
      const url = "ClienteView/" + id;
      axios.get<ClienteView>(url).then((response) => resolve(response.data));
    });
  },
  getCliente(id: number): Promise<Cliente> {
    return new Promise((resolve) => {
      const url = "Cliente/" + id;
      axios.get<Cliente>(url).then((response) => {
        resolve(response.data);
      });
    });
  },
  saveCliente(cliente: Cliente): Promise<Cliente> {
    const newCliente = { ...cliente };

    putIdOk(newCliente.direcciones, "idDireccion");
    putIdOk(newCliente.emails, "idEmail");
    putIdOk(newCliente.telefonos, "idTelefono");
    putIdOk(newCliente.fechasTope, "idFechaTope");
    putIdOk(newCliente.codigos, "idCodigo");

    return new Promise((resolve, reject) => {
      const url = "Cliente";
      axios
        .post(url, newCliente)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  deleteCliente(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Cliente/" + id;
      axios
        .delete(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getCategoriasCliente(): Promise<CategoriaCliente[]> {
    return new Promise((resolve) => {
      const url = "CategoriaCliente";
      axios
        .get<CategoriaCliente[]>(url)
        .then((response) => resolve(response.data));
    });
  },
  getEtiquetasCliente(): Promise<EtiquetaCliente[]> {
    return new Promise((resolve) => {
      const url = "EtiquetaCliente";
      axios
        .get<EtiquetaCliente[]>(url)
        .then((response) => resolve(response.data));
    });
  },
  recalculateClientes() {
    return new Promise((resolve, reject) => {
      const url = "Cliente/recalcular";
      axios
        .post(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};
