import {
  ColeccionView,
  ColeccionFilter
} from "@/types/colecciones/ColeccionView";
import { Coleccion } from "@/types/colecciones/Coleccion";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiColecciones = {
  // Tipos facturas
  getColeccionesView(filter: ColeccionFilter): Promise<ColeccionView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "ColeccionView/filter";
      axios
        .post<ColeccionView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getColeccionView(id: number): Promise<ColeccionView> {
    return new Promise(resolve => {
      const url = "ColeccionView/" + id;
      axios.get<ColeccionView>(url).then(response => resolve(response.data));
    });
  },
  getColeccion(id: number): Promise<Coleccion> {
    return new Promise(resolve => {
      const url = "Coleccion/" + id;
      axios.get<Coleccion>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveColeccion(tipo: Coleccion): Promise<Coleccion> {
    return new Promise((resolve, reject) => {
      const url = "Coleccion";
      axios
        .post(url, tipo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteColeccion(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Coleccion/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
