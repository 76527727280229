import { ComunidadAutonoma } from "@/types/poblaciones/ComunidadAutonoma";
import { Provincia } from "@/types/poblaciones/Provincia";
import { Poblacion } from "@/types/poblaciones/Poblacion";
import { CodigoPostal } from "@/types/poblaciones/CodigoPostal";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";
import axios from "../apiService";

export const apiPoblaciones = {
  // Comunidades Autonomas
  getComunidadesAutonomas(): Promise<ComunidadAutonoma[]> {
    return new Promise(resolve => {
      const url = "ComunidadAutonoma";
      axios
        .get<ComunidadAutonoma[]>(url)
        .then(response => resolve(response.data));
    });
  },
  saveComunidadAutonoma(
    comunidadAutonoma: ComunidadAutonoma
  ): Promise<ComunidadAutonoma> {
    return new Promise((resolve, reject) => {
      const url = "ComunidadAutonoma";
      axios
        .post(url, comunidadAutonoma)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteComunidadAutonoma(id: number) {
    return new Promise((resolve, reject) => {
      const url = "ComunidadAutonoma/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  // Provincias
  getProvincias(): Promise<Provincia[]> {
    return new Promise(resolve => {
      const url = "Provincia";
      axios.get<Provincia[]>(url).then(response => resolve(response.data));
    });
  },
  saveProvincia(provincia: Provincia): Promise<Provincia> {
    return new Promise((resolve, reject) => {
      const url = "Provincia";
      axios
        .post(url, provincia)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteProvincia(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Provincia/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  // Poblaciones
  getPoblaciones(): Promise<Poblacion[]> {
    return new Promise(resolve => {
      const url = "Poblacion";
      axios.get<Poblacion[]>(url).then(response => resolve(response.data));
    });
  },
  savePoblacion(poblacion: Poblacion): Promise<Poblacion> {
    return new Promise((resolve, reject) => {
      const url = "Poblacion";
      axios
        .post(url, poblacion)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deletePoblacion(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Poblacion/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  // Poblaciones
  getCodigosPostales(): Promise<CodigoPostal[]> {
    return new Promise(resolve => {
      const url = "CodigoPostal";
      axios.get<CodigoPostal[]>(url).then(response => resolve(response.data));
    });
  },
  saveCodigoPostal(codigoPostal: CodigoPostal): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      const url = "CodigoPostal";
      axios
        .post(url, codigoPostal)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteCodigoPostal(id: number) {
    return new Promise((resolve, reject) => {
      const url = "CodigoPostal/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  // Poblaciones
  getCodigosPostalesPoblaciones(): Promise<CodigoPostalPoblacionView[]> {
    return new Promise(resolve => {
      const url = "CodigoPostalPoblacionView/filter";
      axios
        .post<CodigoPostalPoblacionView[]>(url, {})
        .then(response => resolve(response.data));
    });
  }
};
