import { FabricanteView, FabricanteFilter } from "@/types/fabricantes/FabricanteView";
import { Fabricante } from "@/types/fabricantes/Fabricante";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiFabricantes = {
  /*getFabricantesView(): Promise<FabricanteView[]> {
    return new Promise(resolve => {
      const url = "FabricanteView/filter";
      axios
        .post<FabricanteView[]>(url, {})
        .then(response => resolve(response.data));
    });
  },*/
  getFabricantesView(filter: FabricanteFilter): Promise<FabricanteView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "FabricanteView/filter";
      axios
        .post<FabricanteView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getFabricanteView(id: number): Promise<FabricanteView> {
    return new Promise(resolve => {
      const url = "FabricanteView/" + id;
      axios.get<FabricanteView>(url).then(response => resolve(response.data));
    });
  },
  getFabricante(id: number): Promise<Fabricante> {
    return new Promise(resolve => {
      const url = "Fabricante/" + id;
      axios.get<Fabricante>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveFabricante(fabricante: Fabricante): Promise<Fabricante> {
    const newFabricante = { ...fabricante };
    putIdOk(newFabricante.direcciones, "idDireccion");
    putIdOk(newFabricante.emails, "idEmail");
    putIdOk(newFabricante.telefonos, "idTelefono");
    putIdOk(newFabricante.marcas, "idMarca");

    return new Promise((resolve, reject) => {
      const url = "Fabricante";
      axios
        .post(url, newFabricante)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteFabricante(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Fabricante/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
