import { computed, onMounted, ref, Ref } from "vue";
import { apiFacturas } from "@/services/facturas/api";
import { FacturaView, FacturaFilter } from "@/types/facturas/FacturaView";
import { Factura, FacturaLinea } from "@/types/facturas/Factura";
import { usePagination } from "@/components/usePagination";

import {
  isValidObject,
  isValidArrayOfObjects
} from "../utilidades/useValidations";
import { getTempId } from "../utilidades/useIDLibrary";

export function useFacturas() {
  const idNotEditItem = -999;
  const items: Ref<FacturaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Factura);

  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);

  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const editFirst = ref(false);

  const filter = ref({} as FacturaFilter);

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiFacturas.getFactura(id);
      if (editingItem.value.fecha != undefined) {
        editingItem.value.fecha = editingItem.value.fecha.substr(0, 10);
      }
    } else {
      const f = new Date();
      const fechaactual =
        f.getFullYear() +
        "-" +
        (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
        "-" +
        (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
      editingItem.value = {
        id: 0,
        pagadaSN: true,
        porcentajeRecargo: 0,
        porcentajeIVA: 0,
        fecha: fechaactual,
        lineas: [] as FacturaLinea[]
      } as Factura;
    }
  }

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("facturaFilter", JSON.stringify(filter.value));
    items.value = await apiFacturas.getFacturasView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
    if (items.value.length > 0 && editFirst.value) {
      editFirst.value = false;
      editItem(items.value[0].id);
    }
  }

  onMounted(async () => {
    filterItems();
  });

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
      pagada: false
    } as FacturaView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiFacturas
      .deleteFactura(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Factura;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    if (editingItem.value.fecha == "") editingItem.value.fecha = undefined;

    await apiFacturas
      .saveFactura(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );

    if (index == -1) return;
    const tipoUpdated = await apiFacturas.getFacturaView(id);
    items.value[index] = tipoUpdated;
    editingItem.value = { id: idNotEditItem } as Factura;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addLinea() {
    editingItem.value.lineas.push({
      idLinea: getTempId(editingItem.value.lineas, "idLinea"),
      idFactura: editingItem.value.id,
      porcentajeIVA: editingItem.value.porcentajeIVA,
      porcentajeRecargo: editingItem.value.porcentajeRecargo,
      porcentajeDescuento: 0,
      unidades: 1,
      porcentajeIRPF: 0
    } as FacturaLinea);
  }

  function deleteLinea(id: number) {
    editingItem.value.lineas = editingItem.value.lineas.filter(
      item => item.idLinea != id
    );
  }

  async function resetFilter() {
    filter.value = {
      anyo: 2018
    } as FacturaFilter;
  }

  function isValid(item: Factura): boolean {
    return isValidObject(item, [
      "fecha",
      "idCliente",
      "idTipoFactura",
      "porcentajeIVA",
      "porcentajeRecargo"
    ]);
  }

  function isValidLineas(lineas: FacturaLinea[]): boolean {
    return isValidArrayOfObjects(lineas, [
      "descripcion",
      "unidades",
      "importe"
    ]);
  }

  return {
    facturas: items,
    facturasShowed: itemsShowed,
    facturasFilter: filter,
    facturasSearch: filterItems,
    facturasResetFilter: resetFilter,
    facturaEdit: editItem,
    facturaAdd: addItem,
    facturaDelete: deleteItem,
    facturaCancel: cancelEdit,
    facturaSave: saveEdit,
    facturaInEdition: editingItem,
    facturasIndex: currentRow,
    facturasGotoNextPage: nextPage,
    facturasGoToPreviousPage: previousPage,
    facturaIsEditing: isEditing,
    facturasIsLoading: isLoading,
    facturasIsFirstPage: isFirstPage,
    facturasIsLastPage: isLastPage,
    facturaAddLinea: addLinea,
    facturaDeleteLinea: deleteLinea,
    facturaSuccess: success,
    facturaError: error,
    facturaErrorText: errorText,
    facturaIsValid: isValid,
    facturaIsValidLineas: isValidLineas,
    facturaEditFirst: editFirst
  };
}
