
import { defineComponent, ref, watchEffect } from "vue";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";
// import UITextInput from "@/components/UITextInput.vue";

export default defineComponent({
  name: "selectCodigoPostal",
  components: {},
  props: {
    items: {
      type: Array,
      required: false
    },
    idCodigoPostal: {
      type: Number,
      required: false
    }
  },

  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): CodigoPostalPoblacionView[] {
      //console.log("search - " + filter);
      if (filter == undefined) return [];
      if (filter.length < 2) return [];
      const filterOk = filter.toUpperCase();
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      return poblaciones.filter(
        item =>
          item.poblacion.indexOf(filterOk) > -1 ||
          (item.codigo != null ? item.codigo.indexOf(filterOk) > -1 : false)
      );
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      console.log("selectItem - id = " + id);
      if (id != -1) {
        emit("update:idCodigoPostal", id);
        //console.log("selectItem - props.idCodigoPostal= " + props.idCodigoPostal);
      }
    }

    function clearValue() {
      //console.log("clear");
      emit("update:idCodigoPostal", null);
    }

    function valueShowed(): string {
      //console.log("valueShowed - props.idCodigoPostal= " + props.idCodigoPostal);
      if (props.idCodigoPostal == 0) return "no hay poblacion seleccionada";
      if (props.idCodigoPostal == undefined) return "seleccione una poblacion";
      const items = props.items as CodigoPostalPoblacionView[];
      const resultado = items.find(x => x.id == props.idCodigoPostal);
      if (resultado == undefined) return "Cargando ...";
      return resultado.codigo + " " + resultado.poblacion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    return {
      showSearch,
      isSearching,
      filter,
      myinput,
      search,
      valueShowed,
      selectItem,
      clearValue
    };
  }
});
