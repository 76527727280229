
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "datatablerowbuttons",
  props: {
    id: {
      type: Number,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: false
    }
  },
  emits: ["edititem", "deleteitem"],
  setup(props, { emit }) {
    const tryingDelete = ref(false);

    function edititem() {
      if (props.isEditing) return;
      emit("edititem", props.id);
    }

    function deleteitem() {
      if (props.isEditing) return;
      tryingDelete.value = false;
      emit("deleteitem", props.id);
    }
    return { edititem, deleteitem, tryingDelete };
  }
});
