import { TemporadaView, TemporadaFilter } from "@/types/temporadas/TemporadaView";
import { Temporada } from "@/types/temporadas/Temporada";
import { TipoTemporada } from "@/types/temporadas/TipoTemporada";
import { TipoTemporadaFilter } from "@/types/temporadas/TipoTemporadaView";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiTemporadas = {
  // Tipos facturas
  /*getTemporadasView(): Promise<TemporadaView[]> {
    return new Promise(resolve => {
      const url = "TemporadaView/filter";
      axios
        .post<TemporadaView[]>(url, {})
        .then(response => resolve(response.data));
    });
  },*/
  getTemporadasView(filter: TemporadaFilter): Promise<TemporadaView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "TemporadaView/filter";
      axios
        .post<TemporadaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getTemporadaView(id: number): Promise<TemporadaView> {
    return new Promise(resolve => {
      const url = "TemporadaView/" + id;
      axios.get<TemporadaView>(url).then(response => resolve(response.data));
    });
  },
  getTemporada(id: number): Promise<Temporada> {
    return new Promise(resolve => {
      const url = "Temporada/" + id;
      axios.get<Temporada>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveTemporada(temporada: Temporada): Promise<Temporada> {
    return new Promise((resolve, reject) => {
      const url = "Temporada";
      axios
        .post(url, temporada)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTemporada(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Temporada/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  // TipoTemporada
  /*getTiposTemporada(): Promise<TipoTemporada[]> {
    return new Promise(resolve => {
      const url = "TipoTemporadaView/filter";
      axios
        .post<TipoTemporada[]>(url, {})
        .then(response => resolve(response.data));
    });
  },*/
  getTiposTemporada(filter: TipoTemporadaFilter): Promise<TipoTemporada[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "TipoTemporadaView/filter";
      axios
        .post<TipoTemporada[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getTipoTemporada(id: number): Promise<TipoTemporada> {
    return new Promise(resolve => {
      const url = "TipoTemporada/" + id;
      axios.get<TipoTemporada>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveTipoTemporada(temporada: TipoTemporada): Promise<TipoTemporada> {
    return new Promise((resolve, reject) => {
      const url = "TipoTemporada";
      axios
        .post(url, temporada)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTipoTemporada(id: number) {
    return new Promise((resolve, reject) => {
      const url = "TipoTemporada/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
