<template>
  <div class="pt-10 grid xl:grid-cols-6 gap-8">
    <div class="xl:col-span-3 mb-10 temporadas">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
      >
        Temporadas
      </h2>
      <datatable
        :headers="['Descripción', 'Tipo de Temporada', 'Año']"
        :numbersRows="temporadas.length"
        :isEditing="temporadaIsEditing"
        :isLoading="temporadasIsLoading"
        :isFirstPage="temporadasIsFirstPage"
        :isLastPage="temporadasIsLastPage"
        @newitem="temporadaAdd"
        @next="temporadasGotoNextPage"
        @previous="temporadasGoToPreviousPage"
      >
        <template
          v-for="temporada in temporadasShowed"
          :key="temporada.id"
          class="border-b border-gray-200"
        >
          <tr v-if="temporada.id != temporadaInEdition.id">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ temporada.descripcion }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ temporada.descripcionTipoTemporada }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ temporada.anyo }}
            </td>

            <datatablerowbuttons
              @edititem="temporadaEdit"
              @deleteitem="temporadaDelete"
              :id="temporada.id"
              :isEditing="temporadaIsEditing"
            />
          </tr>
          <tr v-else>
            <td colspan="5" class="no-columns">
              <rowEdit
                @cancel="temporadaPreviousCancel"
                @save="temporadaSubmit"
              >
                <template v-slot:info>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Temporada
                  </h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Introduzca los datos indicados.
                  </p>
                </template>
                <template v-slot:default>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="temporadaInEdition.descripcion"
                        label="Descripción"
                        :maxLength="50"
                        :required="true"
                        :showRequired="ShowValidateTemporada"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-2 sm:col-span-2">
                      <UINumberInput
                        v-model="temporadaInEdition.anyo"
                        label="Año"
                        id="Anyo"
                        :required="true"
                        :showRequired="ShowValidateTemporada"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextArea
                        v-model="temporadaInEdition.showRoom"
                        label="Localización ShowRoom"
                        id="ShowRoom"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UIListBox
                        v-model="temporadaInEdition.idTipoTemporada"
                        :options="tiposTemporada"
                        optionValue="id"
                        optionName="descripcion"
                        label="Tipo"
                        :required="true"
                        :showRequired="ShowValidateTemporada"
                      />
                    </div>
                  </div>
                </template>
              </rowEdit>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <div class="xl:col-span-3 tipos">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-4"
      >
        Tipos de temporadas
      </h2>
      <datatable
        :headers="['Descripción', 'Abreviatura']"
        :numbersRows="tiposTemporada.length"
        :isEditing="tipoTemporadaIsEditing"
        :isLoading="tiposTemporadaIsLoading"
        :isFirstPage="tiposTemporadaIsFirstPage"
        :isLastPage="tiposTemporadaIsLastPage"
        @newitem="tipoTemporadaAdd"
        @next="tiposTemporadaGotoNextPage"
        @previous="tiposTemporadaGoToPreviousPage"
      >
        <template
          v-for="tipo in tiposTemporadaShowed"
          :key="tipo.id"
          class="border-b border-gray-200"
        >
          <tr v-if="tipo.id != tipoTemporadaInEdition.id">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <span :class="{ 'line-through text-red-400': !tipo.activo }">
                {{ tipo.descripcion }}
              </span>

            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <span :class="{ 'line-through text-red-400': !tipo.activo }">
                {{ tipo.abreviatura }}
              </span>
            </td>
            <datatablerowbuttons
              @edititem="tipoTemporadaEdit"
              @deleteitem="tipoTemporadaDelete"
              :id="tipo.id"
              :isEditing="tipoTemporadaIsEditing"
            />
          </tr>
          <tr v-else>
            <td colspan="5" class="no-columns">
              <rowEdit
                @cancel="tipoTemporadaPreviousCancel"
                @save="tiposTemporadaSubmit"
              >
                <template v-slot:info>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Tipo temporada
                  </h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Introduzca los datos indicados.
                  </p>
                </template>
                <template v-slot:default>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="tipoTemporadaInEdition.descripcion"
                        label="Descripción"
                        :maxLength="30"
                        :required="true"
                        :showRequired="ShowValidateTipoTemporada"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="tipoTemporadaInEdition.abreviatura"
                        label="Abreviatura"
                        :maxLength="10"
                        :required="true"
                        :showRequired="ShowValidateTipoTemporada"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-1">
                      <UICheckBox
                        v-model="tipoTemporadaInEdition.activo"
                        label="Activa"
                      />
                    </div>
                  </div>
                </template>
              </rowEdit>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useTipoTemporada } from "@/views/temporadas/useTipoTemporada";
import { useTemporada } from "@/views/temporadas/useTemporada";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import UITextArea from "../../components/UITextArea.vue";

export default defineComponent({
  name: "Temporadas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIListBox,
    UITextArea,
    UICheckBox
  },
  setup() {
    const {
      tiposTemporada,
      tiposTemporadaShowed,
      tiposTemporadaResetFilter,
      tipoTemporadaEdit,
      tipoTemporadaAdd,
      tipoTemporadaDelete,
      tipoTemporadaCancel,
      tiposTemporadaave,
      tipoTemporadaInEdition,
      tiposTemporadaGotoNextPage,
      tiposTemporadaGoToPreviousPage,
      tipoTemporadaIsEditing,
      tiposTemporadaIsLoading,
      tiposTemporadaIsFirstPage,
      tiposTemporadaIsLastPage,
      tipoTemporadaSuccess,
      tipoTemporadaError,
      tipoTemporadaErrorText,
      tipoTemporadaIsValid,
    } = useTipoTemporada();

    const {
      temporadas,
      temporadasShowed,
      temporadasResetFilter,
      temporadaEdit,
      temporadaAdd,
      temporadaDelete,
      temporadaCancel,
      temporadaSave,
      temporadaInEdition,
      temporadasGotoNextPage,
      temporadasGoToPreviousPage,
      temporadaIsEditing,
      temporadasIsLoading,
      temporadasIsFirstPage,
      temporadasIsLastPage,
      temporadasUpdateTipo,
      temporadaSuccess,
      temporadaError,
      temporadaErrorText,
      temporadaIsValid,
    } = useTemporada();

    const ShowValidateTipoTemporada = ref(false);
    const ShowValidateTemporada = ref(false);

    const tipoTemporadaWaitingForSave = ref(false);
    const temporadaWaitingForSave = ref(false);

    temporadasResetFilter();
    tiposTemporadaResetFilter();

    function tiposTemporadaSubmit() {
      ShowValidateTipoTemporada.value = !tipoTemporadaIsValid(
        tipoTemporadaInEdition.value
      );
      if (ShowValidateTipoTemporada.value) return;
      tipoTemporadaWaitingForSave.value = true;
      temporadasUpdateTipo(
        tipoTemporadaInEdition.value.id,
        tipoTemporadaInEdition.value.descripcion
      );
      tiposTemporadaave().then(() => {
        tipoTemporadaWaitingForSave.value = false;
      });
    }

    function temporadaSubmit() {
      console.log("1");
      ShowValidateTemporada.value = !temporadaIsValid(temporadaInEdition.value);
      console.log(ShowValidateTemporada.value);
      if (ShowValidateTemporada.value) return;
      temporadaWaitingForSave.value = true;
      temporadaSave().then(() => {
        temporadaWaitingForSave.value = false;
      });
    }

    function tipoTemporadaPreviousCancel() {
      ShowValidateTipoTemporada.value = false;
      tipoTemporadaCancel();
    }

    function temporadaPreviousCancel() {
      ShowValidateTemporada.value = false;
      temporadaCancel();
    }

    watch(
      [temporadaError, temporadaSuccess],
      ([temporadaError, temporadaSuccess]) => {
        if (temporadaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (temporadaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: temporadaErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    watch(
      [tipoTemporadaError, tipoTemporadaSuccess],
      ([tipoTemporadaError, tipoTemporadaSuccess]) => {
        if (tipoTemporadaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (tipoTemporadaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: tipoTemporadaErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      //Tipos de temporada
      tiposTemporada,
      tiposTemporadaShowed,
      tipoTemporadaEdit,
      tipoTemporadaAdd,
      tipoTemporadaDelete,
      tipoTemporadaPreviousCancel,
      tiposTemporadaave,
      tipoTemporadaInEdition,
      tiposTemporadaGotoNextPage,
      tiposTemporadaGoToPreviousPage,
      tipoTemporadaIsEditing,
      tiposTemporadaIsLoading,
      tiposTemporadaIsFirstPage,
      tiposTemporadaIsLastPage,
      tiposTemporadaSubmit,
      //Temporadas
      temporadas,
      temporadasShowed,
      temporadaEdit,
      temporadaAdd,
      temporadaDelete,
      temporadaPreviousCancel,
      temporadaSave,
      temporadaInEdition,
      temporadasGotoNextPage,
      temporadasGoToPreviousPage,
      temporadaIsEditing,
      temporadasIsLoading,
      temporadasIsFirstPage,
      temporadasIsLastPage,
      temporadasUpdateTipo,
      temporadaSubmit,

      ShowValidateTipoTemporada,
      ShowValidateTemporada,
    };
  },
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  .temporadas td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .temporadas td:nth-of-type(2):before {
    content: "Tipo";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .temporadas td:nth-of-type(3):before {
    content: "Año";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .tipos td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .tipos td:nth-of-type(2):before {
    content: "Abreviatura";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
