
import { defineComponent } from "vue";

export default defineComponent({
  name: "tabMenuEdition",
  props: {
    title: {
      type: String,
      required: true
    },
    numbersOfItems: {
      type: Number,
      required: false
    },
    active: {
      type: Boolean,
      require: true
    },
    svgdata: {
      type: String,
      require: true
    },
    hasAddButton: {
      type: Boolean,
      require: true,
      default: true
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    addItem() {
      this.$emit("addItem");
    },
    showItems() {
      this.$emit("showItems");
    }
  }
});
