import { computed, onMounted, ref, Ref } from "vue";
import { apiTiendas } from "@/services/tiendas/api";
import { TiendaView, TiendaFilter } from "@/types/tiendas/TiendaView";
import {
  Tienda,
  TiendaDireccion,
  TiendaTelefono
} from "@/types/tiendas/Tienda";

import { usePagination } from "@/components/usePagination";

import {
  isValidObject,
  isValidArrayOfObjects
} from "../utilidades/useValidations";

import { getTempId } from "../utilidades/useIDLibrary";

export function useTiendas() {
  const idNotEditItem = -999;
  const items: Ref<TiendaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Tienda);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as TiendaFilter);

  async function filterItems() {
    //console.log("seraching with" + filter.value);
    isLoading.value = true;
    localStorage.setItem("tiendaFilter", JSON.stringify(filter.value));
    items.value = await apiTiendas.getTiendasView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiTiendas.getTienda(id);
    } else {
      editingItem.value = {
        id: 0,
        idCliente: filter.value.idCliente,
        direcciones: [] as TiendaDireccion[],
        telefonos: [] as TiendaTelefono[]
      } as Tienda;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as TiendaView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiTiendas
      .deleteTienda(id)
      .then(() => {
        success.value = true;
      })
      .catch(data => {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Tienda;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiTiendas
      .saveTienda(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );

    if (index == -1) return;

    const tiendaUpdated = await apiTiendas.getTiendaView(id);
    items.value[index] = tiendaUpdated;
    editingItem.value = { id: idNotEditItem } as Tienda;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addDireccion() {
    editingItem.value.direcciones.push({
      idDireccion: getTempId(editingItem.value.direcciones, "idDireccion"),
      idTienda: editingItem.value.id,
      correspondencia: false,
      fiscal: false,
      entrega: false
    } as TiendaDireccion);
  }

  function deleteDireccion(id: number) {
    editingItem.value.direcciones = editingItem.value.direcciones.filter(
      item => item.idDireccion != id
    );
  }

  function addTelefono() {
    editingItem.value.telefonos.push({
      idTelefono: getTempId(editingItem.value.telefonos, "idTelefono"),
      idTienda: editingItem.value.id
    } as TiendaTelefono);
  }

  function deleteTelefono(id: number) {
    editingItem.value.telefonos = editingItem.value.telefonos.filter(
      item => item.idTelefono != id
    );
  }

  async function resetFilter() {
    filter.value = { inexistenteSN: "N", activoSN: "S" } as TiendaFilter;
  }

  function isValid(item: Tienda): boolean {
    return isValidObject(item, ["nombre"]);
  }

  function isValidDirecciones(direcciones: TiendaDireccion[]): boolean {
    return isValidArrayOfObjects(direcciones, ["domicilio"]);
  }

  function isValidTelefonos(telefonos: TiendaTelefono[]): boolean {
    return isValidArrayOfObjects(telefonos, ["telefono"]);
  }

  return {
    tiendas: items,
    tiendasShowed: itemsShowed,
    tiendasFilter: filter,
    tiendasSearch: filterItems,
    tiendasResetFilter: resetFilter,
    tiendaEdit: editItem,
    tiendaAdd: addItem,
    tiendaDelete: deleteItem,
    tiendaCancel: cancelEdit,
    tiendaSave: saveEdit,
    tiendaInEdition: editingItem,
    tiendasIndex: currentRow,
    tiendasGotoNextPage: nextPage,
    tiendasGoToPreviousPage: previousPage,
    tiendaIsEditing: isEditing,
    tiendasIsLoading: isLoading,
    tiendasIsFirstPage: isFirstPage,
    tiendasIsLastPage: isLastPage,
    tiendaAddDireccion: addDireccion,
    tiendaDeleteDireccion: deleteDireccion,
    tiendaAddTelefono: addTelefono,
    tiendaDeleteTelefono: deleteTelefono,
    tiendaSuccess: success,
    tiendaError: error,
    tiendaErrorText: errorText,
    tiendaIsValid: isValid,
    tiendaIsValidDirecciones: isValidDirecciones,
    tiendaIsValidTelefonos: isValidTelefonos
  };
}
