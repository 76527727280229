
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { apiUsuarios } from "@/services/usuarios/api";

export default defineComponent({
  name: "Login",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const usuario = ref("");
    const clave = ref("");

    async function tryLogin() {
      if (usuario.value != "" && clave.value != "") {
        apiUsuarios
          .validate(usuario.value, clave.value)
          .then((response) => {
            localStorage.setItem("token", response.token);
            if (response.administrador) {
              localStorage.setItem(
                "admin",
                response.token
                  .split("")
                  .reverse()
                  .join("")
              );
            } else {
              localStorage.removeItem("admin");
            }
            console.log(response);
            const newPath = route.params.nextUrl;
            router.push({ path: "/app/" + newPath });
          })
          .catch(() => {
            alert("ERROR");
          });
      }
    }
    return {
      usuario,
      clave,
      tryLogin,
    };
  },
});
