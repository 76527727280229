<template>
  <div class="pt-10"></div>
  <h2
    class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
  >
    Transportistas
  </h2>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="transportistas.length"
      :isEditing="transportistaIsEditing"
      :isLoading="transportistasIsLoading"
      :isFirstPage="transportistasIsFirstPage"
      :isLastPage="transportistasIsLastPage"
      @newitem="transportistaAdd"
      @next="transportistasGotoNextPage"
      @previous="transportistasGoToPreviousPage"
    >
      <template
        v-for="transportista in transportistasShowed"
        :key="transportista.id"
        class="border-b border-gray-200"
      >
        <tr v-if="transportista.id != transportistaInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ transportista.nif }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <template
              v-if="
                !(transportista.url != '' && transportista.url != undefined)
              "
            >
              <span>{{ transportista.nombre }}</span>
            </template>
            <template v-else>
              <span class="flex">
                <span class="mr-2">
                  <a target="_blank" :href="transportista.url">
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                      ></path>
                      <path
                        d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                      ></path>
                    </svg>
                  </a>
                </span>
                <span>{{ transportista.nombre }}</span>
              </span>
            </template>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ transportista.codigoPostal }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ transportista.poblacion }}
          </td>

          <datatablerowbuttons
            @edititem="transportistaEdit"
            @deleteitem="transportistaDelete"
            :id="transportista.id"
            :isEditing="transportistaIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="transportistaPreviousCancel"
              @save="transportistaPreviousSave"
              :waitingForSave="transportistaWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Transportista
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                  />
                  <tabmenuedition
                    title="direcciones"
                    :numbersOfItems="transportistaInEdition.direcciones.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="transportistaAddDireccion"
                  />
                  <tabmenuedition
                    title="emails"
                    :numbersOfItems="transportistaInEdition.emails.length"
                    :active="tabsEdit[2]"
                    :hasAddButton="true"
                    svgdata="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    @showItems="changeActiveTab(2)"
                    @addItem="transportistaAddEmail"
                  />
                  <tabmenuedition
                    title="teléfonos"
                    :numbersOfItems="transportistaInEdition.telefonos.length"
                    :active="tabsEdit[3]"
                    :hasAddButton="true"
                    svgdata="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    @showItems="changeActiveTab(3)"
                    @addItem="transportistaAddTelefono"
                  />
                </div>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div class="grid grid-cols-2 gap-6">
                    <div class="col-span-2 sm:col-span-1">
                      <UITextInput
                        v-model="transportistaInEdition.codigo"
                        label="Código"
                        :maxLength="20"
                      />
                    </div>
                    <div class="col-span-2 sm:col-span-1">
                      <UITextInput
                        v-model="transportistaInEdition.nif"
                        label="Nif"
                        :maxLength="20"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1 sm:col-span-1">
                      <UITextInput
                        v-model="transportistaInEdition.nombre"
                        label="Nombre"
                        :maxLength="100"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1 sm:col-span-1">
                      <UITextInput
                        v-model="transportistaInEdition.url"
                        label="URL"
                        :maxLength="100"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1 sm:col-span-1">
                      <UITextArea
                        v-model="transportistaInEdition.datosContacto"
                        label="Datos de contacto"
                        id="datoscontacto"
                        :maxLength="255"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1 sm:col-span-1">
                      <UITextArea
                        v-model="transportistaInEdition.observaciones"
                        label="Observaciones"
                        id="observaciones"
                        :maxLength="255"
                      />
                    </div>
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <UINoItems
                    :numbersOfItems="transportistaInEdition.direcciones.length"
                    @addItem="transportistaAddDireccion"
                  />
                  <template
                    v-for="dir in transportistaInEdition.direcciones"
                    :key="dir.idDireccion"
                  >
                    <dir-edit
                      v-model:descripcion="dir.descripcion"
                      v-model:domicilio="dir.domicilio"
                      v-model:idCodigoPostal="dir.idCodigoPostal"
                      v-model:correspondencia="dir.correspondencia"
                      v-model:fiscal="dir.fiscal"
                      v-model:entrega="dir.entrega"
                      :id="dir.idDireccion"
                      :items="codigosPostalesPoblaciones"
                      :showRequired="showValidate"
                      @deleteItem="
                        transportistaDeleteDireccion(dir.idDireccion)
                      "
                      :entregaEditable="false"
                    ></dir-edit>
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[2]">
                  <UINoItems
                    :numbersOfItems="transportistaInEdition.emails.length"
                    @addItem="transportistaAddEmail"
                  />
                  <template
                    v-for="email in transportistaInEdition.emails"
                    :key="email.idEmail"
                  >
                    <emailEdit
                      v-model:descripcion="email.descripcion"
                      v-model:email="email.email"
                      :id="email.idEmail"
                      v-model:principal="email.principal"
                      :showRequired="showValidate"
                      @deleteItem="transportistaDeleteEmail(email.idEmail)"
                    />
                  </template>
                </template>

                <template v-if="tabsEdit[3]">
                  <UINoItems
                    :numbersOfItems="transportistaInEdition.telefonos.length"
                    @addItem="transportistaAddTelefono"
                  />
                  <template
                    v-for="telefono in transportistaInEdition.telefonos"
                    :key="telefono.idTelefono"
                  >
                    <telefonoEdit
                      v-model:descripcion="telefono.descripcion"
                      v-model:telefono="telefono.telefono"
                      v-model:tipoTelefono="telefono.idTipoTelefono"
                      :id="telefono.idTelefono"
                      :tiposTelefono="tiposTelefono"
                      :showRequired="showValidate"
                      @deleteItem="
                        transportistaDeleteTelefono(telefono.idTelefono)
                      "
                    />
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useTransportistas } from "./useTransportistas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UINoItems from "@/components/UINoItems.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Home",
  components: {
    rowEdit,
    UITextInput,
    UITextArea,
    UINoItems,
    datatable,
    datatablerowbuttons,
    tabmenuedition,
    dirEdit,
    emailEdit,
    telefonoEdit
  },
  setup() {
    const headers = ["Nif", "Nombre", "Código postal", "Población"];
    const {
      transportistas,
      transportistasShowed,
      transportistasResetFilter,
      transportistaEdit,
      transportistaAdd,
      transportistaDelete,
      transportistaCancel,
      transportistaSave,
      transportistaInEdition,
      transportistasGotoNextPage,
      transportistasGoToPreviousPage,
      transportistaIsEditing,
      transportistasIsLoading,
      transportistasIsFirstPage,
      transportistasIsLastPage,
      transportistaAddDireccion,
      transportistaDeleteDireccion,
      transportistaAddEmail,
      transportistaDeleteEmail,
      transportistaAddTelefono,
      transportistaDeleteTelefono,
      transportistaSuccess,
      transportistaError,
      transportistaErrorText,
      transportistaIsValid,
      transportistaIsValidTelefonos,
      transportistaIsValidDirecciones,
      transportistaIsValidEmails
    } = useTransportistas();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();

    const showValidate = ref(false);

    const tabsEdit = ref([true, false, false, false]);
    const tabsEditIsValid = ref([true, true, true, true]);

    const transportistaWaitingForSave = ref(false);

    transportistasResetFilter();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = transportistaIsValid(
        transportistaInEdition.value
      );

      tabsEditIsValid.value[1] = transportistaIsValidDirecciones(
        transportistaInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = transportistaIsValidEmails(
        transportistaInEdition.value.emails
      );

      tabsEditIsValid.value[3] = transportistaIsValidTelefonos(
        transportistaInEdition.value.telefonos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function transportistaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      transportistaWaitingForSave.value = true;
      transportistaSave().then(() => {
        transportistaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function transportistaPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      transportistaCancel();
    }

    watch(
      [transportistaError, transportistaSuccess],
      ([transportistaError, transportistaSuccess]) => {
        if (transportistaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (transportistaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: transportistaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      // Tipos de factura
      transportistas,
      transportistasShowed,
      transportistaEdit,
      transportistaAdd,
      transportistaDelete,
      transportistaPreviousCancel,
      transportistaCancel,
      transportistaSave,
      transportistaInEdition,
      transportistasGotoNextPage,
      transportistasGoToPreviousPage,
      transportistaIsEditing,
      transportistasIsLoading,
      transportistasIsFirstPage,
      transportistasIsLastPage,
      transportistaPreviousSave,
      transportistaAddDireccion,
      transportistaDeleteDireccion,
      transportistaAddEmail,
      transportistaDeleteEmail,
      transportistaAddTelefono,
      transportistaDeleteTelefono,
      transportistaWaitingForSave,

      tabsEdit,
      changeActiveTab,
      showValidate,

      codigosPostalesPoblaciones,
      tiposTelefono
    };
  }
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "NIF";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  td:nth-of-type(2):before {
    content: "Nombre";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Código postal";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Población";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
