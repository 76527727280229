import { computed, onMounted, ref, Ref } from "vue";
import { apiPedidos } from "@/services/pedidos/api";
import { PedidoView, PedidoFilter, PedidoTotalExpediciones } from "@/types/pedidos/PedidoView";
import { Pedido, PedidoIncidencia } from "@/types/pedidos/Pedido";
import { usePagination } from "@/components/usePagination";
import {
  isValidObject,
  isValidArrayOfObjects
} from "../utilidades/useValidations";

import { getTempId } from "../utilidades/useIDLibrary";
import { apiExpediciones } from "@/services/expediciones/api";
import { ExpedicionView, ExpedicionFilter} from "@/types/expediciones/ExpedicionView";

export function usePedidos() {
  const idNotEditItem = -999;
  const items: Ref<PedidoView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Pedido);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const totalesExpediciones = ref({} as PedidoTotalExpediciones);

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as PedidoFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("pedidoFilter", JSON.stringify(filter.value));
    items.value = await apiPedidos.getPedidosView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

 

  async function editItem(id: number) {
    let totalPrendasExpedidas = 0;
    let totalImporteExpedido  = 0;

    if (id != 0) {
      editingItem.value = await apiPedidos.getPedido(id);
      if (editingItem.value.fechaPedido != undefined) {
        editingItem.value.fechaPedido = editingItem.value.fechaPedido.substr(
          0,
          10
        );
      }
      if (editingItem.value.fechaServicioDesde != undefined) {
        editingItem.value.fechaServicioDesde = editingItem.value.fechaServicioDesde.substr(
          0,
          10
        );
      }
      if (editingItem.value.fechaServicioHasta != undefined) {
        editingItem.value.fechaServicioHasta = editingItem.value.fechaServicioHasta.substr(
          0,
          10
        );
      }
      const filtroExpediciones = {idPedido: id} as ExpedicionFilter;
      const expediciones = await apiExpediciones.getExpedicionesView(filtroExpediciones);
      
      expediciones.forEach(expedicion => {
        totalPrendasExpedidas = totalPrendasExpedidas + expedicion.numeroPrendas;
        totalImporteExpedido  = totalImporteExpedido  + expedicion.importe;
      });
      
    } else {
      editingItem.value = {
        id: 0,
        incidencias: [] as PedidoIncidencia[]
      } as Pedido;
      totalPrendasExpedidas = 0;
      totalImporteExpedido  = 0;
    }
    totalesExpediciones.value.totalPrendasExpedidas =  totalPrendasExpedidas.toString() + "  ("+(Math.round((totalPrendasExpedidas / editingItem.value.numeroPrendasPrevistas ) * 10000)/100).toString()+" %)";
    totalesExpediciones.value.totalImporteExpedido  =  totalImporteExpedido.toString()  + "  ("+(Math.round((totalImporteExpedido  / editingItem.value.importePrendasPrevistas) * 10000)/100).toString()+" %)";

  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as PedidoView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiPedidos
      .deletePedido(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Pedido;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    if (editingItem.value.fechaPedido == "")
      editingItem.value.fechaPedido = undefined;
    if (editingItem.value.fechaServicioDesde == "")
      editingItem.value.fechaServicioDesde = undefined;
    if (editingItem.value.fechaServicioHasta == "")
      editingItem.value.fechaServicioHasta = undefined;

    await apiPedidos
      .savePedido(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );

    if (index == -1) return;
    const pedidoUpdated = await apiPedidos.getPedidoView(id);
    items.value[index] = pedidoUpdated;
    editingItem.value = { id: idNotEditItem } as Pedido;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addIncidencia() {
    //console.log("add incidencia");
    editingItem.value.incidencias.push({
      idIncidencia: getTempId(editingItem.value.incidencias, "idIncidencia"),
      idPedido: editingItem.value.id
    } as PedidoIncidencia);
    //console.log("fin add incidencia");
  }

  function deleteIncidencia(id: number) {
    editingItem.value.incidencias = editingItem.value.incidencias.filter(
      item => item.idIncidencia != id
    );
  }

  async function resetFilter() {
    let dateAux = new Date();
    dateAux.setFullYear(dateAux.getFullYear() - 2);
    const offset = dateAux.getTimezoneOffset();
    dateAux = new Date(dateAux.getTime() - offset * 60 * 1000);
    const dateAux2 = dateAux.toISOString().split("T")[0];

    filter.value = {
      desdeFechaPedido: dateAux2,
      soloColeccionesActivas: false,
      enPeriododeEntrega: false
    } as PedidoFilter;
  }

  function isValid(item: Pedido): boolean {
    return isValidObject(item, [
      "fechaPedido",
      "idColeccion",
      "idCliente",
      "numeroPrendasPrevistas",
      "importePrendasPrevistas"
    ]);
  }

  function isValidIncidencias(incidencias: PedidoIncidencia[]): boolean {
    return isValidArrayOfObjects(incidencias, ["fecha_alta"]);
  }

  return {
    pedidos: items,
    pedidosShowed: itemsShowed,
    pedidosFilter: filter,
    pedidosSearch: filterItems,
    pedidosResetFilter: resetFilter,
    pedidoEdit: editItem,
    pedidoAdd: addItem,
    pedidoDelete: deleteItem,
    pedidoCancel: cancelEdit,
    pedidoSave: saveEdit,
    pedidoInEdition: editingItem,
    pedidosIndex: currentRow,
    pedidosGotoNextPage: nextPage,
    pedidosGoToPreviousPage: previousPage,
    pedidoIsEditing: isEditing,
    pedidosIsLoading: isLoading,
    pedidosIsFirstPage: isFirstPage,
    pedidosIsLastPage: isLastPage,
    pedidoAddIncidencia: addIncidencia,
    pedidoDeleteIncidencia: deleteIncidencia,
    pedidoSuccess: success,
    pedidoError: error,
    pedidoErrorText: errorText,
    pedidoIsValid: isValid,
    pedidoIsValidIncidencias: isValidIncidencias,
    pedidoTotalesExpediciones: totalesExpediciones
  };
}
