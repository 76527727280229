import { ExpedicionView, ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import { Expedicion } from "@/types/expediciones/Expedicion";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";

export const apiExpediciones = {
  getExpedicionesView(filter: ExpedicionFilter): Promise<ExpedicionView[]> {
    return new Promise(resolve => {
      const url = "ExpedicionView/filter";
      axios
        .post<ExpedicionView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getExpedicionView(id: number): Promise<ExpedicionView> {
    return new Promise(resolve => {
      const url = "ExpedicionView/" + id;
      axios.get<ExpedicionView>(url).then(response => resolve(response.data));
    });
  },
  getExpedicion(id: number): Promise<Expedicion> {
    return new Promise(resolve => {
      const url = "Expedicion/" + id;
      axios.get<Expedicion>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveExpedicion(expedicion: Expedicion): Promise<Expedicion> {
    const newExpedicion = { ...expedicion };

    putIdOk(newExpedicion.vencimientos, "idVencimiento");
    
    return new Promise((resolve, reject) => {
      const url = "Expedicion";
      axios
        .post(url, newExpedicion)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteExpedicion(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Expedicion/" + id;
      axios
          .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
