
import { defineComponent, ref, watchEffect, computed } from "vue";
import { TipoTemporada } from "@/types/temporadas/TipoTemporada";

export default defineComponent({
  name: "selectTipoTemporada",
  props: {
    modelValue: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    labelValue: {
      type: String,
      default: "Tipo temporada"
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): TipoTemporada[] {
      const items = props.items as TipoTemporada[];
      const filterOk = filter.toUpperCase();
      //if (filter.length < 2) return [];
      return items.filter(item => !item.inexistente && item.descripcion.toUpperCase().indexOf(filterOk) > -1);
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      if (id != -1) {
        emit("update:modelValue", id);
      }
    }

    function clearValue() {
      emit("update:modelValue", null);
    }

    function valueShowed(): string {
      if (props.modelValue == 0) return "no hay tipo de temporada seleccionado";
      if (props.modelValue == undefined) return "seleccione un tipo de temporada";
      const items = props.items as TipoTemporada[];
      const resultado = items.find(x => x.id == props.modelValue);
      if (resultado == undefined) return "Cargando ...";
      return resultado.descripcion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      filter,
      myinput,
      showSearch,
      search,
      valueShowed,
      selectItem,
      clearValue,
      showError
    };
  }
});
