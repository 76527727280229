
import { defineComponent, ref, watch } from "vue";

import { useTransportistas } from "./useTransportistas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UINoItems from "@/components/UINoItems.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Home",
  components: {
    rowEdit,
    UITextInput,
    UITextArea,
    UINoItems,
    datatable,
    datatablerowbuttons,
    tabmenuedition,
    dirEdit,
    emailEdit,
    telefonoEdit
  },
  setup() {
    const headers = ["Nif", "Nombre", "Código postal", "Población"];
    const {
      transportistas,
      transportistasShowed,
      transportistasResetFilter,
      transportistaEdit,
      transportistaAdd,
      transportistaDelete,
      transportistaCancel,
      transportistaSave,
      transportistaInEdition,
      transportistasGotoNextPage,
      transportistasGoToPreviousPage,
      transportistaIsEditing,
      transportistasIsLoading,
      transportistasIsFirstPage,
      transportistasIsLastPage,
      transportistaAddDireccion,
      transportistaDeleteDireccion,
      transportistaAddEmail,
      transportistaDeleteEmail,
      transportistaAddTelefono,
      transportistaDeleteTelefono,
      transportistaSuccess,
      transportistaError,
      transportistaErrorText,
      transportistaIsValid,
      transportistaIsValidTelefonos,
      transportistaIsValidDirecciones,
      transportistaIsValidEmails
    } = useTransportistas();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();

    const showValidate = ref(false);

    const tabsEdit = ref([true, false, false, false]);
    const tabsEditIsValid = ref([true, true, true, true]);

    const transportistaWaitingForSave = ref(false);

    transportistasResetFilter();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = transportistaIsValid(
        transportistaInEdition.value
      );

      tabsEditIsValid.value[1] = transportistaIsValidDirecciones(
        transportistaInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = transportistaIsValidEmails(
        transportistaInEdition.value.emails
      );

      tabsEditIsValid.value[3] = transportistaIsValidTelefonos(
        transportistaInEdition.value.telefonos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function transportistaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      transportistaWaitingForSave.value = true;
      transportistaSave().then(() => {
        transportistaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function transportistaPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      transportistaCancel();
    }

    watch(
      [transportistaError, transportistaSuccess],
      ([transportistaError, transportistaSuccess]) => {
        if (transportistaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (transportistaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: transportistaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      // Tipos de factura
      transportistas,
      transportistasShowed,
      transportistaEdit,
      transportistaAdd,
      transportistaDelete,
      transportistaPreviousCancel,
      transportistaCancel,
      transportistaSave,
      transportistaInEdition,
      transportistasGotoNextPage,
      transportistasGoToPreviousPage,
      transportistaIsEditing,
      transportistasIsLoading,
      transportistasIsFirstPage,
      transportistasIsLastPage,
      transportistaPreviousSave,
      transportistaAddDireccion,
      transportistaDeleteDireccion,
      transportistaAddEmail,
      transportistaDeleteEmail,
      transportistaAddTelefono,
      transportistaDeleteTelefono,
      transportistaWaitingForSave,

      tabsEdit,
      changeActiveTab,
      showValidate,

      codigosPostalesPoblaciones,
      tiposTelefono
    };
  }
});
