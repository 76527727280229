<template>
  <label :for="myId()" class="block text-sm font-medium text-gray-700 pt-1">
    {{ myLabel() }}
  </label>

  <div class="w-full relative">
    <div
      class="flex justify-between w-full shadow-sm sm:text-sm border-gray-300 rounded-md border pt-2 pl-3 pr-2 pb-2"
      :class="{ 'border-red-500 border-2': showError }"
    >
      <div>
        <span @click.prevent="showSearch" class="overflow-hidden w-full h-6">{{
          valueShowed()
        }}</span>
      </div>
      <div class="flex">
        <svg
          class="w-5 h-5 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click="clearValue"
          v-if="modelValue != null"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click.prevent="showSearch"
          v-if="!isSearching"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click.prevent="showSearch"
          v-if="isSearching"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          ></path>
        </svg>
      </div>
    </div>
  </div>

  <div v-if="isSearching">
    <ul
      class="h-36 overflow-y-scroll border-gray-300 border-b border-l border-r"
    >
      <li
        class="border-gray-300 border-b py-1 px-2 hover:bg-gray-200 cursor-pointer sm:text-sm"
        v-for="(option, index) in options"
        :key="index"
        :value="option.id"
        @click="selectItem(option.id)"
      >
        {{ option[optionName] }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  name: "UIListBox",
  props: {
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    optionValue: {
      type: String,
      required: true
    },
    optionName: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);

    function showSearch() {
      isSearching.value = !isSearching.value;
    }

    const myLabel = function() {
      return props.label;
    };
    const myPlaceHolder = function() {
      if (props.placeholder) return props.placeholder;
      else return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };

    function selectItem(id: string) {
      isSearching.value = !isSearching.value;
      emit("update:modelValue", id);
    }

    function valueShowed() {
      let resultado = "";

      const items = props.options;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const actual = items[i] as any;
        if (actual[props.optionValue] == props.modelValue) {
          resultado = actual[props.optionName];
        }
      }

      return resultado;
    }
    function clearValue() {
      emit("update:modelValue", null);
    }
    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      showSearch,
      myLabel,
      myPlaceHolder,
      myId,
      selectItem,
      valueShowed,
      showError,
      clearValue
    };
  }
});
</script>
