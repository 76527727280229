<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Clientes
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="clientesRecaculateStates"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              ></path>
            </svg>
            Recalcular activos
          </button>
        </span>

        <span>
          <button
            type="button"
            class="inline-flex ml-3 items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>

        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
            <router-link
              target="_blank"
              to="/Clientes/ClientesConColecciones/99"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes con colecciones(todas)</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesConColecciones/0"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes con colecciones(del año)</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesConColecciones/1"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes con colecciones(de los últimos dos
              años)</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesPorProvincia/yes"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes por Provincia con Observaciones</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesPorProvincia/no"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes por Provincia sin Observaciones</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesPorMarca"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes por Marca</router-link
            >
            <router-link
              target="_blank"
              to="/Clientes/ClientesEmail"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Clientes con e-mail</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="clientesSearch"
    @reset="clientesResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <UITextInput v-model="clientesFilter.codigo" label="Código" />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UITextInput v-model="clientesFilter.nif" label="Nif" />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UITextInput v-model="clientesFilter.nombre" label="Nombre" />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UITextInput v-model="clientesFilter.tienda" label="Tienda" />
        </div>
        <div v-if="false" class="col-span-1 sm:col-span-1">
          <UITextInput
            v-model="clientesFilter.codigoFabricante"
            label="Código Fabricante"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <label
            for="codigoFabricante"
            class="block text-sm font-medium text-gray-700"
          >
            Estado
          </label>
          <select
            v-model="clientesFilter.activoSN"
            id="activoSN"
            name="activoSN"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todos</option>
            <option value="S">Activos</option>
            <option value="N">Inactivos</option>
          </select>
        </div>

        <div class="col-span-1 sm:col-span-2">
          <label
            for="direcciones"
            class="block text-sm font-medium text-gray-700"
          >
            Etiquetas
          </label>
          <template v-for="etiqueta in etiquetasCliente" :key="etiqueta.id">
            <div
              class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
              :class="[
                clientesFilter.etiquetas.includes(etiqueta.id)
                  ? 'bg-blue-200 text-blue-700'
                  : '',
              ]"
              @click="updateFilterEtiquetas(etiqueta.id)"
            >
              {{ etiqueta.descripcion }}
            </div>
          </template>
        </div>
        <div class="col-span-1 sm:col-span-2">
          <label
            for="direcciones"
            class="block text-sm font-medium text-gray-700"
          >
            Categorias
          </label>
          <template v-for="categoria in categoriasCliente" :key="categoria.id">
            <div
              class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
              :class="[
                clientesFilter.categorias.includes(categoria.id)
                  ? 'bg-blue-200 text-blue-700'
                  : '',
              ]"
              @click="updateFilterCategorias(categoria.id)"
            >
              {{ categoria.descripcion }}
            </div>
          </template>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-12 gap-2">
        <div class="col-span-1 sm:col-span-4">
          <selectTipoTemporada
            v-model="clientesFilter.pedidosIdTipoTemporada"
            :items="tiposTemporada"
            labelValue="Pedidos Tipo de temporada"
          />
        </div>
        <div class="col-span-1 sm:col-span-3">
          <selectMarca
            v-model="clientesFilter.pedidosIdMarca"
            :items="marcas"
          />
        </div>
        <div class="col-span-1 sm:col-span-2">
          <label
            for="pedidosDesdeAnyo"
            class="block text-sm font-medium text-gray-700"
          >
            Con pedidos en Años
          </label>
          <div class="flex gap-1">
            <UINumberInput
              v-model="clientesFilter.pedidosDesdeAnyo"
              placeholder="Desde Año"
              @blur="clientesFilter.pedidosHastaAnyo = clientesFilter.pedidosDesdeAnyo"
            />
            <UINumberInput
              v-model="clientesFilter.pedidosHastaAnyo"
              placeholder="Hasta Año"
            />
          </div>
        </div>
        <div class="col-span-1 sm:col-span-3">
          <selectColeccion
            v-model="clientesFilter.pedidosIdColeccion"
            :items="colecciones"
          />
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-2">
          <selectTipoTemporada
            v-model="clientesFilter.citasIdTipoTemporada"
            :items="tiposTemporada"
            labelValue="Citas Tipo Temporada"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <label
            for="citasDesdeAnyo"
            class="block text-sm font-medium text-gray-700"
          >
            Citas Años
          </label>
          <div class="flex gap-1">
            <UINumberInput
              v-model="clientesFilter.citasDesdeAnyo"
              placeholder="Desde año"
              @blur="clientesFilter.citasHastaAnyo = clientesFilter.citasDesdeAnyo"
            />
            <UINumberInput
              v-model="clientesFilter.citasHastaAnyo"
              placeholder="Hasta año"
            />
          </div>
        </div>
        <div class="col-span-1 sm:col-span-1">
          <label
            for="citasAsistidoSN"
            class="block text-sm font-medium text-gray-700"
          >
            Citas Asistencia
          </label>
          <select
            v-model="clientesFilter.citasAsistidoSN"
            id="citasAsistidoSN"
            name="citasAsistidoSN"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todos</option>
            <option value="A">Asistido</option>
            <option value="N">No asistido</option>
            <option value="S">Sin cita</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <label
            for="direcciones"
            class="block text-sm font-medium text-gray-700"
          >
            Direcciones
          </label>
          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
            :class="[
              clientesFilter.domicilio.includes('C')
                ? 'bg-blue-200 text-blue-700'
                : '',
            ]"
            @click="updateFilterDomicilio('C')"
          >
            correspondencia
          </div>
          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
            :class="[
              clientesFilter.domicilio.includes('F')
                ? 'bg-blue-200 text-blue-700'
                : '',
            ]"
            @click="updateFilterDomicilio('F')"
          >
            fiscal
          </div>
          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 cursor-pointer"
            :class="[
              clientesFilter.domicilio.includes('E')
                ? 'bg-blue-200 text-blue-700'
                : '',
            ]"
            @click="updateFilterDomicilio('E')"
          >
            entrega
          </div>
          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 cursor-pointer"
            :class="[
              clientesFilter.domicilio.includes('T')
                ? 'bg-blue-200 text-blue-700'
                : '',
            ]"
            @click="updateFilterDomicilio('T')"
          >
            tienda
          </div>
        </div>
        <div class="col-span-1 sm:col-span-1">
          <selectComunidad
            v-model="clientesFilter.idComunidadAutonoma"
            :items="comunidadesAutonomas"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIListBox v-if="1==2"
            v-model="clientesFilter.idProvincia"
            :options="provincias"
            optionValue="id"
            optionName="descripcion"
            label="Provincias"
          />
          <selectProvincia
            v-model="clientesFilter.idProvincia"
            :items="provincias"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">          
          <selectPoblacion
            v-model="clientesFilter.idPoblacion"
            :items="poblaciones"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UITextInput
            v-model="clientesFilter.codigoPostal"
            label="Código postal"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :headersSM="['Código', 'Nombre', 'Tiendas']"
      :numbersRows="clientes.length"
      :isEditing="clienteIsEditing"
      :isLoading="clientesIsLoading"
      :isFirstPage="clientesIsFirstPage"
      :isLastPage="clientesIsLastPage"
      @newitem="clienteAdd"
      @next="clientesGotoNextPage"
      @previous="clientesGoToPreviousPage"
    >
      <template
        v-for="cliente in clientesShowed"
        :key="cliente.id"
        class="border-b border-gray-200"
      >
        <tr class="" v-if="cliente.id != clienteInEdition.id">
          <td class="px-6 py-4  text-sm text-gray-500 ">
            <span
              @click="
                if (!clienteIsEditing) {
                  clienteEdit(cliente.id);
                }
              "
              class="cursor-pointer hover:font-extrabold hover:text-blue-800"
              >{{ cliente.nombre }}</span
            >
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 ">
            <div
              class="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-blue-200 text-blue-700 rounded-full mt-1 "
            >
              {{ cliente.numeroTiendas }}
            </div>
            {{ cliente.tiendas }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 overflow-x-hidden ">
            {{ cliente.categorias }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 ">
            <span v-html="cliente.etiquetas"></span>
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 ">
            {{ cliente.poblacion }}
          </td>
          <datatablerowbuttons
            @edititem="clienteEdit"
            @deleteitem="clienteDelete"
            :id="cliente.id"
            :isEditing="clienteIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="clientePreviousCancel"
              @save="clientePreviousSave"
              :waitingForSave="clienteWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  cliente
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                    :isValid="tabsEditIsValid[0]"
                  />
                  <tabmenuedition
                    v-if="!fusionTabs"
                    title="direcciones"
                    :numbersOfItems="clienteInEdition.direcciones.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="clienteAddDireccion"
                    :isValid="tabsEditIsValid[1]"
                  />
                  <tabmenuedition
                    v-if="!fusionTabs"
                    title="emails"
                    :numbersOfItems="clienteInEdition.emails.length"
                    :active="tabsEdit[2]"
                    :hasAddButton="true"
                    svgdata="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    @showItems="changeActiveTab(2)"
                    @addItem="clienteAddEmail"
                    :isValid="tabsEditIsValid[2]"
                  />
                  <tabmenuedition
                    v-if="!fusionTabs"
                    title="teléfonos"
                    :numbersOfItems="clienteInEdition.telefonos.length"
                    :active="tabsEdit[3]"
                    :hasAddButton="true"
                    svgdata="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    @showItems="changeActiveTab(3)"
                    @addItem="clienteAddTelefono"
                    :isValid="tabsEditIsValid[3]"
                  />
                  <tabmenuedition
                    v-if="false"
                    title="fechas tope"
                    :numbersOfItems="clienteInEdition.fechasTope.length"
                    :active="tabsEdit[4]"
                    :hasAddButton="true"
                    svgdata="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    @showItems="changeActiveTab(4)"
                    @addItem="clienteAddFechaTope"
                    :isValid="tabsEditIsValid[4]"
                  />
                  <tabmenuedition
                    v-if="false"
                    title="códigos"
                    :numbersOfItems="clienteInEdition.codigos.length"
                    :active="tabsEdit[5]"
                    :hasAddButton="true"
                    svgdata="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(5)"
                    @addItem="clienteAddCodigo"
                    :isValid="tabsEditIsValid[5]"
                  />
                </div>
                <p class="mb-5 text-sm text-gray-600">
                  Puede ir a los siguientes datos
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'pedidos',
                          params: { idCliente: clienteInEdition.id },
                        }"
                        target="_blank"
                      >
                        Pedidos del cliente
                      </router-link></span
                    ></span
                  >
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'tiendas',
                          params: { idCliente: clienteInEdition.id },
                        }"
                        target="_blank"
                      >
                        Tiendas del cliente
                      </router-link></span
                    ></span
                  >
                </p>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div class="grid grid-cols-3 sm:gap-6 ">
                    <div class="col-span-3 sm:col-span-1">
                      <UICheckBox
                        v-model="clienteInEdition.activo"
                        label="Activo"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UITextInput
                        v-model="clienteInEdition.codigo"
                        label="Código"
                        :required="true"
                        :showRequired="showValidate"
                        :maxLength="10"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UITextInput
                        v-model="clienteInEdition.nif"
                        label="Nif"
                        :maxLength="20"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <UITextInput
                        v-model="clienteInEdition.nombre"
                        label="Nombre"
                        :maxLength="100"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3 ">
                      <label
                        for="trasnpostista_nombre"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Categorias
                      </label>
                      <template
                        v-for="categoria in categoriasCliente"
                        :key="categoria.id"
                      >
                        <div
                          class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
                          :class="[
                            includeEditCategorias(categoria.id)
                              ? 'bg-blue-200 text-blue-700'
                              : '',
                          ]"
                          @click="updateEditCategorias(categoria.id)"
                        >
                          {{ categoria.descripcion }}
                        </div>
                      </template>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="trasnpostista_nombre"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Etiquetas
                      </label>
                      <template
                        v-for="etiqueta in etiquetasCliente"
                        :key="etiqueta.id"
                      >
                        <div
                          class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
                          :class="[
                            includeEditEtiquetas(etiqueta.id)
                              ? 'bg-blue-200 text-blue-700'
                              : '',
                          ]"
                          @click="updateEditEtiquetas(etiqueta.id)"
                        >
                          {{ etiqueta.descripcion }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    <UITextInput
                      v-model="clienteInEdition.url"
                      label="Url"
                      :maxLength="100"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="clienteInEdition.datosContacto"
                      label="Datos de contacto"
                      id="datoscontacto"
                      :maxLength="255"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="clienteInEdition.observaciones"
                      label="Observaciones"
                      :maxLength="4000"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="clienteInEdition.personaContacto"
                      label="Persona de contacto"
                      id="personaContacto"
                      :maxLength="100"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <headerChild
                    title="direcciones"
                    :numbersOfItems="clienteInEdition.direcciones.length"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @addItem="clienteAddDireccion"
                    :isValid="tabsEditIsValid[1]"
                  />
                  <UINoItems
                    :numbersOfItems="clienteInEdition.direcciones.length"
                    @addItem="clienteAddDireccion"
                  />
                  <template
                    v-for="dir in clienteInEdition.direcciones"
                    :key="dir.idDireccion"
                  >
                    <dir-edit
                      v-model:descripcion="dir.descripcion"
                      v-model:domicilio="dir.domicilio"
                      v-model:idCodigoPostal="dir.idCodigoPostal"
                      v-model:correspondencia="dir.correspondencia"
                      v-model:fiscal="dir.fiscal"
                      v-model:entrega="dir.entrega"
                      :id="dir.idDireccion"
                      :items="codigosPostalesPoblaciones"
                      @deleteItem="clienteDeleteDireccion(dir.idDireccion)"
                      :showRequired="showValidate"
                    />
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[2]">
                  <headerChild
                    title="emails"
                    :numbersOfItems="clienteInEdition.emails.length"
                    :hasAddButton="true"
                    svgdata="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    @addItem="clienteAddEmail"
                    :isValid="tabsEditIsValid[2]"
                  />
                  <UINoItems
                    :numbersOfItems="clienteInEdition.emails.length"
                    @addItem="clienteAddEmail"
                  />
                  <template
                    v-for="email in clienteInEdition.emails"
                    :key="email.idEmail"
                  >
                    <emailEdit
                      v-model:descripcion="email.descripcion"
                      v-model:email="email.email"
                      v-model:principal="email.principal"
                      :id="email.idEmail"
                      @deleteItem="clienteDeleteEmail(email.idEmail)"
                      :showRequired="showValidate"
                    />
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[3]">
                  <headerChild
                    title="teléfonos"
                    :numbersOfItems="clienteInEdition.telefonos.length"
                    :hasAddButton="true"
                    svgdata="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    @addItem="clienteAddTelefono"
                    :isValid="tabsEditIsValid[3]"
                  />
                  <UINoItems
                    :numbersOfItems="clienteInEdition.telefonos.length"
                    @addItem="clienteAddTelefono"
                  />
                  <template
                    v-for="telefono in clienteInEdition.telefonos"
                    :key="telefono.idTelefono"
                  >
                    <telefonoEdit
                      v-model:descripcion="telefono.descripcion"
                      v-model:telefono="telefono.telefono"
                      v-model:tipoTelefono="telefono.idTipoTelefono"
                      :id="telefono.idTelefono"
                      :tiposTelefono="tiposTelefono"
                      @deleteItem="clienteDeleteTelefono(telefono.idTelefono)"
                      :showRequired="showValidate"
                    />
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[4]">
                  <UINoItems
                    :numbersOfItems="clienteInEdition.fechasTope.length"
                    @addItem="clienteAddFechaTope"
                  />
                  <template
                    v-for="fechaTope in clienteInEdition.fechasTope"
                    :key="fechaTope.idFechaTope"
                  >
                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-6 lg:col-span-3  ">
                        <selectTipoTemporada
                          v-model="fechaTope.idTipoTemporada"
                          :items="tiposTemporada"
                          labelValue="Tipo Temporada"
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div
                        class="col-span-6 lg:col-span-3 gap-3 flex justify-between"
                      >
                        <div>
                          <UINumberInput
                            v-model="fechaTope.dia"
                            label="Día"
                            :id="
                              'fechatope_dia_' +
                                fechaTope.idFechaTope.toString()
                            "
                            :required="true"
                            :showRequired="showValidate"
                            :validData="
                              clienteIsValidFechasFechaTope(
                                fechaTope.mes,
                                fechaTope.dia
                              )
                            "
                          />
                        </div>
                        <div>
                          <UINumberInput
                            v-model="fechaTope.mes"
                            label="Mes"
                            :id="
                              'fechatope_mes_' +
                                fechaTope.idFechaTope.toString()
                            "
                            :required="true"
                            :showRequired="showValidate"
                            :validData="
                              clienteIsValidFechasFechaTope(
                                fechaTope.mes,
                                fechaTope.dia
                              )
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="flex items-end col-span-6 sm:col-span-6 lg:col-span-2"
                      >
                        <deleteButtonMulti
                          :id="fechaTope.idFechaTope"
                          @deleteMultiItem="clienteDeleteFechaTope"
                        />
                      </div>
                    </div>
                  </template>
                </template>

                <template v-if="tabsEdit[5]">
                  <UINoItems
                    :numbersOfItems="clienteInEdition.codigos.length"
                    @addItem="clienteAddCodigo"
                  />
                  <template
                    v-for="codigo in clienteInEdition.codigos"
                    :key="codigo.idCodigo"
                  >
                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <selectFabricante
                          v-model="codigo.idFabricante"
                          :items="fabricantes"
                          :id="
                            'codigos_fabricante_' + codigo.idCodigo.toString()
                          "
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-2">
                        <UITextInput
                          v-model="codigo.codigo"
                          label="Código"
                          :id="'codigos_codigo_' + codigo.idCodigo.toString()"
                          :required="true"
                          :showRequired="showValidate"
                          :maxLength="20"
                        />
                      </div>
                      <div class="flex items-end col-span-6 sm:col-span-1">
                        <deleteButtonMulti
                          :id="codigo.idCodigo"
                          @deleteMultiItem="clienteDeleteCodigo"
                        />
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useClientes } from "./useClientes";
import { ClienteFilter } from "@/types/clientes/clienteView";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useMarcas } from "../utilidades/useMarcas";
import { useProvincias } from "../poblaciones/useProvincias";
import { useComunidadesAutonomas } from "../poblaciones/useComunidadesAutonomas";
import { usePoblaciones } from "../poblaciones/usePoblaciones";
import { useEtiquetasCliente } from "./useEtiquetasCliente";
import { useCategoriasCliente } from "./useCategoriasCliente";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useFabricantes } from "@/views/fabricantes/useFabricantes";
import { useColecciones } from "@/views/colecciones/useColecciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import headerChild from "@/components/headerChild.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UINoItems from "@/components/UINoItems.vue";
import UIListBox from "@/components/UIListBox.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectPoblacion from "@/components/selectPoblacion.vue";
import selectProvincia from "@/components/selectProvincia.vue";
import selectComunidad from "@/components/selectComunidad.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";


import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Cliente",
  components: {
    rowEdit,
    UITextInput,
    UINumberInput,
    UITextArea,
    UIListBox,
    UICheckBox,
    datatable,
    datatablerowbuttons,
    tabmenuedition,
    deleteButtonMulti,
    dirEdit,
    emailEdit,
    telefonoEdit,
    selectFabricante,
    selectMarca,
    selectColeccion,
    selectPoblacion,
    selectProvincia,
    selectComunidad,
    selectTipoTemporada,
    headerChild,
    filterEdit,
    UINoItems,
  },
  setup() {
    const headers = [
      "Nombre",
      "Tiendas",
      "Categorias",
      "Etiquetas",
      "Poblacion",
    ];
    const {
      clientes,
      clientesShowed,
      clientesFilter,
      clientesSearch,
      clientesResetFilter,
      clienteEdit,
      clienteAdd,
      clienteDelete,
      clienteCancel,
      clienteSave,
      clienteInEdition,
      clientesIndex,
      clientesGotoNextPage,
      clientesGoToPreviousPage,
      clienteIsEditing,
      clientesIsLoading,
      clientesIsFirstPage,
      clientesIsLastPage,
      clienteAddDireccion,
      clienteDeleteDireccion,
      clienteAddEmail,
      clienteDeleteEmail,
      clienteAddTelefono,
      clienteDeleteTelefono,
      clienteAddFechaTope,
      clienteDeleteFechaTope,
      clienteAddCodigo,
      clienteDeleteCodigo,
      clienteSuccess,
      clienteError,
      clienteErrorText,
      clienteIsValid,
      clienteIsValidDirecciones,
      clienteIsValidEmails,
      clienteIsValidTelefonos,
      clienteIsValidFechasTope,
      clienteIsValidCodigos,
      clienteIsValidFechasFechaTope,
      clientesRecaculateStates,
    } = useClientes();

    const route = useRoute();
    const param = route.params.id;
    if (param != "") {
      //console.log("param=" + param);
      clientesFilter.value = {
        id: Number(param),
      } as ClienteFilter;
    } else {
      clientesResetFilter();
    }

    const { temporadas } = useTemporada();
    const { tiposTemporada } = useTipoTemporada();
    const { marcas } = useMarcas();
    const { poblaciones } = usePoblaciones();
    const { provincias } = useProvincias();
    const { comunidadesAutonomas } = useComunidadesAutonomas();
    const { etiquetasCliente } = useEtiquetasCliente();
    const { categoriasCliente } = useCategoriasCliente();
    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { fabricantes } = useFabricantes();
    const { colecciones } = useColecciones();
    const { tiposTelefono } = useTiposTelefono();

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);
    const fusionTabs = ref(true);

    const tabsEdit = ref([true, true, true, true, false, false]);
    const tabsEditIsValid = ref([true, true, true, true, true, true]);

    const clienteWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = clienteIsValid(clienteInEdition.value);

      tabsEditIsValid.value[1] = clienteIsValidDirecciones(
        clienteInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = clienteIsValidEmails(
        clienteInEdition.value.emails
      );

      tabsEditIsValid.value[3] = clienteIsValidTelefonos(
        clienteInEdition.value.telefonos
      );

      tabsEditIsValid.value[4] = clienteIsValidFechasTope(
        clienteInEdition.value.fechasTope
      );

      tabsEditIsValid.value[5] = clienteIsValidCodigos(
        clienteInEdition.value.codigos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;

      if (fusionTabs.value) {
        if (tabIndex == 0) {
          tabsEdit.value[1] = true;
          tabsEdit.value[2] = true;
          tabsEdit.value[3] = true;
        }
      }
      tabsEdit.value[tabIndex] = true;
    }

    function clientePreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      clienteWaitingForSave.value = true;
      clienteSave().then(() => {
        clienteWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function clientePreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      clienteCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function updateFilterDomicilio(tipoDomicilio: string) {
      if (clientesFilter.value.domicilio.includes(tipoDomicilio))
        clientesFilter.value.domicilio.splice(
          clientesFilter.value.domicilio.indexOf(tipoDomicilio),
          1
        );
      else clientesFilter.value.domicilio.push(tipoDomicilio);
    }

    function updateFilterEtiquetas(idEtiqueta: number) {
      if (clientesFilter.value.etiquetas.includes(idEtiqueta))
        clientesFilter.value.etiquetas.splice(
          clientesFilter.value.etiquetas.indexOf(idEtiqueta),
          1
        );
      else clientesFilter.value.etiquetas.push(idEtiqueta);
    }

    function updateFilterCategorias(idCategoria: number) {
      if (clientesFilter.value.categorias.includes(idCategoria))
        clientesFilter.value.categorias.splice(
          clientesFilter.value.categorias.indexOf(idCategoria),
          1
        );
      else clientesFilter.value.categorias.push(idCategoria);
    }

    function includeEditEtiquetas(idEtiqueta: number): boolean {
      let includeValue = false;
      clienteInEdition.value.etiquetas.forEach((x) => {
        if (x.idTipoEtiqueta == idEtiqueta) includeValue = true;
      });
      return includeValue;
    }

    function updateEditEtiquetas(idEtiqueta: number) {
      if (!includeEditEtiquetas(idEtiqueta)) {
        clienteInEdition.value.etiquetas.push({
          idEtiqueta: 0,
          idCliente: clienteInEdition.value.id,
          idTipoEtiqueta: idEtiqueta,
        });
      } else {
        let i: number;
        for (i = 0; i < clienteInEdition.value.etiquetas.length; i++) {
          if (clienteInEdition.value.etiquetas[i].idTipoEtiqueta == idEtiqueta)
            clienteInEdition.value.etiquetas.splice(i, 1);
        }
      }
    }

    function includeEditCategorias(idCategoria: number): boolean {
      let includeValue = false;
      clienteInEdition.value.categorias.forEach((x) => {
        if (x.idTipoCategoria == idCategoria) includeValue = true;
      });
      return includeValue;
    }

    function updateEditCategorias(idCategoria: number) {
      if (!includeEditCategorias(idCategoria)) {
        clienteInEdition.value.categorias.push({
          idCategoria: 0,
          idCliente: clienteInEdition.value.id,
          idTipoCategoria: idCategoria,
        });
      } else {
        let i: number;
        for (i = 0; i < clienteInEdition.value.categorias.length; i++) {
          if (
            clienteInEdition.value.categorias[i].idTipoCategoria == idCategoria
          )
            clienteInEdition.value.categorias.splice(i, 1);
        }
      }
    }

    watch([clienteError, clienteSuccess], ([clienteError, clienteSuccess]) => {
      if (clienteSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (clienteError) {
        createToast(
          {
            title: "Error 👎 ",
            description: clienteErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      clientes,
      clientesFilter,
      clientesSearch,
      clientesResetFilter,
      clienteEdit,
      clienteAdd,
      clienteDelete,
      clienteCancel,
      clienteSave,
      clienteInEdition,
      clientesIndex,
      clientesGotoNextPage,
      clientesGoToPreviousPage,
      clienteIsEditing,
      clientesIsLoading,
      clientesIsFirstPage,
      clientesIsLastPage,
      clienteAddDireccion,
      clienteDeleteDireccion,
      clienteAddEmail,
      clienteDeleteEmail,
      clienteAddTelefono,
      clienteDeleteTelefono,
      clienteAddFechaTope,
      clienteDeleteFechaTope,
      clienteAddCodigo,
      clienteDeleteCodigo,
      clientesRecaculateStates,

      clienteError,
      clientePreviousCancel,
      clientePreviousSave,
      clienteWaitingForSave,

      clientesShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      temporadas,
      tiposTemporada,
      marcas,
      colecciones,
      poblaciones,
      provincias,
      comunidadesAutonomas,
      etiquetasCliente,
      categoriasCliente,
      tiposTelefono,

      updateFilterDomicilio,
      updateFilterEtiquetas,
      updateFilterCategorias,

      includeEditEtiquetas,
      updateEditEtiquetas,
      includeEditCategorias,
      updateEditCategorias,
      showListados,

      codigosPostalesPoblaciones,
      fabricantes,

      showValidate,
      clienteIsValidFechasFechaTope,

      fusionTabs,      
    };
  },
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "Código";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Nombre";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Tiendas";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Categorias";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "Etiquetas";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(6):before {
    content: "Población";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
