import { onMounted, ref, Ref } from "vue";
import { apiMarcas } from "@/services/marcas/api";
import { MarcaView } from "@/types/marcas/MarcaView";

export function useMarcas() {
  const items: Ref<MarcaView[]> = ref([]);

  onMounted(async () => {
    items.value = await apiMarcas.getMarcasView();
  });

  return {
    marcas: items
  };
}
