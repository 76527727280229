// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getTempId(items: any[], field: string): number {
  const tempId = -100000;

  const Ids = items.map(a => a[field]);

  if (Ids.length == 0) return tempId;
  else {
    const minId = Ids.reduce((a, b) => {
      return Math.min(a, b);
    });

    return tempId < minId ? tempId : minId - 1;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function putIdOk(items: any[], field: string) {
  const tempId = -100000;

  for (let i = 0; i < items.length; i++) {
    items[i][field] = items[i][field] <= tempId ? 0 : items[i][field];
  }
}

export { getTempId, putIdOk };
