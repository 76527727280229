
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useClientes } from "./useClientes";
import { ClienteFilter } from "@/types/clientes/clienteView";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useMarcas } from "../utilidades/useMarcas";
import { useProvincias } from "../poblaciones/useProvincias";
import { useComunidadesAutonomas } from "../poblaciones/useComunidadesAutonomas";
import { usePoblaciones } from "../poblaciones/usePoblaciones";
import { useEtiquetasCliente } from "./useEtiquetasCliente";
import { useCategoriasCliente } from "./useCategoriasCliente";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useFabricantes } from "@/views/fabricantes/useFabricantes";
import { useColecciones } from "@/views/colecciones/useColecciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import headerChild from "@/components/headerChild.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UINoItems from "@/components/UINoItems.vue";
import UIListBox from "@/components/UIListBox.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectPoblacion from "@/components/selectPoblacion.vue";
import selectProvincia from "@/components/selectProvincia.vue";
import selectComunidad from "@/components/selectComunidad.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";


import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Cliente",
  components: {
    rowEdit,
    UITextInput,
    UINumberInput,
    UITextArea,
    UIListBox,
    UICheckBox,
    datatable,
    datatablerowbuttons,
    tabmenuedition,
    deleteButtonMulti,
    dirEdit,
    emailEdit,
    telefonoEdit,
    selectFabricante,
    selectMarca,
    selectColeccion,
    selectPoblacion,
    selectProvincia,
    selectComunidad,
    selectTipoTemporada,
    headerChild,
    filterEdit,
    UINoItems,
  },
  setup() {
    const headers = [
      "Nombre",
      "Tiendas",
      "Categorias",
      "Etiquetas",
      "Poblacion",
    ];
    const {
      clientes,
      clientesShowed,
      clientesFilter,
      clientesSearch,
      clientesResetFilter,
      clienteEdit,
      clienteAdd,
      clienteDelete,
      clienteCancel,
      clienteSave,
      clienteInEdition,
      clientesIndex,
      clientesGotoNextPage,
      clientesGoToPreviousPage,
      clienteIsEditing,
      clientesIsLoading,
      clientesIsFirstPage,
      clientesIsLastPage,
      clienteAddDireccion,
      clienteDeleteDireccion,
      clienteAddEmail,
      clienteDeleteEmail,
      clienteAddTelefono,
      clienteDeleteTelefono,
      clienteAddFechaTope,
      clienteDeleteFechaTope,
      clienteAddCodigo,
      clienteDeleteCodigo,
      clienteSuccess,
      clienteError,
      clienteErrorText,
      clienteIsValid,
      clienteIsValidDirecciones,
      clienteIsValidEmails,
      clienteIsValidTelefonos,
      clienteIsValidFechasTope,
      clienteIsValidCodigos,
      clienteIsValidFechasFechaTope,
      clientesRecaculateStates,
    } = useClientes();

    const route = useRoute();
    const param = route.params.id;
    if (param != "") {
      //console.log("param=" + param);
      clientesFilter.value = {
        id: Number(param),
      } as ClienteFilter;
    } else {
      clientesResetFilter();
    }

    const { temporadas } = useTemporada();
    const { tiposTemporada } = useTipoTemporada();
    const { marcas } = useMarcas();
    const { poblaciones } = usePoblaciones();
    const { provincias } = useProvincias();
    const { comunidadesAutonomas } = useComunidadesAutonomas();
    const { etiquetasCliente } = useEtiquetasCliente();
    const { categoriasCliente } = useCategoriasCliente();
    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { fabricantes } = useFabricantes();
    const { colecciones } = useColecciones();
    const { tiposTelefono } = useTiposTelefono();

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);
    const fusionTabs = ref(true);

    const tabsEdit = ref([true, true, true, true, false, false]);
    const tabsEditIsValid = ref([true, true, true, true, true, true]);

    const clienteWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = clienteIsValid(clienteInEdition.value);

      tabsEditIsValid.value[1] = clienteIsValidDirecciones(
        clienteInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = clienteIsValidEmails(
        clienteInEdition.value.emails
      );

      tabsEditIsValid.value[3] = clienteIsValidTelefonos(
        clienteInEdition.value.telefonos
      );

      tabsEditIsValid.value[4] = clienteIsValidFechasTope(
        clienteInEdition.value.fechasTope
      );

      tabsEditIsValid.value[5] = clienteIsValidCodigos(
        clienteInEdition.value.codigos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;

      if (fusionTabs.value) {
        if (tabIndex == 0) {
          tabsEdit.value[1] = true;
          tabsEdit.value[2] = true;
          tabsEdit.value[3] = true;
        }
      }
      tabsEdit.value[tabIndex] = true;
    }

    function clientePreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      clienteWaitingForSave.value = true;
      clienteSave().then(() => {
        clienteWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function clientePreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      clienteCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function updateFilterDomicilio(tipoDomicilio: string) {
      if (clientesFilter.value.domicilio.includes(tipoDomicilio))
        clientesFilter.value.domicilio.splice(
          clientesFilter.value.domicilio.indexOf(tipoDomicilio),
          1
        );
      else clientesFilter.value.domicilio.push(tipoDomicilio);
    }

    function updateFilterEtiquetas(idEtiqueta: number) {
      if (clientesFilter.value.etiquetas.includes(idEtiqueta))
        clientesFilter.value.etiquetas.splice(
          clientesFilter.value.etiquetas.indexOf(idEtiqueta),
          1
        );
      else clientesFilter.value.etiquetas.push(idEtiqueta);
    }

    function updateFilterCategorias(idCategoria: number) {
      if (clientesFilter.value.categorias.includes(idCategoria))
        clientesFilter.value.categorias.splice(
          clientesFilter.value.categorias.indexOf(idCategoria),
          1
        );
      else clientesFilter.value.categorias.push(idCategoria);
    }

    function includeEditEtiquetas(idEtiqueta: number): boolean {
      let includeValue = false;
      clienteInEdition.value.etiquetas.forEach((x) => {
        if (x.idTipoEtiqueta == idEtiqueta) includeValue = true;
      });
      return includeValue;
    }

    function updateEditEtiquetas(idEtiqueta: number) {
      if (!includeEditEtiquetas(idEtiqueta)) {
        clienteInEdition.value.etiquetas.push({
          idEtiqueta: 0,
          idCliente: clienteInEdition.value.id,
          idTipoEtiqueta: idEtiqueta,
        });
      } else {
        let i: number;
        for (i = 0; i < clienteInEdition.value.etiquetas.length; i++) {
          if (clienteInEdition.value.etiquetas[i].idTipoEtiqueta == idEtiqueta)
            clienteInEdition.value.etiquetas.splice(i, 1);
        }
      }
    }

    function includeEditCategorias(idCategoria: number): boolean {
      let includeValue = false;
      clienteInEdition.value.categorias.forEach((x) => {
        if (x.idTipoCategoria == idCategoria) includeValue = true;
      });
      return includeValue;
    }

    function updateEditCategorias(idCategoria: number) {
      if (!includeEditCategorias(idCategoria)) {
        clienteInEdition.value.categorias.push({
          idCategoria: 0,
          idCliente: clienteInEdition.value.id,
          idTipoCategoria: idCategoria,
        });
      } else {
        let i: number;
        for (i = 0; i < clienteInEdition.value.categorias.length; i++) {
          if (
            clienteInEdition.value.categorias[i].idTipoCategoria == idCategoria
          )
            clienteInEdition.value.categorias.splice(i, 1);
        }
      }
    }

    watch([clienteError, clienteSuccess], ([clienteError, clienteSuccess]) => {
      if (clienteSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (clienteError) {
        createToast(
          {
            title: "Error 👎 ",
            description: clienteErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      clientes,
      clientesFilter,
      clientesSearch,
      clientesResetFilter,
      clienteEdit,
      clienteAdd,
      clienteDelete,
      clienteCancel,
      clienteSave,
      clienteInEdition,
      clientesIndex,
      clientesGotoNextPage,
      clientesGoToPreviousPage,
      clienteIsEditing,
      clientesIsLoading,
      clientesIsFirstPage,
      clientesIsLastPage,
      clienteAddDireccion,
      clienteDeleteDireccion,
      clienteAddEmail,
      clienteDeleteEmail,
      clienteAddTelefono,
      clienteDeleteTelefono,
      clienteAddFechaTope,
      clienteDeleteFechaTope,
      clienteAddCodigo,
      clienteDeleteCodigo,
      clientesRecaculateStates,

      clienteError,
      clientePreviousCancel,
      clientePreviousSave,
      clienteWaitingForSave,

      clientesShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      temporadas,
      tiposTemporada,
      marcas,
      colecciones,
      poblaciones,
      provincias,
      comunidadesAutonomas,
      etiquetasCliente,
      categoriasCliente,
      tiposTelefono,

      updateFilterDomicilio,
      updateFilterEtiquetas,
      updateFilterCategorias,

      includeEditEtiquetas,
      updateEditEtiquetas,
      includeEditCategorias,
      updateEditCategorias,
      showListados,

      codigosPostalesPoblaciones,
      fabricantes,

      showValidate,
      clienteIsValidFechasFechaTope,

      fusionTabs,      
    };
  },
});
