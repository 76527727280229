
import { defineComponent } from "vue";

export default defineComponent({
  name: "UICheckBox",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const myLabel = function() {
      return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };
    return {
      myLabel,
      myId
    };
  }
});
