import { TiendaView, TiendaFilter } from "@/types/tiendas/TiendaView";
import { Tienda } from "@/types/tiendas/Tienda";

import axios from "../apiService";
import { cleanFilter } from "../apiUtility";
import { putIdOk } from "@/views/utilidades/useIDLibrary";

export const apiTiendas = {
  getTiendasView(filter: TiendaFilter): Promise<TiendaView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "TiendaView/filter";
      axios
        .post<TiendaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getTiendaView(id: number): Promise<TiendaView> {
    return new Promise(resolve => {
      const url = "TiendaView/" + id;
      axios.get<TiendaView>(url).then(response => resolve(response.data));
    });
  },
  getTienda(id: number): Promise<Tienda> {
    return new Promise(resolve => {
      const url = "Tienda/" + id;
      axios.get<Tienda>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveTienda(tienda: Tienda): Promise<Tienda> {
    const newTienda = { ...tienda };

    putIdOk(newTienda.direcciones, "idDireccion");
    putIdOk(newTienda.telefonos, "idTelefono");

    return new Promise((resolve, reject) => {
      const url = "Tienda";
      axios
        .post(url, newTienda)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTienda(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Tienda/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
