
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useFacturas } from "./useFacturas";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINoItems from "@/components/UINoItems.vue";
import headerChild from "@/components/headerChild.vue";

import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

import { useTiposFacturas } from "../configfacturas/useTiposFacturas";
import { useReceptoresFactura } from "../receptoresfactura/useReceptoresFactura";

import selectReceptorFactura from "@/components/selectReceptorFactura.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { FacturaFilter } from "@/types/facturas/FacturaView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Facturas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UIListBox,
    UICheckBox,
    UITextArea,
    UINoItems,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectReceptorFactura,
    headerChild
  },
  setup() {
    const headers = [
      "Factura",
      //"tipo",
      "Fecha",
      "Receptor",
      "Importe",
      "Pagada"
    ];

    const {
      facturas,
      facturasShowed,
      facturasFilter,
      facturasSearch,
      facturasResetFilter,
      facturaEdit,
      facturaAdd,
      facturaDelete,
      facturaCancel,
      facturaSave,
      facturaInEdition,
      facturasIndex,
      facturasGotoNextPage,
      facturasGoToPreviousPage,
      facturaIsEditing,
      facturasIsLoading,
      facturasIsFirstPage,
      facturasIsLastPage,
      facturaAddLinea,
      facturaDeleteLinea,
      facturaSuccess,
      facturaError,
      facturaErrorText,
      facturaIsValid,
      facturaIsValidLineas,
      facturaEditFirst
    } = useFacturas();

    const route = useRoute();
    const paramFactura = route.params.idFactura;
    const paramEditar = route.params.Editar;
    const paramReceptor = route.params.idReceptor;

    if (
      paramFactura != undefined &&
      paramFactura != "" &&
      paramFactura != "0"
    ) {
      facturasFilter.value = { id: Number(paramFactura) } as FacturaFilter;
      if (paramEditar != undefined && paramEditar != "" && paramEditar != "0") {
        //   facturaEdit(facturas.value[0].id);
        if (facturas.value != undefined) facturaEditFirst.value = true;
      }
    } else if (
      paramReceptor != undefined &&
      paramReceptor != "" &&
      paramReceptor != "0"
    )
      facturasFilter.value = {
        idReceptor: Number(paramReceptor)
      } as FacturaFilter;
    else
      facturasFilter.value = {
        anyo: new Date().getFullYear()
      } as FacturaFilter;

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);
    const fusionTabs = ref(true);

    const tabsEdit = ref([true, true]);

    const tabsEditIsValid = ref([true, true]);

    const { receptoresFactura } = useReceptoresFactura();

    const { tiposFacturas } = useTiposFacturas();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = facturaIsValid(facturaInEdition.value);

      tabsEditIsValid.value[1] = facturaIsValidLineas(
        facturaInEdition.value.lineas
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      if (fusionTabs.value) {
        if (tabIndex == 0) {
          tabsEdit.value[1] = true;
        }
      }

      tabsEdit.value[tabIndex] = true;
    }

    const facturaWaitingForSave = ref(false);

    function facturasPreviousSearch() {
      facturasSearch();
    }

    function facturaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      facturaWaitingForSave.value = true;
      facturaSave().then(() => {
        facturaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function facturaPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];

      changeActiveTab(0);
      facturaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function totalBaseImponible() {
      let totalBaseImponible = 0;
      facturaInEdition.value.lineas.forEach(linea => {
        totalBaseImponible +=
          Math.round(
            linea.unidades *
              (((linea.importe ? linea.importe : 0) *
                (100 - linea.porcentajeDescuento)) /
                100) *
              100
          ) / 100;
      });
      return totalBaseImponible;
    }

    watch([facturaError, facturaSuccess], ([facturaError, facturaSuccess]) => {
      if (facturaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito"
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a"
          }
        );
      }

      if (facturaError) {
        createToast(
          {
            title: "Error 👎 ",
            description: facturaErrorText.value
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true
          }
        );
      }
    });

    return {
      headers,
      facturas,
      facturasShowed,
      facturasFilter,
      facturasSearch,
      facturasResetFilter,
      facturaEdit,
      facturaAdd,
      facturaDelete,
      facturaCancel,
      facturaSave,
      facturaInEdition,
      facturasIndex,
      facturasGotoNextPage,
      facturasGoToPreviousPage,
      facturaIsEditing,
      facturasIsLoading,
      facturasIsFirstPage,
      facturasIsLastPage,
      facturaAddLinea,
      facturaDeleteLinea,
      totalBaseImponible,

      tabsEdit,
      tabsEditIsValid,
      changeActiveTab,

      isFiltering,
      showFilter,

      facturasPreviousSearch,
      facturaPreviousCancel,
      facturaPreviousSave,
      facturaWaitingForSave,

      showListados,
      tiposFacturas,
      receptoresFactura,

      showValidate,
      fusionTabs
    };
  }
});
