<template>
  <div class="grid grid-cols-12 gap-2">
    <div class="col-span-12 md:col-span-4">
      <UITextInput
        v-model="myDescripcion"
        label="Descripción"
        :id="'descripcion' + id"
        :maxLength="50"
      />
    </div>

    <div class="col-span-6 md:col-span-5">
      <UITextInput
        :id="'telefono' + id"
        v-model="myTelefono"
        label="Teléfono"
        :maxLength="20"
        :required="true"
        :showRequired="showRequired"
      />
    </div>

    <div class="col-span-6 md:col-span-2">
      <UIListBox
        v-model="myTipoTelefono"
        label="Tipo de teléfono"
        :id="'tipoTelefono' + id"
        :options="tiposTelefonosPosibles()"
        optionName="descripcion"
        optionValue="id"
      />
      <!--<label
        :for="'tipoTelefono' + id"
        class="block text-sm font-medium text-gray-700"
      >
        Tipo de teléfono
      </label>
      <select
        v-model="myTipoTelefono"
        :id="'tipoTelefono' + id"
        :name="'tipoTelefono' + id"
        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option
          v-for="tipo in tiposTelefonosPosibles()"
          :key="tipo.id"
          :value="tipo.id"
        >
          {{ tipo.descripcion }}
        </option>
      </select-->
    </div>

    <div class="flex items-end col-span-6 lg:col-span-1">
      <deleteButtonMulti :id="id" @deleteMultiItem="$emit('deleteItem')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import UITextInput from "@/components/UITextInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
//import { TipoTelefonoView } from "@/types/tipostelefono/TipoTelefonoView";

export default defineComponent({
  name: "telefonoEdit",
  components: {
    UITextInput,
    UIListBox,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number
    },
    descripcion: {
      type: String,
      required: false
    },
    telefono: {
      type: String,
      required: false
    },
    tipoTelefono: {
      type: Number,
      required: false
    },
    tiposTelefono: {
      type: Array,
      required: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:telefono",
    "update:tipoTelefono",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const myDescripcion = ref(props.descripcion);
    const myTelefono = ref(props.telefono);
    const myTipoTelefono = ref(props.tipoTelefono);

    watch(
      [myDescripcion, myTelefono, myTipoTelefono],
      ([myDescripcion, myEmail, myTipoTelefono]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:telefono", myEmail);
        emit("update:tipoTelefono", myTipoTelefono);
      }
    );

    function tiposTelefonosPosibles() {
      const myTiposTelefono: unknown[] = [];
      myTiposTelefono.push({ id: null, descripcion: "" });
      if (props.tiposTelefono != undefined) {
        myTiposTelefono.push(...props.tiposTelefono);
      }
      return myTiposTelefono;
    }

    return {
      myDescripcion,
      myTelefono,
      myTipoTelefono,
      tiposTelefonosPosibles
    };
  }
});
</script>
