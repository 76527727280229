import { computed, onMounted, ref, Ref } from "vue";
import { apiColecciones } from "@/services/colecciones/api";
import {
  ColeccionView,
  ColeccionFilter
} from "@/types/colecciones/ColeccionView";
import { Coleccion } from "@/types/colecciones/Coleccion";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useColecciones() {
  const idNotEditItem = -999;
  const items: Ref<ColeccionView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Coleccion);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);

  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({
  } as ColeccionFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("colecionFilter", JSON.stringify(filter.value));
    items.value = await apiColecciones.getColeccionesView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiColecciones.getColeccion(id);
      if (editingItem.value.fechaServicioHasta != undefined) {
        editingItem.value.fechaServicioHasta = editingItem.value.fechaServicioHasta.substr(
          0,
          10
        );
      }
      if (editingItem.value.fechaServicioDesde != undefined) {
        editingItem.value.fechaServicioDesde = editingItem.value.fechaServicioDesde.substr(
          0,
          10
        );
      }
    } else {
      editingItem.value = {
        id: 0,
        activa: true,
        crearFacturaPagada: false
      } as Coleccion;

      const coleccionString = localStorage.getItem("ultimaColeccion");
      let ultimaColeccion = {} as Coleccion;
      if (coleccionString != undefined) {
        ultimaColeccion = JSON.parse(coleccionString);
        editingItem.value.idTemporada = ultimaColeccion.idTemporada;
        editingItem.value.fechaServicioDesde =
          ultimaColeccion.fechaServicioDesde;
        editingItem.value.fechaServicioHasta =
          ultimaColeccion.fechaServicioHasta;
      }
    }
  }

  function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as ColeccionView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiColecciones
      .deleteColeccion(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Coleccion;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    if (editingItem.value.fechaServicioDesde == "")
      editingItem.value.fechaServicioDesde = undefined;
    if (editingItem.value.fechaServicioHasta == "")
      editingItem.value.fechaServicioHasta = undefined;

    await apiColecciones
      .saveColeccion(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    localStorage.setItem("ultimaColeccion", JSON.stringify(editingItem.value));

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );

    if (index == -1) return;
    const coleccionUpdated = await apiColecciones.getColeccionView(id);
    items.value[index] = coleccionUpdated;
    editingItem.value = { id: idNotEditItem } as Coleccion;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  async function resetFilter() {
    filter.value = {
      activa: "S",
      inexistenteSN: "N"
    } as ColeccionFilter;
  }

  function isValid(item: Coleccion): boolean {
    return isValidObject(item, ["descripcion", "idMarca", "idTemporada"]);
  }

  return {
    colecciones: items,
    coleccionesShowed: itemsShowed,
    coleccionesFilter: filter,
    coleccionesSearch: filterItems,
    coleccionesResetFilter: resetFilter,
    coleccionEdit: editItem,
    coleccionAdd: addItem,
    coleccionDelete: deleteItem,
    coleccionCancel: cancelEdit,
    coleccionSave: saveEdit,
    coleccionInEdition: editingItem,
    coleccionIndex: currentRow,
    coleccionesGotoNextPage: nextPage,
    coleccionesGoToPreviousPage: previousPage,
    coleccionIsEditing: isEditing,
    coleccionesIsLoading: isLoading,
    coleccionesIsFirstPage: isFirstPage,
    coleccionesIsLastPage: isLastPage,
    coleccionSuccess: success,
    coleccionError: error,
    coleccionErrorText: errorText,
    coleccionIsValid: isValid
  };
}
