
import { defineComponent } from "vue";

export default defineComponent({
  name: "rowEdit",
  props: {
    waitingForSave: {
      type: Boolean,
      requiered: false
    }
  },
  computed: {
    waitingForSaveItem(): boolean {
      return this.waitingForSave;
    }
  }
});
