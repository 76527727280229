import { computed, onMounted, ref, Ref } from "vue";
import { apiPoblaciones } from "@/services/poblaciones/api";
import { ComunidadAutonoma } from "@/types/poblaciones/ComunidadAutonoma";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useComunidadesAutonomas() {
  const idNotEditItem = -999;
  const items: Ref<ComunidadAutonoma[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as ComunidadAutonoma);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const nombreFilter = ref("");

  const loadedData = ref(false);

  const ComunidadesFiltered = computed(() => {
    console.log(nombreFilter.value);
    if(nombreFilter.value.length > 1) {
      setTotalNumberOfRows(
        items.value.filter(item => item.descripcion.toUpperCase().indexOf(nombreFilter.value.toUpperCase()) > -1)
          .length
      );
      return items.value.filter(
        item => item.descripcion.toUpperCase().indexOf(nombreFilter.value.toUpperCase()) > -1
      );
    }
    else {
      setTotalNumberOfRows(items.value.length);
      return items.value;
    }
  });

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiPoblaciones.getComunidadesAutonomas();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
    loadedData.value = true;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      descripcion: found.descripcion
    } as ComunidadAutonoma;
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0
    } as ComunidadAutonoma);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiPoblaciones
      .deleteComunidadAutonoma(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as ComunidadAutonoma;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiPoblaciones
      .saveComunidadAutonoma(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id == 0) found.id = id;
    found.descripcion = editingItem.value.descripcion;
    editingItem.value = { id: idNotEditItem } as ComunidadAutonoma;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return ComunidadesFiltered.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: ComunidadAutonoma): boolean {
    return isValidObject(item, ["descripcion"]);
  }

  return {
    comunidadesAutonomas: ComunidadesFiltered,
    comunidadesAutonomasShowed: itemsShowed,
    comunidadAutonomaEdit: editItem,
    comunidadAutonomaAdd: addItem,
    comunidadAutonomaDelete: deleteItem,
    comunidadAutonomaCancel: cancelEdit,
    comunidadAutonomaSave: saveEdit,
    comunidadAutonomaInEdition: editingItem,
    comunidadAutonomaGotoNextPage: nextPage,
    comunidadAutonomaGoToPreviousPage: previousPage,
    comunidadAutonomaIsEditing: isEditing,
    comunidadesAutonomasIsLoading: isLoading,
    comunidadesAutonomasIsFirstPage: isFirstPage,
    comunidadesAutonomasIsLastPage: isLastPage,
    comunidadesAutonomasloadedData: loadedData,
    comunidadesAutonomasSuccess: success,
    comunidadesAutonomasError: error,
    comunidadesAutonomasErrorText: errorText,
    comunidadesAutonomasIsValid: isValid,
    comunidadesAutonomasNombreFilter: nombreFilter
  };
}
