import { TransportistaView, TransportistaFilter } from "@/types/transportistas/TransportistaView";
import { Transportista } from "@/types/transportistas/Transportista";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiTransportistas = {
  /*getTransportistasView(): Promise<TransportistaView[]> {
    return new Promise(resolve => {
      const url = "TransportistaView/filter";
      axios
        .post<TransportistaView[]>(url, {})
        .then(response => resolve(response.data));
    });
  },*/
  getTransportistasView(filter: TransportistaFilter): Promise<TransportistaView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "TransportistaView/filter";
      axios
        .post<TransportistaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getTransportistaView(id: number): Promise<TransportistaView> {
    return new Promise(resolve => {
      const url = "TransportistaView/" + id;
      axios
        .get<TransportistaView>(url)
        .then(response => resolve(response.data));
    });
  },
  getTransportista(id: number): Promise<Transportista> {
    return new Promise(resolve => {
      const url = "Transportista/" + id;
      axios.get<Transportista>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveTransportista(transportista: Transportista): Promise<Transportista> {
    const newTransportista = { ...transportista };

    putIdOk(newTransportista.direcciones, "idDireccion");
    putIdOk(newTransportista.emails, "idEmail");
    putIdOk(newTransportista.telefonos, "idTelefono");
    return new Promise((resolve, reject) => {
      const url = "Transportista";
      axios
        .post(url, newTransportista)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTransportista(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Transportista/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
