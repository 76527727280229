
import { defineComponent, ref, watch } from "vue";
import { RouteLocationRaw, Router } from "vue-router";

import { useComisiones } from "@/views/comisiones/useComisiones";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useMarcas } from "../utilidades/useMarcas";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFacturas } from "../facturas/useFacturas";

import rowEdit from "@/components/rowEdit.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectFactura from "@/components/selectFactura.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Comisiones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UIDateInput,
    UINumberInput,
    filterEdit,
    selectFabricante,
    selectMarca,
    selectFactura,
    selectTipoTemporada
  },
  setup() {
    const headers = [
      "Fecha",
      "Fabricante",
      "Marca",
      "Tipo Temporada",
      "Año",
      "Importe",
      "Número Factura"
    ];
    const {
      comisiones,
      comisionesShowed,
      comisionesFilter,
      comisionesResetFilter,
      comisionesSearch,
      comisionEdit,
      comisionAdd,
      comisionDelete,
      comisionCancel,
      comisionSave,
      comisionInEdition,
      comisionesGotoNextPage,
      comisionesGoToPreviousPage,
      comisionIsEditing,
      comisionesIsLoading,
      comisionesIsFirstPage,
      comisionesIsLastPage,
      comisionSuccess,
      comisionError,
      comisionErrorText,
      comisionIsValid,
      comisionCreateFactura,
      comisionFacturaCreada
    } = useComisiones();

    const { marcas } = useMarcas();
    const { fabricantes } = useFabricantes();
    const { tiposTemporada } = useTipoTemporada();
    const { facturas, facturasSearch } = useFacturas();

    const isFiltering = ref(false);

    const showListados = ref(false);

    const showValidate = ref(false);

    const comisionWaitingForSave = ref(false);

    /*function comisionSubmit() {
      comisionSave();
      if (showValidate.value) return;
    }*/

    function comisionPreviousSave() {
      showValidate.value = !comisionIsValid(comisionInEdition.value);
      if (showValidate.value) return;

      comisionWaitingForSave.value = true;
      comisionSave().then(() => {
        comisionWaitingForSave.value = false;
      });
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function createFactura() {
      comisionCreateFactura();
    }

    watch([comisionFacturaCreada], ([facturaCreada]) => {
      if (facturaCreada) {
        facturasSearch();
        comisionFacturaCreada.value = false;
        window.open(
          "/app/facturas/" + comisionInEdition.value.idFactura + "/1",
          "_blank"
        );
      }
    });

    watch(
      [comisionError, comisionSuccess],
      ([comisionError, comisionSuccess]) => {
        if (comisionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (comisionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: comisionErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      comisiones,
      comisionesShowed,
      comisionesFilter,
      comisionesResetFilter,
      comisionesSearch,
      comisionEdit,
      comisionAdd,
      comisionDelete,
      comisionCancel,
      comisionSave,
      comisionInEdition,
      comisionesGotoNextPage,
      comisionesGoToPreviousPage,
      comisionIsEditing,
      comisionesIsLoading,
      comisionesIsFirstPage,
      comisionesIsLastPage,
      comisionPreviousSave,
      comisionWaitingForSave,

      showFilter,
      showListados,
      isFiltering,
      showValidate,

      marcas,
      fabricantes,
      tiposTemporada,
      facturas,
      createFactura
    };
  }
});
