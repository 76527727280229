import { MarcaView } from "@/types/marcas/MarcaView";
import { Marca } from "@/types/marcas/Marca";
import axios from "../apiService";

export const apiMarcas = {
  // Tipos facturas
  getMarcasView(): Promise<MarcaView[]> {
    return new Promise(resolve => {
      const url = "MarcaView/filter";
      axios.post<MarcaView[]>(url, {}).then(response => resolve(response.data));
    });
  },
  getMarcaView(id: number): Promise<MarcaView> {
    return new Promise(resolve => {
      const url = "MarcaView/" + id;
      axios.get<MarcaView>(url).then(response => resolve(response.data));
    });
  },
  getMarca(id: number): Promise<Marca> {
    return new Promise(resolve => {
      const url = "Marca/" + id;
      axios.get<Marca>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveMarca(tipo: Marca): Promise<Marca> {
    return new Promise((resolve, reject) => {
      const url = "Marca";
      axios
        .post(url, tipo)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response.status);
        });
    });
  },
  deleteMarca(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Marca/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response.status);
        });
    });
  }
};
