<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <slot name="info"></slot>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form action="#" method="POST" @keyup.esc="$emit('cancel')">
            <div class="shadow sm:rounded-md relative">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6 ">
                <slot></slot>
              </div>
              <div
                class="absolute inset-x-0 top-0 h-full bg-gray-300 grid grid-cols-1 gap-4"
                v-if="waitingForSaveItem"
              >
                <div class="grid grid-cols-1 gap-4">
                  <div
                    class="justify-self-center inline-flex items-center px-4 py-2 text-base leading-6 font-medium text-gray-700 transition ease-in-out duration-150 cursor-not-allowed"
                  >
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Grabando datos...
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="px-4 py-3 bg-gray-300 text-right sm:px-6">
      <button
        @click.prevent="$emit('cancel')"
        class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancelar
      </button>
      <button
        type="submit"
        @click.prevent="$emit('save')"
        class="ml-5 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Grabar
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "rowEdit",
  props: {
    waitingForSave: {
      type: Boolean,
      requiered: false
    }
  },
  computed: {
    waitingForSaveItem(): boolean {
      return this.waitingForSave;
    }
  }
});
</script>
