import { computed, ref, Ref } from "vue";
import { apiAgenda } from "@/services/agenda/api";
import { CitaView, CitaFilter } from "@/types/agenda/CitaView";
import { Cita } from "@/types/agenda/Cita";

import { isValidObject } from "../utilidades/useValidations";

export function useAgenda() {
  const idNotEditItem = -999;
  const items: Ref<CitaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Cita);

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as CitaFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("agendaFilter", JSON.stringify(filter.value));
    items.value = await apiAgenda.getAgendaView(filter.value);
    isLoading.value = false;
  }

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiAgenda.getCita(id);
      if (editingItem.value.fecha != undefined) {
        editingItem.value.fecha = editingItem.value.fecha.substr(0, 10);
      }
    } else {
      editingItem.value = {
        id: 0,
        asistido: false
      } as Cita;

      const usuario = localStorage.getItem("username");
      if (usuario != undefined) {
        editingItem.value.usuario = usuario.toString();
      }

      const cita = localStorage.getItem("ultimaCita");

      if (cita != undefined) {
        const ultimaCita = JSON.parse(cita) as Cita;
        editingItem.value.idTipoTemporada = ultimaCita.idTipoTemporada;
        editingItem.value.anyo = ultimaCita.anyo;
      }
    }
  }

  async function addItem() {
    items.value.push({
      id: 0
    } as CitaView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    console.log("delete", id);
    success.value = false;
    error.value = false;
    await apiAgenda
      .deleteCita(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
  }

  async function sendEmail(id: number) {
    console.log("email", id);
    success.value = false;
    error.value = false;
    await apiAgenda
      .sendEmailCita(id)
      .then(() => {
        success.value = true;
        console.log("ok uses");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
        console.log("error uses");
      });
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(0, 1);
    editingItem.value = { id: idNotEditItem } as Cita;
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    if (editingItem.value.fecha == "") editingItem.value.fecha = undefined;

    await apiAgenda
      .saveCita(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
        
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    localStorage.setItem("ultimaCita", JSON.stringify(editingItem.value));

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    console.log("index", index);
    if (index == -1) return;

    const citaUpdated = await apiAgenda.getCitaView(id);
    items.value[index] = citaUpdated;
    editingItem.value = { id: idNotEditItem } as Cita;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != -1;
  });

  async function resetFilter() {
    filter.value = {
      desdeFechaCita: new Date().getFullYear().toString() + "-01" + "-01",
      asistidoSN: "T"
    } as CitaFilter;
  }

  function isValid(item: Cita): boolean {
    return isValidObject(item, ["fecha"]);
  }

  return {
    citas: items,
    citasFilter: filter,
    citasSearch: filterItems,
    citasResetFilter: resetFilter,
    citaEdit: editItem,
    citaAdd: addItem,
    citaDelete: deleteItem,
    citaCancel: cancelEdit,
    citaSave: saveEdit,
    citaInEdition: editingItem,
    citaIsEditing: isEditing,
    citasIsLoading: isLoading,
    citaSuccess: success,
    citaError: error,
    citaErrorText: errorText,
    citaIsValid: isValid,
    citaSendEmail: sendEmail
  };
}
