<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 md:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <div v-if="isLoading" class="bg-gray-400">
            <div class="rounded-t-xl overflow-hidden p-10">
              <div class="flex justify-around">
                <span class="inline-flex">
                  <div
                    class="inline-flex items-center px-4 py-2 text-base leading-6 font-medium text-white transition ease-in-out duration-150 cursor-not-allowed"
                  >
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Cargando
                  </div>
                </span>
              </div>
            </div>
          </div>
          <table
            v-if="!isLoading"
            class="min-w-full divide-y divide-gray-200 table-fixed overflow-scroll"
          >
            <thead class="bg-gray-50">
              <tr class="hidden sm:table-row">
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  v-for="header in headers"
                  :key="header"
                >
                  {{ header }}
                </th>
                <th scope="col" class="relative px-2 py-2 text-right">
                  <span class="sr-only">Edit</span>
                  <button
                    @click="$emit('newitem')"
                    class="btn-add-table"
                    :disabled="isEditing"
                  >
                    Nuevo
                  </button>
                </th>
              </tr>
            </thead>

            <tfoot class="bg-gray-50">
              <tr>
                <th
                  :colspan="headers.length + 1"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500  tracking-wider"
                >
                  <div class="flex justify-between">
                    <p class="mt-3 text-sm text-gray-700">
                      Mostrando {{ actualPage + 1 }} a
                      {{ Math.min(actualPage + 10, numbersRows) }} de
                      {{ numbersRows }}
                      resultados
                    </p>
                    <div>
                      <nav
                        class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                        aria-label="Pagination"
                      >
                        <a
                          href="#"
                          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          :disbled="isFirstPage"
                          @click.prevent="previousPage()"
                        >
                          <span class="sr-only">Previous</span>
                          <svg
                            class="h-5 w-5"
                            x-description="Heroicon name: solid/chevron-left"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </a>

                        <a
                          href="#"
                          class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          :disbled="isLastPage"
                          @click.prevent="nextPage()"
                        >
                          <span class="sr-only">Next</span>
                          <svg
                            class="h-5 w-5"
                            x-description="Heroicon name: solid/chevron-right"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </a>
                      </nav>
                    </div>
                  </div>
                </th>
              </tr>
            </tfoot>
            <tbody class="bg-white divide-y divide-gray-200">
              <slot></slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "datatable",
  props: {
    headers: {
      type: Array,
      required: true
    },
    numbersRows: {
      type: Number,
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    isFirstPage: {
      type: Boolean,
      required: false
    },
    isLastPage: {
      type: Boolean,
      required: false
    }
  },
  emits: ["previous", "next"],
  setup(props, { emit }) {
    const actualPage = ref(0);
    const numbersOfRowsPerPage = 10;

    function previousPage() {
      if (props.isFirstPage) return;
      actualPage.value = actualPage.value - numbersOfRowsPerPage;
      emit("previous");
    }

    function nextPage() {
      if (props.isLastPage) return;
      actualPage.value = actualPage.value + numbersOfRowsPerPage;
      emit("next");
    }

    return {
      actualPage,
      previousPage,
      nextPage
    };
  }
});
</script>
