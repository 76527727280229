<template>
  <div class="pt-10 mb-4 tipos">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
    >
      Tipos de factura
    </h2>
  </div>
  <div class="tipos">
    <datatable
      :headers="headersTipos"
      :numbersRows="tiposFacturas.length"
      :isEditing="tipoFacturaIsEditing"
      :isLoading="tiposFacturasIsLoading"
      :isFirstPage="tiposFacturasIsFirstPage"
      :isLastPage="tiposFacturasIsLastPage"
      @newitem="tipoFacturaAdd"
      @next="tiposFacturasGotoNextPage"
      @previous="tiposFacturasGoToPreviousPage"
    >
      <template
        v-for="tipo in tiposFacturasShowed"
        :key="tipo.id"
        class="border-b border-gray-200"
      >
        <tr v-if="tipo.id != tipoFacturaInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ tipo.descripcion }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ tipo.abreviatura }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ tipo.serie }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span v-if="tipo.modeloLineasExtendido">✅</span>
            <span v-else>🚫</span>
          </td>
          <datatablerowbuttons
            @edititem="tipoFacturaEdit"
            @deleteitem="tipoFacturaDelete"
            :id="tipo.id"
            :isEditing="tipoFacturaIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headersTipos.length + 1" class="no-columns">
            <rowEdit
              @cancel="tiposFacturaPreviousCancel"
              @save="tipoFacturaSubmit"
              :waitingForSave="tiposFacturaWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Tipo de factura
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
              </template>
              <template v-slot:default>
                <div class="grid grid-cols-1 gap-6">
                  <div class="col-span-1 sm:col-span-1">
                    <UITextInput
                      v-model="tipoFacturaInEdition.descripcion"
                      label="Descripción"
                      :required="true"
                      :showRequired="showValidateTipoFactura"
                      :maxLength="50"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-6 sm:col-span-4">
                    <UITextInput
                      v-model="tipoFacturaInEdition.abreviatura"
                      label="Abreviatura"
                      :required="true"
                      :showRequired="showValidateTipoFactura"
                      :maxLength="10"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-4">
                    <UIListBox
                      v-model="tipoFacturaInEdition.idSerie"
                      :options="seriesFacturas"
                      optionValue="id"
                      optionName="descripcion"
                      label="Serie"
                      :required="true"
                      :showRequired="showValidateTipoFactura"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-4">
                    <UICheckBox
                      v-model="tipoFacturaInEdition.modeloLineasExtendido"
                      label="Modelo lineas extendido"
                      id="modeloextendido"
                    />
                  </div>
                </div>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
  <div class="pt-10 md:grid md:grid-cols-1 md:gap-6">
    <div class="col-span-1 sm: col-span2 series">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-4"
      >
        Series de factura
      </h2>

      <datatable
        :headers="headersSeries"
        :numbersRows="seriesFacturas.length"
        :isEditing="serieFacturaIsEditing"
        :isLoading="seriesFacturasIsLoading"
        :isFirstPage="seriesFacturasIsFirstPage"
        :isLastPage="seriesFacturasIsLastPage"
        @newitem="serieFacturaAdd"
        @next="seriesFacturasGotoNextPage"
        @previous="seriesFacturasGoToPreviousPage"
      >
        <template
          v-for="serie in seriesFacturasShowed"
          :key="serie.id"
          class="border-b border-gray-200"
        >
          <tr v-if="serie.id != serieFacturaInEdition.id">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ serie.descripcion }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ serie.abreviatura }}
            </td>
            <datatablerowbuttons
              @edititem="serieFacturaEdit"
              @deleteitem="serieFacturaDelete"
              :id="serie.id"
              :isEditing="serieFacturaIsEditing"
            />
          </tr>
          <tr v-else>
            <td :colspan="headersSeries.length + 1" class="no-columns">
              <rowEdit
                @cancel="seriesFacturaPreviousCancel"
                @save="seriesFacturasSubmit"
                :waitingForSave="seriesFacturaWaitingForSave"
              >
                <template v-slot:info>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Serie de factura
                  </h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Introduzca los datos indicados.
                  </p>
                </template>
                <template v-slot:default>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="serieFacturaInEdition.descripcion"
                        label="Descripción"
                        :required="true"
                        :showRequired="showValidateSerieFactura"
                        :maxLength="30"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="serieFacturaInEdition.abreviatura"
                        label="Abreviatura"
                        :required="true"
                        :showRequired="showValidateSerieFactura"
                        :maxLength="5"
                      />
                    </div>
                  </div>
                </template>
              </rowEdit>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <div class="pt-10 col-span-1 sm: col-span2 formas hidden">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-4"
      >
        Formas de pago
      </h2>

      <datatable
        :headers="headersFormas"
        :numbersRows="formasPagos.length"
        :isEditing="formaPagoIsEditing"
        :isLoading="formasPagosIsLoading"
        :isFirstPage="formasPagosIsFirstPage"
        :isLastPage="formasPagosIsLastPage"
        @newitem="formaPagoAdd"
        @next="formasPagosGotoNextPage"
        @previous="formasPagosGoToPreviousPage"
      >
        <template
          v-for="forma in formasPagosShowed"
          :key="forma.id"
          class="border-b border-gray-200"
        >
          <tr v-if="forma.id != formaPagoInEdition.id">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ forma.descripcion }}
            </td>
            <datatablerowbuttons
              @edititem="formaPagoEdit"
              @deleteitem="formaPagoDelete"
              :id="forma.id"
              :isEditing="formaPagoIsEditing"
            />
          </tr>
          <tr v-else>
            <td :colspan="headersFormas.length + 1" class="no-columns">
              <rowEdit
                @cancel="formasPagoPreviousCancel"
                @save="formasPagosSubmit"
                :waitingForSave="formasPagoWaitingForSave"
              >
                <template v-slot:info>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Formas de pago
                  </h3>
                  <p class="mt-1 text-sm text-gray-600">
                    Introduzca los datos indicados.
                  </p>
                </template>
                <template v-slot:default>
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-1">
                      <UITextInput
                        v-model="formaPagoInEdition.descripcion"
                        label="Descripción"
                        :required="true"
                        :showRequired="showValidateFormasPago"
                        :maxLength="30"
                      />
                    </div>
                  </div>
                </template>
              </rowEdit>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useTiposFacturas } from "./useTiposFacturas";
import { useSeriesFacturas } from "./useSeriesFacturas";
import { useFormasPago } from "./useFormasPago";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UIListBox from "@/components/UIListBox.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "ConfigFacturas",
  components: {
    UITextInput,
    UICheckBox,
    UIListBox,
    rowEdit,
    datatable,
    datatablerowbuttons
  },
  setup() {
    const headersTipos = [
      "Descripción",
      "Abreviatura",
      "Serie",
      "Modelo extendido"
    ];
    const headersSeries = ["Descripción", "Abreviatura"];
    const headersFormas = ["Descripción"];

    const {
      tiposFacturas,
      tiposFacturasShowed,
      tipoFacturaEdit,
      tipoFacturaAdd,
      tipoFacturaDelete,
      tipoFacturaCancel,
      tipoFacturaSave,
      tipoFacturaInEdition,
      tiposFacturasGotoNextPage,
      tiposFacturasGoToPreviousPage,
      tipoFacturaIsEditing,
      tiposFacturasIsLoading,
      tiposFacturasIsFirstPage,
      tiposFacturasIsLastPage,
      tiposFacturasUpdateSerie,
      tiposFacturaSuccess,
      tiposFacturaError,
      tiposFacturaErrorText,
      tiposFacturaIsValid
    } = useTiposFacturas();

    const {
      seriesFacturas,
      seriesFacturasShowed,
      serieFacturaEdit,
      serieFacturaAdd,
      serieFacturaDelete,
      serieFacturaCancel,
      serieFacturaSave,
      serieFacturaInEdition,
      seriesFacturasGotoNextPage,
      seriesFacturasGoToPreviousPage,
      serieFacturaIsEditing,
      seriesFacturasIsLoading,
      seriesFacturasIsFirstPage,
      seriesFacturasIsLastPage,
      seriesFacturaSuccess,
      seriesFacturaError,
      seriesFacturaErrorText,
      seriesFacturaIsValid
    } = useSeriesFacturas();

    const {
      formasPagos,
      formasPagosShowed,
      formaPagoEdit,
      formaPagoAdd,
      formaPagoDelete,
      formaPagoCancel,
      formaPagoSave,
      formaPagoInEdition,
      formasPagosGotoNextPage,
      formasPagosGoToPreviousPage,
      formaPagoIsEditing,
      formasPagosIsLoading,
      formasPagosIsFirstPage,
      formasPagosIsLastPage,
      formasPagoSuccess,
      formasPagoError,
      formasPagoErrorText,
      formasPagoIsValid
    } = useFormasPago();

    const showValidateTipoFactura = ref(false);
    const tiposFacturaWaitingForSave = ref(false);

    const showValidateSerieFactura = ref(false);
    const seriesFacturaWaitingForSave = ref(false);

    const showValidateFormasPago = ref(false);
    const formasPagoWaitingForSave = ref(false);

    function tipoFacturaSubmit() {
      showValidateTipoFactura.value = !tiposFacturaIsValid(
        tipoFacturaInEdition.value
      );
      //console.log("showValidateTipoFactura.value="+showValidateTipoFactura.value);
      if (showValidateTipoFactura.value) return;
      tiposFacturaWaitingForSave.value = true;
      tipoFacturaSave().then(() => {
        tiposFacturaWaitingForSave.value = false;
      });
    }

    function seriesFacturasSubmit() {
      showValidateSerieFactura.value = !seriesFacturaIsValid(
        serieFacturaInEdition.value
      );
      if (showValidateSerieFactura.value) return;
      seriesFacturaWaitingForSave.value = true;
      serieFacturaSave().then(() => {
        tiposFacturasUpdateSerie(serieFacturaInEdition.value);
        formasPagoWaitingForSave.value = false;
      });
    }

    function formasPagosSubmit() {
      showValidateFormasPago.value = !formasPagoIsValid(
        formaPagoInEdition.value
      );
      if (showValidateFormasPago.value) return;
      formasPagoWaitingForSave.value = true;
      formaPagoSave().then(() => {
        formasPagoWaitingForSave.value = false;
      });
    }

    function tiposFacturaPreviousCancel() {
      showValidateTipoFactura.value = false;
      tipoFacturaCancel();
    }

    function seriesFacturaPreviousCancel() {
      showValidateSerieFactura.value = false;
      serieFacturaCancel();
    }

    function formasPagoPreviousCancel() {
      showValidateFormasPago.value = false;
      formaPagoCancel();
    }

    watch(
      [tiposFacturaError, tiposFacturaSuccess],
      ([tiposFacturaError, tiposFacturaSuccess]) => {
        if (tiposFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (tiposFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: tiposFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [seriesFacturaError, seriesFacturaSuccess],
      ([seriesFacturaError, seriesFacturaSuccess]) => {
        if (seriesFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (seriesFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: seriesFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [formasPagoError, formasPagoSuccess],
      ([formasPagoError, formasPagoSuccess]) => {
        if (formasPagoSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (formasPagoError) {
          createToast(
            {
              title: "Error 👎 ",
              description: formasPagoErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      // Tipos de factura
      headersTipos,
      tiposFacturas,
      tiposFacturasShowed,
      tipoFacturaEdit,
      tipoFacturaAdd,
      tipoFacturaDelete,
      tiposFacturaPreviousCancel,
      tipoFacturaSave,
      tipoFacturaInEdition,
      tiposFacturasGotoNextPage,
      tiposFacturasGoToPreviousPage,
      tipoFacturaIsEditing,
      tiposFacturasIsLoading,
      tiposFacturasIsFirstPage,
      tiposFacturasIsLastPage,
      tiposFacturasUpdateSerie,
      tipoFacturaSubmit,
      showValidateTipoFactura,
      tiposFacturaWaitingForSave,

      //Series factura
      headersSeries,
      seriesFacturas,
      seriesFacturasShowed,
      serieFacturaEdit,
      serieFacturaAdd,
      serieFacturaDelete,
      seriesFacturaPreviousCancel,
      serieFacturaSave,
      serieFacturaInEdition,
      seriesFacturasGotoNextPage,
      seriesFacturasGoToPreviousPage,
      serieFacturaIsEditing,
      seriesFacturasIsLoading,
      seriesFacturasIsFirstPage,
      seriesFacturasIsLastPage,
      seriesFacturasSubmit,
      showValidateSerieFactura,
      seriesFacturaWaitingForSave,

      //fornas de pago
      headersFormas,
      formasPagos,
      formasPagosShowed,
      formaPagoEdit,
      formaPagoAdd,
      formaPagoDelete,
      formasPagoPreviousCancel,
      formaPagoSave,
      formaPagoInEdition,
      formasPagosGotoNextPage,
      formasPagosGoToPreviousPage,
      formaPagoIsEditing,
      formasPagosIsLoading,
      formasPagosIsFirstPage,
      formasPagosIsLastPage,
      formasPagosSubmit,
      showValidateFormasPago,
      formasPagoWaitingForSave
    };
  }
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  .tipos td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .tipos td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .tipos td:nth-of-type(2):before {
    content: "Abreviatura";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .tipos td:nth-of-type(3):before {
    content: "Serie";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .tipos td:nth-of-type(4):before {
    content: "Modelo ext.";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .series td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .series td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .series td:nth-of-type(2):before {
    content: "Abreviatura";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .formas td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .formas td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
