
import { defineComponent, ref, watch } from "vue";

import { useFabricantes } from "./useFabricantes";
import { useTransportistas } from "@/views/transportistas/useTransportistas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UIListBox from "@/components/UIListBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import UINoItems from "@/components/UINoItems.vue";
import UITextArea from "@/components/UITextArea.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Home",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIListBox,
    tabmenuedition,
    dirEdit,
    UICheckBox,
    emailEdit,
    telefonoEdit,
    UINoItems,
    deleteButtonMulti,
    UITextArea
  },
  setup() {
    const headers = ["Código", "Nif", "Nombre", "Población"];
    const {
      fabricantes,
      fabricantesShowed,
      fabricanteEdit,
      fabricantesResetFilter,
      fabricanteAdd,
      fabricanteDelete,
      fabricanteCancel,
      fabricanteSave,
      fabricanteInEdition,
      fabricantesIndex,
      fabricantesGotoNextPage,
      fabricantesGoToPreviousPage,
      fabricanteIsEditing,
      fabricantesIsLoading,
      fabricantesIsFirstPage,
      fabricantesIsLastPage,
      fabricanteAddDireccion,
      fabricanteDeleteDireccion,
      fabricanteAddEmail,
      fabricanteDeleteEmail,
      fabricanteAddTelefono,
      fabricanteDeleteTelefono,
      fabricanteAddMarca,
      fabricanteDeleteMarca,
      fabricanteSuccess,
      fabricanteError,
      fabricanteErrorText,
      fabricanteIsValid,
      fabricanteIsValidDirecciones,
      fabricanteIsValidEmails,
      fabricanteIsValidTelefonos,
      fabricanteIsValidMarcas
    } = useFabricantes();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();
    const { transportistas } = useTransportistas();

    const showListados = ref(false);

    const showValidate = ref(false);

    const tabsEdit = ref([true, false, false, false]);
    const tabsEditIsValid = ref([true, true, true, true]);

    const fabricanteWaitingForSave = ref(false);

    fabricantesResetFilter();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = fabricanteIsValid(fabricanteInEdition.value);
      tabsEditIsValid.value[1] = fabricanteIsValidDirecciones(
        fabricanteInEdition.value.direcciones
      );
      tabsEditIsValid.value[2] = fabricanteIsValidEmails(
        fabricanteInEdition.value.emails
      );
      tabsEditIsValid.value[3] = fabricanteIsValidTelefonos(
        fabricanteInEdition.value.telefonos
      );
      tabsEditIsValid.value[4] = fabricanteIsValidMarcas(
        fabricanteInEdition.value.marcas
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function fabricantePreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );
      if (showValidate.value) return;

      fabricanteWaitingForSave.value = true;
      fabricanteSave().then(() => {
        fabricanteWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function fabricantePreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      fabricanteCancel();
    }

    watch(
      [fabricanteError, fabricanteSuccess],
      ([fabricanteError, fabricanteSuccess]) => {
        if (fabricanteSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (fabricanteError) {
          createToast(
            {
              title: "Error 👎 ",
              description: fabricanteErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      fabricantes,
      fabricanteEdit,
      fabricanteAdd,
      fabricanteDelete,
      fabricanteCancel,
      fabricanteSave,
      fabricanteInEdition,
      fabricantesIndex,
      fabricantesGotoNextPage,
      fabricantesGoToPreviousPage,
      fabricanteIsEditing,
      fabricantesIsLoading,
      fabricantesIsFirstPage,
      fabricantesIsLastPage,
      fabricanteAddDireccion,
      fabricanteDeleteDireccion,
      fabricanteAddEmail,
      fabricanteDeleteEmail,
      fabricanteAddTelefono,
      fabricanteDeleteTelefono,
      fabricanteAddMarca,
      fabricanteDeleteMarca,
      fabricantePreviousCancel,
      fabricantePreviousSave,
      fabricanteWaitingForSave,

      fabricantesShowed,

      tabsEdit,
      tabsEditIsValid,
      changeActiveTab,
      showListados,
      codigosPostalesPoblaciones,
      tiposTelefono,
      transportistas,
      showValidate
    };
  }
});
