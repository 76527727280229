import { computed, onMounted, ref, Ref } from "vue";
import { apiClientes } from "@/services/clientes/api";
import { ClienteView, ClienteFilter } from "@/types/clientes/ClienteView";
import {
  Cliente,
  ClienteCategoria,
  ClienteDireccion,
  ClienteEmail,
  ClienteEtiqueta,
  ClienteFechaTope,
  ClienteTelefono,
  ClienteCodigo,
} from "@/types/clientes/Cliente";

import { usePagination } from "@/components/usePagination";

import {
  isValidObject,
  isValidArrayOfObjects,
} from "../utilidades/useValidations";

import { getTempId } from "../utilidades/useIDLibrary";

export function useClientes() {
  const idNotEditItem = -999;
  const items: Ref<ClienteView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Cliente);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows,
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({
    domicilio: [] as string[],
    etiquetas: [] as number[],
    categorias: [] as number[],
  } as ClienteFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("clienteFilter", JSON.stringify(filter.value));
    items.value = await apiClientes.getClientesView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function editItem(id: number) {
    if (id != 0) editingItem.value = await apiClientes.getCliente(id);
    else {
      editingItem.value = {
        id: 0,
        direcciones: [] as ClienteDireccion[],
        emails: [] as ClienteEmail[],
        telefonos: [] as ClienteTelefono[],
        categorias: [] as ClienteCategoria[],
        etiquetas: [] as ClienteEtiqueta[],
        fechasTope: [] as ClienteFechaTope[],
        codigos: [] as ClienteCodigo[],
      } as Cliente;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as ClienteView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiClientes
      .deleteCliente(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter((item) => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Cliente;
    setTotalNumberOfRows(items.value.length);
  }

  async function recalculateClientes() {
    await apiClientes
      .recalculateClientes()
      .then(function(res) {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiClientes
      .saveCliente(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      (item) => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const clienteUpdated = await apiClientes.getClienteView(id);
    items.value[index] = clienteUpdated;
    editingItem.value = { id: idNotEditItem } as Cliente;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addDireccion() {
    editingItem.value.direcciones.push({
      idDireccion: getTempId(editingItem.value.direcciones, "idDireccion"),
      idCliente: editingItem.value.id,
      correspondencia: false,
      fiscal: false,
      entrega: false,
    } as ClienteDireccion);
  }

  function deleteDireccion(id: number) {
    editingItem.value.direcciones = editingItem.value.direcciones.filter(
      (item) => item.idDireccion != id
    );
  }

  function addEmail() {
    editingItem.value.emails.push({
      idEmail: getTempId(editingItem.value.emails, "idEmail"),
      idCliente: editingItem.value.id,
      principal: false,
    } as ClienteEmail);
  }

  function deleteEmail(id: number) {
    editingItem.value.emails = editingItem.value.emails.filter(
      (item) => item.idEmail != id
    );
  }

  function addTelefono() {
    editingItem.value.telefonos.push({
      idTelefono: getTempId(editingItem.value.telefonos, "idTelefono"),
      idCliente: editingItem.value.id,
    } as ClienteTelefono);
  }

  function deleteTelefono(id: number) {
    console.log(id);
    editingItem.value.telefonos = editingItem.value.telefonos.filter(
      (item) => item.idTelefono != id
    );
  }

  function addFechaTope() {
    editingItem.value.fechasTope.push({
      idFechaTope: getTempId(editingItem.value.fechasTope, "idFechaTope"),
      idCliente: editingItem.value.id,
    } as ClienteFechaTope);
  }

  function deleteFechaTope(id: number) {
    editingItem.value.fechasTope = editingItem.value.fechasTope.filter(
      (item) => item.idFechaTope != id
    );
  }

  function addCodigo() {
    editingItem.value.codigos.push({
      idCodigo: getTempId(editingItem.value.codigos, "idCodigo"),
      idCliente: editingItem.value.id,
    } as ClienteCodigo);
  }

  function deleteCodigo(id: number) {
    editingItem.value.codigos = editingItem.value.codigos.filter(
      (item) => item.idCodigo != id
    );
  }

  async function resetFilter() {
    filter.value = {
      activoSN: "S",
      domicilio: ["C", "T", "F", "E"] as string[],
      etiquetas: [] as number[],
      categorias: [] as number[],
      inexistenteSN: "N",
    } as ClienteFilter;
  }

  function isValid(item: Cliente): boolean {
    return isValidObject(item, ["nombre"]);
  }

  function isValidDirecciones(direcciones: ClienteDireccion[]): boolean {
    return isValidArrayOfObjects(direcciones, ["domicilio"]);
  }

  function isValidEmails(emails: ClienteEmail[]): boolean {
    return isValidArrayOfObjects(emails, ["email"]);
  }

  function isValidTelefonos(telefonos: ClienteTelefono[]): boolean {
    return isValidArrayOfObjects(telefonos, ["telefono"]);
  }

  /*function isValidFechasTope(fechasTope: ClienteFechaTope[]): boolean {
    return isValidArrayOfObjects(fechasTope, ["dia","mes"]);
  }*/

  function isValidFechasFechaTope(mes: number, dia: number): boolean {
    if (
      mes < 1 ||
      mes > 12 ||
      dia < 1 ||
      dia > 31 ||
      (mes == 2 && dia > 28) ||
      (mes == 4 && dia > 30) ||
      (mes == 6 && dia > 30) ||
      (mes == 9 && dia > 30) ||
      (mes == 11 && dia > 30)
    )
      return false;
    else return true;
  }

  function isValidFechasTope(fechasTope: ClienteFechaTope[]): boolean {
    let valid = true;
    fechasTope.forEach((fechaTope) => {
      valid = valid && isValidFechasFechaTope(fechaTope.mes, fechaTope.dia);
    });
    //console.log("isValidFechasTope", isValidArrayOfObjects(fechasTope, ["dia", "mes"]))
    return isValidArrayOfObjects(fechasTope, ["dia", "mes"]) && valid;
  }

  function isValidCodigos(codigos: ClienteCodigo[]): boolean {
    //console.log("isValidCodigos idfabricante codigo", isValidArrayOfObjects(codigos, ["idfabricante", "codigo"]))
    //console.log(codigos)
    return isValidArrayOfObjects(codigos, ["idFabricante", "codigo"]);
  }

  return {
    clientes: items,
    clientesShowed: itemsShowed,
    clientesFilter: filter,
    clientesSearch: filterItems,
    clientesResetFilter: resetFilter,
    clienteEdit: editItem,
    clienteAdd: addItem,
    clienteDelete: deleteItem,
    clienteCancel: cancelEdit,
    clienteSave: saveEdit,
    clienteInEdition: editingItem,
    clientesIndex: currentRow,
    clientesGotoNextPage: nextPage,
    clientesGoToPreviousPage: previousPage,
    clienteIsEditing: isEditing,
    clientesIsLoading: isLoading,
    clientesIsFirstPage: isFirstPage,
    clientesIsLastPage: isLastPage,
    clienteAddDireccion: addDireccion,
    clienteDeleteDireccion: deleteDireccion,
    clienteAddEmail: addEmail,
    clienteDeleteEmail: deleteEmail,
    clienteAddTelefono: addTelefono,
    clienteDeleteTelefono: deleteTelefono,
    clienteAddFechaTope: addFechaTope,
    clienteDeleteFechaTope: deleteFechaTope,
    clienteAddCodigo: addCodigo,
    clienteDeleteCodigo: deleteCodigo,
    clienteSuccess: success,
    clienteError: error,
    clienteErrorText: errorText,
    clienteIsValid: isValid,
    clienteIsValidTelefonos: isValidTelefonos,
    clienteIsValidDirecciones: isValidDirecciones,
    clienteIsValidEmails: isValidEmails,
    clienteIsValidFechasTope: isValidFechasTope,
    clienteIsValidCodigos: isValidCodigos,
    clienteIsValidFechasFechaTope: isValidFechasFechaTope,
    clientesRecaculateStates: recalculateClientes,
  };
}
