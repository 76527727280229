import { TipoFacturaView } from "@/types/facturas/TipoFacturaView";
import { TipoFactura } from "@/types/facturas/TipoFactura";
import { SerieFactura } from "@/types/facturas/SerieFactura";
import { FormaPago } from "@/types/facturas/FormaPago";
import { ReceptorFactura } from "@/types/facturas/ReceptorFactura";
import {
  ReceptorFacturaFilter,
  ReceptorFacturaView
} from "@/types/facturas/ReceptorFacturaView";
import { FacturaView, FacturaFilter } from "@/types/facturas/FacturaView";
import { Factura } from "@/types/facturas/Factura";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiFacturas = {
  // Facturas
  getFacturasView(filter: FacturaFilter): Promise<FacturaView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "FacturaView/filter";
      axios
        .post<FacturaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getFacturaView(id: number): Promise<FacturaView> {
    return new Promise(resolve => {
      const url = "FacturaView/" + id;
      axios.get<FacturaView>(url).then(response => resolve(response.data));
    });
  },
  getFactura(id: number): Promise<Factura> {
    return new Promise(resolve => {
      const url = "Factura/" + id;
      axios.get<Factura>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveFactura(factura: Factura): Promise<Factura> {
    const newFactura = { ...factura };

    putIdOk(newFactura.lineas, "idLinea");

    return new Promise((resolve, reject) => {
      const url = "Factura";
      axios
        .post(url, newFactura)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteFactura(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Factura/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  // Tipos facturas
  getTiposFacturaView(): Promise<TipoFacturaView[]> {
    return new Promise(resolve => {
      const url = "TipoFacturaView/filter";
      axios
        .post<TipoFacturaView[]>(url, {})
        .then(response => resolve(response.data));
    });
  },
  getTipoFacturaView(id: number): Promise<TipoFacturaView> {
    return new Promise(resolve => {
      const url = "TipoFacturaView/" + id;
      axios.get<TipoFacturaView>(url).then(response => resolve(response.data));
    });
  },
  getTipoFactura(id: number): Promise<TipoFactura> {
    return new Promise(resolve => {
      const url = "TipoFactura/" + id;
      axios.get<TipoFactura>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveTipoFactura(tipo: TipoFactura): Promise<TipoFactura> {
    return new Promise((resolve, reject) => {
      const url = "TipoFactura";
      axios
        .post(url, tipo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteTipoFactura(id: number) {
    return new Promise((resolve, reject) => {
      const url = "TipoFactura/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  // Series facturas
  getSeriesFactura(): Promise<SerieFactura[]> {
    return new Promise(resolve => {
      const url = "SerieFacturaView/filter";
      axios
        .post<SerieFactura[]>(url, {})
        .then(response => resolve(response.data));
    });
  },
  saveSerieFactura(serie: SerieFactura): Promise<SerieFactura> {
    return new Promise((resolve, reject) => {
      const url = "SerieFactura";
      axios
        .post(url, serie)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteSerieFactura(id: number) {
    return new Promise((resolve, reject) => {
      const url = "SerieFactura/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  // Formas pago
  getFormasPago(): Promise<FormaPago[]> {
    return new Promise(resolve => {
      const url = "FormaPagoView/filter";
      axios.post<FormaPago[]>(url, {}).then(response => resolve(response.data));
    });
  },
  saveFormaPago(forma: FormaPago): Promise<FormaPago> {
    return new Promise((resolve, reject) => {
      const url = "FormaPago";
      axios
        .post(url, forma)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteFormaPago(id: number) {
    return new Promise((resolve, reject) => {
      const url = "FormaPago/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  // Receptores factura
  getReceptoresFacturaView(
    filter: ReceptorFacturaFilter
  ): Promise<ReceptorFacturaView[]> {
    return new Promise(resolve => {
      const url = "ReceptorFacturaView/filter";
      axios
        .post<ReceptorFacturaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getReceptorFacturaView(id: number): Promise<ReceptorFacturaView> {
    return new Promise(resolve => {
      const url = "ReceptorFacturaView/" + id;
      axios
        .get<ReceptorFacturaView>(url)
        .then(response => resolve(response.data));
    });
  },
  getReceptoresFactura(): Promise<ReceptorFactura[]> {
    return new Promise(resolve => {
      const url = "ReceptorFacturaView/filter";
      axios
        .post<ReceptorFactura[]>(url, {})
        .then(response => resolve(response.data));
    });
  },
  getReceptorFactura(id: number): Promise<ReceptorFactura> {
    return new Promise(resolve => {
      const url = "ReceptorFactura/" + id;
      axios.get<ReceptorFactura>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveReceptorFactura(tipo: ReceptorFactura): Promise<ReceptorFactura> {
    return new Promise((resolve, reject) => {
      const url = "ReceptorFactura";
      axios
        .post(url, tipo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteReceptorFactura(id: number) {
    return new Promise((resolve, reject) => {
      const url = "ReceptorFactura/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
