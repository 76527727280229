import { onMounted, ref, Ref } from "vue";
import { apiTiposTelefono } from "@/services/tipostelefono/api";
import { TipoTelefonoView } from "@/types/tipostelefono/TipoTelefonoView";

export function useTiposTelefono() {
  const items: Ref<TipoTelefonoView[]> = ref([]);

  onMounted(async () => {
    items.value = await apiTiposTelefono.getTiposTelefonoView();
  });

  return {
    tiposTelefono: items
  };
}
