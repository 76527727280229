
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "UIDateInput",
  props: {
    modelValue: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const myLabel = function() {
      return props.label;
    };
    const myPlaceHolder = function() {
      if (props.placeholder) return props.placeholder;
      else return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };
    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == "" ||
          props.modelValue == null ||
          props.modelValue == undefined)
      );
    });
    return {
      myLabel,
      myPlaceHolder,
      myId,
      showError
    };
  }
});
