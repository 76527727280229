<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Facturas
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>

        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
            <router-link
              target="_blank"
              to="/Facturas/FacturasFacturas/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Facturas</router-link
            >
            <router-link
              target="_blank"
              to="/Facturas/FacturasPorReceptor/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Facturas por Receptor</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="facturasPreviousSearch"
    @reset="facturasResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-7 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <UITextInput
            v-model="facturasFilter.numeroFactura"
            label="Nº de factura"
          />
        </div>
        <div class="col-span-1 sm:col-span-2">
          <UIListBox
            v-model="facturasFilter.idTipoFactura"
            label="Tipo de factura"
            :options="tiposFacturas"
            optionName="descripcion"
            optionValue="id"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <label for="activoSN" class="block text-sm font-medium text-gray-700">
            Pagadas
          </label>
          <select
            v-model="facturasFilter.pagada"
            id="activoSN"
            name="activoSN"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todas</option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            v-model="facturasFilter.desdeFechaFactura"
            label="Desde fecha factura"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            v-model="facturasFilter.hastaFechaFactura"
            label="Hasta fecha factura"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput v-model="facturasFilter.anyo" label="Año" />
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-7 gap-2">
        <div class="col-span-1 sm:col-span-2">
          <selectReceptorFactura
            class="mt-2"
            v-model="facturasFilter.idReceptor"
            :items="receptoresFactura"
          />
        </div>
        <div class="col-span-1 sm:col-span-1"></div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            v-model="facturasFilter.desdeImporteFactura"
            label="Desde importe"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            v-model="facturasFilter.hastaImporteFactura"
            label="Hasta importe"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            v-model="facturasFilter.desdeFechaVencimiento"
            label="Desde fecha vencimiento"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            v-model="facturasFilter.hastaFechaVencimiento"
            label="Hasta fecha vencimiento"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="facturas.length"
      :isEditing="facturaIsEditing"
      :isLoading="facturasIsLoading"
      :isFirstPage="facturasIsFirstPage"
      :isLastPage="facturasIsLastPage"
      @newitem="facturaAdd"
      @next="facturasGotoNextPage"
      @previous="facturasGoToPreviousPage"
    >
      <template
        v-for="factura in facturasShowed"
        :key="factura.id"
        class="border-b border-gray-200"
      >
        <tr v-if="factura.id != facturaInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ factura.numeroFactura }} {{ factura.serie }} (
            {{ factura.tipofactura }} )
          </td>

          <td class="px-6 py-4 text-sm text-gray-500 overflow-x-hidden">
            {{ factura.fecha }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ factura.nombreReceptor }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ factura.importe }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            <span v-if="factura.pagada">✅</span>
            <span v-else>🚫</span>
          </td>
          <datatablerowbuttons
            @edititem="facturaEdit"
            @deleteitem="facturaDelete"
            :id="factura.id"
            :isEditing="facturaIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="facturaPreviousCancel"
              @save="facturaPreviousSave"
              :waitingForSave="facturaWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  factura
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                    :isValid="tabsEditIsValid[0]"
                  />
                  <tabmenuedition
                    v-if="!fusionTabs"
                    title="lineas"
                    :numbersOfItems="facturaInEdition.lineas.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="facturaAddLinea"
                    :isValid="tabsEditIsValid[1]"
                  />
                </div>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 122.88 114.81"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <!-- <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path>-->
                        <path
                          d="M18.46,89.86H7.82A7.8,7.8,0,0,1,2.3,87.57l-.24-.26A7.82,7.82,0,0,1,0,82V35.44a7.81,7.81,0,0,1,2.3-5.53l.25-.23a7.77,7.77,0,0,1,5.27-2.06H20.13A8.07,8.07,0,0,1,20,26.14v-18a8.05,8.05,0,0,1,2.39-5.72h0A8.07,8.07,0,0,1,28.1,0H94.78a8.13,8.13,0,0,1,8.11,8.11v18a8.07,8.07,0,0,1-.14,1.48h12.31a7.81,7.81,0,0,1,5.51,2.29h0a7.8,7.8,0,0,1,2.3,5.52V82a7.84,7.84,0,0,1-7.82,7.82H104.42v20.31a4.63,4.63,0,0,1-1.36,3.28,4.32,4.32,0,0,1-.39.34,4.6,4.6,0,0,1-2.89,1H23.1a4.63,4.63,0,0,1-3.28-1.36,3.06,3.06,0,0,1-.34-.39,4.6,4.6,0,0,1-1-2.89V89.86ZM27,27.62H95.91l.19-.17a1.86,1.86,0,0,0,.55-1.31v-18a1.88,1.88,0,0,0-1.87-1.87H28.1a1.87,1.87,0,0,0-1.32.55h0a1.87,1.87,0,0,0-.54,1.32v18a1.84,1.84,0,0,0,.55,1.31l.19.17Zm9.3,71.71a2.4,2.4,0,0,1,0-4.8H63.64a2.4,2.4,0,0,1,0,4.8Zm0-14.56a2.4,2.4,0,0,1,0-4.8H86.61a2.4,2.4,0,0,1,0,4.8ZM7.07,60.61H115.81V35.44a.76.76,0,0,0-.22-.54.77.77,0,0,0-.53-.22H7.82a.76.76,0,0,0-.46.16l-.07.07a.76.76,0,0,0-.22.53V60.61Zm108.74,7.07H102.19a4.13,4.13,0,0,1,.87.69,4.63,4.63,0,0,1,1.36,3.28V82.79h10.64a.75.75,0,0,0,.53-.22.79.79,0,0,0,.22-.53V67.68Zm-95.12,0H7.07V82a.81.81,0,0,0,.15.46l.07.07a.75.75,0,0,0,.53.22H18.46V71.65a4.63,4.63,0,0,1,1.36-3.28,4.13,4.13,0,0,1,.87-.69Zm76.67,4.23H25.52v35.83H97.36V71.91Z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'FacturaPrint',
                          params: { factura: facturaInEdition.id }
                        }"
                        target="_blank"
                      >
                        Imprimir factura
                      </router-link></span
                    ></span
                  >
                </p>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <!-- {{ facturaInEdition }} -->
                  <div class="grid grid-cols-8 gap-6">
                    <div class="col-span-2 ">
                      <label
                        for="factura_numeroFactura"
                        class="block text-sm font-medium text-gray-700"
                      >
                        N. Factura
                      </label>
                      <div class="flex rounded-md shadow-sm">
                        <input
                          type="number"
                          name="factura_numeroFactura"
                          id="factura_numeroFactura"
                          class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-100"
                          v-model="facturaInEdition.numeroFactura"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-span-2 ">
                      <label
                        for="factura_anyo"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Año
                      </label>
                      <div class="flex rounded-md shadow-sm">
                        <input
                          type="number"
                          name="factura_anyo"
                          id="factura_anyo"
                          class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-100"
                          v-model="facturaInEdition.anyo"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-span-4 sm:col-span-3">
                      <UIDateInput
                        v-model="facturaInEdition.fecha"
                        label="Fecha factura"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-4">
                      <selectReceptorFactura
                        v-model="facturaInEdition.idCliente"
                        :items="receptoresFactura"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <UIListBox
                        v-model="facturaInEdition.idTipoFactura"
                        label="Tipo Fra."
                        :options="tiposFacturas"
                        optionValue="id"
                        optionName="descripcion"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UINumberInput
                        v-model="facturaInEdition.porcentajeIVA"
                        label="% IVA"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UINumberInput
                        v-model="facturaInEdition.porcentajeRecargo"
                        label="% R.Eq."
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1 sm:mt-8">
                      <UICheckBox
                        v-model="facturaInEdition.pagadaSN"
                        label="Pagada"
                      />
                    </div>
                  </div>
                  <div class="">
                    <UITextArea
                      v-model="facturaInEdition.observaciones"
                      label="Observaciones"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <headerChild
                    title="lineas de factura"
                    :numbersOfItems="facturaInEdition.lineas.length"
                    :hasAddButton="true"
                    svgdata="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    @addItem="facturaAddLinea"
                    :isValid="tabsEditIsValid[1]"
                  />
                  <UINoItems
                    :numbersOfItems="facturaInEdition.lineas.length"
                    @addItem="facturaAddLinea"
                  />
                  <template
                    v-for="linea in facturaInEdition.lineas"
                    :key="linea.idLinea"
                  >
                    <div class="grid grid-cols-8 gap-1">
                      <div class="col-span-8 sm:col-span-4 ">
                        <UITextInput
                          v-model="linea.descripcion"
                          label="Articulo"
                          :id="'linea_descripcion_' + linea.idLinea"
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-2 sm:col-span-1 ">
                        <UINumberInput
                          v-model="linea.unidades"
                          label="Unidades"
                          id="'linea_unidades_' + linea.idLinea "
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-3 sm:col-span-1">
                        <UINumberInput
                          v-model="linea.importe"
                          label="Importe"
                          id="'linea_importe_' + linea.idLinea "
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-2 sm:col-span-1">
                        <UINumberInput
                          v-model="linea.porcentajeDescuento"
                          label="% Dto"
                          id="'linea_porcentajeDescuento_' + linea.idLinea "
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div
                        class="flex items-end col-span-1 sm:col-span-1 flex-row-reverse"
                      >
                        <deleteButtonMulti
                          :id="linea.idLinea"
                          @deleteMultiItem="facturaDeleteLinea"
                        />
                      </div>
                    </div>
                  </template>
                  <hr class="mt-4" />
                  <div class="text-right flex flex-row-reverse sm:pr-4">
                    <div>
                      <div class="flex">
                        <div class="w-32 ">Base imponible:</div>
                        <div class="text-right w-32 font-bold">
                          {{ totalBaseImponible() }} €
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-32">I.V.A.:</div>
                        <div class="text-right w-32 font-bold">
                          {{
                            Math.round(
                              totalBaseImponible() *
                                facturaInEdition.porcentajeIVA
                            ) / 100
                          }}
                          €
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-32 ">Total:</div>
                        <div class="text-right w-32 font-bold">
                          {{
                            totalBaseImponible() +
                              Math.round(
                                totalBaseImponible() *
                                  facturaInEdition.porcentajeIVA
                              ) /
                                100
                          }}
                          €
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useFacturas } from "./useFacturas";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINoItems from "@/components/UINoItems.vue";
import headerChild from "@/components/headerChild.vue";

import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

import { useTiposFacturas } from "../configfacturas/useTiposFacturas";
import { useReceptoresFactura } from "../receptoresfactura/useReceptoresFactura";

import selectReceptorFactura from "@/components/selectReceptorFactura.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { FacturaFilter } from "@/types/facturas/FacturaView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Facturas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UIListBox,
    UICheckBox,
    UITextArea,
    UINoItems,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectReceptorFactura,
    headerChild
  },
  setup() {
    const headers = [
      "Factura",
      //"tipo",
      "Fecha",
      "Receptor",
      "Importe",
      "Pagada"
    ];

    const {
      facturas,
      facturasShowed,
      facturasFilter,
      facturasSearch,
      facturasResetFilter,
      facturaEdit,
      facturaAdd,
      facturaDelete,
      facturaCancel,
      facturaSave,
      facturaInEdition,
      facturasIndex,
      facturasGotoNextPage,
      facturasGoToPreviousPage,
      facturaIsEditing,
      facturasIsLoading,
      facturasIsFirstPage,
      facturasIsLastPage,
      facturaAddLinea,
      facturaDeleteLinea,
      facturaSuccess,
      facturaError,
      facturaErrorText,
      facturaIsValid,
      facturaIsValidLineas,
      facturaEditFirst
    } = useFacturas();

    const route = useRoute();
    const paramFactura = route.params.idFactura;
    const paramEditar = route.params.Editar;
    const paramReceptor = route.params.idReceptor;

    if (
      paramFactura != undefined &&
      paramFactura != "" &&
      paramFactura != "0"
    ) {
      facturasFilter.value = { id: Number(paramFactura) } as FacturaFilter;
      if (paramEditar != undefined && paramEditar != "" && paramEditar != "0") {
        //   facturaEdit(facturas.value[0].id);
        if (facturas.value != undefined) facturaEditFirst.value = true;
      }
    } else if (
      paramReceptor != undefined &&
      paramReceptor != "" &&
      paramReceptor != "0"
    )
      facturasFilter.value = {
        idReceptor: Number(paramReceptor)
      } as FacturaFilter;
    else
      facturasFilter.value = {
        anyo: new Date().getFullYear()
      } as FacturaFilter;

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);
    const fusionTabs = ref(true);

    const tabsEdit = ref([true, true]);

    const tabsEditIsValid = ref([true, true]);

    const { receptoresFactura } = useReceptoresFactura();

    const { tiposFacturas } = useTiposFacturas();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = facturaIsValid(facturaInEdition.value);

      tabsEditIsValid.value[1] = facturaIsValidLineas(
        facturaInEdition.value.lineas
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      if (fusionTabs.value) {
        if (tabIndex == 0) {
          tabsEdit.value[1] = true;
        }
      }

      tabsEdit.value[tabIndex] = true;
    }

    const facturaWaitingForSave = ref(false);

    function facturasPreviousSearch() {
      facturasSearch();
    }

    function facturaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      facturaWaitingForSave.value = true;
      facturaSave().then(() => {
        facturaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function facturaPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];

      changeActiveTab(0);
      facturaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function totalBaseImponible() {
      let totalBaseImponible = 0;
      facturaInEdition.value.lineas.forEach(linea => {
        totalBaseImponible +=
          Math.round(
            linea.unidades *
              (((linea.importe ? linea.importe : 0) *
                (100 - linea.porcentajeDescuento)) /
                100) *
              100
          ) / 100;
      });
      return totalBaseImponible;
    }

    watch([facturaError, facturaSuccess], ([facturaError, facturaSuccess]) => {
      if (facturaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito"
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a"
          }
        );
      }

      if (facturaError) {
        createToast(
          {
            title: "Error 👎 ",
            description: facturaErrorText.value
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true
          }
        );
      }
    });

    return {
      headers,
      facturas,
      facturasShowed,
      facturasFilter,
      facturasSearch,
      facturasResetFilter,
      facturaEdit,
      facturaAdd,
      facturaDelete,
      facturaCancel,
      facturaSave,
      facturaInEdition,
      facturasIndex,
      facturasGotoNextPage,
      facturasGoToPreviousPage,
      facturaIsEditing,
      facturasIsLoading,
      facturasIsFirstPage,
      facturasIsLastPage,
      facturaAddLinea,
      facturaDeleteLinea,
      totalBaseImponible,

      tabsEdit,
      tabsEditIsValid,
      changeActiveTab,

      isFiltering,
      showFilter,

      facturasPreviousSearch,
      facturaPreviousCancel,
      facturaPreviousSave,
      facturaWaitingForSave,

      showListados,
      tiposFacturas,
      receptoresFactura,

      showValidate,
      fusionTabs
    };
  }
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "Nº Fra.";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Tipo";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Fecha";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Receptor";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "Importe";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "Pagada";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
