import axios from "../apiService";
import { Usuario } from "@/types/usuarios/Usuario";
import { UsuarioView } from "@/types/usuarios/UsuarioView";

export const apiUsuarios = {
  validate(usuario: string, clave: string): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      const url = "users/Authenticate";
      axios
        .post(url, { username: usuario, password: clave })
        .then(response => {
          localStorage.setItem("username", usuario.toString());
          resolve(response.data);
        })
        .catch(response => {
          reject(response.status);
        });
    });
  },
  getUsuariosView(): Promise<UsuarioView[]> {
    return new Promise(resolve => {
      const url = "users/";
      axios.get<UsuarioView[]>(url).then(response => resolve(response.data));
    });
  },
  getUsuarioView(id: number): Promise<UsuarioView> {
    return new Promise(resolve => {
      const url = "users/" + id;
      axios.get<UsuarioView>(url).then(response => resolve(response.data));
    });
  },
  getUsuario(id: number): Promise<Usuario> {
    return new Promise(resolve => {
      const url = "users/" + id;
      axios.get<Usuario>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveUsuario(user: Usuario): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      const url = "users";
      axios
        .post(url, user)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response.status);
        });
    });
  },
  deleteUsuario(id: number) {
    return new Promise((resolve, reject) => {
      const url = "users/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response.status);
        });
    });
  }
};
