<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Fabricantes
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
            <router-link
              target="_blank"
              to="/Fabricantes/FabricantesFabricantes/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Fabricantes</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="fabricantes.length"
      :isEditing="fabricanteIsEditing"
      :isLoading="fabricantesIsLoading"
      :isFirstPage="fabricantesIsFirstPage"
      :isLastPage="fabricantesIsLastPage"
      @newitem="fabricanteAdd"
      @next="fabricantesGotoNextPage"
      @previous="fabricantesGoToPreviousPage"
    >
      <template
        v-for="fabricante in fabricantesShowed"
        :key="fabricante.id"
        class="border-b border-gray-200"
      >
        <tr v-if="fabricante.id != fabricanteInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span :class="{ 'line-through text-red-400': !fabricante.activo }">
              {{ fabricante.codigo }}
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span :class="{ 'line-through text-red-400': !fabricante.activo }">
              {{ fabricante.nif }}</span
            >
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            <span :class="{ 'line-through text-red-400': !fabricante.activo }">
              {{ fabricante.nombre }}</span
            >
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            <span :class="{ 'line-through text-red-400': !fabricante.activo }">
              {{ fabricante.codigoPostal }} {{ fabricante.poblacion }}</span
            >
          </td>
          <datatablerowbuttons
            @edititem="fabricanteEdit"
            @deleteitem="fabricanteDelete"
            :id="fabricante.id"
            :isEditing="fabricanteIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="fabricantePreviousCancel"
              @save="fabricantePreviousSave"
              :waitingForSave="fabricanteWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Fabricante
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                    :isValid="tabsEditIsValid[0]"
                  />
                  <tabmenuedition
                    title="direcciones"
                    :numbersOfItems="fabricanteInEdition.direcciones.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="fabricanteAddDireccion"
                    :isValid="tabsEditIsValid[1]"
                  />
                  <tabmenuedition
                    title="emails"
                    :numbersOfItems="fabricanteInEdition.emails.length"
                    :active="tabsEdit[2]"
                    :hasAddButton="true"
                    svgdata="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    @showItems="changeActiveTab(2)"
                    @addItem="fabricanteAddEmail"
                    :isValid="tabsEditIsValid[2]"
                  />
                  <tabmenuedition
                    title="teléfonos"
                    :numbersOfItems="fabricanteInEdition.telefonos.length"
                    :active="tabsEdit[3]"
                    :hasAddButton="true"
                    svgdata="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    @showItems="changeActiveTab(3)"
                    @addItem="fabricanteAddTelefono"
                    :isValid="tabsEditIsValid[3]"
                  />
                  <tabmenuedition
                    title="marcas"
                    :numbersOfItems="fabricanteInEdition.marcas.length"
                    :active="tabsEdit[4]"
                    :hasAddButton="true"
                    svgdata="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                    @showItems="changeActiveTab(4)"
                    @addItem="fabricanteAddMarca"
                    :isValid="tabsEditIsValid[4]"
                  />
                </div>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-2">
                      <UITextInput
                        v-model="fabricanteInEdition.codigo"
                        label="Código"
                        :maxLength="10"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <UITextInput
                        v-model="fabricanteInEdition.nif"
                        label="Nif"
                        :maxLength="20"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-2 sm:mt-8">
                      <UICheckBox
                        v-model="fabricanteInEdition.activo"
                        label="Activo"
                      />
                    </div>
                  </div>
                  <div>
                    <UITextInput
                      v-model="fabricanteInEdition.nombre"
                      label="Nombre"
                      :maxLength="100"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-4">
                      <UITextInput
                        v-model="fabricanteInEdition.url"
                        label="Url"
                        :maxLength="100"
                      />
                    </div>

                    <div class="col-span-6 sm:col-span-2">
                      <UINumberInput
                        v-model="fabricanteInEdition.diasVtoPago"
                        label="Días vencimiento"
                      />
                    </div>
                  </div>
                  <div class="hidden">
                    <UIListBox
                      v-model="fabricanteInEdition.idTransportista"
                      label="Transportista"
                      :options="transportistas"
                      optionValue="id"
                      optionName="nombre"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="fabricanteInEdition.datosContacto"
                      label="Datos de contacto"
                      :maxLength="255"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="fabricanteInEdition.observaciones"
                      label="Observaciones"
                      :maxLength="4000"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <UINoItems
                    :numbersOfItems="fabricanteInEdition.direcciones.length"
                    @addItem="fabricanteAddDireccion"
                  />
                  <template
                    v-for="direccion in fabricanteInEdition.direcciones"
                    :key="direccion.idDireccion"
                  >
                    <dir-edit
                      v-model:descripcion="direccion.descripcion"
                      v-model:domicilio="direccion.domicilio"
                      v-model:idCodigoPostal="direccion.idCodigoPostal"
                      v-model:correspondencia="direccion.correspondencia"
                      v-model:fiscal="direccion.fiscal"
                      v-model:entrega="direccion.entrega"
                      :id="direccion.idDireccion"
                      :items="codigosPostalesPoblaciones"
                      :entregaEditable="false"
                      @deleteItem="
                        fabricanteDeleteDireccion(direccion.idDireccion)
                      "
                      :showRequired="showValidate"
                    />
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[2]">
                  <UINoItems
                    :numbersOfItems="fabricanteInEdition.emails.length"
                    @addItem="fabricanteAddEmail"
                  />
                  <template
                    v-for="email in fabricanteInEdition.emails"
                    :key="email.idEmail"
                  >
                    <emailEdit
                      v-model:descripcion="email.descripcion"
                      v-model:email="email.email"
                      v-model:principal="email.principal"
                      :id="email.idEmail"
                      :showRequired="showValidate"
                      @deleteItem="fabricanteDeleteEmail(email.idEmail)"
                    />
                  </template>
                </template>

                <template v-if="tabsEdit[3]">
                  <UINoItems
                    :numbersOfItems="fabricanteInEdition.telefonos.length"
                    @addItem="fabricanteAddTelefono"
                  />
                  <template
                    v-for="telefono in fabricanteInEdition.telefonos"
                    :key="telefono.idTelefono"
                  >
                    <telefonoEdit
                      v-model:descripcion="telefono.descripcion"
                      v-model:telefono="telefono.telefono"
                      v-model:tipoTelefono="telefono.idTipoTelefono"
                      :id="telefono.idTelefono"
                      :tiposTelefono="tiposTelefono"
                      :showRequired="showValidate"
                      @deleteItem="
                        fabricanteDeleteTelefono(telefono.idTelefono)
                      "
                    />
                  </template>
                </template>

                <template v-if="tabsEdit[4]">
                  <UINoItems
                    :numbersOfItems="fabricanteInEdition.marcas.length"
                    @addItem="fabricanteAddMarca"
                  />
                  <template
                    v-for="marca in fabricanteInEdition.marcas"
                    :key="marca.idMarca"
                  >
                    <div class="grid grid-cols-12 gap-1">
                      <div class="col-span-6 sm:col-span-2">
                        <UITextInput
                          v-model="marca.codigo"
                          label="Codigo"
                          :id="'marca_codigo_' + marca.idMarca"
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <UITextInput
                          v-model="marca.descripcion"
                          label="Descripción"
                          :id="'marca_descripcion_' + marca.idMarca"
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-2">
                        <UINumberInput
                          v-model="marca.porcentajeComision"
                          label="%"
                          :id="'marca_porcentajeComision_' + marca.idMarca"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-2">
                        <label
                          for="Activas"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Tipo Prenda
                        </label>
                        <select
                          v-model="marca.tipoPrenda"
                          id="'marca_tipoPrenda_'+marca.idMarca"
                          name="'marca_tipoPrenda_'+marca.idMarca"
                          autocomplete="country"
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="H">Hombre</option>
                          <option value="M">Mujer</option>
                          <option value="U">Unisex</option>
                        </select>
                      </div>
                      <div class="col-span-6 sm:col-span-2 sm:mt-8">
                        <UICheckBox v-model="marca.activa" label="Activa" />
                      </div>
                      <div class="flex items-end col-span-6 sm:col-span-1">
                        <deleteButtonMulti
                          @deleteMultiItem="
                            fabricanteDeleteMarca(marca.idMarca)
                          "
                        />
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useFabricantes } from "./useFabricantes";
import { useTransportistas } from "@/views/transportistas/useTransportistas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UIListBox from "@/components/UIListBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import dirEdit from "@/components/dirEdit.vue";
import emailEdit from "@/components/emailEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import UINoItems from "@/components/UINoItems.vue";
import UITextArea from "@/components/UITextArea.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Home",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIListBox,
    tabmenuedition,
    dirEdit,
    UICheckBox,
    emailEdit,
    telefonoEdit,
    UINoItems,
    deleteButtonMulti,
    UITextArea
  },
  setup() {
    const headers = ["Código", "Nif", "Nombre", "Población"];
    const {
      fabricantes,
      fabricantesShowed,
      fabricanteEdit,
      fabricantesResetFilter,
      fabricanteAdd,
      fabricanteDelete,
      fabricanteCancel,
      fabricanteSave,
      fabricanteInEdition,
      fabricantesIndex,
      fabricantesGotoNextPage,
      fabricantesGoToPreviousPage,
      fabricanteIsEditing,
      fabricantesIsLoading,
      fabricantesIsFirstPage,
      fabricantesIsLastPage,
      fabricanteAddDireccion,
      fabricanteDeleteDireccion,
      fabricanteAddEmail,
      fabricanteDeleteEmail,
      fabricanteAddTelefono,
      fabricanteDeleteTelefono,
      fabricanteAddMarca,
      fabricanteDeleteMarca,
      fabricanteSuccess,
      fabricanteError,
      fabricanteErrorText,
      fabricanteIsValid,
      fabricanteIsValidDirecciones,
      fabricanteIsValidEmails,
      fabricanteIsValidTelefonos,
      fabricanteIsValidMarcas
    } = useFabricantes();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();
    const { transportistas } = useTransportistas();

    const showListados = ref(false);

    const showValidate = ref(false);

    const tabsEdit = ref([true, false, false, false]);
    const tabsEditIsValid = ref([true, true, true, true]);

    const fabricanteWaitingForSave = ref(false);

    fabricantesResetFilter();

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = fabricanteIsValid(fabricanteInEdition.value);
      tabsEditIsValid.value[1] = fabricanteIsValidDirecciones(
        fabricanteInEdition.value.direcciones
      );
      tabsEditIsValid.value[2] = fabricanteIsValidEmails(
        fabricanteInEdition.value.emails
      );
      tabsEditIsValid.value[3] = fabricanteIsValidTelefonos(
        fabricanteInEdition.value.telefonos
      );
      tabsEditIsValid.value[4] = fabricanteIsValidMarcas(
        fabricanteInEdition.value.marcas
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function fabricantePreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );
      if (showValidate.value) return;

      fabricanteWaitingForSave.value = true;
      fabricanteSave().then(() => {
        fabricanteWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function fabricantePreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true, true, true, true, true];
      changeActiveTab(0);
      fabricanteCancel();
    }

    watch(
      [fabricanteError, fabricanteSuccess],
      ([fabricanteError, fabricanteSuccess]) => {
        if (fabricanteSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (fabricanteError) {
          createToast(
            {
              title: "Error 👎 ",
              description: fabricanteErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      fabricantes,
      fabricanteEdit,
      fabricanteAdd,
      fabricanteDelete,
      fabricanteCancel,
      fabricanteSave,
      fabricanteInEdition,
      fabricantesIndex,
      fabricantesGotoNextPage,
      fabricantesGoToPreviousPage,
      fabricanteIsEditing,
      fabricantesIsLoading,
      fabricantesIsFirstPage,
      fabricantesIsLastPage,
      fabricanteAddDireccion,
      fabricanteDeleteDireccion,
      fabricanteAddEmail,
      fabricanteDeleteEmail,
      fabricanteAddTelefono,
      fabricanteDeleteTelefono,
      fabricanteAddMarca,
      fabricanteDeleteMarca,
      fabricantePreviousCancel,
      fabricantePreviousSave,
      fabricanteWaitingForSave,

      fabricantesShowed,

      tabsEdit,
      tabsEditIsValid,
      changeActiveTab,
      showListados,
      codigosPostalesPoblaciones,
      tiposTelefono,
      transportistas,
      showValidate
    };
  }
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }
  td:nth-of-type(1):before {
    content: "Código";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "NIF";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Nombre";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Población";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
