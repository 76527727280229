import { TipoTelefonoView } from "@/types/tipostelefono/TipoTelefonoView";
import axios from "../apiService";

export const apiTiposTelefono = {
  getTiposTelefonoView(): Promise<TipoTelefonoView[]> {
    return new Promise(resolve => {
      const url = "TipoTelefonoView/filter";
      axios
        .post<TipoTelefonoView[]>(url, {})
        .then(response => resolve(response.data));
    });
  },
  getTipoTelefonoView(id: number): Promise<TipoTelefonoView> {
    return new Promise(resolve => {
      const url = "TipoTelefonoView/" + id;
      axios.get<TipoTelefonoView>(url).then(response => resolve(response.data));
    });
  }
};
