<template>
  <div v-if="numbersOfItems == 0">
    <span class="text-3xl mr-2">&#129335;</span> Opps! parece que no hay datos,
    puedes empezar a introducirlos pulsando
    <button class="btn-add-multi px-2 py-1 ml-3" @click="addItem">
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UINoItems",
  props: {
    title: {
      type: String,
      required: false
    },
    numbersOfItems: {
      type: Number,
      required: true
    },
    hasAddButton: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  methods: {
    addItem() {
      this.$emit("addItem");
    }
  }
});
</script>
