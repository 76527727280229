import { onMounted, ref, Ref } from "vue";
import { apiPoblaciones } from "@/services/poblaciones/api";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";

export function useCodigosPostalesPoblaciones() {
  const items: Ref<CodigoPostalPoblacionView[]> = ref([]);

  onMounted(async () => {
    items.value = await apiPoblaciones.getCodigosPostalesPoblaciones();
  });

  return {
    codigosPostalesPoblaciones: items
  };
}
