import { computed, onMounted, ref, Ref } from "vue";
import { apiComisiones } from "@/services/comisiones/api";
import { ComisionView, ComisionFilter } from "@/types/comisiones/ComisionView";
import { Comision } from "@/types/comisiones/Comision";
import { isValidObject } from "../utilidades/useValidations";
import { usePagination } from "@/components/usePagination";
import { Factura, FacturaLinea } from "@/types/facturas/Factura";
import { apiFacturas } from "@/services/facturas/api";
import { ReceptorFacturaFilter } from "@/types/facturas/ReceptorFacturaView";

export function useComisiones() {
  const idNotEditItem = -999;
  const items: Ref<ComisionView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Comision);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const isLoading = ref(false);

  const filter = ref({} as ComisionFilter);

  const facturaCreada = ref(false);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("comisionFilter", JSON.stringify(filter.value));
    items.value = await apiComisiones.getComisionesView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiComisiones.getComision(id);
      if (editingItem.value.fecha != undefined) {
        editingItem.value.fecha = editingItem.value.fecha.substr(0, 10);
      }
    } else {
      editingItem.value = {
        id: 0
      } as Comision;
    }
  }

  function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as ComisionView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiComisiones
      .deleteComision(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Comision;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    if (editingItem.value.fecha == "") editingItem.value.fecha = undefined;

    await apiComisiones
      .saveComision(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );

    if (index == -1) return;
    const comisionUpdated = await apiComisiones.getComisionView(id);
    items.value[index] = comisionUpdated;
    editingItem.value = { id: idNotEditItem } as Comision;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  async function createFactura() {
    const factura = { id: 0 } as Factura;
    const fecha = new Date(); //Fecha actual
    factura.fecha =
      fecha.getFullYear() +
      "-" +
      (fecha.getMonth() + 1 < 10 ? "0" : "") +
      (fecha.getMonth() + 1) +
      "-" +
      (fecha.getDate() < 10 ? "0" : "") +
      fecha.getDate();

    const receptorFacturaFilter = {
      idFabricante: editingItem.value.idFabricante
    } as ReceptorFacturaFilter;
    const receptoresFactura = await apiFacturas.getReceptoresFacturaView(
      receptorFacturaFilter
    );
    console.log(receptoresFactura);

    factura.idCliente = receptoresFactura[0].id;

    factura.porcentajeIVA = 0;
    factura.porcentajeRecargo = 0;

    factura.idTipoFactura = 1;
    factura.pagadaSN = false;
    factura.lineas = [];
    const lineaFactura = {} as FacturaLinea;
    lineaFactura.descripcion = "Factura de Comisiones";
    lineaFactura.importe = editingItem.value.importe;
    lineaFactura.porcentajeDescuento = 0;
    lineaFactura.unidades = 1;
    factura.lineas.push(lineaFactura);

    apiFacturas
      .saveFactura(factura)
      .then(function(res) {
        success.value = true;
        editingItem.value.idFactura = res.id;
        saveEdit();
        facturaCreada.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
  }

  async function resetFilter() {
    filter.value = {} as ComisionFilter;
  }

  function isValid(item: Comision): boolean {
    return isValidObject(item, [
      "idFabricante",
      "idTipoTemporada",
      "anyo",
      "fecha"
    ]);
  }

  return {
    comisiones: items,
    comisionesShowed: itemsShowed,
    comisionesFilter: filter,
    comisionesSearch: filterItems,
    comisionesResetFilter: resetFilter,
    comisionEdit: editItem,
    comisionAdd: addItem,
    comisionDelete: deleteItem,
    comisionCancel: cancelEdit,
    comisionSave: saveEdit,
    comisionInEdition: editingItem,
    comisionIndex: currentRow,
    comisionesGotoNextPage: nextPage,
    comisionesGoToPreviousPage: previousPage,
    comisionIsEditing: isEditing,
    comisionesIsLoading: isLoading,
    comisionesIsFirstPage: isFirstPage,
    comisionesIsLastPage: isLastPage,
    comisionSuccess: success,
    comisionError: error,
    comisionErrorText: errorText,
    comisionIsValid: isValid,
    comisionCreateFactura: createFactura,
    comisionFacturaCreada: facturaCreada
  };
}
