<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
        >
          Usuarios
        </h2>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="usuarios.length"
      :isEditing="usuarioIsEditing"
      :isLoading="usuariosIsLoading"
      :isFirstPage="usuariosIsFirstPage"
      :isLastPage="usuariosIsLastPage"
      :items="usuarios"
      @newitem="usuarioAdd"
      @next="usuariosGotoNextPage"
      @previous="usuariosGoToPreviousPage"
    >
      <template
        v-for="usuario in usuariosShowed"
        :key="usuario.id"
        class="border-b border-gray-200"
      >
        <tr v-if="usuario.id != usuarioInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ usuario.nombre }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ usuario.username }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span v-if="usuario.administrador">✅</span>
            <span v-else>🚫</span>
          </td>
          <datatablerowbuttons
            @edititem="usuarioEdit"
            @deleteitem="usuarioDelete"
            :id="usuario.id"
            :isEditing="usuarioIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1">
            <rowEdit
              @cancel="usuarioPreviousCancel"
              @save="usuarioPreviousSave"
              :waitingForSave="usuarioWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Usuario
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
              </template>
              <template v-slot:default>
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3 sm:col-span-2">
                    <UITextInput
                      v-model="usuarioInEdition.username"
                      label="Usuario"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="30"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UIPasswordInput
                      v-model="usuarioInEdition.password"
                      label="Password"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="30"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UIPasswordInput
                      v-model="passwordConfirmation"
                      label="Confirmación del password"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="30"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UITextInput
                      v-model="usuarioInEdition.nombre"
                      label="Nombre completo"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="30"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UITextInput
                      v-model="usuarioInEdition.email"
                      label="Email"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="100"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UICheckBox
                      v-model="usuarioInEdition.administrador"
                      label="Administrador"
                    />
                  </div>
                </div>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useUsuario } from "@/views/usuarios/useUsuarios";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UIPasswordInput from "@/components/UIPasswordInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "usuarios",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UIPasswordInput,
    UICheckBox
  },
  setup() {
    const headers = ["Nombre", "Usuario", "Administrador"];
    const {
      usuarios,
      usuariosShowed,
      usuarioEdit,
      usuarioAdd,
      usuarioDelete,
      usuarioCancel,
      usuarioSave,
      usuarioInEdition,
      usuariosGotoNextPage,
      usuariosGoToPreviousPage,
      usuarioIsEditing,
      usuariosIsLoading,
      usuariosIsFirstPage,
      usuariosIsLastPage,
      usuarioIsValid,
      usuarioSuccess,
      usuarioError,
      usuarioErrorText,
      passwordConfirmation
    } = useUsuario();

    const showValidate = ref(false);

    const usuarioWaitingForSave = ref(false);

    function usuarioPreviousSave() {
      showValidate.value = !usuarioIsValid(usuarioInEdition.value);
      showValidate.value =
        showValidate.value ||
        passwordConfirmation.value != usuarioInEdition.value.password;
      passwordConfirmation.value = "";
      if (showValidate.value) return;
      localStorage.setItem(
        "ultimausuario",
        JSON.stringify(usuarioInEdition.value)
      );
      //localStorage.setItem("usuarioIdTemporada",usuarioInEdition.value.idTemporada.toString);
      usuarioWaitingForSave.value = true;
      usuarioSave().then(() => {
        usuarioWaitingForSave.value = false;
      });
    }

    function usuarioPreviousCancel() {
      showValidate.value = false;
      usuarioCancel();
    }

    watch([usuarioError, usuarioSuccess], ([usuarioError, usuarioSuccess]) => {
      if (usuarioSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito"
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a"
          }
        );
      }

      if (usuarioError) {
        createToast(
          {
            title: "Error 👎 ",
            description: usuarioErrorText.value
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true
          }
        );
      }
    });

    return {
      headers,
      usuarios,
      usuariosShowed,
      usuarioEdit,
      usuarioAdd,
      usuarioDelete,
      usuarioPreviousCancel,
      usuarioPreviousSave,
      usuarioInEdition,
      usuariosGotoNextPage,
      usuariosGoToPreviousPage,
      usuarioIsEditing,
      usuariosIsLoading,
      usuariosIsFirstPage,
      usuariosIsLastPage,
      usuarioWaitingForSave,
      passwordConfirmation,
      showValidate
    };
  }
});
</script>
