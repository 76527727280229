<template>
  <div class="lg:flex">
    <div class="lg:w-1/2 xl:max-w-screen-sm">
      <div class="py-12 lg:bg-white flex justify-center ">
        <div class2="text-2xl text-indigo-800 tracking-wide ml-2 font-semibold">
          <img
            src="../assets/logociadelamoda40.png"
            alt="Logo"
            class="w-full"
          />
        </div>
      </div>
      <div
        class="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl"
      >
        <div class="mt-12">
          <form>
            <div>
              <div class="text-sm font-bold text-gray-700 tracking-wide">
                Usuario
              </div>
              <input
                class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="text"
                placeholder="usuario"
                v-model="usuario"
              />
            </div>
            <div class="mt-8">
              <div class="flex justify-between items-center">
                <div class="text-sm font-bold text-gray-700 tracking-wide">
                  Password
                </div>
              </div>
              <input
                class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                type="password"
                placeholder="Introduzca su contraseña"
                v-model="clave"
              />
            </div>
            <div class="mt-10">
              <a
                @click="tryLogin"
                class="bg-pink-500 text-gray-100 p-4 w-full rounded tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-pink-600
                                shadow-lg cursor-pointer"
              >
                Entrar
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen"
    >
      <img src="../assets/hero.jpg" alt="Logo" class="h-full object-cover" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { apiUsuarios } from "@/services/usuarios/api";

export default defineComponent({
  name: "Login",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const usuario = ref("");
    const clave = ref("");

    async function tryLogin() {
      if (usuario.value != "" && clave.value != "") {
        apiUsuarios
          .validate(usuario.value, clave.value)
          .then((response) => {
            localStorage.setItem("token", response.token);
            if (response.administrador) {
              localStorage.setItem(
                "admin",
                response.token
                  .split("")
                  .reverse()
                  .join("")
              );
            } else {
              localStorage.removeItem("admin");
            }
            console.log(response);
            const newPath = route.params.nextUrl;
            router.push({ path: "/app/" + newPath });
          })
          .catch(() => {
            alert("ERROR");
          });
      }
    }
    return {
      usuario,
      clave,
      tryLogin,
    };
  },
});
</script>
