<template>
  <div class="bg-gray-100">
    <form action="#" method="POST">
      <div class="shadow sm:rounded-md relative">
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6 ">
          <slot></slot>
        </div>
      </div>
    </form>
    <div class="px-4 py-3 bg-gray-300 text-right sm:px-6">
      <button
        @click.prevent="$emit('cancel')"
        class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Ocultar
      </button>
      <button
        @click.prevent="$emit('reset')"
        class="ml-5 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Reset
      </button>
      <button
        type="submit"
        @click.prevent="$emit('save')"
        class="ml-5 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Buscar
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "filterEdit",
  props: {
    waitingForSave: {
      type: Boolean,
      requiered: false
    }
  },
  computed: {
    waitingForSaveItem(): boolean {
      return this.waitingForSave;
    }
  }
});
</script>
