
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useTiendas } from "./useTiendas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";
import { useClientes } from "../clientes/useClientes";
import { useProvincias } from "../poblaciones/useProvincias";
import { useComunidadesAutonomas } from "../poblaciones/useComunidadesAutonomas";
import { usePoblaciones } from "../poblaciones/usePoblaciones";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIListBox from "@/components/UIListBox.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import dirEdit from "@/components/dirEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UINoItems from "@/components/UINoItems.vue";
import selectCliente from "@/components/selectCliente.vue";
import { TiendaFilter } from "@/types/tiendas/TiendaView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "tiendas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UITextArea,
    UIListBox,
    tabmenuedition,
    filterEdit,
    dirEdit,
    telefonoEdit,
    UINoItems,
    selectCliente,
  },
  setup() {
    const headers = ["Nombre", "Cliente", "Código Postal", "Población"];
    const {
      tiendas,
      tiendasShowed,
      tiendasFilter,
      tiendasSearch,
      tiendasResetFilter,
      tiendaEdit,
      tiendaAdd,
      tiendaDelete,
      tiendaCancel,
      tiendaSave,
      tiendaInEdition,
      tiendasIndex,
      tiendasGotoNextPage,
      tiendasGoToPreviousPage,
      tiendaIsEditing,
      tiendasIsLoading,
      tiendasIsFirstPage,
      tiendasIsLastPage,
      tiendaAddDireccion,
      tiendaDeleteDireccion,
      tiendaAddTelefono,
      tiendaDeleteTelefono,
      tiendaSuccess,
      tiendaError,
      tiendaErrorText,
      tiendaIsValid,
      tiendaIsValidDirecciones,
      tiendaIsValidTelefonos,
    } = useTiendas();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();
    const { clientes } = useClientes();
    const { poblaciones } = usePoblaciones();
    const { provincias } = useProvincias();
    const { comunidadesAutonomas } = useComunidadesAutonomas();

    tiendasResetFilter();

    const route = useRoute();
    const param = route.params.idCliente;

    if (param != "") tiendasFilter.value.idCliente = Number(param);

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);

    const tabsEdit = ref([true, false, false]);
    const tabsEditIsValid = ref([true, true, true]);

    const tiendaWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = tiendaIsValid(tiendaInEdition.value);

      tabsEditIsValid.value[1] = tiendaIsValidDirecciones(
        tiendaInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = tiendaIsValidTelefonos(
        tiendaInEdition.value.telefonos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function tiendaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      tiendaWaitingForSave.value = true;
      tiendaSave().then(() => {
        tiendaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function tiendaPreviousCancel() {
      changeActiveTab(0);
      tiendaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function updateFilterDomicilio(tipoDomicilio: string) {
      if (tipoDomicilio == "C")
        tiendasFilter.value.domicilioCorrespondencia = !tiendasFilter.value
          .domicilioCorrespondencia;
      if (tipoDomicilio == "E")
        tiendasFilter.value.domicilioEntrega = !tiendasFilter.value
          .domicilioEntrega;
    }

    watch([tiendaError, tiendaSuccess], ([tiendaError, tiendaSuccess]) => {
      if (tiendaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (tiendaError) {
        createToast(
          {
            title: "Error 👎 ",
            description: tiendaErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      tiendas,
      tiendasFilter,
      tiendasSearch,
      tiendasResetFilter,
      tiendaEdit,
      tiendaAdd,
      tiendaDelete,
      tiendaCancel,
      tiendaSave,
      tiendaInEdition,
      tiendasIndex,
      tiendasGotoNextPage,
      tiendasGoToPreviousPage,
      tiendaIsEditing,
      tiendasIsLoading,
      tiendasIsFirstPage,
      tiendasIsLastPage,
      tiendaAddDireccion,
      tiendaDeleteDireccion,
      tiendaAddTelefono,
      tiendaDeleteTelefono,
      tiendaPreviousCancel,
      tiendaPreviousSave,
      tiendaWaitingForSave,

      tiendasShowed,

      tabsEdit,
      changeActiveTab,

      isFiltering,
      showFilter,

      showListados,

      codigosPostalesPoblaciones,
      tiposTelefono,
      clientes,
      updateFilterDomicilio,
      poblaciones,
      provincias,
      comunidadesAutonomas,
      showValidate,
    };
  },
});
