import { computed, onMounted, ref, Ref } from "vue";
import { apiFacturas } from "@/services/facturas/api";
import { TipoFacturaView } from "@/types/facturas/TipoFacturaView";
import { TipoFactura } from "@/types/facturas/TipoFactura";
import { SerieFactura } from "@/types/facturas/SerieFactura";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useTiposFacturas() {
  const idNotEditItem = -999;
  const items: Ref<TipoFacturaView[]> = ref([]);
  const editingItem = ref({
    id: idNotEditItem,
    modeloLineasExtendido: false
  } as TipoFactura);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiFacturas.getTiposFacturaView();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  });

  async function editItem(id: number) {
    editingItem.value = {
      id: idNotEditItem,
      modeloLineasExtendido: false
    } as TipoFactura;
    if (id != 0) {
      editingItem.value = await apiFacturas.getTipoFactura(id);
    } else {
      editingItem.value = {
        id: 0,
        modeloLineasExtendido: false
      } as TipoFactura;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
      modeloLineasExtendido: false
    } as TipoFacturaView);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiFacturas
      .deleteTipoFactura(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as TipoFactura;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiFacturas
      .saveTipoFactura(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    const tipoUpdated = await apiFacturas.getTipoFacturaView(id);
    found.id = tipoUpdated.id;
    found.descripcion = tipoUpdated.descripcion;
    found.abreviatura = tipoUpdated.abreviatura;
    found.idSerie = tipoUpdated.idSerie;
    found.serie = tipoUpdated.serie;
    found.modeloLineasExtendido = tipoUpdated.modeloLineasExtendido;
    editingItem.value = { id: idNotEditItem } as TipoFactura;
  }

  function tiposFacturasUpdateSerie(serie: SerieFactura) {
    const updatedTipos = items.value.filter(item => item.idSerie === serie.id);
    updatedTipos.forEach(item => {
      item.serie = serie.abreviatura;
    });
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: TipoFactura): boolean {
    return isValidObject(item, ["descripcion", "abreviatura", "idSerie"]);
  }

  return {
    tiposFacturas: items,
    tiposFacturasShowed: itemsShowed,
    tipoFacturaEdit: editItem,
    tipoFacturaAdd: addItem,
    tipoFacturaDelete: deleteItem,
    tipoFacturaCancel: cancelEdit,
    tipoFacturaSave: saveEdit,
    tipoFacturaInEdition: editingItem,
    tiposFacturasGotoNextPage: nextPage,
    tiposFacturasGoToPreviousPage: previousPage,
    tipoFacturaIsEditing: isEditing,
    tiposFacturasIsLoading: isLoading,
    tiposFacturasIsFirstPage: isFirstPage,
    tiposFacturasIsLastPage: isLastPage,
    tiposFacturasUpdateSerie,
    tiposFacturaSuccess: success,
    tiposFacturaError: error,
    tiposFacturaErrorText: errorText,
    tiposFacturaIsValid: isValid
  };
}
