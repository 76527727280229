
import { defineComponent } from "vue";

export default defineComponent({
  name: "deleteButtonMulti",
  props: {
    id: {
      type: Number,
      required: false
    },
    index: {
      type: Number,
      required: false
    }
  },
  methods: {
    deleteItem() {
      this.$emit("deleteMultiItem", this.id, this.index);
    }
  }
});
