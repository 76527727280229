
import { defineComponent, watch, ref } from "vue";

import { useComunidadesAutonomas } from "@/views/poblaciones/useComunidadesAutonomas";
import { useProvincias } from "@/views/poblaciones/useProvincias";
import { usePoblaciones } from "@/views/poblaciones/usePoblaciones";
import { useCodigosPostales } from "@/views/poblaciones/useCodigosPostales";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Poblaciones",
  components: {
    datatable,
    datatablerowbuttons,
    rowEdit,
    UITextInput
  },
  setup() {
    const {
      comunidadesAutonomas,
      comunidadesAutonomasShowed,
      comunidadAutonomaEdit,
      comunidadAutonomaAdd,
      comunidadAutonomaDelete,
      comunidadAutonomaCancel,
      comunidadAutonomaSave,
      comunidadAutonomaInEdition,
      comunidadAutonomaGotoNextPage,
      comunidadAutonomaGoToPreviousPage,
      comunidadAutonomaIsEditing,
      comunidadesAutonomasIsLoading,
      comunidadesAutonomasIsFirstPage,
      comunidadesAutonomasIsLastPage,
      comunidadesAutonomasloadedData,
      comunidadesAutonomasSuccess,
      comunidadesAutonomasError,
      comunidadesAutonomasErrorText,
      comunidadesAutonomasIsValid,
      comunidadesAutonomasNombreFilter
    } = useComunidadesAutonomas();

    const {
      provincias,
      provinciasComunidad,
      provinciasShowed,
      provinciaEdit,
      provinciaAdd,
      provinciaDelete,
      provinciaCancel,
      provinciaSave,
      provinciaInEdition,
      provinciaGotoNextPage,
      provinciaGoToPreviousPage,
      provinciaIsEditing,
      provinciasIsLoading,
      provinciasIsFirstPage,
      provinciasIsLastPage,
      provinciasIdComunidadAutonoma,
      provinciasloadedData,
      provinciasSuccess,
      provinciasError,
      provinciasErrorText,
      provinciasIsValid
    } = useProvincias();

    const {
      poblaciones,
      poblacionesProvincia,
      poblacionesShowed,
      poblacionEdit,
      poblacionAdd,
      poblacionDelete,
      poblacionCancel,
      poblacionSave,
      poblacionInEdition,
      poblacionGotoNextPage,
      poblacionGoToPreviousPage,
      poblacionIsEditing,
      poblacionesIsLoading,
      poblacionesIsFirstPage,
      poblacionesIsLastPage,
      poblacionesIdProvincia,
      poblacionesloadedData,
      poblacionesSuccess,
      poblacionesError,
      poblacionesErrorText,
      poblacionesIsValid
    } = usePoblaciones();

    const {
      codigosPostales,
      codigosPostalesPoblacion,
      codigosPostalesShowed,
      codigoPostalEdit,
      codigoPostalAdd,
      codigoPostalDelete,
      codigoPostalCancel,
      codigoPostalSave,
      codigoPostalInEdition,
      codigoPostalesGotoNextPage,
      codigoPostalesGoToPreviousPage,
      codigoPostalIsEditing,
      codigosPostalesIsLoading,
      codigosPostalesIsFirstPage,
      codigosPostalesIsLastPage,
      codigoPostalIdPoblacion,
      codigoPostalSuccess,
      codigoPostalError,
      codigoPostalErrorText,
      codigoPostalIsValid
    } = useCodigosPostales();

    const comunidadAutonomaSelectedItem = ref(1);
    const provinciaSelectedItem = ref(0);
    const poblacionSelectedItem = ref(0);

    const comunidadAutonomaWaitingForSave = ref(false);
    const showValidateComunidadAutonoma = ref(false);

    const provinciaWaitingForSave = ref(false);
    const showValidateProvincia = ref(false);

    const poblacionWaitingForSave = ref(false);
    const showValidatePoblacion = ref(false);

    const codigoPostalWaitingForSave = ref(false);
    const showValidateCodigoPostal = ref(false);

    function comunidadAutonomaSubmit() {
      showValidateComunidadAutonoma.value = !comunidadesAutonomasIsValid(
        comunidadAutonomaInEdition.value
      );
      if (showValidateComunidadAutonoma.value) return;
      comunidadAutonomaWaitingForSave.value = true;
      comunidadAutonomaSave().then(() => {
        comunidadAutonomaWaitingForSave.value = false;
      });
    }

    function provinciaSubmit() {
      showValidateProvincia.value = !provinciasIsValid(
        provinciaInEdition.value
      );
      if (showValidateProvincia.value) return;
      provinciaWaitingForSave.value = true;
      provinciaSave().then(() => {
        provinciaWaitingForSave.value = false;
      });
    }

    function poblacionSubmit() {
      showValidatePoblacion.value = !poblacionesIsValid(
        poblacionInEdition.value
      );
      if (showValidatePoblacion.value) return;
      poblacionWaitingForSave.value = true;
      poblacionSave().then(() => {
        poblacionWaitingForSave.value = false;
      });
    }

    function codigoPostalSubmit() {
      showValidateCodigoPostal.value = !codigoPostalIsValid(
        codigoPostalInEdition.value
      );
      if (showValidateCodigoPostal.value) return;
      codigoPostalWaitingForSave.value = true;
      codigoPostalSave().then(() => {
        codigoPostalWaitingForSave.value = false;
      });
    }

    function comunidadAutonomaPreviousCancel() {
      showValidateComunidadAutonoma.value = false;
      comunidadAutonomaCancel();
    }

    function provinciaPreviousCancel() {
      showValidateProvincia.value = false;
      provinciaCancel();
    }

    function poblacionPreviousCancel() {
      showValidatePoblacion.value = false;
      poblacionCancel();
    }

    function codigoPostalPreviousCancel() {
      showValidateCodigoPostal.value = false;
      codigoPostalCancel();
    }

    function poblacionSelect(id: number) {
      poblacionSelectedItem.value = id;
      codigoPostalIdPoblacion.value = id;
    }

    function provinciaSelect(id: number) {
      provinciaSelectedItem.value = id;
      poblacionesIdProvincia.value = id;
      poblacionSelect(poblacionesShowed.value[0].id);
    }

    function comunidadAutonomaSelect(id: number) {
      comunidadAutonomaSelectedItem.value = id;
      provinciasIdComunidadAutonoma.value = id;
      provinciaSelect(provinciasShowed.value[0].id);
    }

    watch(
      [
        comunidadesAutonomasloadedData,
        provinciasloadedData,
        poblacionesloadedData,
        comunidadesAutonomasNombreFilter
      ],
      newValues => {
        if (newValues[0] && newValues[1] && newValues[2]) {
          if(comunidadesAutonomasShowed.value.length > 0) {
            comunidadAutonomaSelect(comunidadesAutonomasShowed.value[0].id);
            provinciaSelect(provinciasShowed.value[0].id);
            poblacionSelect(poblacionesShowed.value[0].id);
          }
        }
      }
    );

    watch(
      [comunidadesAutonomasError, comunidadesAutonomasSuccess],
      ([comunidadesAutonomasError, comunidadesAutonomasSuccess]) => {
        if (comunidadesAutonomasSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (comunidadesAutonomasError) {
          createToast(
            {
              title: "Error 👎 ",
              description: comunidadesAutonomasErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [provinciasError, provinciasSuccess],
      ([provinciasError, provinciasSuccess]) => {
        if (provinciasSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (provinciasError) {
          createToast(
            {
              title: "Error 👎 ",
              description: provinciasErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [poblacionesError, poblacionesSuccess],
      ([poblacionesError, poblacionesSuccess]) => {
        if (poblacionesSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (poblacionesError) {
          createToast(
            {
              title: "Error 👎 ",
              description: poblacionesErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [codigoPostalError, codigoPostalSuccess],
      ([codigoPostalError, codigoPostalSuccess]) => {
        if (codigoPostalSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (codigoPostalError) {
          createToast(
            {
              title: "Error 👎 ",
              description: codigoPostalErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      //Comunidades Autonomas
      comunidadesAutonomas,
      comunidadAutonomaEdit,
      comunidadAutonomaAdd,
      comunidadAutonomaDelete,
      comunidadAutonomaPreviousCancel,
      comunidadAutonomaSave,
      comunidadAutonomaInEdition,
      comunidadAutonomaGotoNextPage,
      comunidadAutonomaGoToPreviousPage,
      comunidadAutonomaIsEditing,
      comunidadesAutonomasIsLoading,
      comunidadesAutonomasIsFirstPage,
      comunidadesAutonomasIsLastPage,
      comunidadAutonomaSubmit,
      comunidadesAutonomasShowed,
      comunidadAutonomaSelectedItem,
      comunidadAutonomaWaitingForSave,
      showValidateComunidadAutonoma,
      comunidadesAutonomasNombreFilter,

      // Provincias
      provincias,
      provinciasComunidad,
      provinciasShowed,
      provinciaEdit,
      provinciaAdd,
      provinciaDelete,
      provinciaPreviousCancel,
      provinciaSave,
      provinciaInEdition,
      provinciaGotoNextPage,
      provinciaGoToPreviousPage,
      provinciaIsEditing,
      provinciasIsLoading,
      provinciasIsFirstPage,
      provinciasIsLastPage,
      provinciasIdComunidadAutonoma,
      provinciaSubmit,
      provinciaSelectedItem,
      provinciaWaitingForSave,
      showValidateProvincia,

      //Poblaciones
      poblaciones,
      poblacionesProvincia,
      poblacionesShowed,
      poblacionEdit,
      poblacionAdd,
      poblacionDelete,
      poblacionPreviousCancel,
      poblacionSave,
      poblacionInEdition,
      poblacionGotoNextPage,
      poblacionGoToPreviousPage,
      poblacionIsEditing,
      poblacionesIsLoading,
      poblacionesIsFirstPage,
      poblacionesIsLastPage,
      poblacionesIdProvincia,
      poblacionSubmit,
      poblacionSelectedItem,
      poblacionWaitingForSave,
      showValidatePoblacion,

      //Códigos Postales
      codigosPostales,
      codigosPostalesPoblacion,
      codigosPostalesShowed,
      codigoPostalEdit,
      codigoPostalAdd,
      codigoPostalDelete,
      codigoPostalPreviousCancel,
      codigoPostalSave,
      codigoPostalInEdition,
      codigoPostalesGotoNextPage,
      codigoPostalesGoToPreviousPage,
      codigoPostalIsEditing,
      codigosPostalesIsLoading,
      codigosPostalesIsFirstPage,
      codigosPostalesIsLastPage,
      codigoPostalIdPoblacion,
      codigoPostalSubmit,
      codigoPostalWaitingForSave,
      showValidateCodigoPostal,

      comunidadAutonomaSelect,
      provinciaSelect,
      poblacionSelect
    };
  }
});
