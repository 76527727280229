<template>
  <div class="w-full relative">
    <label class="block text-sm font-medium text-gray-700 mb-1">{{
      labelValue
    }}</label>
    <div class="w-full relative">
      <div
        class="flex justify-between w-full shadow-sm sm:text-sm border-gray-300 rounded-md border pt-2 pl-3 pr-2 pb-2"
        :class="{ 'border-red-500 border-2': showError }"
      >
        <div>
          <span
            @click.prevent="showSearch"
            v-if="!isSearching"
            class="overflow-hidden w-full h-6"
            >{{ valueShowed() }}</span
          >
          <span
            @click.prevent="showSearch"
            v-if="isSearching"
            class="overflow-hidden w-full h-6"
            >Eliga una opción</span
          >
        </div>
        <div class="flex">
          <svg
            class="w-5 h-5 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="clearValue"
            v-if="modelValue != null"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="showSearch"
            v-if="!isSearching"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="showSearch"
            v-if="isSearching"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="isSearching">
      <template v-if="isSearching">
        <!--  <div class="absolute bg-white w-full"> -->
        <div>
          <input
            type="text"
            v-model="filter"
            ref="myinput"
            placeholder="Escriba al menos, 2 caracteres"
          />
        </div>
        <div>
          <ul
            class="h-36 overflow-y-scroll border-gray-300 border-b border-l border-r"
          >
            <li
              class="border-gray-300 border-b py-1 px-2 hover:bg-gray-200 cursor-pointer sm:text-sm"
              v-for="c in search(filter)"
              :key="c.id"
              @click="selectItem(c.id)"
            >
              {{ c.nombre }}
            </li>
          </ul>
        </div>
        <!-- </div> -->
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, computed } from "vue";
import { ReceptorFacturaView } from "@/types/facturas/ReceptorFacturaView";

export default defineComponent({
  name: "selectReceptorFactura",
  props: {
    modelValue: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    labelValue: {
      type: String,
      default: "Receptor"
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): ReceptorFacturaView[] {
      const items = props.items as ReceptorFacturaView[];
      const filterOk = filter.toUpperCase();
      //console.log(filterOk);
      if (filter.length < 2) return [];
      return items.filter(item =>
        item.nombre != null ? item.nombre.toUpperCase().indexOf(filterOk) > -1 : false
      );
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      if (id != -1) {
        emit("update:modelValue", id);
      }
    }

    function clearValue() {
      // console.log("clear");
      emit("update:modelValue", null);
    }

    function valueShowed(): string {
      //console.log("ValueShowed");
      if (props.modelValue == undefined) return "seleccione un receptor";
      const items = props.items as ReceptorFacturaView[];
      //console.log("props.modelValue="+props.modelValue);
      //console.log(items);
      const resultado = items.find(x => x.id == props.modelValue);
      if (resultado == undefined) return "Cargando ...";
      return resultado.nombre;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      filter,
      myinput,
      showSearch,
      search,
      valueShowed,
      selectItem,
      clearValue,
      showError
    };
  }
});
</script>
