
import { defineComponent, ref, watch } from "vue";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

export default defineComponent({
  name: "emailEdit",
  components: {
    UITextInput,
    UICheckBox,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    descripcion: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    },
    principal: {
      type: Boolean,
      required: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:email",
    "update:principal",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const myDescripcion = ref(props.descripcion);
    const myEmail = ref(props.email);
    const myPrincipal = ref(props.principal);

    watch(
      [myDescripcion, myEmail, myPrincipal],
      ([myDescripcion, myEmail, myPrincipal]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:email", myEmail);
        emit("update:principal", myPrincipal);
      }
    );

    return {
      myPrincipal,
      myEmail,
      myDescripcion
    };
  }
});
