<template>
  <div>
    <label class="block text-sm font-medium text-gray-700 mb-1">{{
      labelValue
    }}</label>
    <div class="w-full relative">
      <div
        class="flex justify-between w-full shadow-sm sm:text-sm border-gray-300 rounded-md border pt-2 pl-3 pr-2 pb-2"
        :class="{ 'border-red-500 border-2': showError }"
      >
        <div>
          <span
            @click.prevent="showSearch"
            v-if="!isSearching"
            class="overflow-hidden w-full h-6"
            >{{ valueShowed() }}</span
          >
          <span
            @click.prevent="showSearch"
            v-if="isSearching"
            class="overflow-hidden w-full h-6"
            >Eliga una opción</span
          >
        </div>
        <div class="flex">
          <svg
            class="w-5 h-5 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="clearValue"
            v-if="modelValue != null"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="showSearch"
            v-if="!isSearching"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click.prevent="showSearch"
            v-if="isSearching"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            ></path>
          </svg>
        </div>
      </div>

      <template v-if="isSearching">
        <div class="z-10 absolute bg-white w-full">
          <div>
            <input
              type="text"
              v-model="filter"
              ref="myinput"
              placeholder="Escriba al menos, 2 caracteres"
            />
          </div>
          <div>
            <ul
              class="h-36 overflow-y-scroll border-gray-300 border-b border-l border-r"
            >
              <li
                class="border-gray-300 border-b py-1 px-2 hover:bg-gray-200 cursor-pointer sm:text-sm"
                v-for="c in search(filter)"
                :key="c.id"
                @click="selectItem(c.id)"
              >
                <span :class="{ 'line-through text-red-400': !c.activa }">
                  {{ c.descripcion }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, computed } from "vue";
import { ColeccionView } from "@/types/colecciones/ColeccionView";

export default defineComponent({
  name: "selectColeccion",
  props: {
    modelValue: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    labelValue: {
      type: String,
      default: "Colección"
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): ColeccionView[] {
      const items = props.items as ColeccionView[];
      const filterOk = filter.toUpperCase();
      if (filter.length < 2) return [];
      return items.filter(
        item => !item.inexistente && item.descripcion.indexOf(filterOk) > -1
      );
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      if (id != -1) {
        emit("update:modelValue", id);
      }
    }

    function clearValue() {
      emit("update:modelValue", null);
    }

    function valueShowed(): string {
      if (props.modelValue == undefined) return "seleccione una colección";
      const items = props.items as ColeccionView[];
      const resultado = items.find(x => x.id == props.modelValue);
      if (resultado == undefined) return "Cargando ...";
      return resultado.descripcion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      filter,
      myinput,
      showSearch,
      search,
      valueShowed,
      selectItem,
      clearValue,
      showError
    };
  }
});
</script>
