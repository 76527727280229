import { CitaFilter, CitaView } from "@/types/agenda/CitaView";
import { Cita } from "@/types/agenda/Cita";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiAgenda = {
  getAgendaView(filter: CitaFilter): Promise<CitaView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      console.log(filter);
      const url = "AgendaView/filter";
      axios
        .post<CitaView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getCitaView(id: number): Promise<CitaView> {
    return new Promise(resolve => {
      const url = "AgendaView/" + id;
      axios.get<CitaView>(url).then(response => resolve(response.data));
    });
  },
  getCita(id: number): Promise<Cita> {
    return new Promise(resolve => {
      const url = "Agenda/" + id;
      axios.get<Cita>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveCita(cita: Cita): Promise<Cita> {
    return new Promise((resolve, reject) => {
      const url = "Agenda";
      axios
        .post(url, cita)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteCita(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Agenda/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  sendEmailCita(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Agenda/Email/" + id;
      axios
        .post(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log("ERROR");
          console.log(error);
          reject("Error al enviar el email");
        });
    });
  }
};
