<template>
  <div class="flex items-start mr-8">
    <input
      type="checkbox"
      :name="myId()"
      :id="myId()"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label
      :for="myId()"
      class="block text-sm font-medium text-gray-700 mr-1 ml-2"
    >
      {{ myLabel() }}
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UICheckBox",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const myLabel = function() {
      return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };
    return {
      myLabel,
      myId
    };
  }
});
</script>
