<template>
  <label :for="myId()" class="block text-sm font-medium text-gray-700">
    {{ myLabel() }}
  </label>
  <div class="flex rounded-md shadow-sm">
    <input
      type="number"
      :name="myId()"
      :id="myId()"
      :maxlength="maxLength"
      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
      :class="{ 'border-red-500': showError }"
      :placeholder="myPlaceHolder()"
      :value="modelValue"
      @input="setNumber($event.target.value)"
      v-on:blur="lostFocus()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "UINumberInput",
  props: {
    modelValue: {
      type: Number || undefined,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    },
    validData: {
      type: Boolean,
      default: true
    }
  },
  emits: [    
    "update:modelValue",
    "blur"
  ],
  setup(props, { emit }) {
    const myLabel = function() {
      return props.label;
    };
    const myPlaceHolder = function() {
      if (props.placeholder) return props.placeholder;
      else return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };
    function setNumber(value: number) {
      emit("update:modelValue", Number(value));
    }
    function lostFocus() {
      console.log("blur!!!!");
      emit("blur");
    }

    const showError = computed(() => {
      return (
        !props.validData ||
        (props.required &&
          props.showRequired &&
          (props.modelValue == null || props.modelValue == undefined))
      );
    });

    return {
      myLabel,
      myPlaceHolder,
      setNumber,
      myId,
      showError,
      lostFocus
    };
  }
});
</script>
