import { computed, onMounted, ref, Ref } from "vue";
import { apiExpediciones } from "@/services/expediciones/api";
import { apiPedidos } from "@/services/pedidos/api";
import {
  ExpedicionView,
  ExpedicionFilter,
} from "@/types/expediciones/ExpedicionView";
import {
  Expedicion,
  ExpedicionVencimiento,
} from "@/types/expediciones/Expedicion";
import { PedidoView } from "@/types/pedidos/PedidoView";
import { usePagination } from "@/components/usePagination";
import {
  isValidObject,
  isValidArrayOfObjects,
} from "../utilidades/useValidations";
import { getTempId } from "../utilidades/useIDLibrary";

export function useExpediciones() {
  const idNotEditItem = -999;
  const items: Ref<ExpedicionView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Expedicion);
  const newItem = ref({} as Expedicion);
  const pedido = ref({} as PedidoView);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows,
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as ExpedicionFilter);

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiExpediciones.getExpedicion(id);
      if (editingItem.value.fecha != undefined) {
        editingItem.value.fecha = editingItem.value.fecha.substr(0, 10);
      }
      if (editingItem.value.fechaPago != undefined) {
        editingItem.value.fechaPago = editingItem.value.fechaPago.substr(0, 10);
      }
      editingItem.value.vencimientos.forEach((vencimiento) => {
        if (vencimiento.fechaPagoVencimiento != undefined) {
          vencimiento.fechaPagoVencimiento = vencimiento.fechaPagoVencimiento.substr(
            0,
            10
          );
        }
        if (vencimiento.fechaVencimiento != undefined) {
          vencimiento.fechaVencimiento = vencimiento.fechaVencimiento.substr(
            0,
            10
          );
        }
      });
      pedido.value = await apiPedidos.getPedidoView(editingItem.value.idPedido);
    } else {
      editingItem.value = {
        id: 0,
        pagadaSN: false,
        vencimientos: [] as ExpedicionVencimiento[],
      } as Expedicion;
      pedido.value = {} as PedidoView;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as ExpedicionView);
    editItem(0);
  }

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("expedicionFilter", JSON.stringify(filter.value));
    items.value = await apiExpediciones.getExpedicionesView(filter.value);
    //console.log(items);
    if (newItem.value.idPedido != undefined) {
      addItem();
      editingItem.value = newItem.value;
      pedido.value = await apiPedidos.getPedidoView(editingItem.value.idPedido);
    }
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiExpediciones
      .deleteExpedicion(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter((item) => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Expedicion;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    if (editingItem.value.idTransportista == 0)
      editingItem.value.idTransportista = undefined;
    //console.log("editingItem.value.idTransportista="+editingItem.value.idTransportista);
    if (editingItem.value.fecha == "") editingItem.value.fecha = undefined;
    if (editingItem.value.fechaPago == "")
      editingItem.value.fechaPago = undefined;

    editingItem.value.vencimientos.forEach((vencimiento) => {
      //console.log("vencimiento.idFormaPagoVencimiento"+vencimiento.idFormaPagoVencimiento);
      if (vencimiento.idFormaPagoVencimiento == 0)
        vencimiento.idFormaPagoVencimiento = undefined;
      if (vencimiento.fechaPagoVencimiento == "")
        vencimiento.fechaPagoVencimiento = undefined;
      if (vencimiento.fechaVencimiento == "")
        vencimiento.fechaVencimiento = undefined;
    });

    await apiExpediciones
      .saveExpedicion(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const index = items.value.findIndex(
      (item) => item.id === editingItem.value.id
    );

    if (index == -1) return;
    const expedicionUpdated = await apiExpediciones.getExpedicionView(id);
    items.value[index] = expedicionUpdated;
    editingItem.value = { id: idNotEditItem } as Expedicion;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addVencimiento() {
    //console.log("add vencimiento");
    editingItem.value.vencimientos.push({
      idVencimiento: getTempId(editingItem.value.vencimientos, "idVencimiento"),
      pagadoSNVencimiento: false,
      idExpedicion: editingItem.value.id,
    } as ExpedicionVencimiento);
    //console.log("fin add vencimiento");
  }

  function deleteVencimiento(id: number) {
    editingItem.value.vencimientos = editingItem.value.vencimientos.filter(
      (item) => item.idVencimiento != id
    );
  }

  async function resetFilter() {
    let dateAux = new Date();
    dateAux.setFullYear(dateAux.getFullYear() - 2);
    const offset = dateAux.getTimezoneOffset();
    dateAux = new Date(dateAux.getTime() - offset * 60 * 1000);
    const dateAux2 = dateAux.toISOString().split("T")[0];

    filter.value = {
      desdeFechaExpedicion: dateAux2,
    } as ExpedicionFilter;

    //filter.value = {} as ExpedicionFilter;
  }

  function isValid(item: Expedicion): boolean {
    return isValidObject(item, ["fecha"]);
  }

  function isValidVencimientos(vencimientos: ExpedicionVencimiento[]): boolean {
    return isValidArrayOfObjects(vencimientos, [
      "fechaVencimiento",
      "importeVencimiento",
    ]);
  }

  return {
    expediciones: items,
    expedicionesShowed: itemsShowed,
    expedicionesFilter: filter,
    expedicionesSearch: filterItems,
    expedicionesResetFilter: resetFilter,
    expedicionEdit: editItem,
    expedicionAdd: addItem,
    expedicionDelete: deleteItem,
    expedicionCancel: cancelEdit,
    expedicionSave: saveEdit,
    expedicionInEdition: editingItem,
    expedicionesIndex: currentRow,
    expedicionesGotoNextPage: nextPage,
    expedicionesGoToPreviousPage: previousPage,
    expedicionIsEditing: isEditing,
    expedicionesIsLoading: isLoading,
    expedicionesIsFirstPage: isFirstPage,
    expedicionesIsLastPage: isLastPage,
    expedicionAddVencimiento: addVencimiento,
    expedicionDeleteVencimiento: deleteVencimiento,
    expedicionSuccess: success,
    expedicionError: error,
    expedicionErrorText: errorText,
    expedicionIsValid: isValid,
    expedicionIsValidVencimientos: isValidVencimientos,
    expedicionPedido: pedido,
    expedicionNewItem: newItem,
  };
}
