
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { usePedidos } from "./usePedidos";
import { useColecciones } from "../colecciones/useColecciones";
import { useClientes } from "../clientes/useClientes";
import { useTiendas } from "../tiendas/useTiendas";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useMarcas } from "../fabricantes/useMarcas";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import selectCliente from "@/components/selectCliente.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectTienda from "@/components/selectTienda.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";
import UINoItems from "@/components/UINoItems.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { PedidoFilter } from "@/types/pedidos/PedidoView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { apiColecciones } from "@/services/colecciones/api";
import { apiFabricantes } from "@/services/fabricantes/api";
import { apiMarcas } from "@/services/marcas/api";

export default defineComponent({
  name: "Pedidos",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UITextArea,
    UICheckBox,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectCliente,
    selectTienda,
    selectColeccion,
    selectFabricante,
    selectMarca,
    selectTipoTemporada,
    UINoItems,
  },
  setup() {
    const headers = [
      "Número",
      "Fecha pedido",
      "Colección",
      "Cliente",
      "Nº prendas",
      "Importe prendas",
      "Fecha servicio",
    ];
    const {
      pedidos,
      pedidosShowed,
      pedidosFilter,
      pedidosSearch,
      pedidosResetFilter,
      pedidoEdit,
      pedidoAdd,
      pedidoDelete,
      pedidoCancel,
      pedidoSave,
      pedidoInEdition,
      pedidosIndex,
      pedidosGotoNextPage,
      pedidosGoToPreviousPage,
      pedidoIsEditing,
      pedidosIsLoading,
      pedidosIsFirstPage,
      pedidosIsLastPage,
      pedidoAddIncidencia,
      pedidoDeleteIncidencia,
      pedidoSuccess,
      pedidoError,
      pedidoErrorText,
      pedidoIsValid,
      pedidoIsValidIncidencias,
      pedidoTotalesExpediciones,
    } = usePedidos();

    const route = useRoute();
    const param = route.params.idCliente;
    const param2 = route.params.numeroPedido;

    console.log("idCliente", param);
    console.log("idTienda", param2);

    if (param != "" && param != "$")
      pedidosFilter.value = {
        idCliente: Number(param),
        soloColeccionesActivas: false,
        enPeriododeEntrega: false,
      } as PedidoFilter;
    else {
      if (param2 != "" && param2 != "$")
        pedidosFilter.value = {
          numeroPedido: param2,
          soloColeccionesActivas: false,
          enPeriododeEntrega: false,
        } as PedidoFilter;
      else {
        pedidosResetFilter();
      }
    }

    const { colecciones } = useColecciones();
    const { clientes } = useClientes();
    const { tiendas } = useTiendas();
    const { tiposTemporada } = useTipoTemporada();
    const { fabricantes } = useFabricantes();
    const { marcas } = useMarcas();

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);

    const tabsEdit = ref([true, false]);
    const tabsEditIsValid = ref([true, true]);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = pedidoIsValid(pedidoInEdition.value);

      tabsEditIsValid.value[1] = pedidoIsValidIncidencias(
        pedidoInEdition.value.incidencias
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    const pedidoWaitingForSave = ref(false);

    function pedidoPreviousSearch() {
      pedidosSearch();
    }

    function pedidoPreviousSave() {
      console.log(pedidoInEdition.value);
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      pedidoWaitingForSave.value = true;
      pedidoSave().then(() => {
        pedidoWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function pedidoPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];
      changeActiveTab(0);
      pedidoCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    async function initializeValuesFromColeccion() {
      const coleccion = await apiColecciones.getColeccion(
        pedidoInEdition.value.idColeccion
      );

      if (coleccion) {
        if (coleccion.fechaServicioDesde)
          pedidoInEdition.value.fechaServicioDesde = coleccion.fechaServicioDesde.substr(
            0,
            10
          );
        else pedidoInEdition.value.fechaServicioDesde = undefined;

        if (coleccion.fechaServicioHasta)
          pedidoInEdition.value.fechaServicioHasta = coleccion.fechaServicioHasta.substr(
            0,
            10
          );
        else pedidoInEdition.value.fechaServicioHasta = undefined;

        const marca = await apiMarcas.getMarca(coleccion.idMarca);
        if (marca) {
          const fabricante = await apiFabricantes.getFabricante(
            marca.idFabricante
          );
          if (fabricante) {
            pedidoInEdition.value.numeroDiasVencimiento =
              fabricante.diasVtoPago;
          }
        }
      }
    }

    watch([pedidoError, pedidoSuccess], ([pedidoError, pedidoSuccess]) => {
      if (pedidoSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (pedidoError) {
        createToast(
          {
            title: "Error 👎 ",
            description: pedidoErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      pedidos,
      pedidosFilter,
      pedidoPreviousSearch,
      pedidosResetFilter,
      pedidoEdit,
      pedidoAdd,
      pedidoDelete,
      pedidoCancel,
      pedidoSave,
      pedidoInEdition,
      pedidosIndex,
      pedidosGotoNextPage,
      pedidosGoToPreviousPage,
      pedidoIsEditing,
      pedidosIsLoading,
      pedidosIsFirstPage,
      pedidosIsLastPage,
      pedidoAddIncidencia,
      pedidoDeleteIncidencia,
      pedidoPreviousCancel,
      pedidoPreviousSave,
      pedidoWaitingForSave,
      pedidoTotalesExpediciones,

      pedidosShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      showListados,

      colecciones,
      clientes,
      tiposTemporada,
      fabricantes,
      tiendas,
      marcas,

      showValidate,
      initializeValuesFromColeccion,
    };
  },
});
