import { PedidoView, PedidoFilter } from "@/types/pedidos/PedidoView";
import { Pedido } from "@/types/pedidos/Pedido";
import { putIdOk } from "@/views/utilidades/useIDLibrary";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiPedidos = {
  getPedidosView(filter: PedidoFilter): Promise<PedidoView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "PedidoView/filter";
      axios
        .post<PedidoView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getPedidoView(id: number): Promise<PedidoView> {
    return new Promise(resolve => {
      const url = "PedidoView/" + id;
      axios.get<PedidoView>(url).then(response => resolve(response.data));
    });
  },
  getPedido(id: number): Promise<Pedido> {
    return new Promise(resolve => {
      const url = "Pedido/" + id;
      axios.get<Pedido>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  savePedido(pedido: Pedido): Promise<Pedido> {
    const newPedido = { ...pedido };

    putIdOk(newPedido.incidencias, "idIncidencia");

    return new Promise((resolve, reject) => {
      const url = "Pedido";
      axios
        .post(url, newPedido)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deletePedido(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Pedido/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
