
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useExpediciones } from "./useExpediciones";
import { usePedidos } from "../pedidos/usePedidos";
import { useClientes } from "../clientes/useClientes";
import { useColecciones } from "../colecciones/useColecciones";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useTransportistas } from "../transportistas/useTransportistas";
import { useFormasPago } from "../configfacturas/useFormasPago";

import rowEdit from "../../components/rowEdit.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITextInput from "@/components/UITextInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UITextArea from "@/components/UITextArea.vue";
import filterEdit from "../../components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import selectCliente from "@/components/selectCliente.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectTransportista from "@/components/selectTransportista.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";
import UINoItems from "@/components/UINoItems.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import { ExpedicionVencimiento } from "@/types/expediciones/Expedicion";
import { Expedicion } from "@/types/expediciones/Expedicion";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Expediciones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UINumberInput,
    UIDateInput,
    UIListBox,
    UITextInput,
    UICheckBox,
    UITextArea,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectCliente,
    selectColeccion,
    selectFabricante,
    selectTransportista,
    selectTipoTemporada,
    UINoItems,
  },
  setup() {
    const headers = ["Fecha", "Cliente", "Colección", "Nº Factura", "Importe", "Prendas"];
    const {
      expediciones,
      expedicionesShowed,
      expedicionesFilter,
      expedicionesSearch,
      expedicionesResetFilter,
      expedicionEdit,
      expedicionAdd,
      expedicionDelete,
      expedicionCancel,
      expedicionSave,
      expedicionInEdition,
      expedicionesIndex,
      expedicionesGotoNextPage,
      expedicionesGoToPreviousPage,
      expedicionIsEditing,
      expedicionesIsLoading,
      expedicionesIsFirstPage,
      expedicionesIsLastPage,
      expedicionAddVencimiento,
      expedicionDeleteVencimiento,
      expedicionSuccess,
      expedicionError,
      expedicionErrorText,
      expedicionIsValid,
      expedicionIsValidVencimientos,
      expedicionPedido,
      expedicionNewItem,
    } = useExpediciones();

    const route = useRoute();
    const param = route.params.idPedido;
    if (param != "") {
      //console.log("param="+param);
      expedicionesFilter.value = {
        idPedido: Number(param),
      } as ExpedicionFilter;
      expedicionNewItem.value = ({
        id: 0,
        idPedido: Number(param),
        vencimientos: [] as ExpedicionVencimiento[],
      } as unknown) as Expedicion;
    } else {
      expedicionesResetFilter();
    }

    const { colecciones } = useColecciones();
    const { clientes } = useClientes();
    const { pedidos } = usePedidos();
    const { temporadas } = useTemporada();
    const { fabricantes } = useFabricantes();
    const { transportistas } = useTransportistas();
    const { formasPagos } = useFormasPago();
    const { tiposTemporada } = useTipoTemporada();

    const isFiltering = ref(false);

    const showValidate = ref(false);

    const showListados = ref(false);

    const tabsEdit = ref([true, false]);
    const tabsEditIsValid = ref([true, true]);

    const expedicionWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = expedicionIsValid(expedicionInEdition.value);

      tabsEditIsValid.value[1] = expedicionIsValidVencimientos(
        expedicionInEdition.value.vencimientos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function expedicionPreviousSearch() {
      expedicionesSearch();
    }

    function expedicionPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      expedicionWaitingForSave.value = true;
      expedicionSave().then(() => {
        expedicionWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function expedicionPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];
      changeActiveTab(0);
      expedicionCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    watch(
      [expedicionError, expedicionSuccess],
      ([expedicionError, expedicionSuccess]) => {
        if (expedicionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (expedicionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: expedicionErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      headers,
      expediciones,
      expedicionesFilter,
      expedicionPreviousSearch,
      expedicionesResetFilter,
      expedicionEdit,
      expedicionAdd,
      expedicionDelete,
      expedicionInEdition,
      expedicionesIndex,
      expedicionesGotoNextPage,
      expedicionesGoToPreviousPage,
      expedicionIsEditing,
      expedicionesIsLoading,
      expedicionesIsFirstPage,
      expedicionesIsLastPage,
      expedicionAddVencimiento,
      expedicionDeleteVencimiento,
      expedicionPreviousCancel,
      expedicionPreviousSave,
      expedicionWaitingForSave,
      expedicionPedido,

      expedicionesShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      showListados,

      colecciones,
      clientes,
      temporadas,
      fabricantes,
      pedidos,
      transportistas,
      formasPagos,
      tiposTemporada,

      showValidate,
    };
  },
});
