
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  name: "UIListBox",
  props: {
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    optionValue: {
      type: String,
      required: true
    },
    optionName: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);

    function showSearch() {
      isSearching.value = !isSearching.value;
    }

    const myLabel = function() {
      return props.label;
    };
    const myPlaceHolder = function() {
      if (props.placeholder) return props.placeholder;
      else return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };

    function selectItem(id: string) {
      isSearching.value = !isSearching.value;
      emit("update:modelValue", id);
    }

    function valueShowed() {
      let resultado = "";

      const items = props.options;
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const actual = items[i] as any;
        if (actual[props.optionValue] == props.modelValue) {
          resultado = actual[props.optionName];
        }
      }

      return resultado;
    }
    function clearValue() {
      emit("update:modelValue", null);
    }
    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      showSearch,
      myLabel,
      myPlaceHolder,
      myId,
      selectItem,
      valueShowed,
      showError,
      clearValue
    };
  }
});
