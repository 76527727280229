<template>
  <label :for="myId()" class="block text-sm font-medium text-gray-700">
    {{ myLabel() }}
  </label>
  <div class="flex rounded-md shadow-sm">
    <input
      type="text"
      :name="myId()"
      :id="myId()"
      :maxlength="maxLength"
      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
      :class="{ 'border-red-500': showError }"
      :placeholder="myPlaceHolder()"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "UITextInput",
  props: {
    modelValue: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const myLabel = function() {
      return props.label;
    };
    const myPlaceHolder = function() {
      if (props.placeholder) return props.placeholder;
      else return props.label;
    };
    const myId = function() {
      if (props.id) return props.id;
      else return props.label;
    };
    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == "" ||
          props.modelValue == null ||
          props.modelValue == undefined)
      );
    });

    return {
      myLabel,
      myPlaceHolder,
      myId,
      showError
    };
  }
});
</script>
