<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
        >
          Colecciones
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="block">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="coleccionPreviousSearch"
    @reset="coleccionesResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-12 gap-2">
        <div class="col-span-1 sm:col-span-4">
          <selectMarca v-model="coleccionesFilter.idMarca" :items="marcas" />
        </div>
        <div class="col-span-1 sm:col-span-3">
          <selectTipoTemporada
            v-model="coleccionesFilter.idTipoTemporada"
            :items="tiposTemporada"
            labelValue="Tipo Temporada"
          />
        </div>
        <div class="col-span-1 sm:col-span-1"></div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            label="Desde Año"
            v-model="coleccionesFilter.desdeAnyo"
            @blur="coleccionesFilter.hastaAnyo = coleccionesFilter.desdeAnyo"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            label="Hasta Año"
            v-model="coleccionesFilter.hastaAnyo"
          />
        </div>
        <div class="col-span-1 sm:col-span-1"></div>
        <div class="col-span-1 sm:col-span-1">
          <label for="Activas" class="block text-sm font-medium text-gray-700">
            Activas
          </label>
          <select
            v-model="coleccionesFilter.activa"
            id="activa"
            name="activa"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todas</option>
            <option value="S">Activas</option>
            <option value="N">Pasivas</option>
          </select>
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="colecciones.length"
      :isEditing="coleccionIsEditing"
      :isLoading="coleccionesIsLoading"
      :isFirstPage="coleccionesIsFirstPage"
      :isLastPage="coleccionesIsLastPage"
      :items="colecciones"
      @newitem="coleccionAdd"
      @next="coleccionesGotoNextPage"
      @previous="coleccionesGoToPreviousPage"
    >
      <template
        v-for="coleccion in coleccionesShowed"
        :key="coleccion.id"
        class="border-b border-gray-200"
      >
        <tr v-if="coleccion.id != coleccionInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span :class="{ 'line-through text-red-400': !coleccion.activa }">
              {{ coleccion.descripcion }}
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span
              :class="{ 'line-through text-red-400': !coleccion.marcaActiva }"
            >
              {{ coleccion.marca }}
            </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ coleccion.temporada }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ coleccion.porcentajeComision }} %
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ coleccion.fechasServicio }}
          </td>
          <datatablerowbuttons
            @edititem="coleccionEdit"
            @deleteitem="coleccionDelete"
            :id="coleccion.id"
            :isEditing="coleccionIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="coleccionPreviousCancel"
              @save="coleccionPreviousSave"
              :waitingForSave="coleccionWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Colección
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <p class="mb-5 mt-5 text-sm text-gray-600">
                  Listados
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mt-1 mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        ></path>
                      </svg> </span
                    ><span
                      ><router-link
                        :to="
                          '/Pedidos/PedidosBudget/' + coleccionInEdition.idMarca
                        "
                        target="_blank"
                      >
                        Listado de Pedidos Budget
                      </router-link></span
                    ></span
                  >
                </p>
                <p class="mb-5 mt-5 text-sm text-gray-600">
                  Graficos
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mt-1 mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                        ></path></svg></span
                    ><span
                      @click="downloadGraphics(coleccionInEdition.idMarca)"
                    >
                      Evolución marca
                    </span></span
                  >
                </p>
              </template>
              <template v-slot:default>
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3 sm:col-span-2">
                    <UITextInput
                      v-model="coleccionInEdition.descripcion"
                      label="Descripción"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="30"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <selectMarca
                      v-model="coleccionInEdition.idMarca"
                      :items="marcas"
                      :required="true"
                      :showRequired="showValidate"
                      @update:modelValue="changeMarca()"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <selectTemporada
                      v-model="coleccionInEdition.idTemporada"
                      :items="temporadas"
                      :required="true"
                      :showRequired="showValidate"
                      @update:modelValue="changeTemporada()"
                    />
                    <!--<UIListBox
                      v-model="coleccionInEdition.idTemporada"
                      :options="temporadas"
                      optionValue="id"
                      optionName="descripcion"
                      label="Temporada"
                      :required="true"
                      :showRequired="showValidate"
                      @update:modelValue="changeTemporada()"
                    />-->
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-2">
                    <UINumberInput
                      v-model="coleccionInEdition.porcentajeComision"
                      label="Porcentaje comisión"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-2">
                    <UIDateInput
                      v-model="coleccionInEdition.fechaServicioDesde"
                      label="Fecha Servicio desde"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-2">
                    <UIDateInput
                      v-model="coleccionInEdition.fechaServicioHasta"
                      label="Fecha Servicio hasta"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-2">
                    <UICheckBox
                      v-model="coleccionInEdition.activa"
                      label="Activa"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-2">
                    <UICheckBox
                      v-model="coleccionInEdition.crearFacturaPagada"
                      label="Crear factura pagada"
                    />
                  </div>
                </div>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useColecciones } from "@/views/colecciones/useColecciones";
import { useMarcas } from "../utilidades/useMarcas";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { ColeccionFilter } from "@/types/colecciones/ColeccionView";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectTemporada from "@/components/selectTemporada.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import { apiColeccionesGraphics } from "@/services/colecciones/apigraphic.ts";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Colecciones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UICheckBox,
    filterEdit,
    selectMarca,
    selectTemporada,
    selectTipoTemporada,
  },
  setup() {
    const headers = [
      "Descripción",
      "Marca",
      "Temporada",
      "Comisión",
      "F. de servicio",
    ];
    const {
      colecciones,
      coleccionesShowed,
      coleccionesFilter,
      coleccionesResetFilter,
      coleccionesSearch,
      coleccionEdit,
      coleccionAdd,
      coleccionDelete,
      coleccionCancel,
      coleccionSave,
      coleccionInEdition,
      coleccionesGotoNextPage,
      coleccionesGoToPreviousPage,
      coleccionIsEditing,
      coleccionesIsLoading,
      coleccionesIsFirstPage,
      coleccionesIsLastPage,
      coleccionIsValid,
      coleccionSuccess,
      coleccionError,
      coleccionErrorText,
    } = useColecciones();

    const { marcas } = useMarcas();
    const { temporadas } = useTemporada();
    const { tiposTemporada } = useTipoTemporada();

    const isFiltering = ref(false);

    const showValidate = ref(false);

    const coleccionWaitingForSave = ref(false);

    coleccionesResetFilter();

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function coleccionPreviousSearch() {
      coleccionesSearch();
    }

    function coleccionPreviousSave() {
      showValidate.value = !coleccionIsValid(coleccionInEdition.value);
      if (showValidate.value) return;
      localStorage.setItem(
        "ultimaColeccion",
        JSON.stringify(coleccionInEdition.value)
      );
      //localStorage.setItem("coleccionIdTemporada",coleccionInEdition.value.idTemporada.toString);
      coleccionWaitingForSave.value = true;
      coleccionSave().then(() => {
        coleccionWaitingForSave.value = false;
      });
    }

    function coleccionPreviousCancel() {
      showValidate.value = false;
      coleccionCancel();
    }

    function calculateDescripcion() {
      if (
        coleccionInEdition.value.descripcion == "" ||
        coleccionInEdition.value.descripcion == undefined
      ) {
        const marcaSelected = marcas.value.find(
          (marca) => marca.id === coleccionInEdition.value.idMarca
        );
        const temporadaSelected = temporadas.value.find(
          (temporada) => temporada.id === coleccionInEdition.value.idTemporada
        );
        if (marcaSelected != undefined && temporadaSelected != undefined) {
          const tipoTemporadaSelected = tiposTemporada.value.find(
            (tipoTemporada) =>
              tipoTemporada.id === temporadaSelected.idTipoTemporada
          );
          coleccionInEdition.value.descripcion =
            marcaSelected.descripcion +
            " " +
            tipoTemporadaSelected?.abreviatura +
            " " +
            temporadaSelected.anyo;
        }
      }
    }

    function setPorcentajeComision() {
      if (
        coleccionInEdition.value.porcentajeComision == undefined ||
        coleccionInEdition.value.porcentajeComision == 0
      ) {
        const marcaSelected = marcas.value.find(
          (marca) => marca.id === coleccionInEdition.value.idMarca
        );
        if (marcaSelected != undefined)
          coleccionInEdition.value.porcentajeComision =
            marcaSelected.porcentajeComision;
      }
    }

    function changeMarca() {
      setPorcentajeComision();
      calculateDescripcion();
    }

    function changeTemporada() {
      calculateDescripcion();
    }

    function downloadGraphics(idMarca: number) {
      console.log("graphics please", idMarca);
      const filter = ({ idMarca: idMarca } as unknown) as ColeccionFilter;
      apiColeccionesGraphics.getColeccionesGraphic(filter);
    }

    watch(
      [coleccionError, coleccionSuccess],
      ([coleccionError, coleccionSuccess]) => {
        if (coleccionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (coleccionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: coleccionErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      headers,
      colecciones,
      coleccionesShowed,
      coleccionesFilter,
      coleccionesResetFilter,
      coleccionPreviousSearch,
      coleccionEdit,
      coleccionAdd,
      coleccionDelete,
      coleccionPreviousCancel,
      coleccionPreviousSave,
      coleccionInEdition,
      coleccionesGotoNextPage,
      coleccionesGoToPreviousPage,
      coleccionIsEditing,
      coleccionesIsLoading,
      coleccionesIsFirstPage,
      coleccionesIsLastPage,
      coleccionWaitingForSave,

      showFilter,
      isFiltering,

      marcas,
      temporadas,
      tiposTemporada,
      showValidate,
      changeMarca,
      changeTemporada,
      downloadGraphics,
    };
  },
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }
  td:nth-of-type(1):before {
    content: "Descripción";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Marca";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Temporada";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Comisión";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "F. de servicio";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
