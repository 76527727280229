<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Expediciones
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>

        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
            <router-link
              target="_blank"
              to="/Expediciones/ExpedicionesPrevisionComisiones/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Previsión de Comisiones</router-link
            >
            <router-link
              target="_blank"
              to="/Expediciones/ExpedicionesEstadoComisiones/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Estado de Comisiones</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="expedicionPreviousSearch"
    @reset="expedicionesResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <UITextInput
            label="Numero pedido"
            v-model="expedicionesFilter.numeroPedido"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Desde fecha expedicion"
            v-model="expedicionesFilter.desdeFechaExpedicion"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="hasta fecha expedicion"
            v-model="expedicionesFilter.hastaFechaExpedicion"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Desde Fecha pago expedicion"
            v-model="expedicionesFilter.desdeFechaPagoExpedicion"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Hasta Fecha pago expedicion"
            v-model="expedicionesFilter.hastaFechaPagoExpedicion"
          />
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <selectCliente
            v-model="expedicionesFilter.idCliente"
            :items="clientes"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <selectFabricante
            v-model="expedicionesFilter.idFabricante"
            :items="fabricantes"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <selectTipoTemporada
            v-model="expedicionesFilter.idTipoTemporada"
            :items="tiposTemporada"
            labelValue="Tipo Temporada"
          />
        </div>
        <div class="col-span-1">
          <UINumberInput label="Desde" v-model="expedicionesFilter.desdeAnyo" @blur="expedicionesFilter.hastaAnyo = expedicionesFilter.desdeAnyo"/>
        </div>
        <div class="col-span-1">
          <UINumberInput label="Hasta" v-model="expedicionesFilter.hastaAnyo" />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <selectColeccion
            class="mt-2"
            :items="colecciones"
            v-model="expedicionesFilter.idColeccion"
          ></selectColeccion>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Desde fecha vencimiento"
            v-model="expedicionesFilter.desdeFechaVencimiento"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Hasta fecha vencimiento"
            v-model="expedicionesFilter.hastaFechaVencimiento"
          />
        </div>

        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label="Desde Fecha pago vencimiento"
            v-model="expedicionesFilter.desdeFechaPagoVencimiento"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIDateInput
            label=" Hasta Fecha pago vencimiento"
            v-model="expedicionesFilter.hastaFechaPagoVencimiento"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="expediciones.length"
      :isEditing="expedicionIsEditing"
      :isLoading="expedicionesIsLoading"
      :isFirstPage="expedicionesIsFirstPage"
      :isLastPage="expedicionesIsLastPage"
      @newitem="expedicionAdd"
      @next="expedicionesGotoNextPage"
      @previous="expedicionesGoToPreviousPage"
    >
      <template
        v-for="expedicion in expedicionesShowed"
        :key="expedicion.id"
        class="border-b border-gray-200"
      >
        <tr v-if="expedicion.id != expedicionInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
            {{ expedicion.fecha }}
          </td>
          <td class="px-6 py-4  text-sm text-gray-500">
            {{ expedicion.cliente }}
          </td>
          <td class="px-6 py-4  text-sm text-gray-500">
            {{ expedicion.coleccion }}
          </td>
          <td
            class="px-6 py-4 text-sm whitespace-nowrap text-gray-500 overflow-x-hidden"
          >
            {{ expedicion.numeroFactura }}
          </td>
          <td
            class="px-6 py-4 text-sm whitespace-nowrap text-gray-500 overflow-x-hidden"
          >
            {{ expedicion.importe }}
          </td>
          <td
            class="px-6 py-4 text-sm whitespace-nowrap text-gray-500 overflow-x-hidden"
          >
            {{ expedicion.numeroPrendas }}
          </td>
          <datatablerowbuttons
            @edititem="expedicionEdit"
            @deleteitem="expedicionDelete"
            :id="expedicion.id"
            :isEditing="expedicionIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="expedicionPreviousCancel"
              @save="expedicionPreviousSave"
              :waitingForSave="expedicionWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  expedicion
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                    :isValid="tabsEditIsValid[0]"
                  />
                  <tabmenuedition
                    v-if="false"
                    title="vencimientos"
                    :numbersOfItems="expedicionInEdition.vencimientos.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="expedicionAddVencimiento"
                    :isValid="tabsEditIsValid[1]"
                  />
                </div>
                <p class="mb-5 text-sm text-gray-600">
                  Puede ir a los siguientes datos
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'pedidos',
                          params: {
                            idCliente: '$',
                            numeroPedido: expedicionPedido.numeroPedido,
                          },
                        }"
                        target="_blank"
                      >
                        Pedido de la expedición
                      </router-link></span
                    ></span
                  >
                </p>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div
                    class="rounded-md border-opacity-50 border-gray-300 bg-gray-200 p-6"
                  >
                    <h3
                      class="font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate"
                    >
                      Datos del pedido
                    </h3>
                    <div class=" grid-cols-6 gap-2 pt-3 flex justify-between">
                      <div class="col-span-2">
                        <label
                          for="expedicion_pedido_numeroPedido"
                          class="text-sm font-medium text-gray-700"
                        >
                          Nº pedido
                        </label>
                        <div class="rounded-md shadow-sm">
                          <input
                            type="text"
                            name="expedicion_pedido_numeroPedido"
                            id="expedicion_pedido_numeroPedido"
                            class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                            v-model="expedicionPedido.numeroPedido"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-span-2">
                        <label
                          for="expedicion_pedido_fechaPedido"
                          class="text-sm font-medium text-gray-700"
                        >
                          Fecha pedido
                        </label>
                        <div class="col-span-2 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="expedicion_pedido_fechaPedido"
                            id="expedicion_pedido_fechaPedido"
                            class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                            v-model="expedicionPedido.fechaPedido"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-6 gap-2  mt-2">
                      <div class="col-span-6 lg:col-span-3">
                        <label
                          for="expedicion_pedido_coleccion"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Colección
                        </label>
                        <div class="flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="expedicion_pedido_coleccion"
                            id="expedicion_pedido_coleccion"
                            class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                            v-model="expedicionPedido.coleccion"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="col-span-6 lg:col-span-3">
                        <label
                          for="expedicion_pedido_cliente"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Cliente
                        </label>
                        <div class="flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="expedicion_pedido_cliente"
                            id="expedicion_pedido_cliente"
                            class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                            v-model="expedicionPedido.cliente"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-1">
                      <UIDateInput
                        label="Fecha expedición"
                        v-model="expedicionInEdition.fecha"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UITextInput
                        label="Número Factura"
                        v-model="expedicionInEdition.numeroFactura"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-1">
                      <UINumberInput
                        v-model="expedicionInEdition.numeroPrendas"
                        label="N. Prendas"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UINumberInput
                        v-model="expedicionInEdition.importe"
                        label="Importe"
                      />
                    </div>
                  </div>
                  <div class="hidden">
                    <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-3 sm:col-span-2">
                        <selectTransportista
                          v-model="expedicionInEdition.idTransportista"
                          :items="transportistas"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-1">
                      <UICheckBox
                        v-model="expedicionInEdition.pagadaSN"
                        label="Pagada"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UIDateInput
                        label="Fecha pago"
                        v-model="expedicionInEdition.fechaPago"
                      />
                    </div>
                  </div>
                  <div class="">
                    <UITextArea
                      v-model="expedicionInEdition.observaciones"
                      label="Observaciones"
                      :maxLength="4000"
                      placeholder="observaciones"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <UINoItems
                    :numbersOfItems="expedicionInEdition.vencimientos.length"
                    @addItem="expedicionAddVencimiento"
                  />

                  <template
                    v-for="vencimiento in expedicionInEdition.vencimientos"
                    :key="vencimiento.idVencimiento"
                  >
                    <div class="grid grid-cols-12 gap-1">
                      <div class="col-span-3 sm:col-span-3 lg:col-span-3">
                        <UIDateInput
                          v-model="vencimiento.fechaVencimiento"
                          label="Fecha de vencimiento"
                          :id="
                            'vencimiento_fechaVencimiento_' +
                              vencimiento.idVencimiento
                          "
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                        <UINumberInput
                          v-model="vencimiento.importeVencimiento"
                          label="Importe"
                          :required="true"
                          :id="
                            'vencimiento_importeVencimiento_' +
                              vencimiento.idVencimiento
                          "
                          :showRequired="showValidate"
                        />
                      </div>
                      <div
                        class="hidden col-span-3 sm:col-span-2 lg:col-span-2"
                      >
                        <UIListBox
                          v-model="vencimiento.idFormaPagoVencimiento"
                          :options="formasPagos"
                          optionValue="id"
                          :id="
                            'vencimiento_idFormaPagoVencimiento_' +
                              vencimiento.idVencimiento
                          "
                          optionName="descripcion"
                          label="Forma de pago"
                        />
                      </div>
                      <div class="col-span-3 sm:col-span-1 lg:col-span-1">
                        <UICheckBox
                          v-model="vencimiento.pagadoSNVencimiento"
                          :id="
                            'vencimiento_pagadoSNVencimiento_' +
                              vencimiento.idVencimiento
                          "
                          label="Pagada"
                        />
                      </div>
                      <div class="col-span-3 sm:col-span-3">
                        <UIDateInput
                          v-model="vencimiento.fechaPagoVencimiento"
                          :id="
                            'vencimiento_fechaPagoVencimiento_' +
                              vencimiento.idVencimiento
                          "
                          label="Fecha de Pago"
                        />
                      </div>
                      <div
                        class="flex items-end col-span-6 sm:col-span-6 lg:col-span-1"
                      >
                        <deleteButtonMulti
                          :id="vencimiento.idVencimiento"
                          :index="index"
                          @deleteMultiItem="expedicionDeleteVencimiento"
                        />
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useExpediciones } from "./useExpediciones";
import { usePedidos } from "../pedidos/usePedidos";
import { useClientes } from "../clientes/useClientes";
import { useColecciones } from "../colecciones/useColecciones";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useTransportistas } from "../transportistas/useTransportistas";
import { useFormasPago } from "../configfacturas/useFormasPago";

import rowEdit from "../../components/rowEdit.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITextInput from "@/components/UITextInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UITextArea from "@/components/UITextArea.vue";
import filterEdit from "../../components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import selectCliente from "@/components/selectCliente.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectTransportista from "@/components/selectTransportista.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";
import UINoItems from "@/components/UINoItems.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import { ExpedicionVencimiento } from "@/types/expediciones/Expedicion";
import { Expedicion } from "@/types/expediciones/Expedicion";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Expediciones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UINumberInput,
    UIDateInput,
    UIListBox,
    UITextInput,
    UICheckBox,
    UITextArea,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectCliente,
    selectColeccion,
    selectFabricante,
    selectTransportista,
    selectTipoTemporada,
    UINoItems,
  },
  setup() {
    const headers = ["Fecha", "Cliente", "Colección", "Nº Factura", "Importe", "Prendas"];
    const {
      expediciones,
      expedicionesShowed,
      expedicionesFilter,
      expedicionesSearch,
      expedicionesResetFilter,
      expedicionEdit,
      expedicionAdd,
      expedicionDelete,
      expedicionCancel,
      expedicionSave,
      expedicionInEdition,
      expedicionesIndex,
      expedicionesGotoNextPage,
      expedicionesGoToPreviousPage,
      expedicionIsEditing,
      expedicionesIsLoading,
      expedicionesIsFirstPage,
      expedicionesIsLastPage,
      expedicionAddVencimiento,
      expedicionDeleteVencimiento,
      expedicionSuccess,
      expedicionError,
      expedicionErrorText,
      expedicionIsValid,
      expedicionIsValidVencimientos,
      expedicionPedido,
      expedicionNewItem,
    } = useExpediciones();

    const route = useRoute();
    const param = route.params.idPedido;
    if (param != "") {
      //console.log("param="+param);
      expedicionesFilter.value = {
        idPedido: Number(param),
      } as ExpedicionFilter;
      expedicionNewItem.value = ({
        id: 0,
        idPedido: Number(param),
        vencimientos: [] as ExpedicionVencimiento[],
      } as unknown) as Expedicion;
    } else {
      expedicionesResetFilter();
    }

    const { colecciones } = useColecciones();
    const { clientes } = useClientes();
    const { pedidos } = usePedidos();
    const { temporadas } = useTemporada();
    const { fabricantes } = useFabricantes();
    const { transportistas } = useTransportistas();
    const { formasPagos } = useFormasPago();
    const { tiposTemporada } = useTipoTemporada();

    const isFiltering = ref(false);

    const showValidate = ref(false);

    const showListados = ref(false);

    const tabsEdit = ref([true, false]);
    const tabsEditIsValid = ref([true, true]);

    const expedicionWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = expedicionIsValid(expedicionInEdition.value);

      tabsEditIsValid.value[1] = expedicionIsValidVencimientos(
        expedicionInEdition.value.vencimientos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function expedicionPreviousSearch() {
      expedicionesSearch();
    }

    function expedicionPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      expedicionWaitingForSave.value = true;
      expedicionSave().then(() => {
        expedicionWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function expedicionPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];
      changeActiveTab(0);
      expedicionCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    watch(
      [expedicionError, expedicionSuccess],
      ([expedicionError, expedicionSuccess]) => {
        if (expedicionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (expedicionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: expedicionErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      headers,
      expediciones,
      expedicionesFilter,
      expedicionPreviousSearch,
      expedicionesResetFilter,
      expedicionEdit,
      expedicionAdd,
      expedicionDelete,
      expedicionInEdition,
      expedicionesIndex,
      expedicionesGotoNextPage,
      expedicionesGoToPreviousPage,
      expedicionIsEditing,
      expedicionesIsLoading,
      expedicionesIsFirstPage,
      expedicionesIsLastPage,
      expedicionAddVencimiento,
      expedicionDeleteVencimiento,
      expedicionPreviousCancel,
      expedicionPreviousSave,
      expedicionWaitingForSave,
      expedicionPedido,

      expedicionesShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      showListados,

      colecciones,
      clientes,
      temporadas,
      fabricantes,
      pedidos,
      transportistas,
      formasPagos,
      tiposTemporada,

      showValidate,
    };
  },
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "Fecha";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Cliente";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Colección";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Importe";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "Prendas";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
