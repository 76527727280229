
import { defineComponent, onMounted, ref, Ref, watch } from "vue";

import { useAgenda } from "./useAgenda";
import { useClientes } from "../clientes/useClientes";
import { useColecciones } from "../colecciones/useColecciones";
import { CalendarDay } from "@/types/agenda/CalendarDay";
import { CitaView } from "@/types/agenda/CitaView";
import { useTipoTemporada } from "@/views/temporadas/useTipoTemporada";
import { useTemporada } from "@/views/temporadas/useTemporada";

import UITextInput from "@/components/UITextInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITimeInput from "@/components/UITimeInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import filterEdit from "@/components/filterEdit.vue";
import selectCliente from "@/components/selectCliente.vue";
import UIListBox from "@/components/UIListBox.vue";

import rowEdit from "@/components/rowEdit.vue";
import { createToast } from "mosha-vue-toastify";

/* import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css"; */

export default defineComponent({
  name: "Agenda",
  components: {
    rowEdit,
    //datatable,
    //datatablerowbuttons,
    UITextInput,
    UIDateInput,
    UITimeInput,
    UINumberInput,
    UICheckBox,
    UIListBox,
    UITextArea,
    filterEdit,
    selectCliente,
    selectColeccion,
    selectTipoTemporada,
  },
  setup() {
    const {
      citas,
      citasFilter,
      citasSearch,
      citasResetFilter,
      citasIsLoading,
      citaInEdition,
      citaAdd,
      citaDelete,
      citaEdit,
      citaSave,
      citaCancel,
      citaSuccess,
      citaError,
      citaErrorText,
      citaIsValid,
      citaSendEmail,
    } = useAgenda();

    const { clientes } = useClientes();
    const { colecciones } = useColecciones();
    const { tiposTemporada } = useTipoTemporada();
    const { temporadas } = useTemporada();

    const tryingDelete = ref(false);

    const isFiltering = ref(false);
    const showListados = ref(false);
    const showValidate = ref(false);
    const actualDay = ref(new Date().getDate());
    const actualMonth = new Date().getMonth() + 1;
    const actualYear = new Date().getFullYear();

    const daysOfWeek = [
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
      "domingo",
    ];

    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days: Ref<CalendarDay[]> = ref([]);

    //const today = ref(new Date());
    citasFilter.value.desdeFechaCita =
      new Date().getFullYear().toString() + "-01" + "-01";
    citasFilter.value.asistidoSN = "T";

    const month = ref(0);
    const year = ref(0);
    const hover = ref(0);

    const citaWaitingForSave = ref(false);

    const selectedCita = ref({ id: -1 } as CitaView);

    async function fillCalendar() {
      days.value = [];
      let dayOfWeek = new Date(year.value, month.value - 1).getDay();
      console.log(`year: ${year.value} month: ${month.value}`);
      console.log(`${dayOfWeek}`);
      const daysInMonth = new Date(year.value, month.value, 0).getDate();
      if (dayOfWeek == 0) dayOfWeek = 7;
      const numMaxDays = Math.ceil((dayOfWeek + daysInMonth - 1) / 7) * 7;
      let actualDay = 0;
      for (let i = 1; i <= numMaxDays; i++) {
        if (i >= dayOfWeek && actualDay < daysInMonth) {
          actualDay++;
          const citasDay: CitaView[] = [];
          citas.value.forEach((cita) => {
            if (cita.fecha != undefined) {
              if (
                parseInt(cita.fecha.substr(0, 4)) == year.value &&
                parseInt(cita.fecha.substr(5, 2)) == month.value &&
                parseInt(cita.fecha.substr(8, 2)) == actualDay
              ) {
                citasDay.push(cita);
              }
            }
          });
          days.value.push({
            day: actualDay,
            citas: citasDay,
          } as CalendarDay);
        } else
          days.value.push({
            day: -1,
            citas: [] as CitaView[],
          });
      }
    }

    async function preSearch() {
      await citasSearch();

      year.value = 0;
      month.value = 0;

      year.value = new Date().getFullYear();
      month.value = new Date().getMonth() + 1;
/*
      const actualYear = new Date().getFullYear();
      const actualMonth = new Date().getMonth() + 1;

      if (citas.value.length == 0) {
        year.value = actualYear;
        month.value = actualMonth;
      } else {
        citas.value.forEach((cita) => {
          if (
            year.value < parseInt(cita.fecha.substr(0, 4)) &&
            actualYear >= parseInt(cita.fecha.substr(0, 4))
          ) {
            year.value = parseInt(cita.fecha.substr(0, 4));
            month.value = parseInt(cita.fecha.substr(5, 2));
          }
          if (
            year.value == parseInt(cita.fecha.substr(0, 4)) &&
            month.value < parseInt(cita.fecha.substr(5, 2)) &&
            actualYear >= parseInt(cita.fecha.substr(0, 4))
          ) {
            month.value = parseInt(cita.fecha.substr(5, 2));
          }
        });

        if (year.value == 9999) year.value = new Date().getFullYear();
      }
*/
      fillCalendar();
    }

    function previousMonth() {
      if (month.value == 1) {
        month.value = 12;
        year.value = year.value - 1;
      } else month.value = month.value - 1;
      fillCalendar();
    }

    function nextMonth() {
      if (month.value == 12) {
        month.value = 1;
        year.value = year.value + 1;
      } else month.value = month.value + 1;

      fillCalendar();
    }

    function previousYear() {
      year.value = year.value - 1;
      fillCalendar();
    }

    function nextYear() {
      year.value = year.value + 1;
      fillCalendar();
    }

    function showCita(id: number) {
      const cita = citas.value.find((x) => x.id == id);
      if (cita != undefined) selectedCita.value = cita;
    }

    function closeselectedCita() {
      selectedCita.value = { id: -1 } as CitaView;
    }

    function citaPreviousAdd(day: number) {
      console.log(day);
      console.log(month);
      console.log(year);
      const element = document.querySelector("#header-calendar");
      if (element != undefined) element.scrollIntoView({ behavior: "smooth" });
      citaAdd();
      citaInEdition.value.fecha =
        year.value.toString() +
        "-" +
        month.value.toString().padStart(2, "0") +
        "-" +
        day.toString().padStart(2, "0");
    }

    function citaPreviousEdit(id: number) {
      const element = document.querySelector("#header-calendar");
      if (element != undefined) element.scrollIntoView({ behavior: "smooth" });
      closeselectedCita();
      citaEdit(id);
    }

    function citaPreviousSave() {
      console.log("citaInEdition", citaInEdition.value);
      showValidate.value = !citaIsValid(citaInEdition.value);
      console.log("showValidate", showValidate.value);
      if (showValidate.value) return;

      citaWaitingForSave.value = true;
      citaSave().then(() => {
        citaWaitingForSave.value = false;
        console.log("*1*");
        fillCalendar();
        console.log("*2*");
      });
    }

    function citaPreviousDelete(id: number) {
      citaDelete(id).then(() => {
        fillCalendar();
      });
    }

    function citaPreviousCancel() {
      citaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    onMounted(async () => {
      preSearch();
    });

    watch([citaError, citaSuccess], ([citaError, citaSuccess]) => {
      if (citaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (citaError) {
        citaWaitingForSave.value = false;
        createToast(
          {
            title: "Error 👎 ",
            description: citaErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      citas,
      days,
      month,
      months,
      year,
      previousMonth,
      nextMonth,
      previousYear,
      nextYear,
      daysOfWeek,
      showCita,
      selectedCita,
      closeselectedCita,
      isFiltering,
      showFilter,
      citasFilter,
      citasResetFilter,
      preSearch,
      showListados,
      citasIsLoading,
      clientes,
      colecciones,
      citaInEdition,
      citaPreviousAdd,
      citaPreviousEdit,
      citaPreviousSave,
      citaPreviousCancel,
      citaEdit,
      citaPreviousDelete,
      citaSendEmail,
      hover,
      tiposTemporada,
      temporadas,
      citaWaitingForSave,
      showValidate,
      tryingDelete,
      actualDay,
      actualMonth,
      actualYear
    };
  },
});
