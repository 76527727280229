<template>
  <div class="grid grid-cols-12 gap-2">
    <div class="col-span-12 md:col-span-4">
      <UITextInput
        v-model="myDescripcion"
        label="Descripción"
        :id="'descripcion' + id"
        :maxLength="50"
      />
    </div>

    <div class="col-span-12 md:col-span-5">
      <UITextInput
        v-model="myEmail"
        label="Email"
        :id="'email' + id"
        :maxLength="100"
        :required="true"
        :showRequired="showRequired"
      />
    </div>

    <div class="col-span-12 md:col-span-2">
      <div class="flex items-start md:pl-4 mt-3 md:mt-8">
        <UICheckBox
          v-model="myPrincipal"
          label="Principal"
          :id="'principal' + id"
        />
      </div>
    </div>

    <div class="flex items-end col-span-6 md:col-span-1">
      <deleteButtonMulti @deleteMultiItem="$emit('deleteItem')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

export default defineComponent({
  name: "emailEdit",
  components: {
    UITextInput,
    UICheckBox,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    descripcion: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    },
    principal: {
      type: Boolean,
      required: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:email",
    "update:principal",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const myDescripcion = ref(props.descripcion);
    const myEmail = ref(props.email);
    const myPrincipal = ref(props.principal);

    watch(
      [myDescripcion, myEmail, myPrincipal],
      ([myDescripcion, myEmail, myPrincipal]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:email", myEmail);
        emit("update:principal", myPrincipal);
      }
    );

    return {
      myPrincipal,
      myEmail,
      myDescripcion
    };
  }
});
</script>
