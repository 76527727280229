<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Tiendas
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>

        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="tiendasSearch"
    @reset="tiendasResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-12 gap-2">
        <div class="col-span-1 sm:col-span-3">
          <UITextInput v-model="tiendasFilter.nombre" label="Nombre" />
        </div>
        <div class="col-span-1 sm:col-span-5">
          <selectCliente v-model="tiendasFilter.idCliente" :items="clientes" />
        </div>
        <div class="col-span-1 sm:col-span-2">
          <label for="activoSN" class="block text-sm font-medium text-gray-700">
            Estado
          </label>
          <select
            v-model="tiendasFilter.activoSN"
            id="activoSN"
            name="activoSN"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todos</option>
            <option value="S">Activos</option>
            <option value="N">Inactivos</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <div class="col-span-1 sm:col-span-1">
          <label
            for="direcciones"
            class="block text-sm font-medium text-gray-700"
          >
            Direcciones
          </label>
          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 mr-1 cursor-pointer"
            :class="[
              tiendasFilter.domicilioCorrespondencia
                ? 'bg-blue-200 text-blue-700'
                : '',
            ]"
            @click="updateFilterDomicilio('C')"
          >
            correspondencia
          </div>

          <div
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-200 text-gray-700 rounded-full mt-1 cursor-pointer"
            :class="[
              tiendasFilter.domicilioEntrega ? 'bg-blue-200 text-blue-700' : '',
            ]"
            @click="updateFilterDomicilio('E')"
          >
            entrega
          </div>
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIListBox
            v-model="tiendasFilter.idComunidadAutonoma"
            label="Comunidad autonoma"
            :options="comunidadesAutonomas"
            optionName="descripcion"
            optionValue="id"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIListBox
            v-model="tiendasFilter.idProvincia"
            label="Provincias"
            :options="provincias"
            optionName="descripcion"
            optionValue="id"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UIListBox
            v-model="tiendasFilter.idPoblacion"
            label="Poblaciones"
            :options="poblaciones"
            optionName="descripcion"
            optionValue="id"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UITextInput
            v-model="tiendasFilter.codigoPostal"
            label="Código postal"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :headersSM="['Código', 'Nombre', 'Tiendas']"
      :numbersRows="tiendas.length"
      :isEditing="tiendaIsEditing"
      :isLoading="tiendasIsLoading"
      :isFirstPage="tiendasIsFirstPage"
      :isLastPage="tiendasIsLastPage"
      @newitem="tiendaAdd"
      @next="tiendasGotoNextPage"
      @previous="tiendasGoToPreviousPage"
    >
      <template
        v-for="tienda in tiendasShowed"
        :key="tienda.id"
        class="border-b border-gray-200"
      >
        <tr v-if="tienda.id != tiendaInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span
              @click="
                if (!tiendaIsEditing) {
                  tiendaEdit(tienda.id);
                }
              "
              class="cursor-pointer hover:font-extrabold hover:text-blue-800"
              >{{ tienda.nombre }}</span
            >
          </td>
          <td class="px-6 py-4  text-sm text-gray-500">
            {{ tienda.cliente }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 ">
            {{ tienda.codigoPostal }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 overflow-x-hidden">
            {{ tienda.poblacion }}
          </td>
          <datatablerowbuttons
            @edititem="tiendaEdit"
            @deleteitem="tiendaDelete"
            :id="tienda.id"
            :isEditing="tiendaIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="tiendaPreviousCancel"
              @save="tiendaPreviousSave"
              :waitingForSave="tiendaWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Tienda
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                  />
                  <tabmenuedition
                    title="direcciones"
                    :numbersOfItems="tiendaInEdition.direcciones.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="tiendaAddDireccion"
                  />
                  <tabmenuedition
                    title="teléfonos"
                    :numbersOfItems="tiendaInEdition.telefonos.length"
                    :active="tabsEdit[2]"
                    :hasAddButton="true"
                    svgdata="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    @showItems="changeActiveTab(2)"
                    @addItem="tiendaAddTelefono"
                  />
                </div>
                <p class="mb-5 text-sm text-gray-600">
                  Puede ir a los siguientes datos
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span>
                      <router-link
                        :to="{
                          name: 'clientes',
                          params: {
                            id: tiendaInEdition.idCliente,
                          },
                        }"
                        target="_blank"
                      >
                        Cliente de la tienda
                      </router-link>
                    </span></span
                  >
                </p>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <selectCliente
                        v-model="tiendaInEdition.idCliente"
                        :items="clientes"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <UITextInput
                        v-model="tiendaInEdition.nombre"
                        label="Nombre"
                        :maxLength="100"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>

                  <div>
                    <UITextArea
                      v-model="tiendaInEdition.datosContacto"
                      label="Datos Contacto"
                      :maxLength="255"
                    />
                  </div>
                  <div>
                    <UITextArea
                      v-model="tiendaInEdition.observaciones"
                      label="Observaciones"
                      :maxLength="4000"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <UINoItems
                    :numbersOfItems="tiendaInEdition.direcciones.length"
                    @addItem="tiendaAddDireccion"
                  />
                  <template
                    v-for="dir in tiendaInEdition.direcciones"
                    :key="dir.idDireccion"
                  >
                    <dir-edit
                      v-model:descripcion="dir.descripcion"
                      v-model:domicilio="dir.domicilio"
                      v-model:idCodigoPostal="dir.idCodigoPostal"
                      v-model:correspondencia="dir.correspondencia"
                      v-model:fiscal="dir.fiscal"
                      v-model:entrega="dir.entrega"
                      :id="dir.idDireccion"
                      :items="codigosPostalesPoblaciones"
                      @deleteItem="tiendaDeleteDireccion(dir.idDireccion)"
                      :fiscalEditable="false"
                      :showRequired="showValidate"
                    />
                    <hr class="mt-4 mb-4" />
                  </template>
                </template>

                <template v-if="tabsEdit[2]">
                  <UINoItems
                    :numbersOfItems="tiendaInEdition.telefonos.length"
                    @addItem="tiendaAddTelefono"
                  />
                  <template
                    v-for="telefono in tiendaInEdition.telefonos"
                    :key="telefono.idTelefono"
                  >
                    <telefonoEdit
                      v-model:descripcion="telefono.descripcion"
                      v-model:telefono="telefono.telefono"
                      v-model:tipoTelefono="telefono.idTipoTelefono"
                      :id="telefono.idTelefono"
                      :tiposTelefono="tiposTelefono"
                      @deleteItem="tiendaDeleteTelefono(telefono.idTelefono)"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useTiendas } from "./useTiendas";
import { useCodigosPostalesPoblaciones } from "@/views/utilidades/useCodigosPostalesPoblaciones";
import { useTiposTelefono } from "@/views/utilidades/useTiposTelefono";
import { useClientes } from "../clientes/useClientes";
import { useProvincias } from "../poblaciones/useProvincias";
import { useComunidadesAutonomas } from "../poblaciones/useComunidadesAutonomas";
import { usePoblaciones } from "../poblaciones/usePoblaciones";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIListBox from "@/components/UIListBox.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";

import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import dirEdit from "@/components/dirEdit.vue";
import telefonoEdit from "@/components/telefonoEdit.vue";
import UINoItems from "@/components/UINoItems.vue";
import selectCliente from "@/components/selectCliente.vue";
import { TiendaFilter } from "@/types/tiendas/TiendaView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "tiendas",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UITextArea,
    UIListBox,
    tabmenuedition,
    filterEdit,
    dirEdit,
    telefonoEdit,
    UINoItems,
    selectCliente,
  },
  setup() {
    const headers = ["Nombre", "Cliente", "Código Postal", "Población"];
    const {
      tiendas,
      tiendasShowed,
      tiendasFilter,
      tiendasSearch,
      tiendasResetFilter,
      tiendaEdit,
      tiendaAdd,
      tiendaDelete,
      tiendaCancel,
      tiendaSave,
      tiendaInEdition,
      tiendasIndex,
      tiendasGotoNextPage,
      tiendasGoToPreviousPage,
      tiendaIsEditing,
      tiendasIsLoading,
      tiendasIsFirstPage,
      tiendasIsLastPage,
      tiendaAddDireccion,
      tiendaDeleteDireccion,
      tiendaAddTelefono,
      tiendaDeleteTelefono,
      tiendaSuccess,
      tiendaError,
      tiendaErrorText,
      tiendaIsValid,
      tiendaIsValidDirecciones,
      tiendaIsValidTelefonos,
    } = useTiendas();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();
    const { tiposTelefono } = useTiposTelefono();
    const { clientes } = useClientes();
    const { poblaciones } = usePoblaciones();
    const { provincias } = useProvincias();
    const { comunidadesAutonomas } = useComunidadesAutonomas();

    tiendasResetFilter();

    const route = useRoute();
    const param = route.params.idCliente;

    if (param != "") tiendasFilter.value.idCliente = Number(param);

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);

    const tabsEdit = ref([true, false, false]);
    const tabsEditIsValid = ref([true, true, true]);

    const tiendaWaitingForSave = ref(false);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = tiendaIsValid(tiendaInEdition.value);

      tabsEditIsValid.value[1] = tiendaIsValidDirecciones(
        tiendaInEdition.value.direcciones
      );

      tabsEditIsValid.value[2] = tiendaIsValidTelefonos(
        tiendaInEdition.value.telefonos
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    function tiendaPreviousSave() {
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      tiendaWaitingForSave.value = true;
      tiendaSave().then(() => {
        tiendaWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function tiendaPreviousCancel() {
      changeActiveTab(0);
      tiendaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function updateFilterDomicilio(tipoDomicilio: string) {
      if (tipoDomicilio == "C")
        tiendasFilter.value.domicilioCorrespondencia = !tiendasFilter.value
          .domicilioCorrespondencia;
      if (tipoDomicilio == "E")
        tiendasFilter.value.domicilioEntrega = !tiendasFilter.value
          .domicilioEntrega;
    }

    watch([tiendaError, tiendaSuccess], ([tiendaError, tiendaSuccess]) => {
      if (tiendaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (tiendaError) {
        createToast(
          {
            title: "Error 👎 ",
            description: tiendaErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      tiendas,
      tiendasFilter,
      tiendasSearch,
      tiendasResetFilter,
      tiendaEdit,
      tiendaAdd,
      tiendaDelete,
      tiendaCancel,
      tiendaSave,
      tiendaInEdition,
      tiendasIndex,
      tiendasGotoNextPage,
      tiendasGoToPreviousPage,
      tiendaIsEditing,
      tiendasIsLoading,
      tiendasIsFirstPage,
      tiendasIsLastPage,
      tiendaAddDireccion,
      tiendaDeleteDireccion,
      tiendaAddTelefono,
      tiendaDeleteTelefono,
      tiendaPreviousCancel,
      tiendaPreviousSave,
      tiendaWaitingForSave,

      tiendasShowed,

      tabsEdit,
      changeActiveTab,

      isFiltering,
      showFilter,

      showListados,

      codigosPostalesPoblaciones,
      tiposTelefono,
      clientes,
      updateFilterDomicilio,
      poblaciones,
      provincias,
      comunidadesAutonomas,
      showValidate,
    };
  },
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "Nombre";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Cliente";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "C.P.";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Población";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
