<template>
  <div class="pt-10 md:grid md:grid-cols-2 md:gap-6">
    <div>
      <div class="mb-10">
        <div class="mb-4">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
              >
                Comunidades autonomas
              </h2>
            </div>
          </div>
        </div>
        <datatable
          :headers="['Descripción']"
          :numbersRows="comunidadesAutonomas.length"
          :isEditing="comunidadAutonomaIsEditing"
          :isLoading="comunidadesAutonomasIsLoading"
          :isFirstPage="comunidadesAutonomasIsFirstPage"
          :isLastPage="comunidadesAutonomasIsLastPage"
          @newitem="comunidadAutonomaAdd"
          @next="comunidadAutonomaGotoNextPage"
          @previous="comunidadAutonomaGoToPreviousPage"
        >
          <tr v-if="1==2">
            <td colspan="2" class="px-2 py-1">
              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 mx-4">
                <div class="col-span-1 sm:col-span-1">
                  <UITextInput v-model="comunidadesAutonomasNombreFilter" label="" placeholder="Comunidad" />
                </div>
              </div>  
            </td>
          </tr>
          <template
            v-for="comunidadAutonoma in comunidadesAutonomasShowed"
            :key="comunidadAutonoma.id"
            class="border-b border-gray-200"
          >
            <tr
              v-if="comunidadAutonoma.id != comunidadAutonomaInEdition.id"
              @click="comunidadAutonomaSelect(comunidadAutonoma.id)"
              :class="
                comunidadAutonomaSelectedItem == comunidadAutonoma.id
                  ? 'bg-blue-100'
                  : ''
              "
            >
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ comunidadAutonoma.descripcion }}
              </td>
              <datatablerowbuttons
                @edititem="comunidadAutonomaEdit"
                @deleteitem="comunidadAutonomaDelete"
                :id="comunidadAutonoma.id"
                :isEditing="comunidadAutonomaIsEditing"
              />
            </tr>
            <tr v-else>
              <td colspan="2">
                <rowEdit
                  @cancel="comunidadAutonomaPreviousCancel"
                  @save="comunidadAutonomaSubmit"
                  :waitingForSave="comunidadAutonomaWaitingForSave"
                >
                  <template v-slot:info>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Comunidad autonoma
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Introduzca los datos indicados.
                    </p>
                  </template>
                  <template v-slot:default>
                    <div class="grid grid-cols-1 gap-6">
                      <div class="col-span-1">
                        <UITextInput
                          v-model="comunidadAutonomaInEdition.descripcion"
                          label="Descripción"
                          :required="true"
                          :showRequired="showValidateComunidadAutonoma"
                          :maxLength="30"
                        />
                      </div>
                    </div>
                  </template>
                </rowEdit>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>

    <div>
      <div class="mb-10">
        <div class="mb-4">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
              >
                Provincias
              </h2>
            </div>
          </div>
        </div>
        <datatable
          :headers="['Descripción']"
          :numbersRows="provinciasComunidad.length"
          :isEditing="provinciaIsEditing"
          :isLoading="provinciasIsLoading"
          :isFirstPage="provinciasIsFirstPage"
          :isLastPage="provinciasIsLastPage"
          @newitem="provinciaAdd"
          @next="provinciaGotoNextPage"
          @previous="provinciaGoToPreviousPage"
        >
          <template
            v-for="provincia in provinciasShowed"
            :key="provincia.id"
            class="border-b border-gray-200"
          >
            <tr
              v-if="provincia.id != provinciaInEdition.id"
              @click="provinciaSelect(provincia.id)"
              :class="
                provinciaSelectedItem == provincia.id ? 'bg-blue-100' : ''
              "
            >
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ provincia.descripcion }}
              </td>
              <datatablerowbuttons
                @edititem="provinciaEdit"
                @deleteitem="provinciaDelete"
                :id="provincia.id"
                :isEditing="provinciaIsEditing"
              />
            </tr>
            <tr v-else>
              <td colspan="2">
                <rowEdit
                  @cancel="provinciaPreviousCancel"
                  @save="provinciaSubmit"
                  :waitingForSave="provinciaWaitingForSave"
                >
                  <template v-slot:info>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Provincia
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Introduzca los datos indicados.
                    </p>
                  </template>
                  <template v-slot:default>
                    <div class="grid grid-cols-1 gap-6">
                      <div class="col-span-1">
                        <UITextInput
                          v-model="provinciaInEdition.descripcion"
                          label="Descripción"
                          :required="true"
                          :showRequired="showValidateProvincia"
                          :maxLength="30"
                        />
                      </div>
                    </div>
                  </template>
                </rowEdit>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>

    <div>
      <div class="mb-10">
        <div class="mb-4">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
              >
                Poblaciones
              </h2>
            </div>
          </div>
        </div>
        <datatable
          :headers="['Descripción']"
          :numbersRows="poblacionesProvincia.length"
          :isEditing="poblacionIsEditing"
          :isLoading="poblacionesIsLoading"
          :isFirstPage="poblacionesIsFirstPage"
          :isLastPage="poblacionesIsLastPage"
          @newitem="poblacionAdd"
          @next="poblacionGotoNextPage"
          @previous="poblacionGoToPreviousPage"
        >
          <template
            v-for="poblacion in poblacionesShowed"
            :key="poblacion.id"
            class="border-b border-gray-200"
          >
            <tr
              v-if="poblacion.id != poblacionInEdition.id"
              @click="poblacionSelect(poblacion.id)"
              :class="
                poblacionSelectedItem == poblacion.id ? 'bg-blue-100' : ''
              "
            >
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ poblacion.descripcion }}
              </td>
              <datatablerowbuttons
                @edititem="poblacionEdit"
                @deleteitem="poblacionDelete"
                :id="poblacion.id"
                :isEditing="poblacionIsEditing"
              />
            </tr>
            <tr v-else>
              <td colspan="2">
                <rowEdit
                  @cancel="poblacionPreviousCancel"
                  @save="poblacionSubmit"
                  :waitingForSave="poblacionWaitingForSave"
                >
                  <template v-slot:info>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Población
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Introduzca los datos indicados.
                    </p>
                  </template>
                  <template v-slot:default>
                    <div class="grid grid-cols-1 gap-6">
                      <div class="col-span-1">
                        <UITextInput
                          v-model="poblacionInEdition.descripcion"
                          label="Descripción"
                          :required="true"
                          :showRequired="showValidatePoblacion"
                          :maxLength="50"
                        />
                      </div>
                    </div>
                  </template>
                </rowEdit>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>

    <div>
      <div class="mb-10">
        <div class="mb-4">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
              >
                Códigos Postales
              </h2>
            </div>
          </div>
        </div>
        <datatable
          :headers="['Código postal']"
          :numbersRows="codigosPostalesPoblacion.length"
          :isEditing="codigoPostalIsEditing"
          :isLoading="codigosPostalesIsLoading"
          :isFirstPage="codigosPostalesIsFirstPage"
          :isLastPage="codigosPostalesIsLastPage"
          @newitem="codigoPostalAdd"
          @next="codigoPostalesGotoNextPage"
          @previous="codigoPostalesGoToPreviousPage"
        >
          <template
            v-for="codigoPostal in codigosPostalesShowed"
            :key="codigoPostal.id"
            class="border-b border-gray-200"
          >
            <tr v-if="codigoPostal.id != codigoPostalInEdition.id">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ codigoPostal.codigo }}
              </td>
              <datatablerowbuttons
                @edititem="codigoPostalEdit"
                @deleteitem="codigoPostalDelete"
                :id="codigoPostal.id"
                :isEditing="codigoPostalIsEditing"
              />
            </tr>
            <tr v-else>
              <td colspan="2">
                <rowEdit
                  @cancel="codigoPostalPreviousCancel"
                  @save="codigoPostalSubmit"
                  :waitingForSave="codigoPostalWaitingForSave"
                >
                  <template v-slot:info>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Código Postal
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Introduzca los datos indicados.
                    </p>
                  </template>
                  <template v-slot:default>
                    <div class="grid grid-cols-1 gap-6">
                      <div class="col-span-1">
                        <UITextInput
                          v-model="codigoPostalInEdition.codigo"
                          label="Código postal"
                          :required="true"
                          :showRequired="showValidateCodigoPostal"
                          :maxLength="10"
                        />
                      </div>
                    </div>
                  </template>
                </rowEdit>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from "vue";

import { useComunidadesAutonomas } from "@/views/poblaciones/useComunidadesAutonomas";
import { useProvincias } from "@/views/poblaciones/useProvincias";
import { usePoblaciones } from "@/views/poblaciones/usePoblaciones";
import { useCodigosPostales } from "@/views/poblaciones/useCodigosPostales";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Poblaciones",
  components: {
    datatable,
    datatablerowbuttons,
    rowEdit,
    UITextInput
  },
  setup() {
    const {
      comunidadesAutonomas,
      comunidadesAutonomasShowed,
      comunidadAutonomaEdit,
      comunidadAutonomaAdd,
      comunidadAutonomaDelete,
      comunidadAutonomaCancel,
      comunidadAutonomaSave,
      comunidadAutonomaInEdition,
      comunidadAutonomaGotoNextPage,
      comunidadAutonomaGoToPreviousPage,
      comunidadAutonomaIsEditing,
      comunidadesAutonomasIsLoading,
      comunidadesAutonomasIsFirstPage,
      comunidadesAutonomasIsLastPage,
      comunidadesAutonomasloadedData,
      comunidadesAutonomasSuccess,
      comunidadesAutonomasError,
      comunidadesAutonomasErrorText,
      comunidadesAutonomasIsValid,
      comunidadesAutonomasNombreFilter
    } = useComunidadesAutonomas();

    const {
      provincias,
      provinciasComunidad,
      provinciasShowed,
      provinciaEdit,
      provinciaAdd,
      provinciaDelete,
      provinciaCancel,
      provinciaSave,
      provinciaInEdition,
      provinciaGotoNextPage,
      provinciaGoToPreviousPage,
      provinciaIsEditing,
      provinciasIsLoading,
      provinciasIsFirstPage,
      provinciasIsLastPage,
      provinciasIdComunidadAutonoma,
      provinciasloadedData,
      provinciasSuccess,
      provinciasError,
      provinciasErrorText,
      provinciasIsValid
    } = useProvincias();

    const {
      poblaciones,
      poblacionesProvincia,
      poblacionesShowed,
      poblacionEdit,
      poblacionAdd,
      poblacionDelete,
      poblacionCancel,
      poblacionSave,
      poblacionInEdition,
      poblacionGotoNextPage,
      poblacionGoToPreviousPage,
      poblacionIsEditing,
      poblacionesIsLoading,
      poblacionesIsFirstPage,
      poblacionesIsLastPage,
      poblacionesIdProvincia,
      poblacionesloadedData,
      poblacionesSuccess,
      poblacionesError,
      poblacionesErrorText,
      poblacionesIsValid
    } = usePoblaciones();

    const {
      codigosPostales,
      codigosPostalesPoblacion,
      codigosPostalesShowed,
      codigoPostalEdit,
      codigoPostalAdd,
      codigoPostalDelete,
      codigoPostalCancel,
      codigoPostalSave,
      codigoPostalInEdition,
      codigoPostalesGotoNextPage,
      codigoPostalesGoToPreviousPage,
      codigoPostalIsEditing,
      codigosPostalesIsLoading,
      codigosPostalesIsFirstPage,
      codigosPostalesIsLastPage,
      codigoPostalIdPoblacion,
      codigoPostalSuccess,
      codigoPostalError,
      codigoPostalErrorText,
      codigoPostalIsValid
    } = useCodigosPostales();

    const comunidadAutonomaSelectedItem = ref(1);
    const provinciaSelectedItem = ref(0);
    const poblacionSelectedItem = ref(0);

    const comunidadAutonomaWaitingForSave = ref(false);
    const showValidateComunidadAutonoma = ref(false);

    const provinciaWaitingForSave = ref(false);
    const showValidateProvincia = ref(false);

    const poblacionWaitingForSave = ref(false);
    const showValidatePoblacion = ref(false);

    const codigoPostalWaitingForSave = ref(false);
    const showValidateCodigoPostal = ref(false);

    function comunidadAutonomaSubmit() {
      showValidateComunidadAutonoma.value = !comunidadesAutonomasIsValid(
        comunidadAutonomaInEdition.value
      );
      if (showValidateComunidadAutonoma.value) return;
      comunidadAutonomaWaitingForSave.value = true;
      comunidadAutonomaSave().then(() => {
        comunidadAutonomaWaitingForSave.value = false;
      });
    }

    function provinciaSubmit() {
      showValidateProvincia.value = !provinciasIsValid(
        provinciaInEdition.value
      );
      if (showValidateProvincia.value) return;
      provinciaWaitingForSave.value = true;
      provinciaSave().then(() => {
        provinciaWaitingForSave.value = false;
      });
    }

    function poblacionSubmit() {
      showValidatePoblacion.value = !poblacionesIsValid(
        poblacionInEdition.value
      );
      if (showValidatePoblacion.value) return;
      poblacionWaitingForSave.value = true;
      poblacionSave().then(() => {
        poblacionWaitingForSave.value = false;
      });
    }

    function codigoPostalSubmit() {
      showValidateCodigoPostal.value = !codigoPostalIsValid(
        codigoPostalInEdition.value
      );
      if (showValidateCodigoPostal.value) return;
      codigoPostalWaitingForSave.value = true;
      codigoPostalSave().then(() => {
        codigoPostalWaitingForSave.value = false;
      });
    }

    function comunidadAutonomaPreviousCancel() {
      showValidateComunidadAutonoma.value = false;
      comunidadAutonomaCancel();
    }

    function provinciaPreviousCancel() {
      showValidateProvincia.value = false;
      provinciaCancel();
    }

    function poblacionPreviousCancel() {
      showValidatePoblacion.value = false;
      poblacionCancel();
    }

    function codigoPostalPreviousCancel() {
      showValidateCodigoPostal.value = false;
      codigoPostalCancel();
    }

    function poblacionSelect(id: number) {
      poblacionSelectedItem.value = id;
      codigoPostalIdPoblacion.value = id;
    }

    function provinciaSelect(id: number) {
      provinciaSelectedItem.value = id;
      poblacionesIdProvincia.value = id;
      poblacionSelect(poblacionesShowed.value[0].id);
    }

    function comunidadAutonomaSelect(id: number) {
      comunidadAutonomaSelectedItem.value = id;
      provinciasIdComunidadAutonoma.value = id;
      provinciaSelect(provinciasShowed.value[0].id);
    }

    watch(
      [
        comunidadesAutonomasloadedData,
        provinciasloadedData,
        poblacionesloadedData,
        comunidadesAutonomasNombreFilter
      ],
      newValues => {
        if (newValues[0] && newValues[1] && newValues[2]) {
          if(comunidadesAutonomasShowed.value.length > 0) {
            comunidadAutonomaSelect(comunidadesAutonomasShowed.value[0].id);
            provinciaSelect(provinciasShowed.value[0].id);
            poblacionSelect(poblacionesShowed.value[0].id);
          }
        }
      }
    );

    watch(
      [comunidadesAutonomasError, comunidadesAutonomasSuccess],
      ([comunidadesAutonomasError, comunidadesAutonomasSuccess]) => {
        if (comunidadesAutonomasSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (comunidadesAutonomasError) {
          createToast(
            {
              title: "Error 👎 ",
              description: comunidadesAutonomasErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [provinciasError, provinciasSuccess],
      ([provinciasError, provinciasSuccess]) => {
        if (provinciasSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (provinciasError) {
          createToast(
            {
              title: "Error 👎 ",
              description: provinciasErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [poblacionesError, poblacionesSuccess],
      ([poblacionesError, poblacionesSuccess]) => {
        if (poblacionesSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (poblacionesError) {
          createToast(
            {
              title: "Error 👎 ",
              description: poblacionesErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [codigoPostalError, codigoPostalSuccess],
      ([codigoPostalError, codigoPostalSuccess]) => {
        if (codigoPostalSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (codigoPostalError) {
          createToast(
            {
              title: "Error 👎 ",
              description: codigoPostalErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      //Comunidades Autonomas
      comunidadesAutonomas,
      comunidadAutonomaEdit,
      comunidadAutonomaAdd,
      comunidadAutonomaDelete,
      comunidadAutonomaPreviousCancel,
      comunidadAutonomaSave,
      comunidadAutonomaInEdition,
      comunidadAutonomaGotoNextPage,
      comunidadAutonomaGoToPreviousPage,
      comunidadAutonomaIsEditing,
      comunidadesAutonomasIsLoading,
      comunidadesAutonomasIsFirstPage,
      comunidadesAutonomasIsLastPage,
      comunidadAutonomaSubmit,
      comunidadesAutonomasShowed,
      comunidadAutonomaSelectedItem,
      comunidadAutonomaWaitingForSave,
      showValidateComunidadAutonoma,
      comunidadesAutonomasNombreFilter,

      // Provincias
      provincias,
      provinciasComunidad,
      provinciasShowed,
      provinciaEdit,
      provinciaAdd,
      provinciaDelete,
      provinciaPreviousCancel,
      provinciaSave,
      provinciaInEdition,
      provinciaGotoNextPage,
      provinciaGoToPreviousPage,
      provinciaIsEditing,
      provinciasIsLoading,
      provinciasIsFirstPage,
      provinciasIsLastPage,
      provinciasIdComunidadAutonoma,
      provinciaSubmit,
      provinciaSelectedItem,
      provinciaWaitingForSave,
      showValidateProvincia,

      //Poblaciones
      poblaciones,
      poblacionesProvincia,
      poblacionesShowed,
      poblacionEdit,
      poblacionAdd,
      poblacionDelete,
      poblacionPreviousCancel,
      poblacionSave,
      poblacionInEdition,
      poblacionGotoNextPage,
      poblacionGoToPreviousPage,
      poblacionIsEditing,
      poblacionesIsLoading,
      poblacionesIsFirstPage,
      poblacionesIsLastPage,
      poblacionesIdProvincia,
      poblacionSubmit,
      poblacionSelectedItem,
      poblacionWaitingForSave,
      showValidatePoblacion,

      //Códigos Postales
      codigosPostales,
      codigosPostalesPoblacion,
      codigosPostalesShowed,
      codigoPostalEdit,
      codigoPostalAdd,
      codigoPostalDelete,
      codigoPostalPreviousCancel,
      codigoPostalSave,
      codigoPostalInEdition,
      codigoPostalesGotoNextPage,
      codigoPostalesGoToPreviousPage,
      codigoPostalIsEditing,
      codigosPostalesIsLoading,
      codigosPostalesIsFirstPage,
      codigosPostalesIsLastPage,
      codigoPostalIdPoblacion,
      codigoPostalSubmit,
      codigoPostalWaitingForSave,
      showValidateCodigoPostal,

      comunidadAutonomaSelect,
      provinciaSelect,
      poblacionSelect
    };
  }
});
</script>
