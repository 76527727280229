
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "datatable",
  props: {
    headers: {
      type: Array,
      required: true
    },
    numbersRows: {
      type: Number,
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    isFirstPage: {
      type: Boolean,
      required: false
    },
    isLastPage: {
      type: Boolean,
      required: false
    }
  },
  emits: ["previous", "next"],
  setup(props, { emit }) {
    const actualPage = ref(0);
    const numbersOfRowsPerPage = 10;

    function previousPage() {
      if (props.isFirstPage) return;
      actualPage.value = actualPage.value - numbersOfRowsPerPage;
      emit("previous");
    }

    function nextPage() {
      if (props.isLastPage) return;
      actualPage.value = actualPage.value + numbersOfRowsPerPage;
      emit("next");
    }

    return {
      actualPage,
      previousPage,
      nextPage
    };
  }
});
