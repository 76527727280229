<template>
  <td class="rowbuttons md:w-10">
    <div class="flex flex-wrap flex-row-reverse content-center pr-5 p-2 sm:p-0">
      <div class="mr-2 btn-edit-multi cursor-pointer" @click="edititem">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 20 20"
          class="w-3 h-3"
        >
          <path
            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
          ></path>
        </svg>
      </div>

      <div
        class="mr-2 btn-delete-row cursor-pointer"
        @click="tryingDelete = true"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 20 20"
          class="w-4 h-4"
        >
          <path
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          ></path>
        </svg>
      </div>
      <div v-if="tryingDelete" class="absolute">
        <div
          class="border border-gray-200 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="h-6 w-6 text-red-600"
                  x-description="Heroicon name: outline/exclamation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Borrar elemento
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Esta seguro de borrar ese elemento, esta acción no se podrá
                    deshacer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              @click="deleteitem"
            >
              Borrar
            </button>
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="tryingDelete = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "datatablerowbuttons",
  props: {
    id: {
      type: Number,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: false
    }
  },
  emits: ["edititem", "deleteitem"],
  setup(props, { emit }) {
    const tryingDelete = ref(false);

    function edititem() {
      if (props.isEditing) return;
      emit("edititem", props.id);
    }

    function deleteitem() {
      if (props.isEditing) return;
      tryingDelete.value = false;
      emit("deleteitem", props.id);
    }
    return { edititem, deleteitem, tryingDelete };
  }
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  td.rowbuttons {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 15px solid #eee;
    position: relative;
    padding-left: 0%;
  }
}
</style>
