import { computed, onMounted, ref, Ref } from "vue";
import { apiTemporadas } from "@/services/temporadas/api";
import {
  TemporadaView,
  TemporadaFilter,
} from "@/types/temporadas/TemporadaView";
import { Temporada } from "@/types/temporadas/Temporada";
import { usePagination } from "@/components/usePagination";

import { isValidObject } from "../utilidades/useValidations";

export function useTemporada() {
  const idNotEditItem = -999;
  const items: Ref<TemporadaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Temporada);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows,
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");
  const filter = ref({} as TemporadaFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("temporadaFilter", JSON.stringify(filter.value));
    items.value = await apiTemporadas.getTemporadasView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
    /*isLoading.value = true;
    items.value = await apiTemporadas.getTemporadasView();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;*/
  });

  async function editItem(id: number) {
    /*if (id != 0) {
      const found = items.value.find(item => item.id === id);
      if (found == undefined) return;
      editingItem.value = {
        id: found.id,
        descripcion: found.descripcion,
        idTipoTemporada: found.idTipoTemporada,
        anyo: found.anyo
      } as Temporada;
    } else {
      editingItem.value = {
        id: 0
      } as Temporada;
    }*/
    if (id != 0) editingItem.value = await apiTemporadas.getTemporada(id);
    else {
      editingItem.value = {
        id: 0,
      } as Temporada;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
    } as TemporadaView);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiTemporadas
      .deleteTemporada(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter((item) => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Temporada;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiTemporadas
      .saveTemporada(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      (item) => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const temporadaUpdated = await apiTemporadas.getTemporadaView(id);
    items.value[index] = temporadaUpdated;
    editingItem.value = { id: idNotEditItem } as Temporada;

    /*const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (editingItem.value.id === 0) found.id = id;
    found.id = editingItem.value.id;
    found.descripcion = editingItem.value.descripcion;
    found.anyo = editingItem.value.anyo;
    editingItem.value = { id: idNotEditItem } as Temporada;*/
  }

  function temporadasUpdateTipo(idTipoTemporada: number, descripcion: string) {
    const updatedTipos = items.value.filter(
      (item) => item.idTipoTemporada == idTipoTemporada
    );
    //console.log(updatedTipos.length);
    updatedTipos.forEach((item) => {
      item.descripcionTipoTemporada = descripcion;
    });
  }
  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    //console.log(`currentRow is ${currentRow.value}`);
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  async function resetFilter() {
    filter.value = {
      inexistenteSN: "N",
    } as TemporadaFilter;
  }

  function isValid(item: Temporada): boolean {
    return isValidObject(item, ["descripcion", "idTipoTemporada", "anyo"]);
  }

  return {
    temporadas: items,
    temporadasShowed: itemsShowed,
    temporadasFilter: filter,
    temporadasSearch: filterItems,
    temporadasResetFilter: resetFilter,
    temporadaEdit: editItem,
    temporadaAdd: addItem,
    temporadaDelete: deleteItem,
    temporadaCancel: cancelEdit,
    temporadaSave: saveEdit,
    temporadaInEdition: editingItem,
    temporadasGotoNextPage: nextPage,
    temporadasGoToPreviousPage: previousPage,
    temporadaIsEditing: isEditing,
    temporadasIsLoading: isLoading,
    temporadasIsFirstPage: isFirstPage,
    temporadasIsLastPage: isLastPage,
    temporadasUpdateTipo,
    temporadaSuccess: success,
    temporadaError: error,
    temporadaErrorText: errorText,
    temporadaIsValid: isValid,
  };
}
