import { ref, computed } from "vue";

export function usePagination() {
  const currentRow = ref(0);
  const numbersOfRowsPerPage = 10;
  const totalNumberOfRows = ref(0);

  function nextPage() {
    if (currentRow.value + numbersOfRowsPerPage > totalNumberOfRows.value)
      return;
    currentRow.value += numbersOfRowsPerPage;
  }

  function previousPage() {
    if (currentRow.value == 0) return;
    currentRow.value -= numbersOfRowsPerPage;
  }

  const isFirstPage = computed(() => {
    return currentRow.value == 0;
  });

  const isLastPage = computed(() => {
    return currentRow.value + numbersOfRowsPerPage >= totalNumberOfRows.value;
  });

  function setTotalNumberOfRows(nRows: number) {
    totalNumberOfRows.value = nRows;
  }

  return {
    currentRow: currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  };
}
