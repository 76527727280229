import { computed, onMounted, ref, Ref } from "vue";
import { apiFacturas } from "@/services/facturas/api";
import { usePagination } from "@/components/usePagination";
import { ReceptorFactura } from "@/types/facturas/ReceptorFactura";
import {
  ReceptorFacturaFilter,
  ReceptorFacturaView
} from "@/types/facturas/ReceptorFacturaView";

import { isValidObject } from "../utilidades/useValidations";

export function useReceptoresFactura() {
  const idNotEditItem = -999;
  const items: Ref<ReceptorFacturaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as ReceptorFactura);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as ReceptorFacturaFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("receptorFacturaFilter", JSON.stringify(filter.value));
    items.value = await apiFacturas.getReceptoresFacturaView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
  });

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiFacturas.getReceptorFactura(id);
      //console.log(editingItem.value);
    } else {
      editingItem.value = {
        id: 0
      } as ReceptorFactura;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as ReceptorFacturaView);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiFacturas
      .deleteReceptorFactura(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as ReceptorFactura;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiFacturas
      .saveReceptorFactura(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const receptorFacturaUpdated = await apiFacturas.getReceptorFacturaView(id);
    items.value[index] = receptorFacturaUpdated;
    editingItem.value = { id: idNotEditItem } as ReceptorFactura;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    //console.log(`currentRow is ${currentRow.value}`);
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  async function resetFilter() {
    filter.value = { esFabricante: "N"} as ReceptorFacturaFilter;
  }

  function isValid(item: ReceptorFactura): boolean {
    return isValidObject(item, ["nif", "nombre"]);
  }

  return {
    receptoresFactura: items,
    receptoresFacturaShowed: itemsShowed,
    receptorFacturaEdit: editItem,
    receptorFacturaAdd: addItem,
    receptorFacturaDelete: deleteItem,
    receptorFacturaCancel: cancelEdit,
    receptorFacturaSave: saveEdit,
    receptorFacturaInEdition: editingItem,
    receptoresFacturaGotoNextPage: nextPage,
    receptoresFacturaGoToPreviousPage: previousPage,
    receptorFacturaIsEditing: isEditing,
    receptoresFacturaIsLoading: isLoading,
    receptoresFacturaIsFirstPage: isFirstPage,
    receptoresFacturaIsLastPage: isLastPage,
    receptoresFacturaFilter: filter,
    receptoresFacturaResetFilter: resetFilter,
    receptoresFacturaSearch: filterItems,
    receptorFacturaError: error,
    receptorFacturaErrorText: errorText,
    receptorFacturaSuccess: success,
    receptorFacturaIsValid: isValid
  };
}
