import { computed, onMounted, ref, Ref } from "vue";
import { apiClientes } from "@/services/clientes/api";
import { EtiquetaCliente } from "@/types/clientes/EtiquetaCliente";

export function useEtiquetasCliente() {
  const items: Ref<EtiquetaCliente[]> = ref([]);
  const editingItem = ref({
    id: -1,
    descripcion: ""
  });
  const firstRow = ref(0);
  const numbersOfRowsPerPage = 10;
  const isLoading = ref(false);
  const loadedData = ref(false);

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiClientes.getEtiquetasCliente();
    isLoading.value = false;
    loadedData.value = true;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value.id = found.id;
    editingItem.value.descripcion = found.descripcion;
  }

  async function addItem() {
    items.value.splice(firstRow.value, 0, {
      id: 0,
      descripcion: ""
    });
    editItem(0);
  }

  async function deleteItem(id: number) {
    console.log(id);
    /*await apiClientes.deleteEtiquetaCliente(id);
    items.value = items.value.filter(item => item.id != id);*/
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(firstRow.value, 1);
    editingItem.value.id = -1;
  }

  async function saveEdit() {
    /*const resultado = await apiClientes.saveEtiquetaCliente(
      editingItem.value
    );
    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id == 0) found.id = resultado.id;
    found.descripcion = editingItem.value.descripcion;
    editingItem.value.id = -1;*/
  }

  function nextPage() {
    if (firstRow.value + numbersOfRowsPerPage > items.value.length) return;
    firstRow.value = firstRow.value + numbersOfRowsPerPage;
  }

  function previousPage() {
    if (firstRow.value == 0) return;
    firstRow.value = firstRow.value - numbersOfRowsPerPage;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != -1;
  });

  const isFirstPage = computed(() => {
    return firstRow.value == 0;
  });

  const isLastPage = computed(() => {
    return firstRow.value + numbersOfRowsPerPage > items.value.length;
  });

  return {
    etiquetasCliente: items,
    etiquetaClienteEdit: editItem,
    etiquetaClienteAdd: addItem,
    etiquetaClienteDelete: deleteItem,
    etiquetaClienteCancel: cancelEdit,
    etiquetaClienteSave: saveEdit,
    etiquetaClienteInEdition: editingItem,
    etiquetaClienteIndex: firstRow,
    etiquetaClienteGotoNextPage: nextPage,
    etiquetaClienteGoToPreviousPage: previousPage,
    etiquetaClienteIsEditing: isEditing,
    etiquetasClienteIsLoading: isLoading,
    etiquetasClienteIsFirstPage: isFirstPage,
    etiquetasClienteIsLastPage: isLastPage,
    etiquetasClienteloadedData: loadedData
  };
}
