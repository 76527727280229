import { computed, onMounted, ref, Ref } from "vue";
import { apiTransportistas } from "@/services/transportistas/api";
import {
  TransportistaView,
  TransportistaFilter
} from "@/types/transportistas/TransportistaView";
import {
  Transportista,
  TransportistaDireccion,
  TransportistaEmail,
  TransportistaTelefono
} from "@/types/transportistas/Transportista";

import { usePagination } from "@/components/usePagination";

import {
  isValidObject,
  isValidArrayOfObjects
} from "../utilidades/useValidations";

import { getTempId } from "../utilidades/useIDLibrary";

export function useTransportistas() {
  const idNotEditItem = -999;
  const items: Ref<TransportistaView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Transportista);

  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const filter = ref({} as TransportistaFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("TransportistaFilter", JSON.stringify(filter.value));
    items.value = await apiTransportistas.getTransportistasView(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
    /*isLoading.value = true;
    items.value = await apiTransportistas.getTransportistasView();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;*/
  });

  async function editItem(id: number) {
    if (id != 0)
      editingItem.value = await apiTransportistas.getTransportista(id);
    else {
      editingItem.value = {
        id: 0,
        direcciones: [] as TransportistaDireccion[],
        emails: [] as TransportistaEmail[],
        telefonos: [] as TransportistaTelefono[]
      } as Transportista;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as TransportistaView);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiTransportistas
      .deleteTransportista(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) {
      items.value = items.value.filter(item => item.id != id);
      setTotalNumberOfRows(items.value.length);
    }
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Transportista;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiTransportistas
      .saveTransportista(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const transportistaUpdated = await apiTransportistas.getTransportistaView(
      id
    );
    items.value[index] = transportistaUpdated;
    editingItem.value = { id: idNotEditItem } as Transportista;

    /*
    /*const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;

    const tipoUpdated = await apiTransportistas.getTransportistaView(id);
    found.id = tipoUpdated.id;
    found.nif = tipoUpdated.nif;
    found.nombre = tipoUpdated.nombre;
    found.codigoPostal = tipoUpdated.codigoPostal;
    found.poblacion = tipoUpdated.poblacion;
    found.url = tipoUpdated.url;
    editingItem.value = { id: idNotEditItem } as Transportista;*/
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function addDireccion() {
    editingItem.value.direcciones.push({
      idDireccion: getTempId(editingItem.value.direcciones, "idDireccion"),
      idTransportista: editingItem.value.id,
      correspondencia: false,
      fiscal: false,
      entrega: false
    } as TransportistaDireccion);
  }

  function deleteDireccion(id: number) {
    editingItem.value.direcciones = editingItem.value.direcciones.filter(
      item => item.idDireccion != id
    );
  }

  function addEmail() {
    editingItem.value.emails.push({
      idEmail: getTempId(editingItem.value.emails, "idEmail"),
      idTransportista: editingItem.value.id,
      principal: false
    } as TransportistaEmail);
  }

  function deleteEmail(id: number) {
    editingItem.value.emails = editingItem.value.emails.filter(
      item => item.idEmail != id
    );
  }

  function addTelefono() {
    editingItem.value.telefonos.push({
      idTelefono: getTempId(editingItem.value.telefonos, "idTelefono"),
      idTransportista: editingItem.value.id
    } as TransportistaTelefono);
  }

  function deleteTelefono(id: number) {
    editingItem.value.telefonos = editingItem.value.telefonos.filter(
      item => item.idTelefono != id
    );
  }

  async function resetFilter() {
    filter.value = {
      inexistenteSN: "N"
    } as TransportistaFilter;
  }

  function isValid(item: Transportista): boolean {
    return isValidObject(item, ["nombre"]);
  }

  function isValidDirecciones(direcciones: TransportistaDireccion[]): boolean {
    return isValidArrayOfObjects(direcciones, ["domicilio"]);
  }

  function isValidEmails(emails: TransportistaEmail[]): boolean {
    return isValidArrayOfObjects(emails, ["email"]);
  }

  function isValidTelefonos(telefonos: TransportistaTelefono[]): boolean {
    return isValidArrayOfObjects(telefonos, ["telefono"]);
  }

  return {
    transportistas: items,
    transportistasShowed: itemsShowed,
    transportistasFilter: filter,
    transportistasSearch: filterItems,
    transportistasResetFilter: resetFilter,
    transportistaEdit: editItem,
    transportistaAdd: addItem,
    transportistaDelete: deleteItem,
    transportistaCancel: cancelEdit,
    transportistaSave: saveEdit,
    transportistaInEdition: editingItem,
    transportistasGotoNextPage: nextPage,
    transportistasGoToPreviousPage: previousPage,
    transportistaIsEditing: isEditing,
    transportistasIsLoading: isLoading,
    transportistasIsFirstPage: isFirstPage,
    transportistasIsLastPage: isLastPage,
    transportistaAddDireccion: addDireccion,
    transportistaDeleteDireccion: deleteDireccion,
    transportistaAddEmail: addEmail,
    transportistaDeleteEmail: deleteEmail,
    transportistaAddTelefono: addTelefono,
    transportistaDeleteTelefono: deleteTelefono,
    transportistaSuccess: success,
    transportistaError: error,
    transportistaErrorText: errorText,
    transportistaIsValid: isValid,
    transportistaIsValidTelefonos: isValidTelefonos,
    transportistaIsValidDirecciones: isValidDirecciones,
    transportistaIsValidEmails: isValidEmails
  };
}
