import { computed, onMounted, ref, Ref } from "vue";
import { apiPoblaciones } from "@/services/poblaciones/api";
import { Provincia } from "@/types/poblaciones/Provincia";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useProvincias() {
  const idNotEditItem = -999;
  const items: Ref<Provincia[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Provincia);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const idComunidadAutonoma = ref(0);

  const loadedData = ref(false);

  const provinciasComunidad = computed(() => {
    setTotalNumberOfRows(
      items.value.filter(item => item.idComunidad == idComunidadAutonoma.value)
        .length
    );
    return items.value.filter(
      item => item.idComunidad == idComunidadAutonoma.value
    );
  });

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiPoblaciones.getProvincias();
    setTotalNumberOfRows(provinciasComunidad.value.length);
    isLoading.value = false;
    loadedData.value = true;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      descripcion: found.descripcion,
      idComunidad: found.idComunidad
    } as Provincia;
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
      idComunidad: idComunidadAutonoma.value
    } as Provincia);
    setTotalNumberOfRows(provinciasComunidad.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiPoblaciones
      .deleteProvincia(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(provinciasComunidad.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value.id = idNotEditItem;
    setTotalNumberOfRows(provinciasComunidad.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiPoblaciones
      .saveProvincia(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id == 0) found.id = id;
    found.descripcion = editingItem.value.descripcion;
    editingItem.value = { id: idNotEditItem } as Provincia;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return provinciasComunidad.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: Provincia): boolean {
    return isValidObject(item, ["descripcion"]);
  }

  return {
    provincias: items,
    provinciasComunidad: provinciasComunidad,
    provinciasShowed: itemsShowed,
    provinciaEdit: editItem,
    provinciaAdd: addItem,
    provinciaDelete: deleteItem,
    provinciaCancel: cancelEdit,
    provinciaSave: saveEdit,
    provinciaInEdition: editingItem,
    provinciaGotoNextPage: nextPage,
    provinciaGoToPreviousPage: previousPage,
    provinciaIsEditing: isEditing,
    provinciasIsLoading: isLoading,
    provinciasIsFirstPage: isFirstPage,
    provinciasIsLastPage: isLastPage,
    provinciasIdComunidadAutonoma: idComunidadAutonoma,
    provinciasloadedData: loadedData,
    provinciasSuccess: success,
    provinciasError: error,
    provinciasErrorText: errorText,
    provinciasIsValid: isValid
  };
}
