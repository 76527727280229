
import { defineComponent, ref, watch } from "vue";

import { useUsuario } from "@/views/usuarios/useUsuarios";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UIPasswordInput from "@/components/UIPasswordInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "usuarios",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UIPasswordInput,
    UICheckBox
  },
  setup() {
    const headers = ["Nombre", "Usuario", "Administrador"];
    const {
      usuarios,
      usuariosShowed,
      usuarioEdit,
      usuarioAdd,
      usuarioDelete,
      usuarioCancel,
      usuarioSave,
      usuarioInEdition,
      usuariosGotoNextPage,
      usuariosGoToPreviousPage,
      usuarioIsEditing,
      usuariosIsLoading,
      usuariosIsFirstPage,
      usuariosIsLastPage,
      usuarioIsValid,
      usuarioSuccess,
      usuarioError,
      usuarioErrorText,
      passwordConfirmation
    } = useUsuario();

    const showValidate = ref(false);

    const usuarioWaitingForSave = ref(false);

    function usuarioPreviousSave() {
      showValidate.value = !usuarioIsValid(usuarioInEdition.value);
      showValidate.value =
        showValidate.value ||
        passwordConfirmation.value != usuarioInEdition.value.password;
      passwordConfirmation.value = "";
      if (showValidate.value) return;
      localStorage.setItem(
        "ultimausuario",
        JSON.stringify(usuarioInEdition.value)
      );
      //localStorage.setItem("usuarioIdTemporada",usuarioInEdition.value.idTemporada.toString);
      usuarioWaitingForSave.value = true;
      usuarioSave().then(() => {
        usuarioWaitingForSave.value = false;
      });
    }

    function usuarioPreviousCancel() {
      showValidate.value = false;
      usuarioCancel();
    }

    watch([usuarioError, usuarioSuccess], ([usuarioError, usuarioSuccess]) => {
      if (usuarioSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito"
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a"
          }
        );
      }

      if (usuarioError) {
        createToast(
          {
            title: "Error 👎 ",
            description: usuarioErrorText.value
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true
          }
        );
      }
    });

    return {
      headers,
      usuarios,
      usuariosShowed,
      usuarioEdit,
      usuarioAdd,
      usuarioDelete,
      usuarioPreviousCancel,
      usuarioPreviousSave,
      usuarioInEdition,
      usuariosGotoNextPage,
      usuariosGoToPreviousPage,
      usuarioIsEditing,
      usuariosIsLoading,
      usuariosIsFirstPage,
      usuariosIsLastPage,
      usuarioWaitingForSave,
      passwordConfirmation,
      showValidate
    };
  }
});
