import { computed, onMounted, ref, Ref } from "vue";
import { apiUsuarios } from "@/services/usuarios/api";
import { UsuarioView } from "@/types/usuarios/UsuarioView";
import { Usuario } from "@/types/usuarios/Usuario";
import { usePagination } from "@/components/usePagination";

import { isValidObject } from "../utilidades/useValidations";

export function useUsuario() {
  const idNotEditItem = -999;
  const items: Ref<UsuarioView[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Usuario);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const passwordConfirmation = ref("");

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiUsuarios.getUsuariosView();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  });

  async function editItem(id: number) {
    if (id != 0) {
      editingItem.value = await apiUsuarios.getUsuario(id);
      passwordConfirmation.value = editingItem.value.password;
    } else {
      editingItem.value = {
        id: 0
      } as Usuario;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0
    } as UsuarioView);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiUsuarios
      .deleteUsuario(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Usuario;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiUsuarios
      .saveUsuario(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });

    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const usuarioUpdated = await apiUsuarios.getUsuarioView(id);
    items.value[index] = usuarioUpdated;
    editingItem.value = { id: idNotEditItem } as Usuario;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    //console.log(`currentRow is ${currentRow.value}`);
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: Usuario): boolean {
    return isValidObject(item, ["username", "password", "nombre"]);
  }

  return {
    usuarios: items,
    usuariosShowed: itemsShowed,
    usuarioEdit: editItem,
    usuarioAdd: addItem,
    usuarioDelete: deleteItem,
    usuarioCancel: cancelEdit,
    usuarioSave: saveEdit,
    usuarioInEdition: editingItem,
    usuariosGotoNextPage: nextPage,
    usuariosGoToPreviousPage: previousPage,
    usuarioIsEditing: isEditing,
    usuariosIsLoading: isLoading,
    usuariosIsFirstPage: isFirstPage,
    usuariosIsLastPage: isLastPage,
    usuarioSuccess: success,
    usuarioError: error,
    usuarioErrorText: errorText,
    usuarioIsValid: isValid,
    passwordConfirmation
  };
}
