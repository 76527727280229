<template>
  <button
    class="mr-2 btn-delete-row cursor-pointer mb-2"
    @click.prevent="deleteItem"
  >
    <svg
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 20 20"
      class="w-4 h-4"
    >
      <path
        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
      ></path>
    </svg>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "deleteButtonMulti",
  props: {
    id: {
      type: Number,
      required: false
    },
    index: {
      type: Number,
      required: false
    }
  },
  methods: {
    deleteItem() {
      this.$emit("deleteMultiItem", this.id, this.index);
    }
  }
});
</script>
