
import { defineComponent, ref, watch } from "vue";

import { useColecciones } from "@/views/colecciones/useColecciones";
import { useMarcas } from "../utilidades/useMarcas";
import { useTemporada } from "../temporadas/useTemporada";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { ColeccionFilter } from "@/types/colecciones/ColeccionView";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectTemporada from "@/components/selectTemporada.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import { apiColeccionesGraphics } from "@/services/colecciones/apigraphic.ts";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Colecciones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UICheckBox,
    filterEdit,
    selectMarca,
    selectTemporada,
    selectTipoTemporada,
  },
  setup() {
    const headers = [
      "Descripción",
      "Marca",
      "Temporada",
      "Comisión",
      "F. de servicio",
    ];
    const {
      colecciones,
      coleccionesShowed,
      coleccionesFilter,
      coleccionesResetFilter,
      coleccionesSearch,
      coleccionEdit,
      coleccionAdd,
      coleccionDelete,
      coleccionCancel,
      coleccionSave,
      coleccionInEdition,
      coleccionesGotoNextPage,
      coleccionesGoToPreviousPage,
      coleccionIsEditing,
      coleccionesIsLoading,
      coleccionesIsFirstPage,
      coleccionesIsLastPage,
      coleccionIsValid,
      coleccionSuccess,
      coleccionError,
      coleccionErrorText,
    } = useColecciones();

    const { marcas } = useMarcas();
    const { temporadas } = useTemporada();
    const { tiposTemporada } = useTipoTemporada();

    const isFiltering = ref(false);

    const showValidate = ref(false);

    const coleccionWaitingForSave = ref(false);

    coleccionesResetFilter();

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function coleccionPreviousSearch() {
      coleccionesSearch();
    }

    function coleccionPreviousSave() {
      showValidate.value = !coleccionIsValid(coleccionInEdition.value);
      if (showValidate.value) return;
      localStorage.setItem(
        "ultimaColeccion",
        JSON.stringify(coleccionInEdition.value)
      );
      //localStorage.setItem("coleccionIdTemporada",coleccionInEdition.value.idTemporada.toString);
      coleccionWaitingForSave.value = true;
      coleccionSave().then(() => {
        coleccionWaitingForSave.value = false;
      });
    }

    function coleccionPreviousCancel() {
      showValidate.value = false;
      coleccionCancel();
    }

    function calculateDescripcion() {
      if (
        coleccionInEdition.value.descripcion == "" ||
        coleccionInEdition.value.descripcion == undefined
      ) {
        const marcaSelected = marcas.value.find(
          (marca) => marca.id === coleccionInEdition.value.idMarca
        );
        const temporadaSelected = temporadas.value.find(
          (temporada) => temporada.id === coleccionInEdition.value.idTemporada
        );
        if (marcaSelected != undefined && temporadaSelected != undefined) {
          const tipoTemporadaSelected = tiposTemporada.value.find(
            (tipoTemporada) =>
              tipoTemporada.id === temporadaSelected.idTipoTemporada
          );
          coleccionInEdition.value.descripcion =
            marcaSelected.descripcion +
            " " +
            tipoTemporadaSelected?.abreviatura +
            " " +
            temporadaSelected.anyo;
        }
      }
    }

    function setPorcentajeComision() {
      if (
        coleccionInEdition.value.porcentajeComision == undefined ||
        coleccionInEdition.value.porcentajeComision == 0
      ) {
        const marcaSelected = marcas.value.find(
          (marca) => marca.id === coleccionInEdition.value.idMarca
        );
        if (marcaSelected != undefined)
          coleccionInEdition.value.porcentajeComision =
            marcaSelected.porcentajeComision;
      }
    }

    function changeMarca() {
      setPorcentajeComision();
      calculateDescripcion();
    }

    function changeTemporada() {
      calculateDescripcion();
    }

    function downloadGraphics(idMarca: number) {
      console.log("graphics please", idMarca);
      const filter = ({ idMarca: idMarca } as unknown) as ColeccionFilter;
      apiColeccionesGraphics.getColeccionesGraphic(filter);
    }

    watch(
      [coleccionError, coleccionSuccess],
      ([coleccionError, coleccionSuccess]) => {
        if (coleccionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito",
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a",
            }
          );
        }

        if (coleccionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: coleccionErrorText.value,
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true,
            }
          );
        }
      }
    );

    return {
      headers,
      colecciones,
      coleccionesShowed,
      coleccionesFilter,
      coleccionesResetFilter,
      coleccionPreviousSearch,
      coleccionEdit,
      coleccionAdd,
      coleccionDelete,
      coleccionPreviousCancel,
      coleccionPreviousSave,
      coleccionInEdition,
      coleccionesGotoNextPage,
      coleccionesGoToPreviousPage,
      coleccionIsEditing,
      coleccionesIsLoading,
      coleccionesIsFirstPage,
      coleccionesIsLastPage,
      coleccionWaitingForSave,

      showFilter,
      isFiltering,

      marcas,
      temporadas,
      tiposTemporada,
      showValidate,
      changeMarca,
      changeTemporada,
      downloadGraphics,
    };
  },
});
