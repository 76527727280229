import { computed, onMounted, ref, Ref } from "vue";
import { apiPoblaciones } from "@/services/poblaciones/api";
import { Poblacion } from "@/types/poblaciones/Poblacion";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function usePoblaciones() {
  const idNotEditItem = -999;
  const items: Ref<Poblacion[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as Poblacion);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const idProvincia = ref(0);

  const loadedData = ref(false);

  const poblacionesProvincia = computed(() => {
    setTotalNumberOfRows(
      items.value.filter(item => item.idProvincia == idProvincia.value).length
    );
    return items.value.filter(item => item.idProvincia == idProvincia.value);
  });

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiPoblaciones.getPoblaciones();
    setTotalNumberOfRows(poblacionesProvincia.value.length);
    isLoading.value = false;
    loadedData.value = true;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      descripcion: found.descripcion,
      idProvincia: found.idProvincia
    } as Poblacion;
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
      idProvincia: idProvincia.value
    } as Poblacion);
    setTotalNumberOfRows(poblacionesProvincia.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiPoblaciones
      .deletePoblacion(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(poblacionesProvincia.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as Poblacion;
    setTotalNumberOfRows(poblacionesProvincia.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiPoblaciones
      .savePoblacion(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id == 0) found.id = id;
    found.descripcion = editingItem.value.descripcion;
    editingItem.value = { id: idNotEditItem } as Poblacion;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return poblacionesProvincia.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: Poblacion): boolean {
    return isValidObject(item, ["descripcion", "idProvincia"]);
  }

  return {
    poblaciones: items,
    poblacionesProvincia: poblacionesProvincia,
    poblacionesShowed: itemsShowed,
    poblacionEdit: editItem,
    poblacionAdd: addItem,
    poblacionDelete: deleteItem,
    poblacionCancel: cancelEdit,
    poblacionSave: saveEdit,
    poblacionInEdition: editingItem,
    poblacionGotoNextPage: nextPage,
    poblacionGoToPreviousPage: previousPage,
    poblacionIsEditing: isEditing,
    poblacionesIsLoading: isLoading,
    poblacionesIsFirstPage: isFirstPage,
    poblacionesIsLastPage: isLastPage,
    poblacionesIdProvincia: idProvincia,
    poblacionesloadedData: loadedData,
    poblacionesSuccess: success,
    poblacionesError: error,
    poblacionesErrorText: errorText,
    poblacionesIsValid: isValid
  };
}
