<template>
  <div class="pb-80" id="header-calendar">
    <div class="pt-10 mb-4">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="flex-1 min-w-0">
          <h2
            class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Agenda
          </h2>
        </div>

        <div class="mt-5 flex lg:mt-0 lg:ml-4">
          <span class="block">
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="showFilter"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              filtros
            </button>
          </span>

          <span
            x-data="{ open: false }"
            @keydown.escape.stop="open = false"
            @click="showListados = !showListados"
            class="ml-3 relative"
          >
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="mobile-menu"
              @click="open = !open"
              aria-haspopup="true"
              x-bind:aria-expanded="open"
              aria-expanded="true"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Listados
              <svg
                class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                x-description="Heroicon name: solid/chevron-down"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>

            <div
              v-show="showListados"
              x-description="Dropdown menu, show/hide based on menu state."
              x-show="false"
              x-transition:enter="transition ease-out duration-200"
              x-transition:enter-start="transform opacity-0 scale-95"
              x-transition:enter-end="transform opacity-100 scale-100"
              x-transition:leave="transition ease-in duration-75"
              x-transition:leave-start="transform opacity-100 scale-100"
              x-transition:leave-end="transform opacity-0 scale-95"
              class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="mobile-menu"
            >
              <router-link
                target="_blank"
                to="/Agenda/AgendaCitasConColecciones/no/0"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >Citas</router-link
              >
              <router-link
                target="_blank"
                to="/Agenda/AgendaCitasConColecciones/yes/0"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >Citas con colecciones del ultimo año</router-link
              >
              <router-link
                target="_blank"
                to="/Agenda/AgendaCitasConColecciones/yes/1"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >Citas con colecciones de los 2 últimos años</router-link
              >
            </div>
          </span>
        </div>
      </div>
    </div>

    <filterEdit
      @cancel="showFilter"
      @save="preSearch"
      @reset="citasResetFilter"
      v-if="isFiltering"
    >
      <template v-slot>
        <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
          <div class="col-span-1 sm:col-span-1">
            <selectCliente v-model="citasFilter.idCliente" :items="clientes" />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <selectTipoTemporada
              v-model="citasFilter.idTipoTemporada"
              :items="tiposTemporada"
            />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <UINumberInput v-model="citasFilter.anyo" label="Año" />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <UIDateInput
              v-model="citasFilter.desdeFechaCita"
              label="Desde fecha cita"
            />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <UIDateInput
              v-model="citasFilter.hastaFechaCita"
              label="Hasta fecha cita"
            />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <label class="block text-sm font-medium text-gray-700">
              Asistencia
            </label>
            <select
              v-model="citasFilter.asistidoSN"
              id="asistidoSN"
              name="asistidoSN"
              autocomplete="country"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="T">Todos</option>
              <option value="S">Asistidos</option>
              <option value="N">No Asistidos</option>
            </select>
          </div>
          <div class="col-span-1 sm:col-span-1">
            <selectColeccion
              :items="colecciones"
              v-model="citasFilter.pedidosIdColeccion"
              labelValue="Clientes con pedido colección"
            />
          </div>
          <div class="col-span-1 sm:col-span-1">
            <UIListBox
              v-model="citasFilter.pedidosIdTemporada"
              :options="temporadas"
              optionValue="id"
              optionName="descripcion"
              label="Clientes con pedido temporada"
            />
          </div>
        </div>
      </template>
    </filterEdit>

    <div v-if="citasIsLoading" class="bg-gray-400">
      <div class="rounded-t-xl overflow-hidden p-10">
        <div class="flex justify-around">
          <span class="inline-flex">
            <div
              class="inline-flex items-center px-4 py-2 text-base leading-6 font-medium text-white transition ease-in-out duration-150 cursor-not-allowed"
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Cargando
            </div>
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="flex mb-2 mt-2">
        <a
          href="#"
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          @click.prevent="previousYear()"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            ></path>
          </svg>
        </a>
        <a
          href="#"
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          @click.prevent="previousMonth()"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </a>
        <div class=" text-xl text-center flex-auto mt-2">
          <h2>
            {{ months[month - 1] }} {{ year }}
            <div v-if="citas.length == 0" class="ml-2 text-base">
              &#129335; No hay resultados con el filtro actual
            </div>
          </h2>
        </div>
        <a
          href="#"
          class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          @click.prevent="nextMonth()"
        >
          <span class="sr-only">Next</span>
          <svg
            class="h-5 w-5"
            x-description="Heroicon name: solid/chevron-right"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
        <a
          href="#"
          class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          @click.prevent="nextYear()"
        >
          <span class="sr-only">Next</span>
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            ></path>
          </svg>
        </a>
      </div>

      <rowEdit
        @cancel="citaPreviousCancel"
        @save="citaPreviousSave"
        :waitingForSave="citaWaitingForSave"
        v-if="citaInEdition.id != -999"
      >
        <template v-slot:info>
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Cita
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Introduzca los datos indicados.
          </p>
        </template>
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <UIDateInput
              v-model="citaInEdition.fecha"
              label="Fecha"
              :required="true"
              :showRequired="showValidate"
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <UITimeInput v-model="citaInEdition.hora" label="Hora" />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <selectCliente
              v-model="citaInEdition.idCliente"
              :items="clientes"
              :required="true"
              :showRequired="showValidate"
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <UITextInput v-model="citaInEdition.usuario" label="Usuario" />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <selectTipoTemporada
              v-model="citaInEdition.idTipoTemporada"
              :items="tiposTemporada"
            />
          </div>
          <div class="col-span-6 sm:col-span-2">
            <UINumberInput v-model="citaInEdition.anyo" label="Año" />
          </div>
          <div class="col-span-6 sm:col-span-1 mt-8">
            <UICheckBox v-model="citaInEdition.asistido" label="Asistido" />
          </div>
          <div class="col-span-6">
            <UITextArea
              v-model="citaInEdition.observaciones"
              label="Observaciones"
            />
          </div>
        </div>
      </rowEdit>

      <div class="grid grid-cols-7 bg-gray-100 gap-0">
        <div
          v-for="d in daysOfWeek"
          :key="d"
          class="col-span-1 border border-gray-300 text-center"
        >
          {{ d }}
        </div>
      </div>
      <div class="grid grid-cols-7 bg-white gap-0 mb-10">
        <template v-for="(day, index) in days" :key="index">
          <template v-if="day.day != -1">
            <div
              class="col-span-1 border border-gray-300 relative "
              :class="{'bg-blue-100' : actualDay==day.day && actualMonth == month && actualYear== year}"
              @mouseover="hover = day.day"
              @mouseleave="hover = 0"
            >              
              <template v-for="(cita, index) in day.citas" :key="index">
                <div
                  v-if="cita.id == selectedCita.id"
                  class=" w-64 border rounded-xl  bg-white overflow-scroll p-4 z-10 absolute top-full left-0  shadow-2xl py-1 ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div
                    class="mb-2 mt-2 flex justify-end"
                    @click="closeselectedCita"
                  >
                    <svg
                      class="w-5 h-5 cursor-pointer"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex text-blue-800">
                    <svg
                      class="w-5 h-5 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span>{{ selectedCita.hora }}</span>
                  </div>
                  <div class="flex">
                    <div class="mr-2 mt-1 text-xs" v-if="selectedCita.asistido">
                      ✅
                    </div>
                    <div class="mr-2 mt-1 text-xs" v-else>🚫</div>
                    
                    <router-link  class="cursor-pointer text-blue-600 flex hover:underline"
                        :to="{
                          name: 'clientes',
                          params: {
                            id: selectedCita.idCliente,
                          },
                        }"
                        target="_blank"
                      >
                       {{ selectedCita.nombreCliente }}
                      </router-link>
                  </div>

                  <div>
                    {{ selectedCita.nombreTemporada }} {{ selectedCita.anyo }}
                  </div>

                  <div class="flex text-gray-400">
                    <svg
                      class="w-4 h-4 mt-1 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      ></path>
                    </svg>
                    <span>{{ selectedCita.usuario }}</span>
                  </div>

                  <div
                    v-if="
                      selectedCita.observaciones != '' &&
                        selectedCita.observaciones != null
                    "
                    class="mt-2 pt-2 border-t"
                  >
                    {{ selectedCita.observaciones }}
                  </div>

                  <div class="mt-2 pt-2 border-t">
                    <div
                      class="mt-2 mr-2 cursor-pointer text-blue-600 flex hover:underline"
                      @click="citaSendEmail(cita.id)"
                    >
                      <svg
                        class="w-4 h-4 mt-1 mr-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <span>enviar email a cliente</span>
                    </div>
                  </div>
                  <div class="flex flex-row mt-2 pt-2 justify-between border-t">
                    <div
                      class="mt-2 mr-2 cursor-pointer text-red-600 flex hover:underline"
                      @click="citaPreviousDelete(cita.id)"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 mt-1 mr-1"
                      >
                        <path
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        ></path>
                      </svg>
                      <span>borrar</span>
                    </div>
                    <div
                      @click="citaPreviousEdit(cita.id)"
                      class="flex cursor-pointer border-gray-400 p-2 border rounded-xl hover:text-white hover:bg-blue-600"
                    >
                      <svg
                        class="w-4 h-4 mt-1 mr-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>editar</span>
                    </div>
                  </div>
                </div>
              </template>
              <div class="relative">
                <div
                  class="absolute top-2 right-2 rounded-full bg-gray-400 text-xs h-5 w-5 text-white text-center font-extrabold"
                >
                  <div style="margin-top:2px;">{{ day.day }}</div>
                </div>
                <div
                  v-if="hover == day.day"
                  class="absolute top-2 left-2 rounded-full bg-green-500 cursor-pointer text-xs h-5 w-5 text-white text-center font-extrabold"
                >
                  <div
                    @click="citaPreviousAdd(day.day)"
                    style="margin-top:2px;"
                  >
                    +
                  </div>
                </div>
              </div>
              <div class="h-40 overflow-y-hidden">
                <div class="mt-8 h-28 p-2 overflow-y-scroll">
                  <div
                    v-for="(cita, index) in day.citas"
                    :key="index"
                    class="w-full h-6 text-xs p-1 flex relative cursor-pointer"
                    @click="showCita(cita.id)"
                  >
                    <div class="rounded px-1 pb-1 font-bold" :class="{'bg-green-300': cita.asistido, 'bg-blue-300': !cita.asistido }">
                      {{ cita.hora }}
                    </div>
                    <div class="ml-1 overflow-y-hidden">
                      {{ cita.nombreCliente }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else
            ><div class="col-span-1 p-10 border border-gray-300"></div
          ></template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref, watch } from "vue";

import { useAgenda } from "./useAgenda";
import { useClientes } from "../clientes/useClientes";
import { useColecciones } from "../colecciones/useColecciones";
import { CalendarDay } from "@/types/agenda/CalendarDay";
import { CitaView } from "@/types/agenda/CitaView";
import { useTipoTemporada } from "@/views/temporadas/useTipoTemporada";
import { useTemporada } from "@/views/temporadas/useTemporada";

import UITextInput from "@/components/UITextInput.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UITimeInput from "@/components/UITimeInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import filterEdit from "@/components/filterEdit.vue";
import selectCliente from "@/components/selectCliente.vue";
import UIListBox from "@/components/UIListBox.vue";

import rowEdit from "@/components/rowEdit.vue";
import { createToast } from "mosha-vue-toastify";

/* import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css"; */

export default defineComponent({
  name: "Agenda",
  components: {
    rowEdit,
    //datatable,
    //datatablerowbuttons,
    UITextInput,
    UIDateInput,
    UITimeInput,
    UINumberInput,
    UICheckBox,
    UIListBox,
    UITextArea,
    filterEdit,
    selectCliente,
    selectColeccion,
    selectTipoTemporada,
  },
  setup() {
    const {
      citas,
      citasFilter,
      citasSearch,
      citasResetFilter,
      citasIsLoading,
      citaInEdition,
      citaAdd,
      citaDelete,
      citaEdit,
      citaSave,
      citaCancel,
      citaSuccess,
      citaError,
      citaErrorText,
      citaIsValid,
      citaSendEmail,
    } = useAgenda();

    const { clientes } = useClientes();
    const { colecciones } = useColecciones();
    const { tiposTemporada } = useTipoTemporada();
    const { temporadas } = useTemporada();

    const tryingDelete = ref(false);

    const isFiltering = ref(false);
    const showListados = ref(false);
    const showValidate = ref(false);
    const actualDay = ref(new Date().getDate());
    const actualMonth = new Date().getMonth() + 1;
    const actualYear = new Date().getFullYear();

    const daysOfWeek = [
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
      "domingo",
    ];

    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const days: Ref<CalendarDay[]> = ref([]);

    //const today = ref(new Date());
    citasFilter.value.desdeFechaCita =
      new Date().getFullYear().toString() + "-01" + "-01";
    citasFilter.value.asistidoSN = "T";

    const month = ref(0);
    const year = ref(0);
    const hover = ref(0);

    const citaWaitingForSave = ref(false);

    const selectedCita = ref({ id: -1 } as CitaView);

    async function fillCalendar() {
      days.value = [];
      let dayOfWeek = new Date(year.value, month.value - 1).getDay();
      console.log(`year: ${year.value} month: ${month.value}`);
      console.log(`${dayOfWeek}`);
      const daysInMonth = new Date(year.value, month.value, 0).getDate();
      if (dayOfWeek == 0) dayOfWeek = 7;
      const numMaxDays = Math.ceil((dayOfWeek + daysInMonth - 1) / 7) * 7;
      let actualDay = 0;
      for (let i = 1; i <= numMaxDays; i++) {
        if (i >= dayOfWeek && actualDay < daysInMonth) {
          actualDay++;
          const citasDay: CitaView[] = [];
          citas.value.forEach((cita) => {
            if (cita.fecha != undefined) {
              if (
                parseInt(cita.fecha.substr(0, 4)) == year.value &&
                parseInt(cita.fecha.substr(5, 2)) == month.value &&
                parseInt(cita.fecha.substr(8, 2)) == actualDay
              ) {
                citasDay.push(cita);
              }
            }
          });
          days.value.push({
            day: actualDay,
            citas: citasDay,
          } as CalendarDay);
        } else
          days.value.push({
            day: -1,
            citas: [] as CitaView[],
          });
      }
    }

    async function preSearch() {
      await citasSearch();

      year.value = 0;
      month.value = 0;

      year.value = new Date().getFullYear();
      month.value = new Date().getMonth() + 1;
/*
      const actualYear = new Date().getFullYear();
      const actualMonth = new Date().getMonth() + 1;

      if (citas.value.length == 0) {
        year.value = actualYear;
        month.value = actualMonth;
      } else {
        citas.value.forEach((cita) => {
          if (
            year.value < parseInt(cita.fecha.substr(0, 4)) &&
            actualYear >= parseInt(cita.fecha.substr(0, 4))
          ) {
            year.value = parseInt(cita.fecha.substr(0, 4));
            month.value = parseInt(cita.fecha.substr(5, 2));
          }
          if (
            year.value == parseInt(cita.fecha.substr(0, 4)) &&
            month.value < parseInt(cita.fecha.substr(5, 2)) &&
            actualYear >= parseInt(cita.fecha.substr(0, 4))
          ) {
            month.value = parseInt(cita.fecha.substr(5, 2));
          }
        });

        if (year.value == 9999) year.value = new Date().getFullYear();
      }
*/
      fillCalendar();
    }

    function previousMonth() {
      if (month.value == 1) {
        month.value = 12;
        year.value = year.value - 1;
      } else month.value = month.value - 1;
      fillCalendar();
    }

    function nextMonth() {
      if (month.value == 12) {
        month.value = 1;
        year.value = year.value + 1;
      } else month.value = month.value + 1;

      fillCalendar();
    }

    function previousYear() {
      year.value = year.value - 1;
      fillCalendar();
    }

    function nextYear() {
      year.value = year.value + 1;
      fillCalendar();
    }

    function showCita(id: number) {
      const cita = citas.value.find((x) => x.id == id);
      if (cita != undefined) selectedCita.value = cita;
    }

    function closeselectedCita() {
      selectedCita.value = { id: -1 } as CitaView;
    }

    function citaPreviousAdd(day: number) {
      console.log(day);
      console.log(month);
      console.log(year);
      const element = document.querySelector("#header-calendar");
      if (element != undefined) element.scrollIntoView({ behavior: "smooth" });
      citaAdd();
      citaInEdition.value.fecha =
        year.value.toString() +
        "-" +
        month.value.toString().padStart(2, "0") +
        "-" +
        day.toString().padStart(2, "0");
    }

    function citaPreviousEdit(id: number) {
      const element = document.querySelector("#header-calendar");
      if (element != undefined) element.scrollIntoView({ behavior: "smooth" });
      closeselectedCita();
      citaEdit(id);
    }

    function citaPreviousSave() {
      console.log("citaInEdition", citaInEdition.value);
      showValidate.value = !citaIsValid(citaInEdition.value);
      console.log("showValidate", showValidate.value);
      if (showValidate.value) return;

      citaWaitingForSave.value = true;
      citaSave().then(() => {
        citaWaitingForSave.value = false;
        console.log("*1*");
        fillCalendar();
        console.log("*2*");
      });
    }

    function citaPreviousDelete(id: number) {
      citaDelete(id).then(() => {
        fillCalendar();
      });
    }

    function citaPreviousCancel() {
      citaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    onMounted(async () => {
      preSearch();
    });

    watch([citaError, citaSuccess], ([citaError, citaSuccess]) => {
      if (citaSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (citaError) {
        citaWaitingForSave.value = false;
        createToast(
          {
            title: "Error 👎 ",
            description: citaErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      citas,
      days,
      month,
      months,
      year,
      previousMonth,
      nextMonth,
      previousYear,
      nextYear,
      daysOfWeek,
      showCita,
      selectedCita,
      closeselectedCita,
      isFiltering,
      showFilter,
      citasFilter,
      citasResetFilter,
      preSearch,
      showListados,
      citasIsLoading,
      clientes,
      colecciones,
      citaInEdition,
      citaPreviousAdd,
      citaPreviousEdit,
      citaPreviousSave,
      citaPreviousCancel,
      citaEdit,
      citaPreviousDelete,
      citaSendEmail,
      hover,
      tiposTemporada,
      temporadas,
      citaWaitingForSave,
      showValidate,
      tryingDelete,
      actualDay,
      actualMonth,
      actualYear
    };
  },
});
</script>
