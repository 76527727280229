
import { defineComponent, ref, watch } from "vue";
import UITextInput from "@/components/UITextInput.vue";
import UIListBox from "@/components/UIListBox.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
//import { TipoTelefonoView } from "@/types/tipostelefono/TipoTelefonoView";

export default defineComponent({
  name: "telefonoEdit",
  components: {
    UITextInput,
    UIListBox,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number
    },
    descripcion: {
      type: String,
      required: false
    },
    telefono: {
      type: String,
      required: false
    },
    tipoTelefono: {
      type: Number,
      required: false
    },
    tiposTelefono: {
      type: Array,
      required: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:telefono",
    "update:tipoTelefono",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const myDescripcion = ref(props.descripcion);
    const myTelefono = ref(props.telefono);
    const myTipoTelefono = ref(props.tipoTelefono);

    watch(
      [myDescripcion, myTelefono, myTipoTelefono],
      ([myDescripcion, myEmail, myTipoTelefono]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:telefono", myEmail);
        emit("update:tipoTelefono", myTipoTelefono);
      }
    );

    function tiposTelefonosPosibles() {
      const myTiposTelefono: unknown[] = [];
      myTiposTelefono.push({ id: null, descripcion: "" });
      if (props.tiposTelefono != undefined) {
        myTiposTelefono.push(...props.tiposTelefono);
      }
      return myTiposTelefono;
    }

    return {
      myDescripcion,
      myTelefono,
      myTipoTelefono,
      tiposTelefonosPosibles
    };
  }
});
