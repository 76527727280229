<template>
<!--  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6 sm:col-span-6 lg:col-span-4"> -->
      <div class="w-full relative">
        <input
          v-show="false"
          type="text"
          :value="idCodigoPostal"
          @input="$emit('update:idCodigoPostal', $event.target.value)"
        />
        <div
          class="flex justify-between w-full shadow-sm sm:text-sm border-gray-300 rounded-md border pt-2 pl-3 pr-2 pb-2"
        >
          <div>
            <!--  <span class="overflow-hidden w-full h-6">{{
                valueShowed()
              }}</span> -->

            <span
              @click.prevent="showSearch"
              v-if="!isSearching"
              class="overflow-hidden w-full h-6"
              >{{ valueShowed() }}</span
            >
            <span
              @click.prevent="showSearch"
              v-if="isSearching"
              class="overflow-hidden w-full h-6"
              >Eliga una opción</span
            >
          </div>
          <div class="flex">
            <svg
              class="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click="clearValue"
              v-if="idCodigoPostal != null"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click.prevent="showSearch"
              v-if="!isSearching"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click.prevent="showSearch"
              v-if="isSearching"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              ></path>
            </svg>
          </div>
        </div>
        <template v-if="isSearching">
          <div class="z-10 bg-white w-full">
            <div>
              <input
                type="text"
                v-model="filter"
                ref="myinput"
                placeholder="Escriba al menos, 2 caracteres o números"
              />
            </div>
            <div>
              <ul
                class="h-36 overflow-y-scroll border-gray-300 border-b border-l border-r"
              >
                <li
                  class="border-gray-300 border-b py-1 px-2 hover:bg-gray-200 cursor-pointer sm:text-sm"
                  v-for="pob in search(filter)"
                  :key="pob.id"
                  :value="pob.id"
                  @click="selectItem(pob.id)"
                >
                  {{ pob.codigo }} {{ pob.poblacion }}
                </li>
              </ul>
            </div>
          </div>
        </template>
  <!--    </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";
// import UITextInput from "@/components/UITextInput.vue";

export default defineComponent({
  name: "selectCodigoPostal",
  components: {},
  props: {
    items: {
      type: Array,
      required: false
    },
    idCodigoPostal: {
      type: Number,
      required: false
    }
  },

  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): CodigoPostalPoblacionView[] {
      //console.log("search - " + filter);
      if (filter == undefined) return [];
      if (filter.length < 2) return [];
      const filterOk = filter.toUpperCase();
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      return poblaciones.filter(
        item =>
          item.poblacion.indexOf(filterOk) > -1 ||
          (item.codigo != null ? item.codigo.indexOf(filterOk) > -1 : false)
      );
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      console.log("selectItem - id = " + id);
      if (id != -1) {
        emit("update:idCodigoPostal", id);
        //console.log("selectItem - props.idCodigoPostal= " + props.idCodigoPostal);
      }
    }

    function clearValue() {
      //console.log("clear");
      emit("update:idCodigoPostal", null);
    }

    function valueShowed(): string {
      //console.log("valueShowed - props.idCodigoPostal= " + props.idCodigoPostal);
      if (props.idCodigoPostal == 0) return "no hay poblacion seleccionada";
      if (props.idCodigoPostal == undefined) return "seleccione una poblacion";
      const items = props.items as CodigoPostalPoblacionView[];
      const resultado = items.find(x => x.id == props.idCodigoPostal);
      if (resultado == undefined) return "Cargando ...";
      return resultado.codigo + " " + resultado.poblacion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    return {
      showSearch,
      isSearching,
      filter,
      myinput,
      search,
      valueShowed,
      selectItem,
      clearValue
    };
  }
});
</script>
