// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidObject(item: any, notNullFields: string[]): boolean {
  let valid = true;
  notNullFields.forEach(field => {
    if (
      item[field] === null ||
      item[field] === undefined ||
      item[field] === ""
    ) {
      valid = false;
    }
  });
  return valid;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidArrayOfObjects(items: any[], NotNullfields: string[]) {
  let valid = true;
  if (items == null || items == undefined) return valid;
  items.forEach(item => {
    valid = valid && isValidObject(item, NotNullfields);
  });
  return valid;
}

export { isValidObject, isValidArrayOfObjects };
