
import { defineComponent, ref, watchEffect, computed } from "vue";
import { ReceptorFacturaView } from "@/types/facturas/ReceptorFacturaView";

export default defineComponent({
  name: "selectReceptorFactura",
  props: {
    modelValue: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    labelValue: {
      type: String,
      default: "Receptor"
    },
    required: {
      type: Boolean,
      default: false
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isSearching = ref(false);
    const filter = ref("");
    const myinput = ref(null);

    function showSearch() {
      filter.value = "";
      isSearching.value = !isSearching.value;
    }

    function search(filter: string): ReceptorFacturaView[] {
      const items = props.items as ReceptorFacturaView[];
      const filterOk = filter.toUpperCase();
      //console.log(filterOk);
      if (filter.length < 2) return [];
      return items.filter(item =>
        item.nombre != null ? item.nombre.toUpperCase().indexOf(filterOk) > -1 : false
      );
    }

    function selectItem(id: number) {
      isSearching.value = !isSearching.value;
      if (id != -1) {
        emit("update:modelValue", id);
      }
    }

    function clearValue() {
      // console.log("clear");
      emit("update:modelValue", null);
    }

    function valueShowed(): string {
      //console.log("ValueShowed");
      if (props.modelValue == undefined) return "seleccione un receptor";
      const items = props.items as ReceptorFacturaView[];
      //console.log("props.modelValue="+props.modelValue);
      //console.log(items);
      const resultado = items.find(x => x.id == props.modelValue);
      if (resultado == undefined) return "Cargando ...";
      return resultado.nombre;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    const showError = computed(() => {
      return (
        props.required &&
        props.showRequired &&
        (props.modelValue == null || props.modelValue == undefined)
      );
    });

    return {
      isSearching,
      filter,
      myinput,
      showSearch,
      search,
      valueShowed,
      selectItem,
      clearValue,
      showError
    };
  }
});
