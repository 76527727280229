import { computed, onMounted, ref, Ref } from "vue";
import { apiFacturas } from "@/services/facturas/api";
import { SerieFactura } from "@/types/facturas/SerieFactura";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useSeriesFacturas() {
  const idNotEditItem = -999;
  const items: Ref<SerieFactura[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as SerieFactura);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiFacturas.getSeriesFactura();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      descripcion: found.descripcion,
      abreviatura: found.abreviatura
    };
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as SerieFactura);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiFacturas
      .deleteSerieFactura(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as SerieFactura;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiFacturas
      .saveSerieFactura(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id === 0) found.id = id;
    found.descripcion = editingItem.value.descripcion;
    found.abreviatura = editingItem.value.abreviatura;
    editingItem.value = { id: idNotEditItem } as SerieFactura;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: SerieFactura): boolean {
    return isValidObject(item, ["descripcion", "abreviatura"]);
  }

  return {
    seriesFacturas: items,
    seriesFacturasShowed: itemsShowed,
    serieFacturaEdit: editItem,
    serieFacturaAdd: addItem,
    serieFacturaDelete: deleteItem,
    serieFacturaCancel: cancelEdit,
    serieFacturaSave: saveEdit,
    serieFacturaInEdition: editingItem,
    seriesFacturasGotoNextPage: nextPage,
    seriesFacturasGoToPreviousPage: previousPage,
    serieFacturaIsEditing: isEditing,
    seriesFacturasIsLoading: isLoading,
    seriesFacturasIsFirstPage: isFirstPage,
    seriesFacturasIsLastPage: isLastPage,
    seriesFacturaSuccess: success,
    seriesFacturaError: error,
    seriesFacturaErrorText: errorText,
    seriesFacturaIsValid: isValid
  };
}
