<template>
  <div class="pt-10">
    <div class="lg:flex lg:items-center lg:justify-between">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-2"
        >
          Receptores de Factura
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>
      </div>
    </div>
    <filterEdit
      @cancel="showFilter"
      @save="receptoresFacturaSearch()"
      @reset="receptoresFacturaResetFilter"
      v-if="isFiltering"
    >
      <template v-slot>
        <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2">
          <div>
            <UITextInput
              v-model="receptoresFacturaFilter.nombre"
              label="Nombre"
            />
          </div>
        </div>
      </template>
    </filterEdit>
    <datatable
      :headers="headers"
      :numbersRows="receptoresFactura.length"
      :isEditing="receptorFacturaIsEditing"
      :isLoading="receptoresFacturaIsLoading"
      :isFirstPage="receptoresFacturaIsFirstPage"
      :isLastPage="receptoresFacturaIsLastPage"
      :items="receptoresFactura"
      @newitem="receptorFacturaAdd"
      @next="receptoresFacturaGotoNextPage"
      @previous="receptoresFacturaGoToPreviousPage"
    >
      <template
        v-for="receptorFactura in receptoresFacturaShowed"
        :key="receptorFactura.id"
        class="border-b border-gray-200"
      >
        <tr v-if="receptorFactura.id != receptorFacturaInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ receptorFactura.nif }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ receptorFactura.nombre }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ receptorFactura.codigoPostal }} {{ receptorFactura.poblacion }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ receptorFactura.telefono }}
          </td>
          <datatablerowbuttons
            @edititem="receptorFacturaEdit"
            @deleteitem="receptorFacturaDelete"
            :id="receptorFactura.id"
            :isEditing="receptorFacturaIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="receptorFacturaPreviousCancel"
              @save="receptorFacturaSubmit"
              :waitingForSave="receptorFacturaWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Receptor de factura
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <p class="mb-5 mt-5 text-sm text-gray-600">
                  Puede ir a los siguientes datos
                </p>
                <p
                  class="cursor-pointer  flex justify-between items-center mt-1 mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'facturas',
                          params: {
                            idFactura: 0,
                            Editar: 0,
                            idReceptor: receptorFacturaInEdition.id
                          }
                        }"
                        target="_blank"
                      >
                        Facturas del receptor
                      </router-link></span
                    ></span
                  >
                </p>
              </template>
              <template v-slot:default>
                <div class="grid grid-cols-12 gap-2">
                  <div class="col-span-3 sm:col-span-3">
                    <UITextInput
                      v-model="receptorFacturaInEdition.nif"
                      label="NIF"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="20"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-9">
                    <UITextInput
                      v-model="receptorFacturaInEdition.nombre"
                      label="Nombre"
                      :required="true"
                      :showRequired="showValidate"
                      :maxLength="50"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-2">
                  <div class="col-span-6 sm:col-span-6">
                    <UITextInput
                      v-model="receptorFacturaInEdition.domicilio"
                      label="Dirección"
                      :maxLength="50"
                    />
                  </div>
                  <div class="col-span-6 sm:col-span-6">
                    <label>
                      Población
                    </label>
                    <selectCodigoPostal
                      v-model:idCodigoPostal="
                        receptorFacturaInEdition.idCodigoPostal
                      "
                      :items="codigosPostalesPoblaciones"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-2">
                  <div class="col-span-6 sm:col-span-6">
                    <UITextInput
                      v-model="receptorFacturaInEdition.telefono"
                      label="Telefono"
                      :maxLength="20"
                    />
                  </div>
                </div>
                <div class="col-span-3 sm:col-span-3">
                  <UITextInput
                    v-model="receptorFacturaInEdition.observaciones"
                    label="Observaciones"
                    :maxLength="4000"
                  />
                </div>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { useReceptoresFactura } from "./useReceptoresFactura";
import SelectCodigoPostal from "@/components/selectCodigoPostal.vue";
import { useCodigosPostalesPoblaciones } from "../utilidades/useCodigosPostalesPoblaciones";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "ReceptoresFactura",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    filterEdit,
    SelectCodigoPostal
  },
  setup() {
    const headers = ["NIF", "Nombre", "Población", "Teléfono"];
    const {
      receptoresFactura,
      receptoresFacturaShowed,
      receptoresFacturaFilter,
      receptoresFacturaResetFilter,
      receptoresFacturaSearch,
      receptorFacturaEdit,
      receptorFacturaAdd,
      receptorFacturaDelete,
      receptorFacturaCancel,
      receptorFacturaSave,
      receptorFacturaInEdition,
      receptoresFacturaGotoNextPage,
      receptoresFacturaGoToPreviousPage,
      receptorFacturaIsEditing,
      receptoresFacturaIsLoading,
      receptoresFacturaIsFirstPage,
      receptoresFacturaIsLastPage,
      receptorFacturaIsValid,
      receptorFacturaSuccess,
      receptorFacturaError,
      receptorFacturaErrorText
    } = useReceptoresFactura();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();

    const isFiltering = ref(false);
    const receptorFacturaWaitingForSave = ref(false);
    const showValidate = ref(false);

    receptoresFacturaResetFilter();

    function receptorFacturaSubmit() {
      //receptorFacturaSave();
      showValidate.value = !receptorFacturaIsValid(
        receptorFacturaInEdition.value
      );
      if (showValidate.value) return;
      receptorFacturaWaitingForSave.value = true;
      receptorFacturaSave().then(() => {
        receptorFacturaWaitingForSave.value = false;
      });
    }

    function receptorFacturaPreviousCancel() {
      showValidate.value = false;
      receptorFacturaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    watch(
      [receptorFacturaError, receptorFacturaSuccess],
      ([receptorFacturaError, receptorFacturaSuccess]) => {
        if (receptorFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (receptorFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: receptorFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      receptoresFactura,
      receptoresFacturaShowed,
      receptoresFacturaFilter,
      receptoresFacturaResetFilter,
      receptoresFacturaSearch,
      receptorFacturaEdit,
      receptorFacturaAdd,
      receptorFacturaDelete,
      receptorFacturaPreviousCancel,
      receptorFacturaSave,
      receptorFacturaInEdition,
      receptoresFacturaGotoNextPage,
      receptoresFacturaGoToPreviousPage,
      receptorFacturaIsEditing,
      receptoresFacturaIsLoading,
      receptoresFacturaIsFirstPage,
      receptoresFacturaIsLastPage,
      receptorFacturaSubmit,
      receptorFacturaWaitingForSave,

      showFilter,
      isFiltering,
      showValidate,

      codigosPostalesPoblaciones
    };
  }
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }
  td:nth-of-type(1):before {
    content: "NIF";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Nombre";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Población";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Teléfono";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
