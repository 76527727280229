import {
  ColeccionView,
  ColeccionFilter,
} from "@/types/colecciones/ColeccionView";
import { Coleccion } from "@/types/colecciones/Coleccion";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiColeccionesGraphics = {
  // Tipos facturas
  getColeccionesGraphic(filter: ColeccionFilter): Promise<ColeccionView[]> {
    filter = cleanFilter(filter);
    return new Promise((resolve, reject) => {
      const url = "ColeccionesGraphics/graph";
      axios
        .post(url, filter, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Grafico.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((response) => {
          reject(response.status);
        });
    });
  },
};
