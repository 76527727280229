
import { defineComponent, ref, watch } from "vue";

import { useTiposFacturas } from "./useTiposFacturas";
import { useSeriesFacturas } from "./useSeriesFacturas";
import { useFormasPago } from "./useFormasPago";

import rowEdit from "@/components/rowEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";

import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UIListBox from "@/components/UIListBox.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "ConfigFacturas",
  components: {
    UITextInput,
    UICheckBox,
    UIListBox,
    rowEdit,
    datatable,
    datatablerowbuttons
  },
  setup() {
    const headersTipos = [
      "Descripción",
      "Abreviatura",
      "Serie",
      "Modelo extendido"
    ];
    const headersSeries = ["Descripción", "Abreviatura"];
    const headersFormas = ["Descripción"];

    const {
      tiposFacturas,
      tiposFacturasShowed,
      tipoFacturaEdit,
      tipoFacturaAdd,
      tipoFacturaDelete,
      tipoFacturaCancel,
      tipoFacturaSave,
      tipoFacturaInEdition,
      tiposFacturasGotoNextPage,
      tiposFacturasGoToPreviousPage,
      tipoFacturaIsEditing,
      tiposFacturasIsLoading,
      tiposFacturasIsFirstPage,
      tiposFacturasIsLastPage,
      tiposFacturasUpdateSerie,
      tiposFacturaSuccess,
      tiposFacturaError,
      tiposFacturaErrorText,
      tiposFacturaIsValid
    } = useTiposFacturas();

    const {
      seriesFacturas,
      seriesFacturasShowed,
      serieFacturaEdit,
      serieFacturaAdd,
      serieFacturaDelete,
      serieFacturaCancel,
      serieFacturaSave,
      serieFacturaInEdition,
      seriesFacturasGotoNextPage,
      seriesFacturasGoToPreviousPage,
      serieFacturaIsEditing,
      seriesFacturasIsLoading,
      seriesFacturasIsFirstPage,
      seriesFacturasIsLastPage,
      seriesFacturaSuccess,
      seriesFacturaError,
      seriesFacturaErrorText,
      seriesFacturaIsValid
    } = useSeriesFacturas();

    const {
      formasPagos,
      formasPagosShowed,
      formaPagoEdit,
      formaPagoAdd,
      formaPagoDelete,
      formaPagoCancel,
      formaPagoSave,
      formaPagoInEdition,
      formasPagosGotoNextPage,
      formasPagosGoToPreviousPage,
      formaPagoIsEditing,
      formasPagosIsLoading,
      formasPagosIsFirstPage,
      formasPagosIsLastPage,
      formasPagoSuccess,
      formasPagoError,
      formasPagoErrorText,
      formasPagoIsValid
    } = useFormasPago();

    const showValidateTipoFactura = ref(false);
    const tiposFacturaWaitingForSave = ref(false);

    const showValidateSerieFactura = ref(false);
    const seriesFacturaWaitingForSave = ref(false);

    const showValidateFormasPago = ref(false);
    const formasPagoWaitingForSave = ref(false);

    function tipoFacturaSubmit() {
      showValidateTipoFactura.value = !tiposFacturaIsValid(
        tipoFacturaInEdition.value
      );
      //console.log("showValidateTipoFactura.value="+showValidateTipoFactura.value);
      if (showValidateTipoFactura.value) return;
      tiposFacturaWaitingForSave.value = true;
      tipoFacturaSave().then(() => {
        tiposFacturaWaitingForSave.value = false;
      });
    }

    function seriesFacturasSubmit() {
      showValidateSerieFactura.value = !seriesFacturaIsValid(
        serieFacturaInEdition.value
      );
      if (showValidateSerieFactura.value) return;
      seriesFacturaWaitingForSave.value = true;
      serieFacturaSave().then(() => {
        tiposFacturasUpdateSerie(serieFacturaInEdition.value);
        formasPagoWaitingForSave.value = false;
      });
    }

    function formasPagosSubmit() {
      showValidateFormasPago.value = !formasPagoIsValid(
        formaPagoInEdition.value
      );
      if (showValidateFormasPago.value) return;
      formasPagoWaitingForSave.value = true;
      formaPagoSave().then(() => {
        formasPagoWaitingForSave.value = false;
      });
    }

    function tiposFacturaPreviousCancel() {
      showValidateTipoFactura.value = false;
      tipoFacturaCancel();
    }

    function seriesFacturaPreviousCancel() {
      showValidateSerieFactura.value = false;
      serieFacturaCancel();
    }

    function formasPagoPreviousCancel() {
      showValidateFormasPago.value = false;
      formaPagoCancel();
    }

    watch(
      [tiposFacturaError, tiposFacturaSuccess],
      ([tiposFacturaError, tiposFacturaSuccess]) => {
        if (tiposFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (tiposFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: tiposFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [seriesFacturaError, seriesFacturaSuccess],
      ([seriesFacturaError, seriesFacturaSuccess]) => {
        if (seriesFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (seriesFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: seriesFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    watch(
      [formasPagoError, formasPagoSuccess],
      ([formasPagoError, formasPagoSuccess]) => {
        if (formasPagoSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (formasPagoError) {
          createToast(
            {
              title: "Error 👎 ",
              description: formasPagoErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      // Tipos de factura
      headersTipos,
      tiposFacturas,
      tiposFacturasShowed,
      tipoFacturaEdit,
      tipoFacturaAdd,
      tipoFacturaDelete,
      tiposFacturaPreviousCancel,
      tipoFacturaSave,
      tipoFacturaInEdition,
      tiposFacturasGotoNextPage,
      tiposFacturasGoToPreviousPage,
      tipoFacturaIsEditing,
      tiposFacturasIsLoading,
      tiposFacturasIsFirstPage,
      tiposFacturasIsLastPage,
      tiposFacturasUpdateSerie,
      tipoFacturaSubmit,
      showValidateTipoFactura,
      tiposFacturaWaitingForSave,

      //Series factura
      headersSeries,
      seriesFacturas,
      seriesFacturasShowed,
      serieFacturaEdit,
      serieFacturaAdd,
      serieFacturaDelete,
      seriesFacturaPreviousCancel,
      serieFacturaSave,
      serieFacturaInEdition,
      seriesFacturasGotoNextPage,
      seriesFacturasGoToPreviousPage,
      serieFacturaIsEditing,
      seriesFacturasIsLoading,
      seriesFacturasIsFirstPage,
      seriesFacturasIsLastPage,
      seriesFacturasSubmit,
      showValidateSerieFactura,
      seriesFacturaWaitingForSave,

      //fornas de pago
      headersFormas,
      formasPagos,
      formasPagosShowed,
      formaPagoEdit,
      formaPagoAdd,
      formaPagoDelete,
      formasPagoPreviousCancel,
      formaPagoSave,
      formaPagoInEdition,
      formasPagosGotoNextPage,
      formasPagosGoToPreviousPage,
      formaPagoIsEditing,
      formasPagosIsLoading,
      formasPagosIsFirstPage,
      formasPagosIsLastPage,
      formasPagosSubmit,
      showValidateFormasPago,
      formasPagoWaitingForSave
    };
  }
});
