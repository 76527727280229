import { computed, onMounted, ref, Ref } from "vue";
import { apiTemporadas } from "@/services/temporadas/api";
import { TipoTemporada } from "@/types/temporadas/TipoTemporada";
import { TipoTemporadaFilter } from "@/types/temporadas/TipoTemporadaView";
import { usePagination } from "@/components/usePagination";

import { isValidObject } from "../utilidades/useValidations";

export function useTipoTemporada() {
  const idNotEditItem = -999;
  const items: Ref<TipoTemporada[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as TipoTemporada);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");
  const filter = ref({} as TipoTemporadaFilter);

  async function filterItems() {
    isLoading.value = true;
    localStorage.setItem("tipotemporadaFilter", JSON.stringify(filter.value));
    items.value = await apiTemporadas.getTiposTemporada(filter.value);
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;
  }

  onMounted(async () => {
    filterItems();
    /*isLoading.value = true;
    items.value = await apiTemporadas.getTiposTemporada();
    setTotalNumberOfRows(items.value.length);
    isLoading.value = false;*/
  });

  async function editItem(id: number) {
    /*const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      descripcion: found.descripcion,
      abreviatura: found.abreviatura
    };*/
    if (id != 0) editingItem.value = await apiTemporadas.getTipoTemporada(id);
    else {
      editingItem.value = {
        id: 0
      } as TipoTemporada;
    }
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, { id: 0 } as TipoTemporada);
    setTotalNumberOfRows(items.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiTemporadas
      .deleteTipoTemporada(id)
      .then(() => {
        success.value = true;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(items.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as TipoTemporada;
    setTotalNumberOfRows(items.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;

    let id = 0;

    await apiTemporadas
      .saveTipoTemporada(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const index = items.value.findIndex(
      item => item.id === editingItem.value.id
    );
    if (index == -1) return;

    const tipoTemporadaUpdated = await apiTemporadas.getTipoTemporada(id);
    items.value[index] = tipoTemporadaUpdated;
    editingItem.value = { id: idNotEditItem } as TipoTemporada;

    /*const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (editingItem.value.id == 0) found.id = id;
    found.descripcion = editingItem.value.descripcion;
    found.abreviatura = editingItem.value.abreviatura;
    editingItem.value = { id: idNotEditItem } as TipoTemporada;*/
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    //console.log(`currentRow is ${currentRow.value}`);
    return items.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  async function resetFilter() {
    filter.value = {
      inexistenteSN: "N"
    } as TipoTemporadaFilter;
  }

  function isValid(item: TipoTemporada): boolean {
    return isValidObject(item, ["descripcion", "abreviatura"]);
  }

  return {
    tiposTemporada: items,
    tiposTemporadaShowed: itemsShowed,
    tiposTemporadaFilter: filter,
    tiposTemporadaSearch: filterItems,
    tiposTemporadaResetFilter: resetFilter,
    tipoTemporadaEdit: editItem,
    tipoTemporadaAdd: addItem,
    tipoTemporadaDelete: deleteItem,
    tipoTemporadaCancel: cancelEdit,
    tiposTemporadaave: saveEdit,
    tipoTemporadaInEdition: editingItem,
    tiposTemporadaGotoNextPage: nextPage,
    tiposTemporadaGoToPreviousPage: previousPage,
    tipoTemporadaIsEditing: isEditing,
    tiposTemporadaIsLoading: isLoading,
    tiposTemporadaIsFirstPage: isFirstPage,
    tiposTemporadaIsLastPage: isLastPage,
    tipoTemporadaSuccess: success,
    tipoTemporadaError: error,
    tipoTemporadaErrorText: errorText,
    tipoTemporadaIsValid: isValid
  };
}
