import { ComisionView, ComisionFilter } from "@/types/comisiones/ComisionView";
import { Comision } from "@/types/comisiones/Comision";
import axios from "../apiService";
import { cleanFilter } from "../apiUtility";

export const apiComisiones = {
  getComisionesView(filter: ComisionFilter): Promise<ComisionView[]> {
    filter = cleanFilter(filter);
    return new Promise(resolve => {
      const url = "ComisionView/filter";
      axios
        .post<ComisionView[]>(url, filter)
        .then(response => resolve(response.data));
    });
  },
  getComisionView(id: number): Promise<ComisionView> {
    return new Promise(resolve => {
      const url = "ComisionView/" + id;
      axios.get<ComisionView>(url).then(response => resolve(response.data));
    });
  },
  getComision(id: number): Promise<Comision> {
    return new Promise(resolve => {
      const url = "Comision/" + id;
      axios.get<Comision>(url).then(response => {
        resolve(response.data);
      });
    });
  },
  saveComision(tipo: Comision): Promise<Comision> {
    return new Promise((resolve, reject) => {
      const url = "Comision";
      axios
        .post(url, tipo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  deleteComision(id: number) {
    return new Promise((resolve, reject) => {
      const url = "Comision/" + id;
      axios
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};
