<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Comisiones
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>
       <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
          <router-link
              target="_blank"
              to="/Comisiones/ComisionesComisiones/"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Comisiones</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>
  <filterEdit
    @cancel="showFilter"
    @save="comisionesSearch()"
    @reset="comisionesResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-2">
        <div class="col-span-1 md:col-span-1">
          <UIDateInput
            label="Desde fecha"
            v-model="comisionesFilter.desdeFechaComision"
          />
        </div>
        <div class="col-span-1 md:col-span-1">
          <UIDateInput
            label="Hasta fecha"
            v-model="comisionesFilter.hastaFechaComision"
          />
        </div>
        <div class="col-span-1 md:col-span-2"></div>

        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            label="Numero Factura"
            v-model="comisionesFilter.numeroFactura"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <label for="pagadaSN" class="block text-sm font-medium text-gray-700">
            Factura Pagada
          </label>
          <select
            v-model="comisionesFilter.pagadaSN"
            id="pagadaSN"
            name="pagadaSN"
            autocomplete="country"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="T">Todos</option>
            <option value="S">Pagadas</option>
            <option value="N">No Pagadas</option>
          </select>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-2">
        <div class="col-span-1 sm:col-span-2">
          <selectFabricante
            v-model="comisionesFilter.idFabricante"
            :items="fabricantes"
          />
        </div>
        <div class="col-span-1 sm:col-span-2">
          <selectTipoTemporada
            v-model="comisionesFilter.idTipoTemporada"
            :items="tiposTemporada"
            labelValue="Tipo Temporada"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            label="Desde Año"
            v-model="comisionesFilter.desdeAnyo"
            @blur="comisionesFilter.hastaAnyo = comisionesFilter.desdeAnyo"
          />
        </div>
        <div class="col-span-1 sm:col-span-1">
          <UINumberInput
            label="Hasta Año"
            v-model="comisionesFilter.hastaAnyo"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :numbersRows="comisiones.length"
      :isEditing="comisionIsEditing"
      :isLoading="comisionesIsLoading"
      :isFirstPage="comisionesIsFirstPage"
      :isLastPage="comisionesIsLastPage"
      :items="comisiones"
      @newitem="comisionAdd"
      @next="comisionesGotoNextPage"
      @previous="comisionesGoToPreviousPage"
    >
      <template
        v-for="comision in comisionesShowed"
        :key="comision.id"
        class="border-b border-gray-200"
      >
        <tr v-if="comision.id != comisionInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ comision.fecha }}
          </td>
          <td class="px-6 py-4  text-sm text-gray-500">
            {{ comision.fabricante }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ comision.marca }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ comision.tipoTemporada }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ comision.anyo }}
          </td>
          <td
            class="sm:text-right px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {{ comision.importe }}
          </td>
          <td
            class="sm:text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {{ comision.numeroFactura }}
          </td>
          <datatablerowbuttons
            @edititem="comisionEdit"
            @deleteitem="comisionDelete"
            :id="comision.id"
            :isEditing="comisionIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="comisionCancel"
              @save="comisionPreviousSave"
              :waitingForSave="comisionWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Comisión
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <p class="mb-2 pt-10 text-sm text-gray-600">
                  Puede realizar las siguientes acciones
                </p>
                <p
                  class="cursor-pointer flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span v-if="comisionInEdition.id != 0" class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span v-if="comisionInEdition.idFactura == undefined"
                      ><a @click="createFactura()">
                        Crear factura de comisiones
                      </a></span
                    >
                    <span v-if="comisionInEdition.idFactura != undefined">
                      <router-link
                        :to="{
                          name: 'facturas',
                          params: {
                            idFactura: comisionInEdition.idFactura,
                            Editar: 1
                          }
                        }"
                        target="_blank"
                      >
                        Consultar factura de comisiones
                      </router-link>
                    </span>
                  </span>
                </p>
              </template>
              <template v-slot:default>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <selectFabricante
                      v-model="comisionInEdition.idFabricante"
                      :items="fabricantes"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                  <div class="col-span-6">
                    <selectMarca
                      v-model="comisionInEdition.idMarca"
                      :items="marcas"
                    />
                  </div>
                </div>
                <div class="grid-cols-6 gap-6 flex justify-between">
                  <div class="col-span-6 sm:col-span-4">
                    <selectTipoTemporada
                      v-model="comisionInEdition.idTipoTemporada"
                      :items="tiposTemporada"
                      labelValue="Tipo Temporada"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                  <div class="col-span-2 sm:col-span-1">
                    <UINumberInput
                      v-model="comisionInEdition.anyo"
                      label="Año"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                </div>
                <div class="grid-cols-6 gap-6 flex justify-between">
                  <div class="col-span-3 sm:col-span-2">
                    <UIDateInput
                      v-model="comisionInEdition.fecha"
                      label="Fecha"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <UINumberInput
                      v-model="comisionInEdition.importe"
                      label="Importe"
                      :required="true"
                      :showRequired="showValidate"
                    />
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-4">
                  <div class="col-span-6 sm:col-span-4">
                    <selectFactura
                      v-model="comisionInEdition.idFactura"
                      :items="facturas"
                    />
                  </div>
                </div>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { RouteLocationRaw, Router } from "vue-router";

import { useComisiones } from "@/views/comisiones/useComisiones";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useMarcas } from "../utilidades/useMarcas";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFacturas } from "../facturas/useFacturas";

import rowEdit from "@/components/rowEdit.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectFactura from "@/components/selectFactura.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "Comisiones",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UIDateInput,
    UINumberInput,
    filterEdit,
    selectFabricante,
    selectMarca,
    selectFactura,
    selectTipoTemporada
  },
  setup() {
    const headers = [
      "Fecha",
      "Fabricante",
      "Marca",
      "Tipo Temporada",
      "Año",
      "Importe",
      "Número Factura"
    ];
    const {
      comisiones,
      comisionesShowed,
      comisionesFilter,
      comisionesResetFilter,
      comisionesSearch,
      comisionEdit,
      comisionAdd,
      comisionDelete,
      comisionCancel,
      comisionSave,
      comisionInEdition,
      comisionesGotoNextPage,
      comisionesGoToPreviousPage,
      comisionIsEditing,
      comisionesIsLoading,
      comisionesIsFirstPage,
      comisionesIsLastPage,
      comisionSuccess,
      comisionError,
      comisionErrorText,
      comisionIsValid,
      comisionCreateFactura,
      comisionFacturaCreada
    } = useComisiones();

    const { marcas } = useMarcas();
    const { fabricantes } = useFabricantes();
    const { tiposTemporada } = useTipoTemporada();
    const { facturas, facturasSearch } = useFacturas();

    const isFiltering = ref(false);

    const showListados = ref(false);

    const showValidate = ref(false);

    const comisionWaitingForSave = ref(false);

    /*function comisionSubmit() {
      comisionSave();
      if (showValidate.value) return;
    }*/

    function comisionPreviousSave() {
      showValidate.value = !comisionIsValid(comisionInEdition.value);
      if (showValidate.value) return;

      comisionWaitingForSave.value = true;
      comisionSave().then(() => {
        comisionWaitingForSave.value = false;
      });
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    function createFactura() {
      comisionCreateFactura();
    }

    watch([comisionFacturaCreada], ([facturaCreada]) => {
      if (facturaCreada) {
        facturasSearch();
        comisionFacturaCreada.value = false;
        window.open(
          "/app/facturas/" + comisionInEdition.value.idFactura + "/1",
          "_blank"
        );
      }
    });

    watch(
      [comisionError, comisionSuccess],
      ([comisionError, comisionSuccess]) => {
        if (comisionSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (comisionError) {
          createToast(
            {
              title: "Error 👎 ",
              description: comisionErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      comisiones,
      comisionesShowed,
      comisionesFilter,
      comisionesResetFilter,
      comisionesSearch,
      comisionEdit,
      comisionAdd,
      comisionDelete,
      comisionCancel,
      comisionSave,
      comisionInEdition,
      comisionesGotoNextPage,
      comisionesGoToPreviousPage,
      comisionIsEditing,
      comisionesIsLoading,
      comisionesIsFirstPage,
      comisionesIsLastPage,
      comisionPreviousSave,
      comisionWaitingForSave,

      showFilter,
      showListados,
      isFiltering,
      showValidate,

      marcas,
      fabricantes,
      tiposTemporada,
      facturas,
      createFactura
    };
  }
});
</script>
<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }
  td:nth-of-type(1):before {
    content: "Fecha";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(2):before {
    content: "Fabricante";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Marca";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Tipo Temp.:";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "Año:";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(6):before {
    content: "Importe:";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(7):before {
    content: "N. Fra.:";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  .no-columns {
    padding-left: 0;
  }
}
</style>
