
import { defineComponent, ref, watch } from "vue";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { useReceptoresFactura } from "./useReceptoresFactura";
import SelectCodigoPostal from "@/components/selectCodigoPostal.vue";
import { useCodigosPostalesPoblaciones } from "../utilidades/useCodigosPostalesPoblaciones";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default defineComponent({
  name: "ReceptoresFactura",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    filterEdit,
    SelectCodigoPostal
  },
  setup() {
    const headers = ["NIF", "Nombre", "Población", "Teléfono"];
    const {
      receptoresFactura,
      receptoresFacturaShowed,
      receptoresFacturaFilter,
      receptoresFacturaResetFilter,
      receptoresFacturaSearch,
      receptorFacturaEdit,
      receptorFacturaAdd,
      receptorFacturaDelete,
      receptorFacturaCancel,
      receptorFacturaSave,
      receptorFacturaInEdition,
      receptoresFacturaGotoNextPage,
      receptoresFacturaGoToPreviousPage,
      receptorFacturaIsEditing,
      receptoresFacturaIsLoading,
      receptoresFacturaIsFirstPage,
      receptoresFacturaIsLastPage,
      receptorFacturaIsValid,
      receptorFacturaSuccess,
      receptorFacturaError,
      receptorFacturaErrorText
    } = useReceptoresFactura();

    const { codigosPostalesPoblaciones } = useCodigosPostalesPoblaciones();

    const isFiltering = ref(false);
    const receptorFacturaWaitingForSave = ref(false);
    const showValidate = ref(false);

    receptoresFacturaResetFilter();

    function receptorFacturaSubmit() {
      //receptorFacturaSave();
      showValidate.value = !receptorFacturaIsValid(
        receptorFacturaInEdition.value
      );
      if (showValidate.value) return;
      receptorFacturaWaitingForSave.value = true;
      receptorFacturaSave().then(() => {
        receptorFacturaWaitingForSave.value = false;
      });
    }

    function receptorFacturaPreviousCancel() {
      showValidate.value = false;
      receptorFacturaCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    watch(
      [receptorFacturaError, receptorFacturaSuccess],
      ([receptorFacturaError, receptorFacturaSuccess]) => {
        if (receptorFacturaSuccess) {
          createToast(
            {
              title: "Éxito 👏 ",
              description: "La operación se ha completado con éxito"
            },
            {
              transition: "bounce",
              type: "success",
              position: "top-center",
              hideProgressBar: true,
              toastBackgroundColor: "#048c5a"
            }
          );
        }

        if (receptorFacturaError) {
          createToast(
            {
              title: "Error 👎 ",
              description: receptorFacturaErrorText.value
            },
            {
              transition: "bounce",
              type: "danger",
              position: "top-center",
              hideProgressBar: true
            }
          );
        }
      }
    );

    return {
      headers,
      receptoresFactura,
      receptoresFacturaShowed,
      receptoresFacturaFilter,
      receptoresFacturaResetFilter,
      receptoresFacturaSearch,
      receptorFacturaEdit,
      receptorFacturaAdd,
      receptorFacturaDelete,
      receptorFacturaPreviousCancel,
      receptorFacturaSave,
      receptorFacturaInEdition,
      receptoresFacturaGotoNextPage,
      receptoresFacturaGoToPreviousPage,
      receptorFacturaIsEditing,
      receptoresFacturaIsLoading,
      receptoresFacturaIsFirstPage,
      receptoresFacturaIsLastPage,
      receptorFacturaSubmit,
      receptorFacturaWaitingForSave,

      showFilter,
      isFiltering,
      showValidate,

      codigosPostalesPoblaciones
    };
  }
});
