<template>
  <div class="pt-10 mb-4">
    <div class="lg:flex lg:items-center lg:justify-between pl-2 sm:pl-0">
      <div class="flex-1 min-w-0 ">
        <h2
          class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
        >
          Pedidos
        </h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="showFilter"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            filtros
          </button>
        </span>

        <span
          x-data="{ open: false }"
          @keydown.escape.stop="open = false"
          @click="showListados = !showListados"
          class="ml-3 relative"
        >
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="mobile-menu"
            @click="open = !open"
            aria-haspopup="true"
            x-bind:aria-expanded="open"
            aria-expanded="true"
          >
            <svg
              class="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Listados
            <svg
              class="-mr-1 ml-2 h-5 w-5 text-gray-500"
              x-description="Heroicon name: solid/chevron-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            v-show="showListados"
            x-description="Dropdown menu, show/hide based on menu state."
            x-show="false"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class=" z-10 origin-top-right absolute right-0 mt-2 -mr-1 w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="mobile-menu"
          >
            <router-link
              target="_blank"
              to="/Pedidos/PedidosConfirmadosVsServidos/no"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos Confirmados vs Servidos</router-link
            >
            <router-link
              target="_blank"
              to="/Pedidos/PedidosConfirmadosVsServidos/yes"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos Confirmados vs Servidos
              (detallado)</router-link
            >
            <router-link
              v-if="false"
              target="_blank"
              to="/Pedidos/PedidosFacturasPendientes"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos con Facturas Pendientes</router-link
            >
            <router-link
              target="_blank"
              to="/Pedidos/PedidosVendidoVsConfirmado/no"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos Vendido vs Confirmado</router-link
            >
            <router-link
              target="_blank"
              to="/Pedidos/PedidosVendidoVsConfirmado/yes"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos Vendido vs Confirmado (detallado)</router-link
            >
            <router-link
              target="_blank"
              to="/Pedidos/PedidosPrevisionComisiones"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Previsión de Comisiones</router-link
            >
            <router-link
              v-if="false"
              target="_blank"
              to="/Pedidos/PedidosPorColecciones"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Listado de Pedidos por Colecciones</router-link
            >
          </div>
        </span>
      </div>
    </div>
  </div>

  <filterEdit
    @cancel="showFilter"
    @save="pedidoPreviousSearch"
    @reset="pedidosResetFilter"
    v-if="isFiltering"
  >
    <template v-slot>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-1 md:col-span-2">
          <UITextInput label="Nº pedido" v-model="pedidosFilter.numeroPedido" />
        </div>
        <div class="col-span-1 md:col-span-4">
          <label class="block text-sm font-medium text-gray-700">
            Fecha de pedido (desde/hasta)
          </label>
          <div class="grid grid-cols-2 gap-2">
            <div class="col-span-1">
              <UIDateInput
                id="desdeFechaPedido"
                v-model="pedidosFilter.desdeFechaPedido"
              />
            </div>
            <div class="col-span-1">
              <UIDateInput
                id="hastaFechaPedido"
                v-model="pedidosFilter.hastaFechaPedido"
              />
            </div>
          </div>
        </div>
        <div class="col-span-1 md:col-span-3">
          <selectCliente v-model="pedidosFilter.idCliente" :items="clientes" />
        </div>
        <div class="col-span-1 md:col-span-3">
          <selectTienda v-model="pedidosFilter.idTienda" :items="tiendas" />
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-1 md:col-span-4">
          <selectColeccion
            :items="colecciones"
            v-model="pedidosFilter.idColeccion"
          ></selectColeccion>
        </div>
        <div class="col-span-1 md:col-span-2 sm:pt-9">
          <UICheckBox
            v-model="pedidosFilter.soloColeccionesActivas"
            label="solo Colecciones Activas"
          />
        </div>
        <div class="col-span-1 md:col-span-4">
          <label class="block text-sm font-medium text-gray-700">
            Comienzo Servicio Colección (desde/hasta)
          </label>
          <div class="grid grid-cols-2 gap-2">
            <div class="col-span-1">
              <UIDateInput
                id="desdeFechaComienzoServicioColeccion"
                v-model="pedidosFilter.desdeFechaComienzoServicioColeccion"
              />
            </div>
            <div class="col-span-1">
              <UIDateInput
                id="hastaFechaComienzoServicioColeccion"
                v-model="pedidosFilter.hastaFechaComienzoServicioColeccion"
              />
            </div>
          </div>
        </div>
        <div v-if="false" class="col-span-1 md:col-span-4">
          <label class="block text-sm font-medium text-gray-700">
            F. exp. No pagada cliente (desde-hasta)
          </label>
          <div class="grid grid-cols-2 gap-2">
            <div class="col-span-1">
              <UIDateInput
                id="desdeFechaExpedicionNoPagadaCliente"
                v-model="pedidosFilter.desdeFechaExpedicionNoPagadaCliente"
              />
            </div>
            <div class="col-span-1">
              <UIDateInput
                id="hastaFechaExpedicionNoPagadaCliente"
                v-model="pedidosFilter.hastaFechaExpedicionNoPagadaCliente"
              />
            </div>
          </div>
        </div>
        <div v-if="false" class="col-span-1 md:col-span-4">
          <label class="block text-sm font-medium text-gray-700">
            F. exp. No pagada pedido (desde-hasta)
          </label>
          <div class="grid grid-cols-2 gap-2">
            <div class="col-span-1">
              <UIDateInput
                id="desdeFechaExpedicionNoPagadaPedido"
                v-model="pedidosFilter.desdeFechaExpedicionNoPagadaPedido"
              />
            </div>
            <div class="col-span-1">
              <UIDateInput
                id="hastaFechaExpedicionNoPagadaPedido"
                v-model="pedidosFilter.hastaFechaExpedicionNoPagadaPedido"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-1 md:col-span-2">
          <selectTipoTemporada
            v-model="pedidosFilter.idTipoTemporada"
            :items="tiposTemporada"
            labelValue="Tipo de temporada"
          />
        </div>
        <div class="col-span-1">
          <UINumberInput label="Desde" v-model="pedidosFilter.desdeAnyo" @blur="pedidosFilter.hastaAnyo = pedidosFilter.desdeAnyo"/>
        </div>
        <div class="col-span-1">
          <UINumberInput label="Hasta" v-model="pedidosFilter.hastaAnyo"  />
        </div>
        <div class="col-span-1 md:col-span-3">
          <selectMarca v-model="pedidosFilter.idMarca" :items="marcas" />
        </div>
        <div class="col-span-1 md:col-span-3">
          <selectFabricante
            v-model="pedidosFilter.idFabricante"
            :items="fabricantes"
          />
        </div>
        <div class="col-span-1 md:col-span-2 sm:pt-9">
          <UICheckBox
            v-model="pedidosFilter.enPeriododeEntrega"
            label="En perido de entrega"
          />
        </div>
      </div>
    </template>
  </filterEdit>

  <div class="mb-2">
    <datatable
      :headers="headers"
      :headersSM="['Código', 'Nombre', 'Tiendas']"
      :numbersRows="pedidos.length"
      :isEditing="pedidoIsEditing"
      :isLoading="pedidosIsLoading"
      :isFirstPage="pedidosIsFirstPage"
      :isLastPage="pedidosIsLastPage"
      @newitem="pedidoAdd"
      @next="pedidosGotoNextPage"
      @previous="pedidosGoToPreviousPage"
    >
      <template
        v-for="pedido in pedidosShowed"
        :key="pedido.id"
        class="border-b border-gray-200"
      >
        <tr v-if="pedido.id != pedidoInEdition.id">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ pedido.numeroPedido }}
          </td>
          <td class="px-6 py-4  text-sm text-gray-500">
            {{ pedido.fechaPedido }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 ">
            {{ pedido.coleccion }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500 overflow-x-hidden">
            {{ pedido.cliente }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ pedido.numeroPrendas }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ pedido.importePrendas }}
          </td>
          <td class="px-6 py-4 text-sm text-gray-500">
            {{ pedido.fechasServicio }}
          </td>
          <datatablerowbuttons
            @edititem="pedidoEdit"
            @deleteitem="pedidoDelete"
            :id="pedido.id"
            :isEditing="pedidoIsEditing"
          />
        </tr>
        <tr v-else>
          <td :colspan="headers.length + 1" class="no-columns">
            <rowEdit
              @cancel="pedidoPreviousCancel"
              @save="pedidoPreviousSave"
              :waitingForSave="pedidoWaitingForSave"
            >
              <template v-slot:info>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  pedido
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Introduzca los datos indicados.
                </p>
                <div class="mt-3 mb-8">
                  <tabmenuedition
                    title="datos generales"
                    :active="tabsEdit[0]"
                    :hasAddButton="false"
                    svgdata="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    @showItems="changeActiveTab(0)"
                    :isValid="tabsEditIsValid[0]"
                  />
                  <tabmenuedition
                    v-if="false"
                    title="incidencias"
                    :numbersOfItems="pedidoInEdition.incidencias.length"
                    :active="tabsEdit[1]"
                    :hasAddButton="true"
                    svgdata="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                    @showItems="changeActiveTab(1)"
                    @addItem="pedidoAddIncidencia"
                    :isValid="tabsEditIsValid[1]"
                  />
                </div>
                <p
                  class="cursor-pointer  flex justify-between items-center mb-3 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium hover:bg-gray-200 hover:text-gray-700 focus:outline-none bg-gray-100 text-gray-700"
                >
                  <span class="flex"
                    ><span class="mr-3"
                      ><svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                        ></path>
                        <path
                          d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                        ></path></svg></span
                    ><span
                      ><router-link
                        :to="{
                          name: 'expediciones',
                          params: { idPedido: pedidoInEdition.id },
                        }"
                        target="_blank"
                      >
                        Crear expedición del pedido
                      </router-link></span
                    ></span
                  >
                </p>
              </template>
              <template v-slot>
                <template v-if="tabsEdit[0]">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-1">
                      <UITextInput
                        v-model="pedidoInEdition.numeroPedido"
                        label="N. Pedido"
                        :maxLength="10"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <UIDateInput
                        v-model="pedidoInEdition.fechaPedido"
                        label="Fecha pedido"
                        id="fechapedido"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <selectColeccion
                        :items="colecciones"
                        v-model="pedidoInEdition.idColeccion"
                        :required="true"
                        :showRequired="showValidate"
                        @update:modelValue="initializeValuesFromColeccion()"
                      ></selectColeccion>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <selectCliente
                        v-model="pedidoInEdition.idCliente"
                        :items="clientes"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <selectTienda
                        v-model="pedidoInEdition.idTienda"
                        :items="tiendas"
                      ></selectTienda>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <div class="col-span-1">
                      <UINumberInput
                        v-model="pedidoInEdition.numeroPrendasPrevistas"
                        label="N. Prendas Previstas"
                        id="n_prendas"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-1">
                      <UINumberInput
                        v-model="pedidoInEdition.importePrendasPrevistas"
                        label="Importe Prendas Previstas"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <div class="col-span-1">
                      <UINumberInput
                        v-model="pedidoInEdition.numeroPrendasConfirmadas"
                        label="N. Prendas Confirmadas"
                      />
                    </div>
                    <div class="col-span-1">
                      <UINumberInput
                        v-model="pedidoInEdition.importePrendasConfirmadas"
                        label="Importe Prendas Confirmadas"
                      />
                    </div>
                    <div class="col-span-1">
                      <label
                        for="pedido_prendasExpedidas"
                        class="text-sm font-medium text-gray-700"
                      >
                        N. Prendas expedidas
                      </label>
                      <div class="col-span-1 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="pedido_prendasExpedidas"
                          id="pedido_prendasExpedidas"
                          class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                          v-model="
                            pedidoTotalesExpediciones.totalPrendasExpedidas
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-span-1">
                      <label
                        for="pedido_importeExpedido"
                        class="text-sm font-medium text-gray-700"
                      >
                        Importe expedido
                      </label>
                      <div class="col-span-1 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="pedido_importeExpedido"
                          id="pedido_importeExpedido"
                          class="flex-1 block w-full rounded sm:text-sm bg-gray-100"
                          v-model="
                            pedidoTotalesExpediciones.totalImporteExpedido
                          "
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-1">
                      <UIDateInput
                        v-model="pedidoInEdition.fechaServicioDesde"
                        label="Fecha Servicio desde"
                        :required="true"
                        :showRequired="showValidate"
                      />
                    </div>
                    <div class="col-span-1">
                      <UIDateInput
                        v-model="pedidoInEdition.fechaServicioHasta"
                        label="Fecha Servicio hasta"
                      />
                    </div>
                    <div class="col-span-1">
                      <UINumberInput
                        v-model="pedidoInEdition.numeroDiasVencimiento"
                        label="Nº días vencimiento"
                      />
                    </div>
                  </div>
                  <div class="">
                    <UITextArea
                      v-model="pedidoInEdition.observaciones"
                      label="Observaciones"
                      :maxLength="4000"
                    />
                  </div>
                </template>

                <template v-if="tabsEdit[1]">
                  <UINoItems
                    :numbersOfItems="pedidoInEdition.incidencias.length"
                    @addItem="pedidoAddIncidencia"
                  />
                  <template
                    v-for="incidencia in pedidoInEdition.incidencias"
                    :key="incidencia.idIncidencia"
                  >
                    <div class="grid grid-cols-9 gap-6">
                      <div class="col-span-6 sm:col-span-6 lg:col-span-4">
                        <UITextInput
                          v-model="incidencia.resumen"
                          :id="'resumen_' + incidencia.idIncidencia"
                          label="Resumen"
                          :maxLength="4000"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                        <UIDateInput
                          v-model="incidencia.fechaAlta"
                          :id="'fechaalta' + incidencia.idIncidencia"
                          label="Fecha Alta"
                          :required="true"
                          :showRequired="showValidate"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                        <UIDateInput
                          v-model="incidencia.fechaResolucion"
                          :id="'fecharesolucion' + incidencia.idIncidencia"
                          label="Fecha Resolucion"
                        />
                      </div>
                      <div
                        class="flex items-end col-span-1 sm:col-span-1 lg:col-span-1"
                      >
                        <deleteButtonMulti
                          :id="incidencia.idIncidencia"
                          @deleteMultiItem="pedidoDeleteIncidencia"
                        />
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </rowEdit>
          </td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { usePedidos } from "./usePedidos";
import { useColecciones } from "../colecciones/useColecciones";
import { useClientes } from "../clientes/useClientes";
import { useTiendas } from "../tiendas/useTiendas";
import { useTipoTemporada } from "../temporadas/useTipoTemporada";
import { useFabricantes } from "../fabricantes/useFabricantes";
import { useMarcas } from "../fabricantes/useMarcas";

import rowEdit from "@/components/rowEdit.vue";
import UITextInput from "@/components/UITextInput.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import UITextArea from "@/components/UITextArea.vue";
import UIDateInput from "@/components/UIDateInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import filterEdit from "@/components/filterEdit.vue";
import datatable from "@/components/datatable.vue";
import tabmenuedition from "@/components/tabmenuedition.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";
import selectCliente from "@/components/selectCliente.vue";
import selectColeccion from "@/components/selectColeccion.vue";
import selectTienda from "@/components/selectTienda.vue";
import selectFabricante from "@/components/selectFabricante.vue";
import selectMarca from "@/components/selectMarca.vue";
import selectTipoTemporada from "@/components/selectTipoTemporada.vue";
import UINoItems from "@/components/UINoItems.vue";
import datatablerowbuttons from "@/components/datatablerowbuttons.vue";
import { PedidoFilter } from "@/types/pedidos/PedidoView";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { apiColecciones } from "@/services/colecciones/api";
import { apiFabricantes } from "@/services/fabricantes/api";
import { apiMarcas } from "@/services/marcas/api";

export default defineComponent({
  name: "Pedidos",
  components: {
    rowEdit,
    datatable,
    datatablerowbuttons,
    UITextInput,
    UINumberInput,
    UIDateInput,
    UITextArea,
    UICheckBox,
    tabmenuedition,
    deleteButtonMulti,
    filterEdit,
    selectCliente,
    selectTienda,
    selectColeccion,
    selectFabricante,
    selectMarca,
    selectTipoTemporada,
    UINoItems,
  },
  setup() {
    const headers = [
      "Número",
      "Fecha pedido",
      "Colección",
      "Cliente",
      "Nº prendas",
      "Importe prendas",
      "Fecha servicio",
    ];
    const {
      pedidos,
      pedidosShowed,
      pedidosFilter,
      pedidosSearch,
      pedidosResetFilter,
      pedidoEdit,
      pedidoAdd,
      pedidoDelete,
      pedidoCancel,
      pedidoSave,
      pedidoInEdition,
      pedidosIndex,
      pedidosGotoNextPage,
      pedidosGoToPreviousPage,
      pedidoIsEditing,
      pedidosIsLoading,
      pedidosIsFirstPage,
      pedidosIsLastPage,
      pedidoAddIncidencia,
      pedidoDeleteIncidencia,
      pedidoSuccess,
      pedidoError,
      pedidoErrorText,
      pedidoIsValid,
      pedidoIsValidIncidencias,
      pedidoTotalesExpediciones,
    } = usePedidos();

    const route = useRoute();
    const param = route.params.idCliente;
    const param2 = route.params.numeroPedido;

    console.log("idCliente", param);
    console.log("idTienda", param2);

    if (param != "" && param != "$")
      pedidosFilter.value = {
        idCliente: Number(param),
        soloColeccionesActivas: false,
        enPeriododeEntrega: false,
      } as PedidoFilter;
    else {
      if (param2 != "" && param2 != "$")
        pedidosFilter.value = {
          numeroPedido: param2,
          soloColeccionesActivas: false,
          enPeriododeEntrega: false,
        } as PedidoFilter;
      else {
        pedidosResetFilter();
      }
    }

    const { colecciones } = useColecciones();
    const { clientes } = useClientes();
    const { tiendas } = useTiendas();
    const { tiposTemporada } = useTipoTemporada();
    const { fabricantes } = useFabricantes();
    const { marcas } = useMarcas();

    const isFiltering = ref(false);
    const showValidate = ref(false);
    const showListados = ref(false);

    const tabsEdit = ref([true, false]);
    const tabsEditIsValid = ref([true, true]);

    function updateValidationTabs() {
      tabsEditIsValid.value[0] = pedidoIsValid(pedidoInEdition.value);

      tabsEditIsValid.value[1] = pedidoIsValidIncidencias(
        pedidoInEdition.value.incidencias
      );
    }

    function changeActiveTab(tabIndex: number) {
      if (showValidate.value) updateValidationTabs();

      for (let i = 0; i < tabsEdit.value.length; i++) tabsEdit.value[i] = false;
      tabsEdit.value[tabIndex] = true;
    }

    const pedidoWaitingForSave = ref(false);

    function pedidoPreviousSearch() {
      pedidosSearch();
    }

    function pedidoPreviousSave() {
      console.log(pedidoInEdition.value);
      updateValidationTabs();

      showValidate.value = !tabsEditIsValid.value.reduce(
        (acumulator, currentValue) => {
          return acumulator && currentValue;
        }
      );

      if (showValidate.value) return;

      pedidoWaitingForSave.value = true;
      pedidoSave().then(() => {
        pedidoWaitingForSave.value = false;
        changeActiveTab(0);
      });
    }

    function pedidoPreviousCancel() {
      showValidate.value = false;
      tabsEditIsValid.value = [true, true];
      changeActiveTab(0);
      pedidoCancel();
    }

    function showFilter() {
      isFiltering.value = !isFiltering.value;
    }

    async function initializeValuesFromColeccion() {
      const coleccion = await apiColecciones.getColeccion(
        pedidoInEdition.value.idColeccion
      );

      if (coleccion) {
        if (coleccion.fechaServicioDesde)
          pedidoInEdition.value.fechaServicioDesde = coleccion.fechaServicioDesde.substr(
            0,
            10
          );
        else pedidoInEdition.value.fechaServicioDesde = undefined;

        if (coleccion.fechaServicioHasta)
          pedidoInEdition.value.fechaServicioHasta = coleccion.fechaServicioHasta.substr(
            0,
            10
          );
        else pedidoInEdition.value.fechaServicioHasta = undefined;

        const marca = await apiMarcas.getMarca(coleccion.idMarca);
        if (marca) {
          const fabricante = await apiFabricantes.getFabricante(
            marca.idFabricante
          );
          if (fabricante) {
            pedidoInEdition.value.numeroDiasVencimiento =
              fabricante.diasVtoPago;
          }
        }
      }
    }

    watch([pedidoError, pedidoSuccess], ([pedidoError, pedidoSuccess]) => {
      if (pedidoSuccess) {
        createToast(
          {
            title: "Éxito 👏 ",
            description: "La operación se ha completado con éxito",
          },
          {
            transition: "bounce",
            type: "success",
            position: "top-center",
            hideProgressBar: true,
            toastBackgroundColor: "#048c5a",
          }
        );
      }

      if (pedidoError) {
        createToast(
          {
            title: "Error 👎 ",
            description: pedidoErrorText.value,
          },
          {
            transition: "bounce",
            type: "danger",
            position: "top-center",
            hideProgressBar: true,
          }
        );
      }
    });

    return {
      headers,
      pedidos,
      pedidosFilter,
      pedidoPreviousSearch,
      pedidosResetFilter,
      pedidoEdit,
      pedidoAdd,
      pedidoDelete,
      pedidoCancel,
      pedidoSave,
      pedidoInEdition,
      pedidosIndex,
      pedidosGotoNextPage,
      pedidosGoToPreviousPage,
      pedidoIsEditing,
      pedidosIsLoading,
      pedidosIsFirstPage,
      pedidosIsLastPage,
      pedidoAddIncidencia,
      pedidoDeleteIncidencia,
      pedidoPreviousCancel,
      pedidoPreviousSave,
      pedidoWaitingForSave,
      pedidoTotalesExpediciones,

      pedidosShowed,

      tabsEdit,
      changeActiveTab,
      tabsEditIsValid,

      isFiltering,
      showFilter,

      showListados,

      colecciones,
      clientes,
      tiposTemporada,
      fabricantes,
      tiendas,
      marcas,

      showValidate,
      initializeValuesFromColeccion,
    };
  },
});
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  tr {
    display: block;
  }
  td {
    display: block;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30%;
  }

  td:nth-of-type(1):before {
    content: "Número";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  td.no-columns:nth-of-type(1):before {
    content: "";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  td:nth-of-type(2):before {
    content: "F. Pedido";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(3):before {
    content: "Colección";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(4):before {
    content: "Cliente";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(5):before {
    content: "N. prendas";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(6):before {
    content: "Importe";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }
  td:nth-of-type(7):before {
    content: "F. Servicio";
    color: black;
    margin-left: 5px;
    margin-top: 1rem;
    font-weight: 700;
  }

  .no-columns {
    padding-left: 0;
  }
}
</style>
