import { computed, onMounted, ref, Ref } from "vue";
import { apiPoblaciones } from "@/services/poblaciones/api";
import { CodigoPostal } from "@/types/poblaciones/CodigoPostal";
import { usePagination } from "@/components/usePagination";
import { isValidObject } from "../utilidades/useValidations";

export function useCodigosPostales() {
  const idNotEditItem = -999;
  const items: Ref<CodigoPostal[]> = ref([]);
  const editingItem = ref({ id: idNotEditItem } as CodigoPostal);
  const {
    currentRow,
    numbersOfRowsPerPage,
    nextPage,
    previousPage,
    isFirstPage,
    isLastPage,
    setTotalNumberOfRows
  } = usePagination();

  const isLoading = ref(false);
  const success = ref(false);
  const error = ref(false);
  const errorText = ref("");

  const idPoblacion = ref(0);

  const codigosPoblacion = computed(() => {
    setTotalNumberOfRows(
      items.value.filter(item => item.idPoblacion == idPoblacion.value).length
    );
    return items.value.filter(item => item.idPoblacion == idPoblacion.value);
  });

  onMounted(async () => {
    isLoading.value = true;
    items.value = await apiPoblaciones.getCodigosPostales();
    setTotalNumberOfRows(codigosPoblacion.value.length);
    isLoading.value = false;
  });

  function editItem(id: number) {
    const found = items.value.find(item => item.id === id);
    if (found == undefined) return;
    editingItem.value = {
      id: found.id,
      codigo: found.codigo,
      idPoblacion: found.idPoblacion
    } as CodigoPostal;
  }

  async function addItem() {
    items.value.splice(currentRow.value, 0, {
      id: 0,
      idPoblacion: idPoblacion.value
    } as CodigoPostal);
    setTotalNumberOfRows(codigosPoblacion.value.length);
    editItem(0);
  }

  async function deleteItem(id: number) {
    success.value = false;
    error.value = false;
    await apiPoblaciones
      .deleteCodigoPostal(id)
      .then(() => {
        success.value = true;
        //console.log("error");
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (!error.value) items.value = items.value.filter(item => item.id != id);
    setTotalNumberOfRows(codigosPoblacion.value.length);
  }

  function cancelEdit() {
    if (editingItem.value.id === 0) items.value.splice(currentRow.value, 1);
    editingItem.value = { id: idNotEditItem } as CodigoPostal;
    setTotalNumberOfRows(codigosPoblacion.value.length);
  }

  async function saveEdit() {
    success.value = false;
    error.value = false;
    let id = 0;

    await apiPoblaciones
      .saveCodigoPostal(editingItem.value)
      .then(function(res) {
        success.value = true;
        id = res.id;
      })
      .catch(function(data) {
        error.value = true;
        errorText.value = data;
      });
    if (error.value) return;

    const found = items.value.find(item => item.id === editingItem.value.id);
    if (found == undefined) return;
    if (found.id == 0) found.id = id;
    found.codigo = editingItem.value.codigo;
    editingItem.value.id = idNotEditItem;
  }

  const isEditing = computed(() => {
    return editingItem.value.id != idNotEditItem;
  });

  const itemsShowed = computed(() => {
    return codigosPoblacion.value.slice(
      currentRow.value,
      currentRow.value + numbersOfRowsPerPage
    );
  });

  function isValid(item: CodigoPostal): boolean {
    return isValidObject(item, ["codigo", "idPoblacion"]);
  }

  return {
    codigosPostales: items,
    codigosPostalesPoblacion: codigosPoblacion,
    codigosPostalesShowed: itemsShowed,
    codigoPostalEdit: editItem,
    codigoPostalAdd: addItem,
    codigoPostalDelete: deleteItem,
    codigoPostalCancel: cancelEdit,
    codigoPostalSave: saveEdit,
    codigoPostalInEdition: editingItem,
    codigoPostalesGotoNextPage: nextPage,
    codigoPostalesGoToPreviousPage: previousPage,
    codigoPostalIsEditing: isEditing,
    codigosPostalesIsLoading: isLoading,
    codigosPostalesIsFirstPage: isFirstPage,
    codigosPostalesIsLastPage: isLastPage,
    codigoPostalIdPoblacion: idPoblacion,
    codigoPostalSuccess: success,
    codigoPostalError: error,
    codigoPostalErrorText: errorText,
    codigoPostalIsValid: isValid
  };
}
