<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 lg:col-span-3">
      <UITextInput
        v-model="myDescripcion"
        label="Descripción"
        :id="'descripcion' + id"
        :maxLength="50"
      />
    </div>
    <div class="col-span-12 lg:col-span-4">
      <UITextInput
        v-model="myDomicilio"
        label="Domicilio"
        :id="'domicilio' + id"
        :maxLength="50"
        :required="true"
        :showRequired="showRequired"
      />
    </div>
    <div class="col-span-12 lg:col-span-5">
      <div class="w-full relative">
        <div v-show="false">
          <UINumberInput v-model="myIdCodigoPostal" label="CodigoPostal" />
        </div>
        <label for="" class="block text-sm font-medium text-gray-700">
          Población
        </label>
        <div
          class="flex justify-between w-full shadow-sm sm:text-sm border-gray-300 rounded-md border pt-2 pl-3 pr-2 pb-1 mt-1"
          @click.prevent="showSearch"
        >
          <span class="overflow-hidden w-full h-6">{{
            poblacionShowed()
          }}</span>
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <template v-if="isSearching">
          <div class="z-10 bg-white w-full absolute">
            <div>
              <input
                type="text"
                v-model="poblacionFilter"
                ref="myinput"
                placeholder="Escriba al menos, 2 caracteres o números"
              />
            </div>
            <div>
              <ul
                class="h-36 overflow-y-scroll border-gray-300 border-b border-l border-r"
              >
                <li
                  class="border-gray-300 border-b py-1 px-2 hover:bg-gray-200 cursor-pointer sm:text-sm"
                  v-for="pob in search(poblacionFilter)"
                  :key="pob.id"
                  :value="pob.id"
                  @click="
                    selectPoblacion(
                      id,
                      pob.id,
                      pob.codigo + ' ' + pob.poblacion
                    )
                  "
                >
                  {{ pob.codigo }} {{ pob.poblacion }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div class="sm:flex justify-between">
    <div class="sm:flex">
      <div class="flex items-start mt-1">
        <UICheckBox v-model="myCorrespondencia" label="Correspondencia" />
      </div>
      <div v-if="fiscalEditable" class="flex items-start mt-1">
        <UICheckBox v-model="myFiscal" label="Fiscal" />
      </div>
      <div v-if="entregaEditable" class="flex items-start mt-1">
        <UICheckBox v-model="myEntrega" label="Entrega" />
      </div>
    </div>

    <div class="">
      <deleteButtonMulti @deleteMultiItem="$emit('deleteItem')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, watchEffect } from "vue";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

export default defineComponent({
  name: "dirEdit",
  components: {
    UITextInput,
    UICheckBox,
    UINumberInput,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    descripcion: {
      type: String,
      required: false
    },
    domicilio: {
      type: String,
      required: false
    },
    correspondencia: {
      type: Boolean,
      required: false
    },
    fiscal: {
      type: Boolean,
      required: false
    },
    entrega: {
      type: Boolean,
      required: false
    },
    idCodigoPostal: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    fiscalEditable: {
      type: Boolean,
      default: true
    },
    entregaEditable: {
      type: Boolean,
      default: true
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:domicilio",
    "update:correspondencia",
    "update:fiscal",
    "update:entrega",
    "update:idCodigoPostal",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const isSearching = ref(false);
    const poblacionFilter = ref("");
    const idCodigoPostalSelected = ref(0);
    const poblacion = ref("");
    const myinput = ref(null);

    const myDescripcion = ref(props.descripcion);
    const myDomicilio = ref(props.domicilio);
    const myIdCodigoPostal = ref(props.idCodigoPostal);
    const myCorrespondencia = ref(props.correspondencia);
    const myFiscal = ref(props.fiscal);
    const myEntrega = ref(props.entrega);

    function showSearch() {
      poblacionFilter.value = "";
      isSearching.value = !isSearching.value;
    }

    function selectPoblacion(
      idDireccion: number,
      idCodigoPostal: number,
      visibleText: string
    ) {
      isSearching.value = !isSearching.value;
      if (idCodigoPostal != -1) {
        myIdCodigoPostal.value = idCodigoPostal;
        poblacion.value = visibleText;
      }
    }

    function search(filter: string): CodigoPostalPoblacionView[] {
      const filterOk = filter.toUpperCase();
      if (filter.length < 2) return [];
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      return poblaciones.filter(
        item =>
          item.poblacion.indexOf(filterOk) > -1 ||
          (item.codigo != null ? item.codigo.indexOf(filterOk) > -1 : false)
      );
    }

    function poblacionShowed(): string {
      if (props.idCodigoPostal == undefined) return "seleccione una población";
      if (poblacion.value != "") return poblacion.value;
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      const resultado = poblaciones.find(x => x.id == props.idCodigoPostal);
      if (resultado == undefined) return "Cargando ...";
      return resultado.codigo + " " + resultado.poblacion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    watch(
      [
        myDescripcion,
        myDomicilio,
        myCorrespondencia,
        myFiscal,
        myEntrega,
        myIdCodigoPostal
      ],
      ([
        myDescripcion,
        myDomicilio,
        myCorrespondencia,
        myFiscal,
        myEntrega,
        myIdCodigoPostal
      ]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:domicilio", myDomicilio);
        emit("update:correspondencia", myCorrespondencia);
        emit("update:fiscal", myFiscal);
        emit("update:entrega", myEntrega);
        emit("update:idCodigoPostal", myIdCodigoPostal);
      }
    );

    return {
      showSearch,
      isSearching,
      poblacionFilter,
      idCodigoPostalSelected,
      search,
      poblacionShowed,
      selectPoblacion,
      myinput,
      myCorrespondencia,
      myFiscal,
      myEntrega,
      myDescripcion,
      myDomicilio,
      myIdCodigoPostal
    };
  }
});
</script>
