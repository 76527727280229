
import { defineComponent, ref, watch, watchEffect } from "vue";
import { CodigoPostalPoblacionView } from "@/types/poblaciones/CodigoPostalPoblacionView";
import UITextInput from "@/components/UITextInput.vue";
import UICheckBox from "@/components/UICheckBox.vue";
import UINumberInput from "@/components/UINumberInput.vue";
import deleteButtonMulti from "@/components/deleteButtonMulti.vue";

export default defineComponent({
  name: "dirEdit",
  components: {
    UITextInput,
    UICheckBox,
    UINumberInput,
    deleteButtonMulti
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    descripcion: {
      type: String,
      required: false
    },
    domicilio: {
      type: String,
      required: false
    },
    correspondencia: {
      type: Boolean,
      required: false
    },
    fiscal: {
      type: Boolean,
      required: false
    },
    entrega: {
      type: Boolean,
      required: false
    },
    idCodigoPostal: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    fiscalEditable: {
      type: Boolean,
      default: true
    },
    entregaEditable: {
      type: Boolean,
      default: true
    },
    showRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "update:descripcion",
    "update:domicilio",
    "update:correspondencia",
    "update:fiscal",
    "update:entrega",
    "update:idCodigoPostal",
    "deleteItem"
  ],

  setup(props, { emit }) {
    const isSearching = ref(false);
    const poblacionFilter = ref("");
    const idCodigoPostalSelected = ref(0);
    const poblacion = ref("");
    const myinput = ref(null);

    const myDescripcion = ref(props.descripcion);
    const myDomicilio = ref(props.domicilio);
    const myIdCodigoPostal = ref(props.idCodigoPostal);
    const myCorrespondencia = ref(props.correspondencia);
    const myFiscal = ref(props.fiscal);
    const myEntrega = ref(props.entrega);

    function showSearch() {
      poblacionFilter.value = "";
      isSearching.value = !isSearching.value;
    }

    function selectPoblacion(
      idDireccion: number,
      idCodigoPostal: number,
      visibleText: string
    ) {
      isSearching.value = !isSearching.value;
      if (idCodigoPostal != -1) {
        myIdCodigoPostal.value = idCodigoPostal;
        poblacion.value = visibleText;
      }
    }

    function search(filter: string): CodigoPostalPoblacionView[] {
      const filterOk = filter.toUpperCase();
      if (filter.length < 2) return [];
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      return poblaciones.filter(
        item =>
          item.poblacion.indexOf(filterOk) > -1 ||
          (item.codigo != null ? item.codigo.indexOf(filterOk) > -1 : false)
      );
    }

    function poblacionShowed(): string {
      if (props.idCodigoPostal == undefined) return "seleccione una población";
      if (poblacion.value != "") return poblacion.value;
      const poblaciones = props.items as CodigoPostalPoblacionView[];
      const resultado = poblaciones.find(x => x.id == props.idCodigoPostal);
      if (resultado == undefined) return "Cargando ...";
      return resultado.codigo + " " + resultado.poblacion;
    }

    watchEffect(
      () => {
        if (myinput.value != null) {
          ((myinput.value as unknown) as HTMLInputElement).focus();
        }
      },
      {
        flush: "post"
      }
    );

    watch(
      [
        myDescripcion,
        myDomicilio,
        myCorrespondencia,
        myFiscal,
        myEntrega,
        myIdCodigoPostal
      ],
      ([
        myDescripcion,
        myDomicilio,
        myCorrespondencia,
        myFiscal,
        myEntrega,
        myIdCodigoPostal
      ]) => {
        emit("update:descripcion", myDescripcion);
        emit("update:domicilio", myDomicilio);
        emit("update:correspondencia", myCorrespondencia);
        emit("update:fiscal", myFiscal);
        emit("update:entrega", myEntrega);
        emit("update:idCodigoPostal", myIdCodigoPostal);
      }
    );

    return {
      showSearch,
      isSearching,
      poblacionFilter,
      idCodigoPostalSelected,
      search,
      poblacionShowed,
      selectPoblacion,
      myinput,
      myCorrespondencia,
      myFiscal,
      myEntrega,
      myDescripcion,
      myDomicilio,
      myIdCodigoPostal
    };
  }
});
